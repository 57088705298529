import React, {useEffect, useState} from "react"
import { Confirm, useNotify } from "react-admin"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from "@material-ui/core"

import createPhoneCall from "./createPhoneCall"
import getHttpsCallable from "../../../utils/getHttpsCallable"
import {find} from "lodash"
import { PhoneCallResult } from "../../../lib/enums"
import { db } from "../../../firebase"

const sendEmailTemplate = getHttpsCallable("admin-sendEmailTemplate")
const sendWhatsAppTemplate = getHttpsCallable("admin-sendWhatsappTemplate")
const getTemplates = getHttpsCallable("admin-getTemplates")
const updateCase = getHttpsCallable("admin-cases-updateCase")

interface CallAcceptedDialogParams {
  open: boolean
  handleClose: (ticketRef?: any) => void
  ticket: Ticket
}

export default function CallAcceptedDialog (
  { open, handleClose, ticket }: CallAcceptedDialogParams,
) {
  const notify = useNotify()
  const [notes, setNotes] = useState("")
  const [sendingEmailEcCard, setSendingEmailEcCard] = useState(false)
  const [emailSendResultEcCard, setEmailSendResultEcCard] =
    useState<SendEmailTemplateResult | null>(null)
  const [sendingEmailPoa, setSendingEmailPoa] =
    useState(false)
  const [emailSendResultPoa, setEmailSendResultPoa] =
    useState<SendEmailTemplateResult | null>(null)
  const [sendingEmailFalseFalse, setSendingEmailFalseFalse] =
    useState(false)
  const [emailSendResultFalseFalse, setEmailSendResultFalseFalse] =
    useState<SendEmailTemplateResult | null>(null)
  const [sendingWhatsAppEcCard, setSendingWhatsAppEcCard] =
    useState(false)
  const [WhatsAppSendResultEcCard, setWhatsAppSendResultEcCard] =
    useState<{sent: boolean} | null>(null)
  const [sendingWhatsAppPoa, setSendingWhatsAppPoa] =
    useState(false)
  const [WhatsAppSendResultPoa, setWhatsAppSendResultPoa] =
    useState<{sent: boolean} | null>(null)
  const [sendingWhatsAppFalseFalse, setSendingWhatsAppFalseFalse] =
    useState(false)
  const [WhatsAppSendResultFalseFalse, setWhatsAppSendResultFalseFalse] =
    useState<{sent: boolean} | null>(null)
  const [whatsAppTemplates, setWhatsAppTemplates] = useState([])
  const [emailTemplates, setEmailTemplates] = useState([])
  const [fetchData, setFetchData] = useState(true)
  const [cancelingCase, setCancelingCase] = useState<boolean>(false)
  const [cancelCaseDialog, setCancelCaseDialog] =
    useState<boolean>(false)
  const [claimActionStatus, setClaimActionStatus] =
    useState<string | undefined>(undefined)

  useEffect(() => {
    async function getTemplate () {
      const dataWhatsappTemplate = await getTemplates("whatsapp")
      setWhatsAppTemplates(dataWhatsappTemplate?.data)

      const dataEmailTemplate = await getTemplates("email")
      setEmailTemplates(dataEmailTemplate?.data)
    }

    async function getClaimActionStatus () {
      const claims = await db
        .collectionGroup("claims")
        .where("id", "==", ticket.claimId)
        .get()

      if (!claims.size || !claims.docs[0]) {
        console.error(`Claim ${ticket.claimId} not found`)
      }

      const claim = claims.docs[0].data() as Partial<Claim>

      setClaimActionStatus(claim.claimActionStatus)
    }

    if (fetchData) {
      getTemplate()
      getClaimActionStatus()
      setFetchData(false)
    }
  }, [fetchData])

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          Leave any notes here about the phone call or do any
          of the following actions.
        </DialogContentText>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          disabled={Boolean(sendingEmailEcCard || emailSendResultEcCard?.sent)}
          style={{marginBottom: "15px"}}
          onClick={async () => {
            if (emailSendResultEcCard) {
              return
            }

            setSendingEmailEcCard(true)

            const selectedTemplate: any = find(
              emailTemplates,
              (t: Template) =>
                t.userDefinedName === "BGFollowUpPhoneCallNachweis",
            )

            const result = await sendEmailTemplate({
              claimIds: [ticket.claimId],
              templateName: selectedTemplate?.name,
            })
            setEmailSendResultEcCard(result.data[0])
            setSendingEmailEcCard(false)
          }}
        >
          {!emailSendResultEcCard ? (sendingEmailEcCard
            ? <CircularProgress size={20} />
            : "Send \"BG: Phonecall Nachweis\" Email")
            : (emailSendResultEcCard?.sent
              ? "\"BG: Phonecall Nachweis\" Email Sent"
              : "\"BG: Phonecall Nachweis\" Email Failed to Send")
          }
        </Button>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          disabled={Boolean(sendingEmailPoa || emailSendResultPoa?.sent)}
          style={{marginBottom: "15px"}}
          onClick={async () => {
            if (emailSendResultPoa) {
              return
            }

            setSendingEmailPoa(true)

            const selectedTemplate: any = find(
              emailTemplates,
              (t: Template) =>
                t.userDefinedName === "BGFollowUpPhoneCallVollmacht",
            )

            const result = await sendEmailTemplate({
              claimIds: [ticket.claimId],
              templateName: selectedTemplate?.name,
            })
            setEmailSendResultPoa(result.data[0])
            setSendingEmailPoa(false)
          }}
        >
          {!emailSendResultPoa ? (sendingEmailPoa
            ? <CircularProgress size={20} />
            : "Send \"BG: Phonecall Vollmacht\" Email")
            : (emailSendResultPoa?.sent
              ? "\"BG: Phonecall Vollmacht\" Email Sent"
              : "\"BG: Phonecall Vollmacht\" Email Failed to Send")
          }
        </Button>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          disabled={Boolean(
            sendingEmailFalseFalse || emailSendResultFalseFalse?.sent,
          )}
          style={{marginBottom: "15px"}}
          onClick={async () => {
            if (emailSendResultFalseFalse) {
              return
            }

            setSendingEmailFalseFalse(true)

            const selectedTemplate: any = find(
              emailTemplates,
              (t: Template) =>
                t.userDefinedName === "BGFollowUpPhoneCallFalseFalse",
            )

            const result = await sendEmailTemplate({
              claimIds: [ticket.claimId],
              templateName: selectedTemplate?.name,
            })
            setEmailSendResultFalseFalse(result.data[0])
            setSendingEmailFalseFalse(false)
          }}
        >
          {!emailSendResultFalseFalse ? (sendingEmailFalseFalse
            ? <CircularProgress size={20} />
            : "Send \"BG: Phonecall False False\" Email")
            : (emailSendResultFalseFalse?.sent
              ? "\"BG: Phonecall False False\" Email Sent"
              : "\"BG: Phonecall False False\" Email Failed to Send")
          }
        </Button>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          disabled={
            Boolean(sendingWhatsAppEcCard || WhatsAppSendResultEcCard?.sent)
          }
          style={{marginBottom: "15px"}}
          onClick={async () => {
            if (WhatsAppSendResultEcCard) {
              return
            }

            setSendingWhatsAppEcCard(true)
            const selectedTemplate: any = find(
              whatsAppTemplates,
              (t: Template) =>
                t.name === "bg_phonecall_nachweis",
            )

            const result = await sendWhatsAppTemplate({
              claimIds: [ticket.claimId],
              template: selectedTemplate?.name,
              provider: selectedTemplate?.provider,
              templateLocale: selectedTemplate?.locale,
              placeholders: selectedTemplate?.placeholders ?? [],
            })

            setWhatsAppSendResultEcCard(result.data[0])
            setSendingWhatsAppEcCard(false)
          }}
        >
          {!WhatsAppSendResultEcCard ? (sendingWhatsAppEcCard
            ? <CircularProgress size={20} />
            : "Send \"BG: Phonecall Nachweis\" WhatsApp")
            : (WhatsAppSendResultEcCard?.sent
              ? "\"BG: Phonecall Nachweis\" WhatsApp Sent"
              : "\"BG: Phonecall Nachweis\" WhatsApp Failed to Send")
          }
        </Button>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          disabled={Boolean(sendingWhatsAppPoa || WhatsAppSendResultPoa?.sent)}
          style={{marginBottom: "15px"}}
          onClick={async () => {
            if (WhatsAppSendResultPoa) {
              return
            }

            setSendingWhatsAppPoa(true)
            const selectedTemplate: any = find(
              whatsAppTemplates,
              (t: Template) =>
                t.name === "bg_phonecall_vollmacht",
            )

            const result = await sendWhatsAppTemplate({
              claimIds: [ticket.claimId],
              template: selectedTemplate?.name,
              provider: selectedTemplate?.provider,
              templateLocale: selectedTemplate?.locale,
              placeholders: selectedTemplate?.placeholders ?? [],
            })

            setWhatsAppSendResultPoa(result.data[0])
            setSendingWhatsAppPoa(false)
          }}
        >
          {!WhatsAppSendResultPoa ? (sendingWhatsAppPoa
            ? <CircularProgress size={20} />
            : "Send \"BG: Phonecall Vollmacht\" WhatsApp")
            : (WhatsAppSendResultPoa?.sent
              ? "\"BG: Phonecall Vollmacht\" WhatsApp Sent"
              : "\"BG: Phonecall Vollmacht\" WhatsApp Failed to Send")
          }
        </Button>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          disabled={
            Boolean(
              sendingWhatsAppFalseFalse || WhatsAppSendResultFalseFalse?.sent,
            )
          }
          style={{marginBottom: "15px"}}
          onClick={async () => {
            if (WhatsAppSendResultFalseFalse) {
              return
            }

            setSendingWhatsAppFalseFalse(true)
            const selectedTemplate: any = find(
              whatsAppTemplates,
              (t: Template) =>
                t.name === "bg_phonecall_false",
            )

            const result = await sendWhatsAppTemplate({
              claimIds: [ticket.claimId],
              template: selectedTemplate?.name,
              provider: selectedTemplate?.provider,
              templateLocale: selectedTemplate?.locale,
              placeholders: selectedTemplate?.placeholders ?? [],
            })

            setWhatsAppSendResultFalseFalse(result.data[0])
            setSendingWhatsAppFalseFalse(false)
          }}
        >
          {!WhatsAppSendResultFalseFalse ? (sendingWhatsAppFalseFalse
            ? <CircularProgress size={20} />
            : "Send \"BG: Phonecall False False\" WhatsApp")
            : (WhatsAppSendResultFalseFalse?.sent
              ? "\"BG: Phonecall False False\" WhatsApp Sent"
              : "\"BG: Phonecall False False\" WhatsApp Failed to Send")
          }
        </Button>
        <>
          <Button
            fullWidth
            color='primary'
            variant='outlined'
            disabled={cancelingCase ||
            claimActionStatus === "dead" ||
            claimActionStatus === undefined}
            style={{marginBottom: "15px", color: "red", borderColor: "red"}}
            onClick={ async () => {
              setCancelingCase(true)
              try {
                await updateCase({
                  claimId: ticket.claimId,
                  updateData: {
                    workflowInstance: {
                      id: 34,
                    },
                  },
                })
                setClaimActionStatus("dead")
                notify("Claim updated successfully", "success")
              }
              catch (error) {
                console.error(error)
                if (error instanceof Error) {
                  notify(error.message, "warning")
                }
              }
              setCancelingCase(false)
            }}
          >
            {cancelingCase
              ? <CircularProgress size={20} />
              : "Set Canceled by Client"
            }
          </Button>
          <Confirm
            isOpen={cancelCaseDialog}
            title="Set Canceled by Client?"
            content={""}
            confirm="Yes"
            cancel="No"
            onClose={() => setCancelCaseDialog(false)}
            onConfirm={ async () => {
              setCancelCaseDialog(false)
              setCancelingCase(true)
              try {
                await updateCase({
                  claimId: ticket.claimId,
                  updateData: {
                    workflowInstance: {
                      id: 34,
                    },
                  },
                })
                setClaimActionStatus("dead")
                notify("Claim updated successfully", "success")
              }
              catch (error) {
                console.error(error)
                if (error instanceof Error) {
                  notify(error.message, "warning")
                }
              }
              setCancelingCase(false)
            }}
          />
        </>
      </DialogContent>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Call Notes'
          fullWidth
          onChange={(e) => setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={async () => {
            try {
              await createPhoneCall({
                claimId: ticket.claimId,
                callResult: PhoneCallResult.ANSWERED,
                phoneNumberValid: true,
                phoneNumber: ticket.claimPhoneNumber,
                callNotes: notes,
              })
              handleClose()
            }
            catch (error) {
              notify(`${error}`, "warning")
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
