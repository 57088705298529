import {
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
  ListItem,
  ListItemAvatar,
  Chip,
  List,
  ListItemText,
  Checkbox,
  ListItemIcon,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import EmailIcon from "@material-ui/icons/Email"
import useGdprFiles from "../../hooks/useGdprFiles"
import {GdprUploadFileInput} from "./index"
import { SendEmailButton } from "../SendEmailButton"
import { useEffect, useState } from "react"
import { db } from "../../firebase"
import { Show, TextField } from "react-admin"
import moment from "moment"
import { get, sortBy, truncate } from "lodash"
import getHttpsCallable from "../../utils/getHttpsCallable"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import WhatsappIcon from "../WhatsappIcon"
import ScheduleIcon from "@material-ui/icons/Schedule"


interface EmailListAvatarParams {
  email: Email
  link: string
}

const notifTypeEnum = {
  email: "Email",
  whatsApp: "WhatsApp",
}

const useStyles = makeStyles(theme => ({
  centeredList: {
    alignItems: "center",
  },
  inline: {
    display: "inline",
  },
  padded: {
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
  },
  checkBox: {
    marginRight: "14px",
  },
  margin: {
    marginRight: "1.5em",
  },
}))

function formatDate (timestamp: number) {
  const d = new Date(timestamp * 1000)

  const day = String(d.getDate())
  let month = String(d.getMonth() + 1)
  const year = String(d.getFullYear())
  let hour =  String(d.getHours())
  let minute =  String(d.getMinutes())
  let second =  String(d.getSeconds())

  if (month.toString().length < 2) month = "0" + month
  if (hour.toString().length < 2) hour = "0" + hour
  if (minute.toString().length < 2) minute = "0" + minute
  if (second.toString().length < 2) second = "0" + second

  return day + "." + month + "." + year + 
  " " + hour + ":" + minute + ":" + second
}

interface WhatsappListItemParams {
  whatsapp: Whatsapp
  [x: string]: any
}

function WhatsappListItem ({ whatsapp, ...props }: WhatsappListItemParams) {
  const classes = useStyles()
  return (
    <>
      <ListItem {...props} dense style={{paddingLeft: "0"}}>
        <ListItemAvatar className={classes.padded} style={{minWidth: "46px"}}>
          {
            whatsapp?.rawProviderData?.errorCode !== null
              ? <ErrorOutlineIcon/>
              : <WhatsappIcon />
          }
        </ListItemAvatar>
        <ListItemText
          primary={
            truncate(whatsapp.template, { length: 60 }) +
            (whatsapp?.rawProviderData?.errorCode !== null
              ? " / ERROR CODE: " + whatsapp?.rawProviderData?.errorCode : "")
          }
          secondary={
            <Typography
              variant='caption'
              color='textSecondary'
            >
              {moment(whatsapp.timestamp.toDate()).format("LLL")}
            </Typography>
          }
        />
        <Chip label={whatsapp.operationType} />
      </ListItem>
    </>
  )
}

function EmailListAvatar ({ email, link }: EmailListAvatarParams) {
  const classes = useStyles()
  const badgeContent = "M"

  return (
    <ListItemAvatar className={classes.padded} style={{minWidth: "46px"}}>
      <EmailIcon />
    </ListItemAvatar>
  )
}

function EmailListItem (
  { email, ...props }: { email: Email, [x: string]: any },
) {
  const time = moment(email.timestamp.toDate()).format("LLL")
  const zendeskLink = get(email, "metadata.zendeskTicketLink") &&
    `https://${get(email, "metadata.zendeskTicketLink")}`

  return (
    <>
      <ListItem {...props} dense style={{paddingLeft: "0"}}>
        <EmailListAvatar email={email} link={zendeskLink} />
        <ListItemText
          primary={email.template || email.subject}
          secondary={
            <>
              <Typography
                variant='caption'
                color='textSecondary'
              >{`Sent by:${email.sender} - ${time}`}
              </Typography>
              {email.body && <br />}
              {email.body && `${truncate(email.body, { length: 200 })}`}
            </>
          }
        />
        <Chip label={email.state} />
      </ListItem>
    </>
  )
}

function useCommunications ({ claimId = null }) {
  const [commsArray, setCommsArray] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!claimId) {
      console.error("Claim not found")
      return
    }

    async function getUserComms () {
      const emails = await db
        .collection("emails")
        .where("claimId", "==", claimId)
        .where("claimType", "==", "gdpr")
        .get()
      const whatsapp = await db
        .collection("whatsapp")
        .where("claimId", "==", claimId)
        .where("claimType", "==", "gdpr")
        .get()

      const emailList = emails.docs
        .map(s => ({
          ...s.data(),
          type: "email",
        }))
      let whatsappList = whatsapp.docs
        .map(s => ({
          ...s.data(),
          type: "whatsapp",
          timestamp: s.data().timestamp,
        }))

      whatsappList = await whatsappList.filter((message) => {
        // @ts-expect-error
        return message.provider !== null || (
          // @ts-expect-error
          message?.rawProviderData?.status === "queued" &&
          // 86400 seconds == 1 day
          message?.timestamp._seconds > Math.floor(Date.now() / 1000) - 86400
        )
      })

      const comms = emailList
        .concat(whatsappList)

      // @ts-expect-error
      setCommsArray(comms)
      setLoading(false)
    }
    getUserComms()
  }, [claimId])

  return [commsArray, loading]
}

export default function GdprCommunications (
  props: {
    record?: {
      userId: string
      id: string
      lawFirm: string
      lawFirmCaseId: string
      customFieldValues: any
      notificationQueue: any
    },
  },
) {
  const gdprCaseId = props?.record?.id
  const userId = props?.record?.userId
  const [user, setUser] = useState<Record<string, any> | undefined>()
  const [email, setEmail] = useState<Record<string, any>[]>([])
  const [loadingContent, setLoading] = useState<boolean>(true)
  const [notificationQueueMap, setNotificationQueueMap] =
    useState<Record<string, any>[]>([])

  if (!userId) {
    return <Card>
      <CardContent>User id must be specified</CardContent>
    </Card>
  }
  if (!gdprCaseId) {
    return <Card>
      <CardContent>Gdpr case id must be specified</CardContent>
    </Card>
  }

  const [comms, loading] = useCommunications({
    // @ts-expect-error
    claimId: props?.record?.id || "",
  })

  const [queue, setQueue] =
    useState<Record<string, any> | undefined>(props?.record?.notificationQueue)

  useEffect(() => {
    async function mapNotification (
      claimId: string,
      queueRecord: Record<string, any>,
      commsRecord: Record<string, any>[],
    ) {

      try {
        const getNotificationPass =
          await getHttpsCallable("admin-getNotificationException")

        for (const type of Object.keys(queueRecord)) {
          for (const templateName of Object.keys(queueRecord[type])) {
            const res = await getNotificationPass({
              claimId: claimId,
              exceptions: queueRecord[type][templateName].exceptions,
            })

            Object.assign(queueRecord[type][templateName], {
              exceptionFailed: res.data && !res.data.error
                ? res.data.exception_failed
                : true,
            })
          }
        }
      }
      catch (err) {
        console.error(err)
      }

      const mappedQueue =
        Object.keys(queueRecord).length > 0
          ? Object.keys(queueRecord).reduce<Record<string, any>[]>(
            (acc, type) => {

              Object.keys(queueRecord[type]).forEach(async (templateName) => {

                acc.push(
                  {
                    type: type,
                    timestamp: queueRecord[type][templateName].scheduledTime,
                    templateName: templateName,
                    scheduled: true,
                    exceptionFailed:
                      queueRecord[type][templateName].exceptionFailed,
                  },
                )
              })
              return acc
            }, [])
          :      []

      if (commsRecord && Array.isArray(commsRecord)) {
        const commsCopy = [...commsRecord]
        const sorted = sortBy(
          commsCopy
            // @ts-ignore
            .concat(mappedQueue),
          (item) => {
            let filter
            if (typeof item.timestamp === "object" ) {
              filter = moment(item.timestamp.toDate()).unix()
            }
            else {
              filter = moment(item.timestamp).unix()
            }
            return filter
          },
        )

        setNotificationQueueMap(sorted)
      }

    }

    mapNotification(
      gdprCaseId || "",
      queue || {},
      // @ts-expect-error
      comms || [],
    )
  }, [gdprCaseId, queue, comms])

  useEffect(() => {
    async function getUser (userId: string) {
      const userEntry = await db.collection("users")
        .where("id", "==", userId)
        .get()

      if (!userEntry.docs) {
        return
      }

      setUser(userEntry.docs[0]?.data())
    }

    async function getEmail (claimId: string) {
      const emailEntry = await db.collection("emails")
        .where("claimId", "==", claimId)
        .orderBy("timestamp", "desc")
        .get()

      if (!emailEntry.docs) {
        return
      }

      setEmail(emailEntry.docs)
    }

    if (gdprCaseId) {
      getEmail(gdprCaseId)
      setLoading(false)
    }

    if (userId) {
      getUser(userId)
      setLoading(false)
    }
  }, [gdprCaseId, userId])

  if (loadingContent) {
    return <>
      <p>Loading ...</p>
      <p>(Please hard reload the website if this seems stuck)</p>
    </>
  }

  return (
    <div style={{ padding: 10 }}>
      <SendEmailButton 
        email={user?.email} 
        claimId={gdprCaseId} 
        claimType="gdpr" 
      />
      <List>
        {
          notificationQueueMap.length > 0 && 
          notificationQueueMap.map((notifItem, index) => {
            if (notifItem.scheduled) {
              return (
                <ListItem key={index} dense style={{paddingLeft: "0"}}>
                  <ListItemText
                    primary={
                    // @ts-expect-error
                      truncate(`Scheduled: ${notifTypeEnum[notifItem.type]
                      } 
                  "${notifItem.templateName}"`,
                      { length: 60 },
                      )
                    }
                    secondary={
                      <Typography
                        variant='caption'
                        color='textSecondary'
                      >
                        {`Scheduled Date:
                      ${moment(notifItem.timestamp)
                  .format("LLL")}`}
                      </Typography>
                    }
                  />
                  <a
                    style={{
                      marginRight: "1rem",
                      fontSize: "small",
                    }}
                  >
                    {
                      notifItem.exceptionFailed
                        ? "Exception met"
                        : "Exception not met"
                    }
                  </a>
                  <ListItemIcon>
                    {
                      notifItem.exceptionFailed
                        ? <ErrorOutlineIcon/>
                        : <ScheduleIcon/>
                    }
                  </ListItemIcon>
                  <Chip label={"scheduled"}
                  />
                </ListItem>
              )
            }
            else {
              switch (notifItem.type) {
                case "email":
                  return <EmailListItem
                    // @ts-expect-error
                    email={notifItem} key={index}
                  />
                case "whatsapp":
                  return <WhatsappListItem
                    // @ts-expect-error
                    whatsapp={notifItem} key={index}
                    notificationQueue={queue}
                    claimId={gdprCaseId}
                  />
                default:
                  return null
              }
            }
          })
        }
      </List>
    </div>
  )
}
