import React from "react"
import Dialog from "@material-ui/core/Dialog"
import {useDataProvider} from "react-admin"
import PhoneCallForm from "../PhoneCall/PhoneCallForm"



interface MakePhoneCallDialogProps {
  onClose: (val: string) => void
  selectedValue: string
  open: boolean
  claim: Claim
}

export default function MakePhoneCallDialog (props: MakePhoneCallDialogProps) {
  const dataProvider = useDataProvider()

  // const classes = useStyles()
  const { onClose, selectedValue, open, claim } = props

  function handleClose () {
    onClose(selectedValue)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <PhoneCallForm
        claimId={claim.id}
        phoneNumber={claim.phoneNumber}
        resource='phoneCalls'
        save={async (values: string[]) => {
          handleClose()
          await dataProvider.create("phoneCalls", { data: values })
        }}
      />
    </Dialog>
  )
}
