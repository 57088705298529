import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const directionMap = new Map()
directionMap.set('ltr', 'row')
directionMap.set('rtl', 'row-reverse')
directionMap.set('ttb', 'column')
directionMap.set('btt', 'column-reverse')

const wrapMap = new Map()
wrapMap.set(true, 'wrap')
wrapMap.set(false, 'nowrap')
wrapMap.set('reverse', 'wrap-reverse')

const justifyMap = new Map()
justifyMap.set('start', 'flex-start')
justifyMap.set('end', 'flex-end')
justifyMap.set('center', 'center')
justifyMap.set('between', 'space-between')
justifyMap.set('around', 'space-around')

const alignItemsMap = new Map()
alignItemsMap.set('start', 'flex-start')
alignItemsMap.set('end', 'flex-end')
alignItemsMap.set('center', 'center')
alignItemsMap.set('baseline', 'baseline')
alignItemsMap.set('stretch', 'stretch')

const Div = styled.div`
  display: flex;
  ${({ flexDirection }: any) =>
    flexDirection && `flex-direction: ${flexDirection};`}
  ${({ flexWrap }: any) =>
    flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ justifyContent }: any) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }: any) =>
    alignItems && `align-items: ${alignItems};`}
  ${({ alignContent }: any) =>
    alignContent && `align-content: ${alignContent};`}
  ${({ width }: any) =>
    width && 'width: 100%;'}
`

const Flex = (props: Record<string, any>) => {
  const {
    direction,
    wrap,
    center,
    fullWidth,
    ...otherProps
  } = props

  let {
    justify,
    alignItems,
    alignContent,
  } = props

  if (center === true) {
    justify = 'center'
    alignItems = 'center'
  }

  const flexDirection = directionMap.get(direction)
  const flexWrap = wrapMap.get(wrap)
  const justifyContent = justifyMap.get(justify)
  alignItems = alignItemsMap.get(alignItems)
  alignContent = justifyMap.get(alignContent)

  const divStyle = {
    flexDirection,
    flexWrap,
    justifyContent,
    alignItems,
    alignContent,
  }

  if (fullWidth === true) {
    // @ts-expect-error
    divStyle.width = '100%'
  }

  return (
    // @ts-expect-error
    <Div {...divStyle} {...otherProps} />
  )
}

Flex.propTypes = {
  direction: PropTypes.oneOf(
    ['ltr', 'rtl', 'ttb', 'btt'],
  ),
  wrap: PropTypes.oneOf(
    [true, false, 'reverse'],
  ),
  justify: PropTypes.oneOf(
    ['start', 'end', 'center', 'between', 'around'],
  ),
  alignItems: PropTypes.oneOf(
    ['start', 'end', 'center', 'baseline', 'stretch'],
  ),
  alignContent: PropTypes.oneOf(
    ['start', 'end', 'center', 'between', 'around'],
  ),
}

Flex.defaultProps = {
  direction: 'ltr',
}

export default Flex
