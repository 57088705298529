import React from "react"
import { Grid } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"


export default function FullPageLoader () {
  return (
    <Grid
      container
      style={{ width: "100vw", height: "100vh" }}
      justifyContent='center'
      alignItems='center'
    >
      <CircularProgress />
    </Grid>
  )
}
