import moment from "moment"
// eslint-disable-next-line import/no-unresolved
import { Timestamp } from "@firebase/firestore-types"

import firebase from "../firebase"
import { TicketAssignee } from "../constants/enums"


function ticketRequiresAttention (ticket: Ticket): boolean {
  if (!ticket) {
    console.warn('No ticket was passed to "ticketRequiresAttention"')
    return false
  }

  const ticketComplete = ticket.assignedGroup === TicketAssignee.COMPLETE
  let snoozeTimeEnd: Timestamp | undefined
  let snoozeTimeEndDate: Date | undefined

  const isDate = Object.prototype.toString
    .call(ticket.snoozeTimeEnd) === "[object Date]"

  if (isDate) {
    // TODO: Don't forcefully overwrite type
    snoozeTimeEndDate = (ticket.snoozeTimeEnd as unknown) as Date
  }

  if (ticket.snoozeTimeEnd?.seconds > 0) {
    snoozeTimeEnd = new firebase.firestore.Timestamp(
      ticket.snoozeTimeEnd.seconds,
      ticket.snoozeTimeEnd.nanoseconds,
    )
  }

  // Cast to Date if Firebase timestamp
  if (snoozeTimeEnd && snoozeTimeEnd.toDate) {
    snoozeTimeEndDate = snoozeTimeEnd.toDate()
  }


  // Completed tickets don't require attention
  if (ticketComplete) {
    return false
  }

  // If ticket is not snoozed, it requires attention
  if (!snoozeTimeEndDate) {
    return true
  }

  // If ticket is finished snoozing, it requires attention
  if (moment().isSameOrAfter(moment(snoozeTimeEndDate))) {
    return true
  }

  return false
}

export default ticketRequiresAttention
