import { blueGrey, green, lightGreen, red,
  yellow, deepOrange, deepPurple } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"
import requiresAttention from "../../utils/ticketRequiresAttention"


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  ticketListItem: ({ ticketData }: TicketListItemParams) => {
    const bgColor = requiresAttention(ticketData || {})
      ? lightGreen[500]
      : blueGrey[400]
    return {
      marginTop: 2,
      backgroundColor: bgColor,
    }
  },
  header: {
    textAlign: "center",
    textDecoration: "none",
  },
  attentionCount: {
    backgroundColor: lightGreen[500],
  },
  snoozedCount: {
    backgroundColor: blueGrey[400],
  },
  onHoldIndicator: {
    backgroundColor: yellow[800],
  },
  deadCount: {
    backgroundColor: red[300],
  },
  holdCount: {
    backgroundColor: yellow[800],
  },
  sentCount: {
    backgroundColor: green[400],
  },
  tagAvatar: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  phoneAvatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}))

export default useStyles
