import React from "react"

import {
  useDelete,
  useRecordContext,
  Toolbar,
  SaveButton,
  useRedirect,
  CRUD_DELETE,
  useNotify,
} from "react-admin"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import DeleteIcon from "@material-ui/icons/Delete"
import IconCancel from "@material-ui/icons/Cancel"
import { red } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core"
import {db} from "../firebase"

const useStyles = makeStyles(theme => ({
  toolbarStyle: {
    display: "flex",
    justifyContent: "space-between",
  },
}))
  
const buttonStyles = makeStyles(theme => ({
  deleteIcon: {
    color: red[500],
  },
  cancelIcon: {
    color: "#009be5",
  },
}))

export interface DeleteDialogProps{
    open:boolean,
    cancel:()=>void,
    deletion:()=>void,
  }
  
export interface DeleteButtonProps{
    onClick:()=>void,
  }

function DeleteButton (props:DeleteButtonProps) {
  const classes = buttonStyles()
  const {onClick} = props
  
  return <Button onClick={onClick}
    className={classes.deleteIcon}
    startIcon={<DeleteIcon />}
  >
    Delete
  </Button>
} 

function DeleteDialog (props:DeleteDialogProps) {
  const {open, cancel, deletion} = props
  const classes = buttonStyles()
  
  return (
    <Dialog 
      fullWidth
      open={open}
      aria-label="Are you sure?"
    >
      <DialogTitle id="delete-dialog-title">
          Are you sure you want to delete?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-text">
            Corresponding tickets will be deleted as well.
        </DialogContentText>
        <DialogActions>
          <Button onClick={deletion}
            className={classes.deleteIcon}
            startIcon={<DeleteIcon />}
          >
              Delete
          </Button>
          <Button 
            startIcon={<IconCancel />}
            onClick={cancel}
            className={classes.cancelIcon}
          >
              Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default function ClaimDeleteBar (props: Record<string, unknown>) {
  const classes = useStyles()
  
  const [open, setOpen] = React.useState(false)
  const [deleteOne, {error}] = useDelete()
  const {path} = props
  const record = useRecordContext()
  const redirect = useRedirect()
  const notify = useNotify()
 
  
  function handleModal () {
    setOpen(!open)
  }
  
  function handleDelete () {
    setOpen(!open)

    // get database phoneCalls and delete if exists
    const query = db.collection("phoneCalls")
      .where("claimId", "==", record.id)

    query.get().then((documents) => {
      documents.forEach((doc) => {
        if (!doc.exists) {
          return
        }

        deleteOne("phoneCalls",
          doc.id, doc,
          {
            action: CRUD_DELETE,
          },
        )
      })
    })
    deleteOne("tickets",
      `ticket_${record.id}`, record,
      {
        action: CRUD_DELETE,
        onFailure: error => {
          notify(
            typeof error === "string"
              ? error
              : error.message || "ra.notification.http_error",
            {
              type: "warning",
              messageArgs: {
                _:
                                    typeof error === "string"
                                      ? error
                                      : error && error.message
                                        ? error.message
                                        : undefined,
              },
            },
          )
          setOpen(false)
        },
      })

    deleteOne("claims", 
      record.id, record,
      {
        action: CRUD_DELETE,
        onSuccess: () => {
          notify("Claim and ticket deleted")
          redirect("list", `/${path}`)
        },
        onFailure: error => {
          notify(
            typeof error === "string"
              ? error
              : error.message || "ra.notification.http_error",
            {
              type: "warning",
              messageArgs: {
                _:
                                    typeof error === "string"
                                      ? error
                                      : error && error.message
                                        ? error.message
                                        : undefined,
              },
            },
          )
          setOpen(false)
        } })
    
  }
    
  return (
    <Toolbar {...props}
      className={classes.toolbarStyle}>
      <SaveButton label="Save"/>
      <DeleteButton onClick={handleModal}/>
      <DeleteDialog
        open={open}
        cancel={handleModal}
        deletion={handleDelete}/>
    </Toolbar>
  )
}
