import { useEffect, useState } from "react"
import { db } from "../firebase"

export type CustomFieldType = {
  id: string
  name: string
  label: string
  type:  "number" | "currency" | "text" | "date" | "boolean"
}

export default function () {
  const [customFields, setCustomFields] = useState<CustomFieldType[]>([])
  const [loading, setLoading] = useState(true)

  async function loadCustomFields () {
    const customFields = await db.collection("customFields").get()
    const customFieldsRef = await (customFields).docs

    if (customFieldsRef.length > 0) {
      setCustomFields(
        customFieldsRef.map(customField => ({
          id: customField.get("id"),
          name: customField.get("name"),
          label: customField.get("label"),
          type: customField.get("type"),
        })),
      )
    }
    else {
      setCustomFields([])
    }

    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    loadCustomFields()
  }, [])

  return {
    customFields, // document snapshot
    loading, // false once first snap returned
  }
}
