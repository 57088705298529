import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import red from "@material-ui/core/colors/red"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import Flex from "../../../lib/components/Flex"
import { sortBy, get } from "lodash"
import React, { useState } from "react"
import { db } from "../../../firebase"
import moment from "moment"
import { Popover } from "@material-ui/core"


const useStyles = makeStyles({
  deleteButton: {
    color: red[500],
  },
  strikeout: (props) => ({
    textDecoration: get(props, "subTask.completed", false)
      ? "line-through"
      : "none",
  }),
})


async function addSubTask (
  { subTasksRef, description }: { subTasksRef: any, description: string },
) {
  const result = await subTasksRef.add({
    description,
    completed: false,
    ticketId: subTasksRef.parent.id,
  })
  await result.update({ id: result.id })
  console.info(`Added subtask with id "${result.id}"`)
}


async function updateSubTask (
  { subTaskRef, data }: { subTaskRef: any, data: { [key: string]: any } },
) {
  await subTaskRef.update({
    ...data,
  })
  console.info(`Updated subTask with id "${subTaskRef.id}"`)
}


async function deleteSubTask ({ subTaskRef }: { subTaskRef: any }) {
  await subTaskRef.delete()
  console.info(`Deleted subTask with id "${subTaskRef.id}"`)
}


interface AddSubTaskDialogParams {
  handleClose: () => void
  open: boolean
  subTasksRef: any
}

function AddSubTaskDialog (
  { handleClose, open, subTasksRef }: AddSubTaskDialogParams,
) {
  const [description, setDescription] = useState("")
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Sub Task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sub Tasks should be short descriptions
            and contain only one action each.
            For example 'Get user to accept T&C's'
            or 'Page 1 of KV document was blurry and unreadable -
            request a re-upload'
          </DialogContentText>
          <TextField
            fullWidth
            placeholder='Description of the task'
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              addSubTask({ subTasksRef, description })
              handleClose()
            }}
            color='primary'
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


interface EditSubTaskDialogParams {
  handleClose: () => void;
  open: boolean;
  subTaskRef: any;
  subTaskData: any;
}

function EditSubTaskDialog (
  { handleClose, open, subTaskRef, subTaskData }: EditSubTaskDialogParams,
) {
  const [description, setDescription] = useState(subTaskData.description)
  const classes = useStyles()
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Sub Task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sub Tasks should be short descriptions
            and contain only one action each.
            For example 'Get user to accept T&C's'
            or 'Page 1 of KV document was blurry and unreadable -
            request a re-upload'
          </DialogContentText>
          <TextField
            fullWidth
            placeholder='Description of the task'
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Flex fullWidth>
            <Button
              className={classes.deleteButton}
              startIcon={<DeleteIcon />}
              onClick={() => {
                deleteSubTask({ subTaskRef })
                handleClose()
              }}
            >
              Delete
            </Button>
          </Flex>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              updateSubTask({
                subTaskRef, data: { description },
              })
              handleClose()
            }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


function SubTask (
  { subTask, claimId }: { subTask: SubTaskType, claimId: string },
) {
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles({ subTask })

  function handleClick (event: any) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose () {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  if (!subTask.id || !subTask.ticketId) {
    return null
  }
  const subTaskRef = db.doc(
    `tickets/ticket_${claimId}/subTasks/${subTask.id}`,
  )

  let subTaskDate = ""
  if (subTask.createdAt) {
    subTaskDate = moment(subTask.createdAt.toDate()).format("LLL")
  }
   
  return (

    <>
      <ListItem key={subTask.id} dense button>
        <ListItemIcon>
          <Checkbox
            edge='start'
            checked={subTask.completed}
            tabIndex={-1}
            disableRipple
            onChange={(e) => {
              updateSubTask({
                subTaskRef,
                data: { completed: e.target.checked },
              })
            }}
          />
        </ListItemIcon>
        <ListItemText
          onClick={handleClick}
          primary={subTask.description}
          className={classes.strikeout}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}>
          <Typography style={{padding: "10px"}}>{subTaskDate}</Typography>
        </Popover>
        <ListItemSecondaryAction>
          <IconButton
            edge='end'
            color='primary'
            onClick={() => {
              setEditDialogOpen(true)
            }}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <EditSubTaskDialog
        open={editDialogOpen}
        handleClose={() => setEditDialogOpen(false)}
        subTaskData={subTask}
        subTaskRef={subTaskRef}
      />
    </>
  )
}



interface SubTasksParams {
  subTasks: SubTaskType[]
  subTasksRef: any
  claimId: string
  [x: string]: any
}

export default function SubTasks (
  { subTasks, subTasksRef, claimId, ...props }: SubTasksParams,
) {
  const [addDialog, setAddDialogOpen] = useState(false)
  const sortedTasks = sortBy(
    subTasks,
    ({ createdAt }) => createdAt && createdAt.toDate(),
  )
  return (
    <>
      <AddSubTaskDialog
        subTasksRef={subTasksRef}
        open={addDialog}
        handleClose={() => setAddDialogOpen(false)}
      />
      <List {...props}>
        <Typography variant='subtitle2'>Sub Tasks</Typography>
        {sortedTasks.map((subTask, index) =>
          <SubTask claimId={claimId} key={index} subTask={subTask} />,
        )}
        <Flex justify='end'>
          <Button
            variant='outlined'
            size='small'
            startIcon={<AddIcon />}
            onClick={() => setAddDialogOpen(true)}
          >
            Add
          </Button>
        </Flex>
      </List>
    </>
  )
}
