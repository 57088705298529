import {List, ListItem, ListItemText} from "@material-ui/core"
import { useRecordContext } from "react-admin"

export default function MUIList (props: { source: string, label: string }) {
  const record = useRecordContext(props)
  return (
    <>
      <h3>{props.label}</h3>
      {
        record[props.source]?.map((item: string) =>
          <ListItem  key={item}>
            <ListItemText primary={item}/>
          </ListItem>,
        )
      }
    </>
  )
}
