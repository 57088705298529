import React from "react"
import {Create, SimpleForm} from "react-admin"
import PhoneCallForm from "./PhoneCallForm"


export default function PhoneCallCreate (props: Record<string, unknown>) {
  return (
    <Create {...props}>
      <PhoneCallForm />
    </Create>
  )
}
