import React from "react"
import {
  BooleanField,
  BooleanInput,
  DateField,
  DateInput,
  Edit,
  FormTab,
  NumberField,
  NumberInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  SimpleFormIterator,
  FormDataConsumer,
  ArrayInput,
  FunctionField,
} from "react-admin"
import { makeStyles } from "@material-ui/core"
import ClaimDeleteBar from "../ClaimDeleteBar"

import TicketPanel from "./TicketPanel"
import INSURANCE_OPTIONS from "../../lib/constants/insurance-providers"


const useStyles = makeStyles(theme => ({
  lastField: {
    marginBottom: theme.spacing(3),
  },
}))

const insuranceChoices = INSURANCE_OPTIONS
  .map(opt => ({ id: opt.value, name: opt.label }))

const OPTS = {
  YES: "yes",
  NO: "no",
  DONTKNOW: "dont-know",
}

const yesNoUnsureChoices = [
  { id: OPTS.YES, name: OPTS.YES },
  { id: OPTS.NO, name: OPTS.NO },
  { id: OPTS.DONTKNOW, name: OPTS.DONTKNOW },
]

const yesNoChoices = [
  { id: OPTS.YES, name: OPTS.YES },
  { id: OPTS.NO, name: OPTS.NO },
]

const yesNoNullChoices = [
  { id: null, name: "NULL" },
  ...yesNoChoices,
]

function Aside ({ record }: { record?: Claim }) {
  if (!record) {
    return null
  }

  return (
    <TicketPanel
      userId={record.userId}
      claimId={record.id}
      claim={record}
    />
  )
}

const dateFieldOpts = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}

function renderUtmParamsField (obj: any) {
  return (
    <ArrayInput source={"utmParams"} label="UTM parameters">
      <SimpleFormIterator disableReordering>
        <FormDataConsumer>
          {({ getSource = () => {} }) => {
            return (
              <div>
                <TextInput
                  label="Source"
                  source={getSource("source") || ""}
                  style={{
                    marginRight: "1rem",
                  }}
                />
                <TextInput
                  label="Value"
                  source={getSource("value") || ""}
                  style={{
                    marginRight: "1rem",
                  }}
                />
              </div>
            )
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export default function ClaimEdit (props: Record<string, unknown>) {
  const classes = useStyles()

  return (
    <Edit
      {...props}
      aside={<Aside />}
      transform={data => {
        if (data.transmittedToLawfirmAt === undefined) {
          data.transmittedToLawfirmAt = null
          data._transmittedToLawfirmAt = null
        }

        return data
      }}
    >
      <TabbedForm redirect='show' sanitizeEmptyValues={false}
        toolbar={<ClaimDeleteBar path="claims"/>}
      >
        <FormTab label='Summary'>
          {/* <BooleanInput label='Lead' source='lead' addLabel={true} /> */}
          <SelectInput
            source='lawFirm'
            choices={[
              { id: "AILTS", name: "AILTS" },
              { id: "BRC", name: "BRC" },
              { id: "LEO", name: "LEO" },
              { id: "KAP", name: "KAP" },
              { id: "Karimi", name: "Karimi" },
            ]}
            allowEmpty={true}
          />
          <TextInput source='leadSource' />
          <DateField source='createdTime' options={{...dateFieldOpts}} />
          <DateField source='updatedTime' options={{...dateFieldOpts}} />
          {/* <DateField*/}
          {/*  source='transmittedToLawfirmAt'*/}
          {/*  label='Transmitted to Lawfirm At'*/}
          {/* /> */}
          {/* <BooleanInput source='applicationApproved'
            label='Application Approved (Renders Success Page)' /> */}
          {/* <DateField source='applicationApprovedTime'
            options={{...dateFieldOpts}} /> */}
          <TextField label='Claim ID' source='id' />
          <TextField label='User Email' source='userEmail' />
          {/* <BooleanInput label='Claim Eligible' source='isEligible' /> */}
          {/* <BooleanInput source='acceptedTermsAndConditions'
            label='Accepted T&Cs' /> */}
          {/* <BooleanInput source='brcMultipleCars' 
            label='BRC Multiple Cars' /> */}
          {/* <BooleanInput source='highValueInsuranceProvider'
            label='High Value Insurance' /> */}
          <SelectInput
            source='claimActionStatus'
            choices={[
              { id: "active", name: "Active" },
              { id: "dead", name: "Dead" },
              { id: "onHold", name: "On Hold" },
              { id: "finished", name: "Finished" },
            ]}
          />
          <BooleanField label='Uploaded Purchase Contract'
            source='hasUploadedPurchaseContract' addLabel={true} />
          <BooleanField label='Uploaded Sales Contract'
            source='hasUploadedSalesContract' addLabel={true} />
          <BooleanField label='Uploaded Finance Contract'
            source='hasUploadedFinanceContract' addLabel={true} />
          {/* <TextInput source='notes' /> */}
          <DateInput source='transmittedToLawfirmAt' />
          <FunctionField
            label="UTM Parameters"
            source="utmParams"
            render={(obj: any) => {
              return <>
                <table>
                  {renderUtmParamsField(obj)}
                </table>
              </>
            }}
          />
        </FormTab>
        <FormTab label='Contact Information'>
          <TextInput label='Claim First Name'
            source='firstName' />
          <TextInput label='Claim Last Name'
            source='lastName' />
          {/* <TextInput label='Contract First Name'
            source='purchaseContractFirstName' />
          <TextInput label='Contract Last Name'
            source='purchaseContractLastName' /> */}
          <TextInput label='Contract Company'
            source='purchaseContractCompanyName' />
          <TextInput source='address' />
          <TextInput source='city' />
          <TextInput source='postcode' />
          {/* <BooleanInput source='carIsTop' label='TOP' /> */}
          <TextInput source='phoneNumber' />
          {/* <SelectInput
            source='whatsappContactedNumber'
            choices={[
              { id: null, name: "None" },
              { id: "0", name: "WA 0 Carl  442" },
              { id: "1", name: "WA 1 Follow Up  975" },
              { id: "2", name: "WA 2 Follow Up 982" },
            ]}
          />
          <TextInput
            label='IBAN'
            source='iban'
            className={classes.lastField}
          /> */}
        </FormTab>
        <FormTab label='Car Info'>
          <SelectInput
            source='carRecallStatus'
            choices={[
              { id: "mandatory", name: "Mandatory" },
              { id: "voluntary", name: "Voluntary" },
              { id: "not_recalled", name: "Not Recalled" },
            ]}
          />
          <TextInput source='carBrand' />
          <TextInput source='carModel' />
          <SelectInput
            source='carMotorDisplacement'
            choices={[
              { id: null, name: "NULL" },
              { id: "1.4", name: "1.4" },
              { id: "1.6", name: "1.6" },
              { id: "1.8", name: "1.8" },
              { id: "2.0", name: "2.0" },
              { id: "2.1", name: "2.1" },
              { id: "2.2", name: "2.2" },
              { id: "2.7", name: "2.7" },
              { id: "3.0", name: "3.0" },
              { id: "4.2", name: "4.2" },
            ]}
          />
          <TextInput source='carType' />
          <TextInput source='carVariant' />
          <TextInput source='carYear' />
          <SelectInput
            label='Has Car been sold'
            source='hasCarBeenSold'
            choices={yesNoChoices}
          />
          <NumberInput source='currentMileage' />
          <NumberInput source='mileageAtPurchase' />
          <TextInput source='mileageAtSale' />
          <NumberInput source='carPurchasePrice' />
          <TextInput source='carPurchaseEntity' />
          <DateInput source='carPurchaseDate' />
          <TextInput source='carPurchaseYear' />
          <NumberInput source='carSalePrice' />
          <TextInput source='vinNumber' />
          <SelectInput
            source='vinCheck'
            choices={[
              { id: null, name: "NULL" },
              { id: "nicht überprüfbar",
                name: "nicht überprüfbar",
              },
              { id: "laut Website betroffen",
                name: "laut Website betroffen",
              },
              { id: "laut Website freiwillige Maßnahme",
                name: "laut Website freiwillige Maßnahme",
              },
              { id: "laut Website nicht betroffen",
                name: "laut Website nicht betroffen",
              },
              { id: "laut Website geupdated",
                name: "laut Website geupdated",
              },
            ]}
          />
          <TextInput source='carAuthorizationCode' />
          <TextInput source='motorType' />
          {/* <SelectInput */}
          {/*  source='motorType'*/}
          {/*  choices={[*/}
          {/*    { id: null, name: "NULL" },*/}
          {/*    { id: "VW27E4", name: "VW27E4" },*/}
          {/*    { id: "VW30E4", name: "VW30E4" },*/}
          {/*    { id: "EA188", name: "EA188" },*/}
          {/*    { id: "EA189", name: "EA189" },*/}
          {/*    { id: "EA288", name: "EA288" },*/}
          {/*    { id: "EA896", name: "EA896" },*/}
          {/*    { id: "EA896-evo", name: "EA896-evo" },*/}
          {/*    { id: "EA897", name: "EA897" },*/}
          {/*    { id: "EA897-evo", name: "EA897-evo" },*/}
          {/*    { id: "OM607", name: "OM607" },*/}
          {/*    { id: "OM622", name: "OM622" },*/}
          {/*    { id: "OM626", name: "OM626" },*/}
          {/*    { id: "OM629", name: "OM629" },*/}
          {/*    { id: "OM640", name: "OM640" },*/}
          {/*    { id: "OM642", name: "OM642" },*/}
          {/*    { id: "OM646", name: "OM646" },*/}
          {/*    { id: "OM651", name: "OM651" },*/}
          {/*    { id: "OM654", name: "OM654" },*/}
          {/*    { id: "N47D20", name: "N47D20" },*/}
          {/*    { id: "B47D20", name: "B47D20" },*/}
          {/*    { id: "Other", name: "Other" },*/}
          {/*  ]}*/}
          {/* /> */}
          <TextInput
            source='carMotorVariant'
            label='Car Motor Variant (MKB)'
          />
          <TextInput
            source='carMotorKilowatt'
            label='Car kilowatt Output (kW)'
          />
          <SelectInput
            source='euroRating'
            choices={[
              { id: "euro4", name: "EURO4" },
              { id: "euro5", name: "EURO5" },
              { id: "euro6", name: "EURO6" },
              { id: "euro6d", name: "EURO6D" },
              { id: "euro6dtemp", name: "EURO6DTEMP" },
            ]}
          />
          <NumberInput
            source='remainingValueClaimback'
            label='Remaining Value (Claimback)'
          />
          <DateInput
            source='dateRemainingValueClaimback'
            label='Date Remaining Value (Claimback)'
          />
          <NumberInput
            source='remainingValueClaimant'
            label='Remaining Value (Claimant)'
          />
          <DateInput
            source='dateRemainingValueClaimant'
            label='Date Remaining Value (Claimant)'
          />
        </FormTab>
        <FormTab label='Claim Info'>
          <BooleanInput source='claimPassesMinimum' />
          <NumberInput
            source='claimValue'
            label='Old calculated award (Do not use)'
          />
          <NumberField source='returnCarReward' />
          <NumberField source='keepCarReward' />
          <NumberField source='maxReward' />
          <TextInput source='returnCarInCaseOfSuccess' />
          <SelectInput
            label='Participated in Class Action'
            source='participatedInClassAction'
            choices={yesNoUnsureChoices}
          />
          <SelectInput
            label='Has car been financed'
            source='hasCarBeenFinanced'
            choices={yesNoNullChoices}
          />
          <SelectInput
            label='Is financing paid off'
            source='isFinancingPaidOff'
            choices={yesNoChoices}
          />
          <SelectInput
            label='Has car been sold'
            source='hasCarBeenSold'
            choices={yesNoChoices}
          />
          <TextInput label='KAP Email URL' source='kapEmailUrl' />
          <BooleanInput label='EC card is available' source='hasEcCard' />
          <BooleanInput
            label='Power of attorney document is available'
            source='hasPowerOfAttorneyDoc'
          />
        </FormTab>
        <FormTab label='RSV Info'>
          <BooleanInput source='claimVerifiedExternally' />
          <SelectInput
            label='Legal Expenses Insurance'
            source='legalExpensesInsurance'
            choices={yesNoUnsureChoices}
          />
          <SelectInput source='insuranceProvider' choices={insuranceChoices} />
          <TextInput source='customInsuranceProvider' />
          <SelectInput
            source='legalExpensesInsuranceAtPurchase'
            choices={yesNoChoices}
          />
          <SelectInput
            source='legalExpensesInsurancePolicyAsCompany'
            choices={yesNoChoices}
          />
          <TextInput source='legalExpensesInsurancePolicyNumber' />
          <TextInput source='legalExpensesInsurancePolicyholderCompanyName' />
          <TextInput source='legalExpensesInsurancePolicyholderFirstName' />
          <TextInput source='legalExpensesInsurancePolicyholderLastName' />
        </FormTab>
        {/* <FormTab label='Contract Info'>
          <BooleanInput source='kvCorrect' />
          <SelectInput
            label='KV Incorrect Document Upload'
            source='kvWrongDocReason'
            choices={[
              { id: null, name: "Deselect" },
              { id: "financeContract", name: "Uploaded a finance contract" },
              { id: "carRegistration", name: "Uploaded a car registration" },
              { id: "carPhoto", name: "Uploaded a photo of the car" },
              { id: "other", name: "Uploaded something else" },
            ]}
          />
          <BooleanInput source='kvReadable' />
          <BooleanInput source='kvDataComplete' />
          <BooleanInput source='carPurchasedBeforeRecall' />
          <SelectInput
            lable='Diesel Warning'
            source='dieselWarning'
            choices={[
              { id: null, name: "Deselect" },
              { id: "yes", name: "Yes" },
              { id: "no", name: "No" },
            ]}
          />
        </FormTab> */}
      </TabbedForm>
      {/* <TicketPanel claimId={props.id} /> */}
    </Edit>
  )
}
