import Button from "@material-ui/core/Button"
import red from "@material-ui/core/colors/red"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import Flex from "../../../lib/components/Flex"
import {get, omit} from "lodash"
import React, {useEffect, useState} from "react"


const useStyles = makeStyles({
  deleteButton: {
    color: red[500],
  },
  strikeout: (props) => ({
    textDecoration: get(props, "note.completed", false)
      ? "line-through"
      : "none",
  }),
})


async function addNote (
  { ticket, ticketRef, note }: { ticket: any, ticketRef: any, note: string },
) {
  if (ticket.notes && ticket.notes.length > 0) {
    ticket.notes.push(note)
  }
  else {
    ticket.notes = [note]
  }

  ticketRef.set(
    { ...omit(ticket, "update"), notes: ticket.notes },
  )
}


async function updateNote (
  { ticketRef, ticket }: { ticketRef: any, ticket: any },
) {
  ticketRef.set(
    { ...omit(ticket, "update"), notes: ticket.notes },
  )
}


async function deleteNote ({ ticketRef, ticket, index }:
  { ticketRef: any, ticket: any, index: number }) {
  ticket.notes.splice(index, 1)
  ticketRef.set(
    { ...omit(ticket, "update"), notes: ticket.notes },
  )
}


interface AddNoteDialogParams {
  handleClose: () => void
  open: boolean
  ticketRef: any
  ticket: any
}

function AddNoteDialog (
  { handleClose, open, ticketRef, ticket }: AddNoteDialogParams,
) {
  const [note, setNote] = useState("")
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Add Note</DialogTitle>
        <DialogContent>
          <TextField
            placeholder='Note'
            value={note}
            onChange={e => setNote(e.target.value)}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              addNote({ ticket, ticketRef, note })
              setNote("")
              handleClose()
            }}
            color='primary'
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


interface EditNoteParams {
  handleClose: () => void;
  open: boolean;
  index: number;
  ticketRef: any;
  ticket: any;
}

function EditNoteDialog (
  { handleClose, open, index, ticket, ticketRef }: EditNoteParams,
) {
  const classes = useStyles()
  const [note, setNote] = useState(ticket.notes[index])

  useEffect(() => {
    setNote(ticket.notes[index])
  }, [ticket.notes[index]])
  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Edit Note</DialogTitle>
        <DialogContent>
          <TextField
            placeholder='Note'
            value={note}
            onChange={e => {
              ticket.notes[index] = e.target.value
              setNote(ticket.notes[index])
            }}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Flex fullWidth>
            <Button
              className={classes.deleteButton}
              startIcon={<DeleteIcon />}
              onClick={() => {
                deleteNote({ ticketRef, ticket, index })
                handleClose()
              }}
            >
              Delete
            </Button>
          </Flex>
          <Button onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              updateNote({
                ticketRef, ticket,
              })
              handleClose()
            }}
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


function Note (
  { ticket, ticketRef, note, index }:
    { ticket: any, ticketRef: any, note: string, index: number },
) {
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const classes = useStyles({ note })

  if (!note) {
    return null
  }

  return (

    <>
      <ListItem key={index} dense button>
        <ListItemText
          primary={note}
          className={classes.strikeout}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge='end'
            color='primary'
            onClick={() => {
              setEditDialogOpen(true)
            }}
          >
            <EditIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <EditNoteDialog
        open={editDialogOpen}
        handleClose={() => setEditDialogOpen(false)}
        index={index}
        ticket={ticket}
        ticketRef={ticketRef}
      />
    </>
  )
}



interface NotesProps {
  ticketRef: any
  ticket: Ticket
  notes?: string
  [x: string]: any
}
export default function Notes (
  {
    ticketRef,
    ticket,
    ...props
  }: NotesProps,
) {
  const [addDialog, setAddDialogOpen] = useState(false)

  return (
    <>
      <AddNoteDialog
        ticket={ticket}
        ticketRef={ticketRef}
        open={addDialog}
        handleClose={() => setAddDialogOpen(false)}
      />
      <List {...props}>
        <Typography variant='subtitle2'>Notes</Typography>
        {ticket?.notes?.map((note, index) =>
          <Note
            key={index}
            ticket={ticket}
            ticketRef={ticketRef}
            index={index}
            note={note}
          />,
        )}
        <Flex justify='end'>
          <Button
            variant='outlined'
            size='small'
            startIcon={<AddIcon />}
            onClick={() => setAddDialogOpen(true)}
            style={{marginTop: "5px"}}
          >
            Add
          </Button>
        </Flex>
      </List>
    </>
  )
}
