import React, {useEffect, useState} from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core"

import { grey } from "@material-ui/core/colors"
import getHttpsCallable from "../../utils/getHttpsCallable"
import MultiEmailWsPrompt from "../MultiEmailWsPrompt"

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 300,
  },
  toolbar: {
    flexGrow: 1,
    justifyContent: "space-between",
    paddingLeft: 0,
    paddingTop: 15,
  },
  textarea: {
    width: "100%",
    backgroundColor: grey[800],
    color: theme.palette.text.primary,
    borderRadius: 4,
    borderColor: theme.palette.primary.light,
  },
}))

interface Result {
  rawProviderData: {
    statusCode: string,
    status: string,
    statusMessage: string,
  },
  claimId: string,
  phoneNumber: string,
  error: string,
  sent: string,
}

interface ResultDialogParams {
  isOpen: boolean
  handleClose: (a: unknown) => unknown
  sending: boolean
  results: Result[]
  claimIds: string[]
}


function ResultDialog (
  {
    isOpen,
    handleClose,
    sending,
    results,
    claimIds,
  }: ResultDialogParams,
) {
  const sentCount = results.filter(r => r.sent).length
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>Result</DialogTitle>
      <DialogContent>
        {sending
          ? (
            <DialogContentText>
              Currently sending {claimIds.length} people
              the chosen template ....
            </DialogContentText>
          )
          : (
            <>
              <Typography variant='h6'>
                Sent {sentCount} from {claimIds.length}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ClaimID</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>(Message)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((result) => {
                    let status = result?.rawProviderData?.statusCode ??
                      (result?.rawProviderData?.status ?? "")
                    status = status === "queued"
                      ? "Submitted to Twilio" : status
                    const message = result?.rawProviderData?.statusMessage ?? ""

                    return (
                      <TableRow key={result.claimId}>
                        <TableCell>{result.claimId}</TableCell>
                        <TableCell>{result.phoneNumber}</TableCell>
                        <TableCell>{status ?? "error"}</TableCell>
                        <TableCell>
                          {message ?? (result?.error ?? "")}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          disabled={sending}
          color='primary'
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const sendWhatsAppTemplate = getHttpsCallable("admin-sendWhatsappTemplate")
const getTemplates = getHttpsCallable("admin-getTemplates")

type providerOption = "WebSMS" | "Twilio"

interface WhatsAppTemplate {
  userDefinedName: string
  name: string
  body: string
  locale: string
  provider: providerOption
  placeholders: []
}


export default function SendWhatsApp ({ list = [] }) {
  const classes = useStyles()
  const [template, setTemplate] = useState<WhatsAppTemplate | undefined>()
  const [templateError, setTemplateError] = useState(null)
  const [claimIds, setClaimIds] = useState(list)
  const [claimIdsError, setClaimIdsError] = useState<string | null>(null)
  const [results, setResults] = useState([])
  const [sending, setSending] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [templates, setTemplates] = useState([])
  const [fetchTemplate, setFetchTemplate] = useState(true)
  const [openPrompt, setOpenPrompt] = useState(false)

  useEffect(() => {
    async function getTemplate () {
      const data = await getTemplates("whatsapp")
      setTemplates(data?.data)
      setFetchTemplate(false)
    }

    if (fetchTemplate) {
      getTemplate()
    }
  }, [fetchTemplate])

  async function handleSend () {
    if (claimIds.length < 1) {
      return setClaimIdsError("You must input claim Ids")
    }

    setSending(true)
    setDialogOpen(true)

    try {
      console.info("-> : selectedTemplate", template)

      const result = await sendWhatsAppTemplate({
        claimIds,
        template: template?.name,
        provider: template?.provider,
        templateLocale: template?.locale,
        placeholders: template?.placeholders ?? [],
      })

      setSending(false)
      console.info("-> : result", result)
      console.info(result.data)
      setResults(result.data)
    }
    catch (e) {
      window.alert(`
      problem occurred trying to commit send operation
      ${e}
      `)
    }
  }

  return (
    <>
      <ResultDialog
        isOpen={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        sending={sending}
        results={results}
        claimIds={claimIds}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography color='textPrimary' variant='h6'>
            Send WhatsApp Message
          </Typography>
          <Toolbar className={classes.toolbar}>
            <FormControl className={classes.formControl}>
              <InputLabel>Template</InputLabel>
              <Select
                error={Boolean(templateError)}
                value={template || ""}
                onChange={(event: any) => {
                  setTemplate(event.target.value)
                  setTemplateError(null)
                }}
              >
                {Object
                  .values(templates)
                  .sort((a: Template, b: Template) : number =>
                    (a.userDefinedName && b.userDefinedName)
                      ? a.userDefinedName.localeCompare(b.userDefinedName)
                      : 0,
                  )
                  .sort((a: Template, b: Template) : number =>
                    Number(a.userDefinedName?.includes(":")) -
                    Number(b.userDefinedName?.includes(":")),
                  )
                  .map((template: Template) => {
                    const displayName =
                     `${template.userDefinedName} (${template.locale})`
                    return (
                      <MenuItem
                        key={displayName}
                        // @ts-expect-error
                        value={template}
                      >
                        {displayName}
                      </MenuItem>
                    )
                  },
                  )
                }
              </Select>
              {templateError &&
                <FormHelperText>{templateError}</FormHelperText>
              }
            </FormControl>
            <MultiEmailWsPrompt
              successAction={handleSend}
              claimIds={claimIds}
              selectedTemplate={template?.name}
              template={template}
              sending={sending}
            >Send</MultiEmailWsPrompt>
          </Toolbar>
        </Grid>

        <Grid item xs={12}>
          {claimIdsError &&
          <Typography variant='caption' style={{ color: "red" }}>
            {claimIdsError}
          </Typography>
          }
          <Typography variant='caption' color='textPrimary'>
            Place one claim id per line
          </Typography>
          <textarea
            className={classes.textarea}
            rows={30}
            defaultValue={list.length > 0 ? list.join("\n") : ""}
            onChange={(event: any /* ChangeEvent */) => {
              setClaimIds(
                event.target.value
                  .trim()
                  .split(/\r?\n/)
                  .map((s: string) => s.trim()),
              )
              setClaimIdsError(null)
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

