import React from "react"
import { Show, SimpleShowLayout, TextField,
  ReferenceField, EmailField, DateField } from "react-admin"

export default function EmailShow (props: Record<string, unknown>) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField
          link='show'
          source='claimId'
          reference='claims'
        >
          <TextField source='id' />
        </ReferenceField>
        <ReferenceField
          link='show'
          source='userId'
          reference='users'
        >
          <TextField source='id' />
        </ReferenceField>
        <EmailField source='email' />
        <TextField source='sender' />
        <TextField source='state' />
        <TextField source='subject' />
        <TextField source='template' />
        <DateField source='timestamp' />
        <TextField source='type' />
      </SimpleShowLayout>
    </Show>
  )
}
