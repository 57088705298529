import fetch from "node-fetch"

const n8nUrl = "https://n8n-blihhroc5a-ew.a.run.app"

export enum Trigger {
  NewBankingLead = "New Banking Lead",
  NewKAPFormURL = "New KAP Form URL",
}

// TODO: Load from database
export const workflows: Workflow[] = [
  {
    id: 1,
    name: "Transmit new banking lead to KAP",
    isActive: true,
    triggers: [
      Trigger.NewBankingLead,
    ],
    url: `${n8nUrl}/workflow/1`,
    webhookUrl: `${n8nUrl}/webhook/5ced7183-5e76-4b77-bcd1-dad61e2d760c`,
    webhookTestUrl:
      `${n8nUrl}/webhook-test/5ced7183-5e76-4b77-bcd1-dad61e2d760c`,
  },
  {
    id: 2,
    name: "Send WhatsApp message with URL to KAP form",
    isActive: true,
    triggers: [
      Trigger.NewKAPFormURL,
    ],
    url: `${n8nUrl}/workflow/4`,
    webhookUrl: `${n8nUrl}/webhook/a35152f9-267d-49a3-9b3e-6efe5ac47c02`,
    webhookTestUrl:
      `${n8nUrl}/webhook-test/a35152f9-267d-49a3-9b3e-6efe5ac47c02`,
  },
]


export async function runWorkflows (
  args: {
    environment: "development" | "production",
    authToken: string,
    trigger: Trigger,
    data: Record<string, any>,
  },
) {
  console.info("Start executing workflows")

  const workflowPromises = workflows
    .map(async wf => {
      if (
        !wf.triggers.includes(args.trigger) ||
        !wf.isActive
      ) {
        return null
      }

      const webhookURL = args.environment === "development"
        ? wf.webhookTestUrl
        : wf.webhookUrl

      console.info(
        `Execute workflow "${wf.name}" at "${wf.url}" via "${webhookURL}"`,
      )

      const result = await fetch(webhookURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${args.authToken}`,
        },
        body: JSON.stringify(args.data),
      })

      if (result.ok) {  // 200 <= result.status < 300
        return await result.json()
      }
      else {
        const errorText = await result.text()
        console.error(
          new Error(errorText),
        )
        return errorText
      }
    })

  const workflowResults = (await Promise.all(workflowPromises))
    .filter(Boolean)

  console.info("Workflow results:", JSON.stringify(workflowResults))

  return workflowResults
}
