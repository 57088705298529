import React from "react"
import DefaultActions from "./DefaultActions"
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  FormDataConsumer,
  SelectField,
  required,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectArrayInput,
} from "react-admin"
import DefaultPagination from "./DefaultPagination"
import attachments from "../lib/constants/attachments"
import JsonInput from "./JsonInput"

const attachment: { id: string; name: string }[] = []

Object.entries(attachments).map(([key]) => (
  attachment.push({id: key, name: key})
))

const stateChoices = [
  { id: "active", name: "Active" },
  { id: "disabled", name: "Disabled" },
]

export function TemplateList ({ ...props }) {
  return (
    <List
      {...props}
      actions={<DefaultActions />}
      perPage={15}
      pagination={<DefaultPagination />}
    >
      <Datagrid rowClick='show'>
        <TextField source='type' sortable={false} />
        <TextField source='userDefinedName' sortable={false} />
        <TextField source='name' sortable={false} />
        <SelectField source="state" label='Status' choices={stateChoices} />
      </Datagrid>
    </List>
  )
}

export function TemplateEdit ({ ...props }) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput
          source='type'
          choices={[
            { id: "whatsapp", name: "Whatsapp" },
            { id: "email", name: "Email" },
          ]}
          validate={[required()]}
        />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) => formData.type === "whatsapp"
            ? (
              <div>
                <TextInput
                  source='userDefinedName'
                  fullWidth
                  label='Name in system'
                />
                <SelectInput
                  choices={[
                    { id: null, name: "-" },
                    { id: "de", name: "German" },
                    { id: "en", name: "English" },
                  ]}
                  source='locale'
                  fullWidth
                  label='Language'
                />
                <TextInput source='provider' fullWidth />
                <TextInput
                  source='name'
                  fullWidth
                  label='Template name'
                  helperText='Used for database queries and statistics.'
                />
                <TextInput
                  source='sid'
                  fullWidth
                  label='Twilio SID'
                  helperText='Copy from URL of Twilio template;
                  starts with "HT" and ends before "%3F"'
                />
                <BooleanInput
                  source='ignoreMaximumWhatsAppsPerClaimRule'
                  fullWidth
                  label='Allow sending WhatsApp to client multiple times'
                />
                <NumberInput
                  source='customDurationToBlockTemplate'
                  fullWidth
                  label='Duration to block template'
                  helperText='In seconds'
                />
                <BooleanInput
                  source='enablePrompt'
                  label='Enable prompt'
                />
                {
                  formData.enablePrompt && <JsonInput
                    source='conditions'
                    label='Prompt condition'
                    multiline
                    fullWidth
                  />
                }
                {
                  formData.enablePrompt && <TextInput
                    source='promptBody'
                    label='Prompt Text'
                    helperText='Message to show on prompt'
                    fullWidth
                  />
                }
                <ArrayInput source="placeholders" fullWidth>
                  <SimpleFormIterator>
                    <TextInput source="" fullWidth/>
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
            ) : formData.type === "email"
              ? (
                <div>
                  <TextInput
                    source='userDefinedName'
                    fullWidth
                    label='Name in system'
                  />
                  <TextInput
                    source='name'
                    fullWidth
                    label='Template name'
                  />
                  <SelectInput
                    choices={[
                      { id: "user", name: "User" },
                      { id: "referrer", name: "Referrer" },
                      { id: "custom", name: "Custom" },
                    ]}
                    source='recipient'
                    fullWidth
                    label='Recipient'
                  />
                  { formData.recipient === "custom" &&
                    <TextInput
                      source='to'
                      fullWidth
                      label='To'
                    />
                  }
                  <TextInput
                    source='bcc'
                    fullWidth
                    label='BCC'
                  />
                  <TextInput
                    source='cc'
                    fullWidth
                    label='CC'
                  />
                  <SelectInput
                    source='mergeLanguage'
                    choices={[
                      { id: null, name: "-" },
                      { id: "mailchimp", name: "Mailchimp" },
                      { id: "handlebars", name: "Handlebars" },
                    ]}
                    fullWidth
                    label='Merge Language'
                    helperText='Choose Handlebars for new templates'
                  />
                  <TextInput
                    source="pathname"
                    label='Path name'
                    helperText='Add file from file system'
                    fullWidth
                  />
                  <SelectArrayInput
                    source='attachment_ids'
                    choices={attachment}
                    fullWidth
                  />
                  <SelectInput
                    source='futureDateTag'
                    label='Replace date tag with future date'
                    choices={[
                      { id: null, name: "-" },
                      { id: "cronexpression", name: "Cron expression" },
                      { id: "minutes", name: "Minutes from now" },
                    ]}
                    fullWidth
                  />
                  {
                    formData.futureDateTag === "cronexpression" &&
                    <TextInput
                      source='cronExpressionTag'
                      label='Cron expression'
                      fullWidth
                    />
                  }
                  {
                    formData.futureDateTag === "minutes" &&
                    <TextInput
                      source='minutesTag'
                      label='Minutes from now'
                      fullWidth
                    />
                  }
                  {
                    (
                      formData.futureDateTag === "cronexpression" ||
                      formData.futureDateTag === "minutes"
                    ) &&
                    <TextInput
                      source='dateTagFormat'
                      label='Date tag format'
                      fullWidth
                    />
                  }
                  <BooleanInput
                    source='enablePrompt'
                    label='Enable prompt'
                  />
                  {
                    formData.enablePrompt && <JsonInput
                      source='conditions'
                      label='Prompt condition'
                      multiline
                      fullWidth
                    />
                  }
                  {
                    formData.enablePrompt && <TextInput
                      source='promptBody'
                      label='Prompt Text'
                      helperText='Message to show on prompt'
                      fullWidth
                    />
                  }
                </div>
              ) : null
          }
        </FormDataConsumer>
        <BooleanInput
          source='doNotSendToRejectedInboxes'
          label='Do not send rejected emails to recipients again'
        />
        <SelectInput
          source='state'
          choices={stateChoices}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  )
}

export function TemplateCreate ({ ...props }) {
  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput
          source='type'
          choices={[
            { id: "whatsapp", name: "Whatsapp" },
            { id: "email", name: "Email" },
          ]}
          validate={[required()]}
        />
        <FormDataConsumer fullWidth>
          {({ formData, ...rest }) => formData.type === "whatsapp"
            ? (
              <div>
                <TextInput
                  source='userDefinedName'
                  fullWidth
                  label='Name in system'
                />
                <SelectInput
                  choices={[
                    { id: null, name: "-" },
                    { id: "de", name: "German" },
                    { id: "en", name: "English" },
                  ]}
                  source='locale'
                  fullWidth
                  label='Language'
                />
                <TextInput source='provider' fullWidth />
                <TextInput
                  source='name'
                  fullWidth
                  label='Template name'
                  helperText='Used for database queries and statistics.'
                />
                <TextInput
                  source='sid'
                  fullWidth
                  label='Twilio SID'
                  helperText='Copy from URL of Twilio template;
                  starts with "HT" and ends before "%3F"'
                />
                <BooleanInput
                  source='ignoreMaximumWhatsAppsPerClaimRule'
                  fullWidth
                  label='Allow sending WhatsApp to client multiple times'
                />
                <NumberInput
                  source='customDurationToBlockTemplate'
                  fullWidth
                  label='Duration to block template'
                  helperText='In seconds'
                />
                <BooleanInput
                  source='enablePrompt'
                  label='Enable prompt'
                />
                {
                  formData.enablePrompt && <JsonInput
                    source='conditions'
                    label='Prompt condition'
                    multiline
                    fullWidth
                  />
                }
                {
                  formData.enablePrompt && <TextInput
                    source='promptBody'
                    label='Prompt Text'
                    helperText='Message to show on prompt'
                    fullWidth
                  />
                }
                <ArrayInput source="placeholders" fullWidth>
                  <SimpleFormIterator>
                    <TextInput source="" fullWidth/>
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
            ) : formData.type === "email"
              ? (
                <div>
                  <TextInput
                    source='userDefinedName'
                    fullWidth
                    label='Name in system'
                  />
                  <TextInput
                    source='name'
                    fullWidth
                    label='Template name'
                  />
                  <TextInput
                    source='bcc'
                    fullWidth
                    label='BCC'
                  />
                  <TextInput
                    source='cc'
                    fullWidth
                    label='CC'
                  />
                  <SelectInput
                    source='mergeLanguage'
                    choices={[
                      { id: null, name: "-" },
                      { id: "mailchimp", name: "Mailchimp" },
                      { id: "handlebars", name: "Handlebars" },
                    ]}
                    fullWidth
                    label='Merge Language'
                    helperText='Choose Handlebars for new templates'
                  />
                  <TextInput
                    source="pathname"
                    label='Path name'
                    helperText='Add file from file system'
                    fullWidth
                  />
                  <SelectArrayInput
                    source='attachment_ids'
                    choices={attachment}
                    fullWidth
                  />
                  <SelectInput
                    source='futureDateTag'
                    label='Replace date tag with future date'
                    choices={[
                      { id: null, name: "-" },
                      { id: "cronexpression", name: "Cron expression" },
                      { id: "minutes", name: "Minutes from now" },
                    ]}
                    fullWidth
                  />
                  {
                    formData.futureDateTag === "cronexpression" &&
                    <TextInput
                      source='cronExpressionTag'
                      label='Cron expression'
                      fullWidth
                    />
                  }
                  {
                    formData.futureDateTag === "minutes" &&
                    <TextInput
                      source='minutesTag'
                      label='Minutes from now'
                    />
                  }
                  {
                    formData.futureDateTag !== null &&
                    <TextInput
                      source='dateTagFormat'
                      label='Date tag format'
                      fullWidth
                    />
                  }
                  <BooleanInput
                    source='enablePrompt'
                    label='Enable prompt'
                    fullWidth
                  />
                  {
                    formData.enablePrompt && <JsonInput
                      source='conditions'
                      label='Prompt condition'
                      multiline
                      fullWidth
                    />
                  }
                  {
                    formData.enablePrompt && <TextInput
                      source='promptBody'
                      label='Prompt Text'
                      helperText='Message to show on prompt'
                      fullWidth
                    />
                  }
                </div>
              ) : null
          }
        </FormDataConsumer>
        <SelectInput
          source='state'
          choices={stateChoices}
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  )
}
