import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import BREAKPOINTS from "../../lib/constants/breakpoints"


const breakpoints: { [key: string]: any} = BREAKPOINTS


function getPx (prop: string | number, max = true) {
  if (typeof prop === "string") {
    if (max) {
      return breakpoints[prop].max
    }
    else {
      return breakpoints[prop].min
    }
  }
  if (typeof prop === "number") {
    return prop
  }
}


// CSS shortcuts
function between (abovePx: number, belowPx: number) {
  return css`
    @media (min-width: ${abovePx}px) and (max-width: ${belowPx}px) {
      display: inherit;
    };
  `
}


function above (abovePx: number) {
  return css`
    @media (min-width: ${abovePx}px){
      display: inherit;
    }
  `
}


function below (belowPx: number) {
  return css`
    @media (max-width: ${belowPx}px){
      display: inherit;
    }
  `
}


const Wrapper = styled.div`
  display: none;
  ${({ abovePx, belowPx }: { abovePx: number, belowPx: number }) => {
    if (abovePx && belowPx) {
      return between(abovePx, belowPx)
    }
    if (abovePx) {
      return above(abovePx)
    }
    if (belowPx) {
      return below(belowPx)
    }
  }}
`


interface ShowParams {
  above: number
  below: number
  from: number
  to: number
  [key: string]: any
}


export default function Show (
  { above, below, from, to, ...props }: ShowParams,
) {
  if ((above || below) && (from || to)) {
    console.warn(
      "<Show>:",
      "Mixing props from/to and below/above " +
      "is not supported and may give unexpected results",
    )
  }

  let abovePx; let belowPx
  // on above we want it to go to the next breakpoint
  // (ie above md means on lg and xl screens)
  if (above) {
    abovePx = getPx(above) + 1 // get the max of breakpoint 'above'
  }
  if (below) {
    belowPx = getPx(below, false) - 1 // get the min of breakpoint 'below'
  }
  if (from) {
    abovePx = getPx(from, false)
  }
  if (to) {
    belowPx = getPx(to)
  }

  // if we use this component without props
  // then we want it to Show on all screens
  if (
    above === undefined &&
    below === undefined &&
    from === undefined &&
    to === undefined
  ) {
    abovePx = 1
  }

  return (
    <Wrapper
      abovePx={abovePx}
      belowPx={belowPx}
      {...props}
    />
  )
}


const breakpointEnum = PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"])


Show.propTypes = {
  // can be a breakpoint selector or a number in px
  above: PropTypes.oneOfType([
    breakpointEnum,
    PropTypes.number,
  ]),
  below: PropTypes.oneOfType([
    breakpointEnum,
    PropTypes.number,
  ]),
  from: PropTypes.oneOfType([
    breakpointEnum,
    PropTypes.number,
  ]),
  to: PropTypes.oneOfType([
    breakpointEnum,
    PropTypes.number,
  ]),
}
