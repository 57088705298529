import FirebaseTimestamp from "./FirebaseTimestamp"

const messageFields = {
  id: String,
  claimId: String,
  userId: String,
  phoneNumber: String,
  recipientPhoneNumber: String,
  timestamp: FirebaseTimestamp,
  _timestamp: Date,
  template: String,
  templateLocale: String,
  content: String,
  provider: String,
  providerId: String,
  rawProviderData: Object,
}

export default messageFields
