import MEDIA from '../../utils/media'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { scaledCss } from '../../utils/scaledCss'
import Flex from '../Flex'

interface PaddingScale {
  p: number
  py: number
  px: number
  pl: number
  pr: number
  pt: number
  pb: number
}

interface MarginScale {
  m: number
  my: number
  mx: number
  ml: number
  mr: number
  mt: number
  mb: number
}

export const containerCss = css`
  box-sizing: border-box;

  ${MEDIA.only.xs`
    ${({ p, py, px, pl, pr, pt, pb }: PaddingScale) =>
    scaledCss('padding', 'xs',
      {
        scale: p,
        yScale: py,
        xScale: px,
        lScale: pl,
        rScale: pr,
        tScale: pt,
        bScale: pb,
      },
    )
}
    ${({ m, my, mx, ml, mr, mt, mb }: MarginScale) =>
    scaledCss('margin', 'xs',
      {
        scale: m,
        yScale: my,
        xScale: mx,
        lScale: ml,
        rScale: mr,
        tScale: mt,
        bScale: mb,
      },
    )
}
  `}
  ${MEDIA.only.sm`
    ${({ p, py, px, pl, pr, pt, pb }: PaddingScale) =>
    scaledCss('padding', 'sm',
      {
        scale: p,
        yScale: py,
        xScale: px,
        lScale: pl,
        rScale: pr,
        tScale: pt,
        bScale: pb,
      },
    )
}
    ${({ m, my, mx, ml, mr, mt, mb }: MarginScale) =>
    scaledCss('margin', 'sm',
      {
        scale: m,
        yScale: my,
        xScale: mx,
        lScale: ml,
        rScale: mr,
        tScale: mt,
        bScale: mb,
      },
    )
}

  `}
  ${MEDIA.only.md`
    ${({ p, py, px, pl, pr, pt, pb }: PaddingScale) =>
    scaledCss('padding', 'md',
      {
        scale: p,
        yScale: py,
        xScale: px,
        lScale: pl,
        rScale: pr,
        tScale: pt,
        bScale: pb,
      },
    )
}
    ${({ m, my, mx, ml, mr, mt, mb }: MarginScale) =>
    scaledCss('margin', 'md',
      {
        scale: m,
        yScale: my,
        xScale: mx,
        lScale: ml,
        rScale: mr,
        tScale: mt,
        bScale: mb,
      },
    )
}
  `}
  ${MEDIA.only.lg`
    ${({ p, py, px, pl, pr, pt, pb }: PaddingScale) =>
    scaledCss('padding', 'lg',
      {
        scale: p,
        yScale: py,
        xScale: px,
        lScale: pl,
        rScale: pr,
        tScale: pt,
        bScale: pb,
      },
    )
}
    ${({ m, my, mx, ml, mr, mt, mb }: MarginScale) =>
    scaledCss('margin', 'lg',
      {
        scale: m,
        yScale: my,
        xScale: mx,
        lScale: ml,
        rScale: mr,
        tScale: mt,
        bScale: mb,
      },
    )
}
  `}
  ${MEDIA.only.xl`
    ${({ p, py, px, pl, pr, pt, pb }: PaddingScale) =>
    scaledCss('padding', 'xl',
      {
        scale: p,
        yScale: py,
        xScale: px,
        lScale: pl,
        rScale: pr,
        tScale: pt,
        bScale: pb,
      },
    )
}
    ${({ m, my, mx, ml, mr, mt, mb }: MarginScale) =>
    scaledCss('margin', 'xl',
      {
        scale: m,
        yScale: my,
        xScale: mx,
        lScale: ml,
        rScale: mr,
        tScale: mt,
        bScale: mb,
      },
    )
}
  `}
`

const Container = styled.div`
  ${containerCss}
`

const FlexContainer = styled(Flex)`
  ${containerCss}
`

const propTypes = {
  m: PropTypes.number,
  mx: PropTypes.number,
  my: PropTypes.number,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
  p: PropTypes.number,
  px: PropTypes.number,
  py: PropTypes.number,
  pt: PropTypes.number,
  pr: PropTypes.number,
  pb: PropTypes.number,
  pl: PropTypes.number,
}

const defaultProps = {
  p: 1,
  m: 0,
}

// @ts-expect-error
Container.propTypes = propTypes
// @ts-expect-error
Container.defaultProps = defaultProps
FlexContainer.propTypes = propTypes
FlexContainer.defaultProps = defaultProps

export { FlexContainer }
export default Container
