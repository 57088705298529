import React, { useState } from "react"
import { Typography, Divider, Button } from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import { useTheme } from "@material-ui/styles"

import UserSMSTable from "./UserSMSTable"
import firebase from "../../firebase"
import { Flex } from "../../lib"
import getHttpsCallable from "../../utils/getHttpsCallable"


const sendSMSFn = getHttpsCallable("admin-sendSms")

export default function SendSMS () {
  const theme = useTheme()
  const [messageContent, setMessageContent] = useState("")
  const [claimIds, setClaimIds] = useState([])
  const [imported, setImported] = useState(false)


  async function sendSms ({ claim }: { claim: Claim }) {
    console.info(`Sending:
      ${messageContent}
      claimId: ${claim.id}  - phoneNumber: ${claim.phoneNumber}
    `)
    try {
      const res = await sendSMSFn({ claim, messageContent })
      return res.data
    }
    catch (err) {
      // @ts-ignore
      return window.alert(err.message)
    }
  }


  return (
    <Flex direction='ttb'>
      <Typography
        color='textPrimary'
        variant='h6'
        style={{ marginBottom: 20 }}
      >
        Send SMS
      </Typography>
      <Typography color='textPrimary' variant='caption'>
        Message Content
      </Typography>
      <textarea
        style={{
          backgroundColor: grey[800],
          // @ts-expect-error
          color: theme.palette.text.primary,
          borderRadius: 4,
          // @ts-expect-error
          borderColor: theme.palette.primary.light,
        }}
        rows={8}
        value={messageContent}
        onChange={(e) => setMessageContent(e.target.value)}
      />
      <Divider style={{ margin: "20px 0px" }} />
      <Typography color='textPrimary' variant='caption'>
        Claim ID list
      </Typography>
      {imported
        ? (
          <UserSMSTable claimIds={claimIds} sendSms={sendSms} />
        )
        : (
          <Flex>
            <textarea
              style={{
                width: "100%",
                backgroundColor: grey[800],
                // @ts-expect-error
                color: theme.palette.text.primary,
                borderRadius: 4,
                // @ts-expect-error
                borderColor: theme.palette.primary.light,
              }}
              rows={20}
              onChange={(event: any) => {
                setClaimIds(
                  event.target.value
                    .trim()
                    .split(/\r?\n/)
                    .map((s: string) => s.trim()),
                )
              }}
            />
            <Button onClick={() => {
              if (claimIds.length > 0) {
                setImported(true)
              }
            }}
            >Import
            </Button>
          </Flex>
        )}

    </Flex>
  )
}
