import React from "react"
import {
  Button,
  CreateButton,
  ExportButton,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
} from "react-admin"
import ClearIcon from "@material-ui/icons/Clear"


function DefaultActions (props: Record<string, any>) {
  const {
    className,
    filters,
    permanentFilter,
    setFilters,
    ...rest
  } = props

  const {
    total,
    currentSort,
    resource,
    displayedFilters,
    exporter,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
  } = useListContext()

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
      <Button
        label='Clear Filters'
        size='small'
        onClick={() => setFilters && setFilters({})}
      >
        <ClearIcon />
      </Button>
      {hasCreate && <CreateButton basePath={basePath}/>}
      {exporter !== false && (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={{ ...filterValues, ...permanentFilter }}
          exporter={exporter}
          maxResults={10000}
        />
      )}
      {/*
        TODO: allow custom actions here from children
          (implement prop passing)
      */}
      {/* {props.children} */}
    </TopToolbar>
  )
}


export default DefaultActions
