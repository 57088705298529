import React, { useEffect, useState } from "react"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Hidden,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import getHttpsCallable from "../../../utils/getHttpsCallable"
import { Flex } from "../../../lib"

const submitNotificationSetup = getHttpsCallable(
  "admin-submitNotificationSetup",
)
const getTemplates = getHttpsCallable("admin-getNotificationScheduler")

interface NotificationSendDialogParams {
  open: any
  handleClose: any
  handleSubmit: any
  template: string
  sending: boolean
  templateError: any
  setTemplate: any
  setTemplateError: any
  setSending: any
  setResult: any
  claimId: string
  result: string | null
}

function NotificationSetupDialog ({
  open,
  handleClose,
  handleSubmit,
  template,
  sending,
  templateError,
  setTemplate,
  setTemplateError,
  setSending,
  setResult,
  result,
  claimId,
}: NotificationSendDialogParams) {
  function NotificationSetupForm () {
    const [templates, setTemplates] = useState([])
    const [fetchTemplate, setFetchTemplate] = useState(true)

    useEffect(() => {
      async function getTemplate () {
        const data = await getTemplates({})
        setTemplates(data?.data)
        setFetchTemplate(false)
      }

      if (fetchTemplate) {
        getTemplate()
      }
    }, [fetchTemplate])

    return (
      <>
        <DialogContent>
          <DialogContentText>
            Select the template you wish to setup the notification.
          </DialogContentText>
          <Select
            error={Boolean(templateError)}
            disabled={sending}
            value={template}
            fullWidth
            label="Template"
            onChange={(e) => {
              setTemplate(e.target.value)
              setTemplateError(null)
            }}
          >
            {Object.values(templates)
              .sort((a: Template, b: Template): number =>
                a.name && b.name ? a.name.localeCompare(b.name) : 0,
              )
              .map((template: Template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.name}
                </MenuItem>
              ))}
          </Select>
          {templateError && (
            <FormHelperText error>{templateError}</FormHelperText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleSubmit(template, setSending, setTemplateError, setResult)
            }
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </>
    )
  }

  function Result () {
    return (
      <>
        <DialogContent>
          <DialogContentText>{result}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>Setup Notification Queue</DialogTitle>
      {!sending && !result && <NotificationSetupForm />}
      {sending && (
        <Flex center style={{ margin: 20 }}>
          <CircularProgress />
        </Flex>
      )}
      {result && <Result />}
    </Dialog>
  )
}

export default function SetupNotificationQueue ({
  claimId,
  claimType = "claim",
  ...props
}: {
  claimId: string
  [key: string]: any
}) {
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false)
  const [template, setTemplate] = useState("")
  const [templateError, setTemplateError] = useState(null)
  const [sending, setSending] = useState(false)
  const [result, setResult] = useState<string | null>(null)
  const [templates, setTemplates] = useState<Template[]>([])
  const [fetchTemplate, setFetchTemplate] = useState(true)

  useEffect(() => {
    async function getTemplate () {
      const data = await getTemplates({})
      setTemplates(data?.data ?? [])
      setFetchTemplate(false)
    }

    if (fetchTemplate) {
      getTemplate()
    }
  }, [fetchTemplate])

  async function handleSubmit (
    templateId: string,
    setSending: (a: unknown) => unknown,
    setTemplateError: (a: unknown) => unknown,
    setResult: (a: unknown) => unknown,
  ) {
    if (!templateId) {
      return setTemplateError("You must select a template to send")
    }
    setSending(true)
    try {
      const selectedTemplate: Template | undefined = templates.find(
        (template: Template) => template.id === templateId,
      )

      if (!selectedTemplate) {
        throw new Error("Could not find template")
      }

      console.info("-> : selectedTemplate", selectedTemplate)

      const result = await submitNotificationSetup({
        claimId: claimId,
        templateId: templateId,
        // templatePathname: selectedTemplate.pathname ?? "",
        // attachments: selectedTemplate.attachments ?? [],
        // claimType: claimType,
      })
      console.info(`send result: ${JSON.stringify(result, null, 2)}`)
      setSending(false)
      setResult(`Notification successfully scheduled for 
        "${selectedTemplate.name} - ${result.data.templateId}" 
        in Claim "${result.data.claimId}"`)
    }
    catch (e) {
      window.alert(`
        problem occurred trying to commit send operation
        ${e}
        `)
    }
  }

  function handleCloseDialog () {
    setTemplate("")
    setTemplateError(null)
    setSending(false)
    setResult(null)

    setNotificationDialogOpen(false)
  }

  function handleOpenDialog () {
    setNotificationDialogOpen(true)
  }

  return (
    <>
      <NotificationSetupDialog
        handleClose={handleCloseDialog}
        open={notificationDialogOpen}
        handleSubmit={handleSubmit}
        template={template}
        sending={sending}
        templateError={templateError}
        setTemplate={setTemplate}
        setTemplateError={setTemplateError}
        setSending={setSending}
        setResult={setResult}
        result={result}
        claimId={claimId}
      />

      <Button
        {...props}
        variant="outlined"
        color="primary"
        onClick={handleOpenDialog}
      >
        Setup Notification Queue
      </Button>
    </>
  )
}
