import React from "react"
import PhoneAnsweredIcon from "@material-ui/icons/PhoneInTalk"
import PhoneInvalidIcon from "@material-ui/icons/PhoneDisabled"
import { Avatar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { green, indigo } from "@material-ui/core/colors"

import { Flex } from "../../../lib"


interface StateData {
  phoneNumberValid: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 0),
  },
  indicator: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: ({ phoneNumberValid }: StateData) => phoneNumberValid
      ? green[600]
      : indigo[200],
    color: ({ phoneNumberValid }: StateData) => phoneNumberValid
      ? theme.palette.getContrastText(green[600])
      : theme.palette.getContrastText(indigo[200]),
    "& > *": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
}))


export default function PhoneValidBadge ({ phoneNumberValid }: StateData) {
  const classes = useStyles({ phoneNumberValid })
  return (
    <Flex align='center' className={classes.root}>
      <Avatar className={classes.indicator}>
        {phoneNumberValid ? <PhoneAnsweredIcon /> : <PhoneInvalidIcon />}
      </Avatar>
    </Flex>
  )
}
