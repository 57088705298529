import React, { useState } from "react"
import { reject } from "lodash"
import Grid from "@material-ui/core/Grid"
import { Typography, Chip, TextField } from "@material-ui/core"

import useStyles from "./styles"
import TrackingColumn from "./TrackingColumn"
import { dieselTags } from "../../constants/ticketTags"
import Flex from "../../lib/components/Flex/Flex"
import { TicketAssignee } from "../../constants/enums"


function TagFilters (
  { filters, setFilters }: {
    filters: string[]
    setFilters: (a: any) => void
  },
) {
  const [textInput, setTextInput] = useState("")

  function handleChangeFilter (e: React.ChangeEvent<HTMLInputElement>) {
    setTextInput(e.target.value)
    const ocTagIds = dieselTags.map(({id}) => id)

    const cleanStateId = filters.filter((tagId) => ocTagIds.includes(tagId))

    if (!e.target.value || e.target.value.length < 1) {
      // clear value
      setFilters(cleanStateId)
      return
    }

    const userInput = e.target.value

    if (!userInput.trim().includes(",")) {
      setFilters([
        ...cleanStateId,
        userInput,
      ])

      return
    }

    const tagSplit = userInput.split(",")

    const multipleTags = tagSplit.filter(tag =>
      !cleanStateId.includes(tag.trim()),
    )

    setFilters([
      ...cleanStateId,
      ...multipleTags,
    ])

  }

  return (
    <Flex wrap center>
      <Typography
        color='textPrimary'
        style={{ marginRight: 14 }}
      >
        Filters:
      </Typography>
      <TextField
        variant="outlined"
        label="Tag Filter"
        onChange={handleChangeFilter}
        size="small"
        value={textInput}
      />
      {dieselTags.map(({ name, id }) => {
        return (
          <Chip
            key={id}
            style={{ margin: 4 }}
            color={filters.includes(id) ? "primary" : "default"}
            clickable
            label={name}
            onClick={() => {
              filters.includes(id)
                ? setFilters(reject(filters, (t) => id === t))
                : setFilters(filters.concat(id))
            }}
          />
        )
      })}
    </Flex>
  )
}


export default function Tracking (props: Record<string, unknown>) {
  const [filters, setFilters] = useState([])
  const classes = useStyles({})

  return (
    <div className={classes.root}>
      <TagFilters filters={filters} setFilters={setFilters} />
      <Grid container spacing={1} wrap='nowrap'>
        {Object
          .values(TicketAssignee)
          .filter((assignee) =>
            assignee !== TicketAssignee.BFR_CALL_LIST,
          )
          .map(type => {
            return (
              <Grid item xs key={type}>
                <TrackingColumn assignedGroup={type} tagFilters={filters} />
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}
