import styled from 'styled-components'
import { LinearProgress } from '@material-ui/core'


const StyledBar = styled(LinearProgress)`
  && {
    width: 100%;
    height: 3px;
    min-width: 100px;
    background-color: #f8f9fe;
    border-radius:1.5px;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: #2460d8;
    border-radius:1.5px;
  }
`


export default function ProgressBar (
  { progress, ...props }: { progress: number },
) {
  return (
    <StyledBar
      variant='determinate'
      value={progress}
      {...props}
    />
  )
}
