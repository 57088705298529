import Button from "@material-ui/core/Button"
import Switch from "@material-ui/core/Switch"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Chip from "@material-ui/core/Chip"
import List from "@material-ui/core/List"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { FlexContainer } from "../../../lib/components/Container/Container"
import moment from "moment"
import { toNumber, reject } from "lodash"
import React, { useEffect, useState } from "react"
import { db } from "../../../firebase"
import CallKey from "./CallKey"
import CallListSidebar from "./CallListSidebar"
import useStyles from "./styles"
import CallListItem from "./CallListItem"
import Flex from "../../../lib/components/Flex/Flex"
import { TicketAssignee } from "../../../constants/enums"


function TagFilters (
  { filters, setFilters }: {
    filters: string[],
    setFilters: (a: any) => void,
  },
) {
  return (
    <Flex wrap center>
      <Typography color='textPrimary' style={{ marginRight: 14 }}>
        Filters:
      </Typography>

      <Chip
        style={{ margin: 4 }}
        color={filters.includes("topModels") ? "primary" : "default"}
        clickable
        label='TOP'
        onClick={() => {
          filters.includes("topModels")
            ? setFilters(reject(filters, (t) => t === "topModels"))
            : setFilters(filters.concat("topModels"))
        }}
      />
      <Chip
        style={{ margin: 4 }}
        color={filters.includes("kapModels") ? "primary" : "default"}
        clickable
        label='KAP'
        onClick={() => {
          filters.includes("kapModels")
            ? setFilters(reject(filters, (t) => t === "kapModels"))
            : setFilters(filters.concat("kapModels"))
        }}
      />
    </Flex>
  )
}


export default function AwaitingKVCallList () {
  const classes = useStyles()
  const [ticketRefs, setTicketRefs] = useState([])
  const [showNewClaims, setShowNewClaims] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [snapCount, setSnapCount] = useState(0)
  const [activeTicketRef, setActiveTicketRef] = useState(null)
  const [limit, setLimit] = useState(250)
  const [minReachedCount, setMinReachedCount] = useState<number>(0)
  const [filters, setFilters] = useState([])

  useEffect(() => {
    let q = db.collection("tickets")
      .where("assignedGroup", "==", TicketAssignee.AWAITING_KV)
      .where("hasPhoneNumber", "==", true)
      .where("phoneNumberValid", "==", true)
      .where("reachedCount", ">=", toNumber(minReachedCount) || 0)
      .orderBy("reachedCount", "asc")
      .orderBy("callCount", "asc")
      .orderBy("createdAt", "desc")
      .limit(limit)

    if (filters.length) {
      q = q.where("tags", "array-contains-any", filters)
    }
    return q.onSnapshot(snap => {
      // @ts-expect-error
      setTicketRefs(snap.docs.filter(s => {
        // client filter to only older than 72 hours with callcount of 0
        const ticket = s.data()
        if (!ticket.createdAt) {
          return false
        }
        if (!showNewClaims) {
          if (
            ticket.callCount === 0 &&
            moment(ticket.createdAt.toDate())
              .isAfter(moment().subtract(72, "hours"))
          ) {
            return false
          }
        }
        return true
      }))
      setLoaded(true)
      setSnapCount(snap.size)
    })
  }, [limit, showNewClaims, minReachedCount, filters])

  if (loaded && ticketRefs.length === 0 && snapCount === limit) {
    setLimit(limit + 250)
  }

  const showMore = loaded && (snapCount >= limit)

  return (
    <>
      <Flex justify='between' wrap>
        <CallKey />
        <TagFilters filters={filters} setFilters={setFilters} />

        <TextField
          label='Min Reached Count'
          type='number'
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) =>
            setMinReachedCount(toNumber(event.target.value))
          }
        />
        <FormControlLabel
          control={<Switch onClick={() => setShowNewClaims(!showNewClaims)} />}
          label='Show new claims'
        />

      </Flex>
      <FlexContainer px={0}>
        <List className={classes.list}>
          {ticketRefs.map((ticketRef, i) => (
            <CallListItem
              key={i}
              ticketRef={ticketRef}
              onRowClicked={(ticketRef: any) => setActiveTicketRef(ticketRef)}
              // @ts-expect-error
              active={activeTicketRef && (ticketRef.id === activeTicketRef.id)}
            />
          ))}
          {showMore &&
            <Button fullWidth onClick={() => setLimit(limit + 250)}>
              Show More
            </Button>
          }
        </List>
        {activeTicketRef && (
          <CallListSidebar
            ticketRef={activeTicketRef}
            className={classes.sidebar}
          />
        )}
      </FlexContainer>
    </>
  )
}
