import React, { useState } from "react"
import { useNotify, Button as Btn } from "react-admin"
import {
  Box,
  Button,
  CircularProgress, Dialog, DialogContentText,
  Divider, Link,
  TextField,
  Typography,
} from "@material-ui/core"
// eslint-disable-next-line import/no-unresolved
import { DocumentData } from "@firebase/firestore-types"

import Container, { FlexContainer } from "../lib/components/Container"
import Flex from "../lib/components/Flex/Flex"
import firebase from "../firebase"
import getHttpsCallable from "../utils/getHttpsCallable"
import DialogTitle from "@material-ui/core/DialogTitle"
import CloseIcon from "@material-ui/icons/Close"
import DialogActions from "@material-ui/core/DialogActions"
import FileCopy from "@material-ui/icons/FileCopy"
import IconCancel from "@material-ui/icons/Cancel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import {Send} from "@material-ui/icons"


function Field (
  { label, value }: {
    label: string,
    value: string,
  },
) {
  return (
    <>
      <div>
        <Typography variant='overline' color='textPrimary'>
          {label}
        </Typography>
        <Typography
          variant='body2'
          color='textPrimary'
          style={{ fontStyle: "italic" }}
        >
          {value}
        </Typography>
      </div>
      <Divider />
    </>
  )
}


const updateUserEmail = getHttpsCallable("admin-updateUserEmail")


const createAdditionalClaim = getHttpsCallable("admin-createAdditionalClaim")


export default function UserModification () {
  const [userId, setUserId] = useState("zDVB2obyCSX0KomtLVDfN5JWp3n1")
  const [newEmail, setNewEmail] = useState("")
  const [userData, setUserData] = useState<DocumentData | undefined>()
  const [submitting, setSubmitting] = useState(false)
  const [editingEmail, setEditingEmail] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [newClaimType, setNewClaimType] = useState("bank-fee-recovery")
  const [newLawFirm, setNewLawFirm] = useState("KAP")
  const notify = useNotify()

  async function handleGetUser () {
    if (!userId) {
      return
    }
    const userDoc = await firebase.firestore().doc(`users/${userId}`).get()
    if (userDoc.exists) {
      setUserData(userDoc.data())
    }
    else {
      notify(`Cannot find user "${userId}"`, "warning")
    }
  }

  async function handleChangeEmail () {
    setSubmitting(true)

    try {
      console.info(userId, newEmail)
      await updateUserEmail({ userId, newEmail })
      await handleGetUser()
      notify(
        `Email address was successfully changed to "${newEmail}"`,
        "success",
      )
    }
    catch (error) {
      console.error(error)
      // @ts-ignore
      notify(error.message, "warning")
    }

    setSubmitting(false)
  }

  function openDialog () {
    setShowDialog(true)
  }

  async function handleCreateNewClaim () {
    if (!newClaimType || !newLawFirm) {
      return
    }

    setSubmitting(true)
    try {
      console.info(userId)
      console.info(newClaimType)
      console.info(newLawFirm)
      const { data } = await createAdditionalClaim({
        userId,
        claimType: newClaimType,
        lawFirm: newLawFirm,
      })
      console.info(data)
      await handleGetUser()
      notify(`Created a new claim for user "${data.id}"`, "success")
    }
    catch (error) {
      console.error(error)
      // @ts-ignore
      notify(error.message, "warning")
    }
    setSubmitting(false)
    setShowDialog(false)
    setNewClaimType("bank-fee-recovery")
    setNewLawFirm("KAP")
  }

  return (
    <FlexContainer direction='ttb'>
      <Typography
        color='textPrimary'
        variant='h4'
        style={{ marginBottom: 20 }}
      >
        Modify Users
      </Typography>
      <Divider style={{ margin: "20px 0px" }} />
      <Flex>
        <TextField
          id='outlined-basic'
          label='UserId'
          variant='outlined'
          style={{ width: "100%", marginRight: "20px" }}
          onChange={(e) => setUserId(e.target.value)}
        />
        <Button variant='outlined' onClick={handleGetUser}>Check</Button>
      </Flex>
      <Divider style={{ margin: "20px 0px" }} />

      {userData && (
        <>
          <Typography color='textPrimary' variant='h6'>User Data</Typography>

          <Container>
            <Field
              label='Created At'
              value={`${userData.createdAt.toDate()}`}
            />
            <Field label='Id' value={`${userData.id}`} />
            <Field label='Email' value={userData.email} />
            <Field
              label='Registered'
              value={userData.hasCompletedRegistration ? "true" : "false"}
            />
          </Container>
          <Divider style={{ margin: "20px 0px" }} />
          <Typography color='textPrimary' variant='h6'>Edit User</Typography>
          <Button
            variant='outlined'
            onClick={() => setEditingEmail(true)}
            style={{ marginBottom: 12 }}
            disabled={submitting}
          >
            Change Email
          </Button>

          <Flex>
            {editingEmail && (
              <>
                <TextField
                  label='New email address'
                  style={{ marginBottom: 32, marginRight: 16, width: "100%" }}
                  onChange={e => setNewEmail(e.target.value)}
                />
                <Button
                  onClick={handleChangeEmail}
                  disabled={submitting}
                >
                  {submitting ? <CircularProgress /> : "Change"}
                </Button>
              </>
            )}
          </Flex>

          <Button
            variant='outlined'
            style={{ marginBottom: 12, marginRight: 16, width: "100%" }}
            onClick={openDialog}
            disabled={submitting}
          >
            Create Additional Claim
          </Button>
          <Dialog
            fullWidth
            open={showDialog}
          >
            <DialogTitle>Create additional claim</DialogTitle>
            {!submitting
              ? <>
                <div style={{padding: "24px"}}>
                  <label style={{color: "rgba(255, 255, 255, 0.7)",
                    fontSize: "0.8rem"}}>Claim type</label>
                  <Select
                    fullWidth
                    defaultValue='bank-fee-recovery'
                    onChange={(event: any) =>
                      setNewClaimType(event.target.value)}
                  >
                    <MenuItem key='bank-fee-recovery' value='bank-fee-recovery'>
                      Bank fee recovery
                    </MenuItem>
                    <MenuItem key='diesel' value='diesel'>
                      Diesel
                    </MenuItem>
                    <MenuItem key='online-casino' value='online-casino'>
                      Online casino
                    </MenuItem>
                  </Select>
                </div>
                <div style={{padding: "24px", paddingTop: "0px"}}>
                  <label style={{color: "rgba(255, 255, 255, 0.7)",
                    fontSize: "0.8rem"}}>Law firm</label>
                  <Select
                    fullWidth
                    defaultValue='KAP'
                    onChange={(event: any) =>
                      setNewLawFirm(event.target.value)}
                  >
                    <MenuItem key='KAP' value='KAP'>
                      KAP
                    </MenuItem>
                    <MenuItem key='AILTS' value='AILTS'>
                      AILTS
                    </MenuItem>
                    <MenuItem key='BRC' value='BRC'>
                      BRC
                    </MenuItem>
                  </Select>
                </div>
                <DialogActions>
                  <Btn
                    label="Submit"
                    key='button'
                    onClick={handleCreateNewClaim}
                  >
                    <Send />
                  </Btn>
                  <Btn
                    label='ra.action.cancel'
                    onClick={() => setShowDialog(false)}
                  >
                    <IconCancel />
                  </Btn>
                </DialogActions>
              </>
              : <div style={{width: "100%", textAlign: "center",
                marginBottom: "1rem", marginTop: "1rem"}}>
                <CircularProgress/>
              </div>
            }
          </Dialog>
          <Button variant='outlined' disabled>Delete User</Button>
        </>
      )}

    </FlexContainer>
  )
}
