import React from "react"
import {
  BooleanField,
  DateField,
  EditButton,
  FunctionField,
  Link,
  NumberField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from "react-admin"
import { useHistory } from "react-router-dom"
import { Button, makeStyles } from "@material-ui/core"

import PhoneCallButton from "../PhoneCallButton"
import SendSMSButton from "../SendSMSButton"
import { SendEmailButton } from "../SendEmailButton"
import CheckVinButton from "./VinCheckButton"
import ClaimCommunications from "./ClaimCommunications"
import ClaimHistory from "./ClaimHistory"
import ClaimFiles from "./ClaimFiles"
import SendToLawyersButton from "../SendToLawyersButton"
import TicketPanel from "./TicketPanel"
import GenerateLinkButton from "./GenerateLinkButton"
import SendWhatsappButton from "../SendWhatsappButton"
import { FlexContainer } from "../../lib/components/Container"
import INSURANCE_OPTIONS from "../../lib/constants/insurance-providers"
import { ChipListObjects } from "../ChipList"
import DuplicateClaims from "../DuplicateClaims"
import GeneratePoaClaim from "../GeneratePoaClaim"
import GenerateLfaClaim from "../GenerateLfaClaim"


const insuranceChoices = INSURANCE_OPTIONS
  .map(opt => ({ id: opt.value, name: opt.label }))

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
  },
  lastField: {
    marginBottom: theme.spacing(3),
  },
}))


interface ClaimActionsParams {
  data?: Claim
  basePath: string
}

function ClaimActions ({ data, basePath }: ClaimActionsParams) {
  const classes = useStyles()
  const history = useHistory()

  if (!data) {
    return null
  }

  return (
    <FlexContainer direction='rtl' p={0.3}>
      <EditButton
        // @ts-expect-error
        data={data}
        record={{ id: data.id }}
        basePath={basePath}
        className={classes.actionButton}
      />
      <PhoneCallButton claim={data} className={classes.actionButton} />
      <SendEmailButton email={data.userEmail} claimId={data.id}
        className={classes.actionButton} />
      <SendWhatsappButton claim={data} className={classes.actionButton} />
      <SendSMSButton claim={data} className={classes.actionButton} />
      <SendToLawyersButton claimData={data} className={classes.actionButton} />
      <GenerateLinkButton userId={data.userId}
        className={classes.actionButton} />
      <CheckVinButton data={data} className={classes.actionButton} />
      <Button
        variant='outlined'
        color='primary'
        className={classes.actionButton}
        onClick={() => history.push(`/seven-day?claimId=${data.id}`)}
      >
        7Day
      </Button>
      <DuplicateClaims selectedIds={[data.id]} isShow={true}/>
      <GeneratePoaClaim claim={data} className={classes.actionButton}/>
      <GenerateLfaClaim claim={data} className={classes.actionButton}/>
    </FlexContainer>
  )
}


function Aside ({ record }: { record?: Claim }) {
  if (!record) {
    return null
  }

  return (
    <TicketPanel
      userId={record.userId}
      claimId={record.id}
      claim={record}
      type="claims"
    />
  )
}


function OptionalBrcMultipleCars (props: Record<string, any>) {
  const claim = useRecordContext(props)
  return claim && claim.lawFirm === "BRC"
    ? <BooleanField
      source='brcMultipleCars'
      label='BRC Multiple Cars'
      addLabel={true}
    />
    : null
}


export default function ClaimShow (props: Record<string, any>) {
  const classes = useStyles()

  return (
    <Show
      {...props}
      // @ts-expect-error TODO
      label='ShowClaim'
      // @ts-expect-error TODO
      actions={<ClaimActions {...props} />}
      style={{ flexGrow: 1 }}
      aside={<Aside />}
    >
      <TabbedShowLayout>
        <Tab label='Summary'>
          {/* <BooleanField label='Lead' source='lead'
            addLabel={true} /> */}
          {/* <ChipListObjects source="tags" label="Tags" /> */}
          {/* <FunctionField
            label='Custom Field Values'
            source='customFieldValues'
            render={(obj: any) => {
              if (!obj?.customFieldValues) {
                return
              }

              return <table>
                {Object
                  .entries(
                    obj.customFieldValues as
                      Record<string, { value: any }>,
                  )
                  .map(
                    ([key, valObj]: [string, { value: any }]) =>
                      <tr>
                        <td>{key}: </td>
                        <td>
                          <strong>
                            {Array.isArray(valObj?.value)
                              ? valObj?.value.join(", ")
                              : valObj?.value
                            }
                          </strong>
                        </td>
                      </tr>,
                  )
                }
              </table>
            }}
          /> */}
          <FunctionField
            label='UTM Parameters'
            source='utmParams'
            render={(obj: any) => {
              if (!obj?.utmParams) {
                return
              }

              return <table>
                {Object
                  .entries(
                    obj.utmParams as
                      Record<string, { source: string, value: any }>,
                  )
                  .map(
                    ([key, valObj]: [string, { source: string, value: any }]) =>
                      <tr>
                        <td>{valObj?.source}: </td>
                        <td>
                          <strong>
                            {Array.isArray(valObj?.value)
                              ? valObj?.value.join(", ")
                              : valObj?.value
                            }
                          </strong>
                        </td>
                      </tr>,
                  )
                }
              </table>
            }}
          />
          <TextField label='Law Firm' source='lawFirm' />
          <TextField label='Law Firm Case ID' source='lawFirmCaseId' />
          <TextField label='Lead Source' source='leadSource' />
          {/* <DateField
            source='userRegistrationTime'
            locales='sv-SE'
            showTime
          /> */}
          <DateField
            source='createdTime'
            locales='sv-SE'
            showTime
          />
          <DateField
            source='updatedTime'
            locales='sv-SE'
            showTime
          />
          <DateField
            source='transmittedToLawfirmAt'
            locales='sv-SE'
            showTime
          />
          {/* <DateField
            source='lastPushbackLawFirmAt'
            locales='sv-SE'
            showTime
          /> */}
          {/* <BooleanField
            source='applicationApproved'
            label='Application Approved (Renders Success Page)'
            addLabel={true}
          /> */}
          {/* <DateField
            source='applicationApprovedTime'
            locales='sv-SE'
            showTime
          /> */}
          <TextField label='Claim ID' source='id' />
          <FunctionField
            label='User ID'
            source='userId'
            render={(claim: any) =>
              <Link to={`/users/${claim.userId}/show`}>
                {claim.userId}
              </Link>
            }
          />
          <TextField label='User Email' source='userEmail' />
          {/* <TextField label='Email State' 
            source='messageTracking.email' /> */}
          {/* <BooleanField source='userIsRegistered' sortable={false}
            addLabel={true} /> */}
          {/* <BooleanField label='Claim Eligible' source='isEligible'
            addLabel={true} /> */}
          {/* <BooleanField source='acceptedTermsAndConditions'
            label='Accepted T&Cs' addLabel={true} /> */}
          {/* <OptionalBrcMultipleCars /> */}
          {/* <BooleanField source='highValueInsuranceProvider'
            label='High Value Insurance' addLabel={true} /> */}
          <SelectField
            source='claimActionStatus'
            choices={[
              { id: "active", name: "Active" },
              { id: "dead", name: "Dead" },
              { id: "onHold", name: "On Hold" },
              { id: "finished", name: "Finished" },
            ]}
          />
          <BooleanField label='Uploaded Purchase Contract'
            source='hasUploadedPurchaseContract' addLabel={true} />
          <BooleanField label='Uploaded Sales Contract'
            source='hasUploadedSalesContract' addLabel={true} />
          <BooleanField label='Uploaded Finance Contract'
            source='hasUploadedFinanceContract' addLabel={true} />
          {/* <TextField source='notes' /> */}
        </Tab>
        <Tab label='Contact Info'>
          <TextField label='Claim First Name'
            source='firstName' />
          <TextField label='Claim Last Name'
            source='lastName' />
          {/* <TextField label='Contract First Name'
            source='purchaseContractFirstName' />
          <TextField label='Contract Last Name'
            source='purchaseContractLastName' /> */}
          <TextField label='Contract Company'
            source='purchaseContractCompanyName' />
          <TextField source='address' />
          <TextField source='city' />
          <TextField source='postcode' />
          {/* <BooleanField source='carIsTop' label='TOP' addLabel={true} /> */}
          <TextField source='phoneNumber' />
          {/* <TextField
            label='WhatsApp State'
            source='messageTracking.whatsApp' />
          <SelectField
            source='whatsappContactedNumber'
            choices={[
              { id: null, name: "None" },
              { id: "0", name: "WA 0 Carl  442" },
              { id: "1", name: "WA 1 Follow Up  975" },
              { id: "2", name: "WA 2 Follow Up 982" },
            ]}
          />
          <TextField
            label='IBAN'
            source='iban'
            className={classes.lastField}
          /> */}
        </Tab>
        <Tab label='Car Info'>
          <SelectField
            source='carRecallStatus'
            choices={[
              { id: "mandatory", name: "Mandatory" },
              { id: "voluntary", name: "Voluntary" },
              { id: "not_recalled", name: "Not Recalled" },
            ]}
          />
          <TextField source='carBrand' />
          <TextField source='carModel' />
          <SelectField
            source='carMotorDisplacement'
            choices={[
              { id: null, name: "NULL" },
              { id: "1.4", name: "1.4" },
              { id: "1.6", name: "1.6" },
              { id: "1.8", name: "1.8" },
              { id: "2.0", name: "2.0" },
              { id: "2.1", name: "2.1" },
              { id: "2.2", name: "2.2" },
              { id: "2.7", name: "2.7" },
              { id: "3.0", name: "3.0" },
              { id: "4.2", name: "4.2" },
            ]}
          />
          <TextField source='carType' />
          <TextField source='carVariant' />
          <TextField source='carYear' />
          <TextField source='hasCarBeenSold' />
          <NumberField source='currentMileage' />
          <NumberField source='mileageAtPurchase' />
          <TextField source='mileageAtSale' />
          <NumberField source='carPurchasePrice' />
          <TextField source='carPurchaseEntity' />
          <DateField source='carPurchaseDate'
            options={{ year: "numeric", month: "short", day: "numeric" }} />
          <TextField source='carPurchaseYear' />
          <NumberField source='carSalePrice' />
          <TextField source='vinNumber' />
          <TextField source='vinCheck' />
          <TextField source='carAuthorizationCode' />
          <TextField
            source='motorType'
          />
          <TextField source='carMotorVariant'
            label='Car Motor Variant (MKB)' />
          <TextField source='carMotorKilowatt'
            label='Car kilowatt Output (kW)' />
          <SelectField
            source='euroRating'
            choices={[
              { id: "euro4", name: "EURO4" },
              { id: "euro5", name: "EURO5" },
              { id: "euro6", name: "EURO6" },
              { id: "euro6d", name: "EURO6D" },
              { id: "euro6dtemp", name: "EURO6DTEMP" },
            ]}
          />
          <NumberField
            source='remainingValueClaimback'
            label='Remaining Value (Claimback)'
          />
          <DateField
            source='dateRemainingValueClaimback'
            label='Date Remaining Value (Claimback)'
            options={{ year: "numeric", month: "short", day: "numeric" }}
          />
          <NumberField
            source='remainingValueClaimant'
            label='Remaining Value (Claimant)'
          />
          <DateField
            source='dateRemainingValueClaimant'
            label='Date Remaining Value (Claimant)'
            options={{ year: "numeric", month: "short", day: "numeric" }}
          />
        </Tab>
        <Tab label='Claim Info'>
          <BooleanField source='claimPassesMinimum' addLabel={true}  />
          <NumberField source='claimValue'
            label='Old Claim value (Do not use)' />
          <NumberField source='returnCarReward' />
          <NumberField source='keepCarReward' />
          <NumberField source='maxReward' />
          <TextField source='returnCarInCaseOfSuccess' />
          <TextField source='participatedInClassAction' />
          <TextField source='hasCarBeenFinanced' />
          <TextField source='isFinancingPaidOff' />
          <TextField source='hasCarBeenSold' />
          <TextField label='KAP Email URL' source='kapEmailUrl' />
          <BooleanField label='EC card is available' source='hasEcCard'
            addLabel={true} />
          <BooleanField
            label='Power of attorney document is available'
            source='hasPowerOfAttorneyDoc'
            addLabel={true}
          />
        </Tab>
        <Tab label='RSV Info'>
          <BooleanField source='claimVerifiedExternally' addLabel={true} />
          <TextField source='legalExpensesInsurance' />
          <SelectField source='insuranceProvider' choices={insuranceChoices} />
          <TextField source='customInsuranceProvider' />
          <TextField source='legalExpensesInsuranceAtPurchase' />
          <TextField source='legalExpensesInsurancePolicyAsCompany' />
          <TextField source='legalExpensesInsurancePolicyNumber' />
          <TextField source='legalExpensesInsurancePolicyholderCompanyName' />
          <TextField source='legalExpensesInsurancePolicyholderFirstName' />
          <TextField source='legalExpensesInsurancePolicyholderLastName' />
        </Tab>
        {/* <Tab label='Contract Info'>
          <BooleanField source='kvCorrect' addLabel={true} />
          <SelectField
            label='KV Incorrect Document Upload'
            source='kvWrongDocReason'
            choices={[
              { id: "financeContract", name: "Uploaded a finance contract" },
              { id: "carRegistration", name: "Uploaded a car registration" },
              { id: "carPhoto", name: "Uploaded a photo of the car" },
              { id: "other", name: "Uploaded something else" },
            ]}
          />
          <BooleanField source='kvReadable' addLabel={true} />
          <BooleanField source='kvDataComplete' addLabel={true} />
          <BooleanField source='carPurchasedBeforeRecall'addLabel={true} />
          <SelectField
            label='Diesel Warning'
            source='dieselWarning'
            choices={[
              { id: "yes", name: "Yes" },
              { id: "no", name: "No" },
            ]}
          />
        </Tab> */}
        <Tab label='Files'>
          <FunctionField addLabel={false} render={
            (obj: any) => <ClaimFiles record={obj}/>
          }/>
        </Tab>
        <Tab label='Communications'>
          <ClaimCommunications />
        </Tab>
        <Tab label='History'>
          <ClaimHistory />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
