import React, { useState } from "react"
import {
  Accordion,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { first } from "lodash"

import { FlexContainer } from "../../../lib/components/Container"
import { useTicket } from "../../../lib/hooks"
import useCollection from "../../../lib/hooks/useCollection"
import firebase, { db } from "../../../firebase"
import AttentionIndicator from "./AttentionIndicator"
import MoveButtons from "./MoveButtons"
import SnoozeButtons from "./SnoozeButtons"
import SubTasks from "./SubTasks"
import UpdateTimes from "./UpdateTimes"
import ZendeskPanel from "./ZendeskPanel"
import Notes from "./Notes"
import TagPanel from "./TagPanel"
import PhoneValidBadge from "./PhoneValidBadge"
import { TicketAssignee } from "../../../constants/enums"
import { dieselTags, ocTags } from "../../../constants/ticketTags"
import SetupNotificationQueue from "./SetupNotificationQueue"


const useStyles = makeStyles(theme => ({
  root: {
    width: 370,
    marginLeft: 12,
    borderRadius: 8,
    "&:before": {
      height: 0,
    },
  },
  attentionIndicator: {
    marginBottom: theme.spacing(1),
  },
  groupLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  updateTimes: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonGroups: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subTasks: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  notes: {
    marginBottom: theme.spacing(2),
  },
}))


function Wrapper ({ children }: { children: any }) {
  const classes = useStyles()
  return (
    <Accordion className={classes.root}>
      <FlexContainer direction='ttb' p={0.5}>
        {children}
      </FlexContainer>
    </Accordion>
  )
}


export default function TicketPanel (
  {
    userId,
    claimId,
    claim,
    type,
  }: {
    userId: string
    claimId: string
    claim?: Claim
    type?: string
  },
) {
  const classes = useStyles()
  const [ticket, loading, error, ticketRef] = useTicket(claimId)
  const [creatingTicket, setCreatingTicket] = useState(false)
  const [
    subTasks,
    subTasksSnap,
    ,
    subTaskErr,
    subTasksRef,
  ] = useCollection(`tickets/ticket_${claimId}/subTasks`)

  const claimsRef = useCollection(`users/${userId}/claims`)
  const claims = []

  if (claimsRef[0] && claimsRef[0].length > 0) {
    for (const entry in claimsRef[0]) {
      if (!entry ||
        claimsRef[0][entry].id === claimId) {
        continue
      }

      claims.push(claimsRef[0][entry])
    }
  }

  if (loading || creatingTicket) {
    if (ticket && ticket.id && creatingTicket) {
      setCreatingTicket(false)
    }
    return (
      <Wrapper>
        <Grid
          container
          style={{ width: "100%", height: "100%" }}
          justifyContent="center"
          alignItems='center'
        >
          <CircularProgress />
        </Grid>
      </Wrapper>
    )
  }

  if (!ticket || !ticket.id) {
    return (
      <Wrapper>
        <Button
          variant='outlined' color='primary' onClick={async () => {
            setCreatingTicket(true)
            const claims = await db
              .collectionGroup("claims")
              .where("id", "==", claimId)
              .get()
            if (!claims.docs) {
              throw new Error(`No claim with id "${claimId}"`)
            }
            const claimData = first(claims.docs)?.data()
            const claimLastUpdated = claimData?.updatedTime
            const phoneNumber = claimData?.phoneNumber
            await db.collection("tickets").doc(`ticket_${claimId}`).set({
              id: `ticket_${claimId}`,
              claimId,
              claimLastUpdated,
              claimPhoneNumber: phoneNumber ||
                firebase.firestore.FieldValue.delete(),
              hasPhoneNumber: Boolean(phoneNumber),
              callCount: 0,
              reachedCount: 0,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              snoozeTimeStart: null,
              snoozeTimeEnd: null,
              assignedGroup: TicketAssignee.AWAITING_KV,
            }, { merge: true })
          }}
        >Create Ticket
        </Button>
      </Wrapper>
    )
  }

  if (error || subTaskErr) {
    return <Typography variant='body1'>{error || subTaskErr}</Typography>
  }

  const {
    snoozeTimeStart,
    snoozeTimeEnd,
    claimLastUpdated,
    createdAt,
    updatedAt,
    phoneNumberValid,
    assignedGroup,
  } = ticket
  console.info("-> : TicketPanel -> phoneNumberValid", phoneNumberValid)

  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
  const dataPath = `firestore/data/users/${userId}/claims/${claimId}`
  const firestoreUrl = projectId === "demo-claimback"
    ? `http://localhost:4000/${dataPath}`
    : `https://console.firebase.google.com/project/${projectId}/${dataPath}`

  const mongodbOrg = "5df8c37c79358e9b6a7bec53"
  const replicaSet = "5dfb83d0cf09a2162ff364bc"
  const mongodbProjectId = projectId === "demo-claimback"
    ? "claimback-localhost"
    : projectId
  const mongodbUrl = `https://cloud.mongodb.com/v2/${
    mongodbOrg}#metrics/replicaSet/${replicaSet}/explorer/${
    mongodbProjectId}/claims/find`
  const hostUrl = "https://claimback.de"
  const onlineCasinoUrl = hostUrl + "/online-casino"
  const dieselUrl = hostUrl + "/diesel"
  
  return (
    <Wrapper>
      <AttentionIndicator
        className={classes.attentionIndicator}
        snoozeTimeEnd={snoozeTimeEnd}
        snoozeTimeStart={snoozeTimeStart}
        ticket={ticket}
      />
      <Chip
        className={classes.groupLabel}
        label={assignedGroup}
      />
      <UpdateTimes
        className={classes.updateTimes}
        claimLastUpdated={claimLastUpdated}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <PhoneValidBadge phoneNumberValid={phoneNumberValid} />

      <SnoozeButtons
        className={classes.buttonGroups}
        ticket={ticket}
      />
      <MoveButtons
        className={classes.buttonGroups}
        ticket={ticket}
        claimType={claim?.type}
      />
      {type === "online-casino" &&
      <>
        <div style={{marginTop: "16px", marginBottom: "16px"}}>
          <Typography variant='subtitle2'>Online Casino Funnel</Typography>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={onlineCasinoUrl + "/spiel-art/" + claimId}>
            Type of games page
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={onlineCasinoUrl + "/accountinformationen/" + claimId}>
            Contact info page
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={onlineCasinoUrl + "/transaktionslisten/" + claimId}>
            Transaction list page
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={onlineCasinoUrl + "/transaktionslisten-passwords/" + claimId}>
            Transaction list password
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={onlineCasinoUrl + "/confirmation/" + claimId}>
            Confirmation page
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={onlineCasinoUrl + "/transaktionslisten-anfordern/" + claimId}>
            How to request TL
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={onlineCasinoUrl + "/unterschrift/" + claimId}>
            POA page
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={hostUrl + "/datenschutzerklaerung/" + claimId}>
            Lead registration
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={onlineCasinoUrl + "/casinos/" + claimId}>
            Casinos page
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={onlineCasinoUrl + "/login/" + userId}>
            Status page
          </Link>
        </div>
        <div style={{ marginTop: "16px", marginBottom: "16px" }}>
          <Typography variant="subtitle2">Notification</Typography>
          <SetupNotificationQueue claimId={claimId}/>
        </div>
      </>
      }
      {type === "claims" && (
        <div style={{ marginTop: "16px", marginBottom: "16px" }}>
          <Typography variant="subtitle2">Claims Funnel</Typography>

          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={dieselUrl + "/unterschrift/" + claimId}
          >
            POA page
          </Link>
          <span> • </span>
          <Link
            target='_blank'
            rel='noopener noreferrer'
            href={dieselUrl + "/upload-page/" + claimId}>
            Upload Page
          </Link>
        </div>
      )}
      {
        claims && claims.length > 0 &&
          <div style={{marginTop: "16px"}}>
            <Typography variant='subtitle2'>
              Other claims with the same user
            </Typography>
            <ul style={{paddingInlineStart: "30px",
              marginBlockStart: "8px", marginBlockEnd: "8px"}}>
              {
                claims.map((claimRecord, index) => {
                  return <li key={index}>
                    <Link
                      target='_blank'
                      rel='noopener noreferrer'
                      href={(claimRecord.type === "online-casino"
                        ? "/online-casino-claims/"
                        : "/claims/") + claimRecord.id + "/show"}>
                      {claimRecord.id}
                    </Link>
                  </li>
                })
              }
            </ul>
          </div>
      }

      <SubTasks
        className={classes.subTasks}
        // @ts-expect-error
        subTasks={subTasks}
        snap={subTasksSnap}
        subTasksRef={subTasksRef}
        claimId={claimId}
      />
      {
        (
          !(
            typeof ticket.notes === "string" ||
            ticket.notes instanceof String
          )
        ) ? <Notes
            className={classes.notes}
            ticket={ticket}
            ticketRef={ticketRef}
          />
          : <p style={{paddingTop: "8px",
            paddingBottom: "8px", fontSize: "0.8rem"}}>
            <i>A note was saved in the wrong format and can’t be displayed.</i>
          </p>
      }
      <TagPanel
        ticket={ticket}
        ticketRef={ticketRef}
        tags={
          claim?.type === "diesel"
            ? dieselTags
            : claim?.type === "online-casino"
              ? ocTags
              : []
        }
        claimType={claim?.type}
      />

      {claimId && claim &&
        <ZendeskPanel
          claim={claim}
          claimId={claimId}
        />
      }

      <div>
        <Typography variant='subtitle2'>External Links</Typography>
        <Link href={firestoreUrl}>
          Firestore
        </Link>
        <span> • </span>
        <Link href={mongodbUrl}>
          MongoDB
        </Link>
      </div>

    </Wrapper>
  )
}
