import {
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
} from "react-admin"
import { db } from "../../firebase"

export default function () {
  const notify = useNotify()

  return (
    <SimpleForm
      save={async (data: {[key: string]: any}) => {
        const updatedFields = {
          name: data.name,
          label: data.label,
          type: data.type,
        }

        if (data.id) {
          const dataRef = await db.collection("customFields").doc(data.id)
          await dataRef.set(updatedFields)
          notify("Element updated")
        }
        else {
          await db.collection("customFields").add(updatedFields)
          notify("Element created")
        }
      }}
      
    >
      <TextInput label="Name" source="name" />
      <TextInput label="Label" source="label" />
      <SelectInput
        label="Datatype"
        source="type"
        initialValue="text"
        choices={[
          { id: "number", name: "Number" },
          { id: "text", name: "Text" },
          { id: "currency", name: "Currency"},
          { id: "boolean", name: "Boolean" },
          { id: "date", name: "Date" },
        ]}
      />
    </SimpleForm>
  )
}
