import React from "react"

import AudiIcon from "../lib/icons/Audi"
import BmwIcon from "../lib/icons/Bmw"
import MercedesIcon from "../lib/icons/Mercedes"
import PorscheIcon from "../lib/icons/Porsche"
import SeatIcon from "../lib/icons/Seat"
import SkodaIcon from "../lib/icons/Skoda"
import VolkswagonIcon from "../lib/icons/Volkswagon"


export default function BrandIcon ({ brand }: { brand: string }) {
  switch (brand) {
    case "audi":
      return <AudiIcon />
    case "bmw":
      return <BmwIcon />
    case "volkswagon":
      return <VolkswagonIcon />
    case "porsche":
      return <PorscheIcon />
    case "seat":
      return <SeatIcon />
    case "skoda":
      return <SkodaIcon />
    case "mercedes":
      return <MercedesIcon />
    default:
      return null
  }
}
