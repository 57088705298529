import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  grid: {
    padding: 10,
    justify: "space-evenly",
    alignItems: "flex-start",
  },
  card: {
    width: 450,
    height: 475,
  },
  cardHeader: {
    maxWidth: 400,
    color: "white",
  },
  editIcon: {
    color: "white",
  },
  listIcons: {
    padding: 4,
    fontSize: 30,
  },
  searchPanel: {
    padding: 5,
    color: "white",
    fontSize: 12,
  },
  filter: {
    padding: 2,
  },
  body: {
    color: "white",
    fontSize: 14,
  },
}))
export default useStyles
