/**
 * Maps resource name to database table name.
 * This is used to implement different views for the same data in react admin.
 */
export default function getTable (name: string) {
  switch (name) {
    case "bank-fee-recovery":
      return "KapCases_backup"

    case "brc-cases":
      return "kapCases"

    case "cases":
      return "kapCases"

    case "diesel":
      return "kapCases"

    case "online-casino-claims":
      return "claims"

    case "claims-reject":
      return "claims"

    default:
      return name
  }
}
