import MUIButton from "@material-ui/core/Button"
import styled from "styled-components"

const Button = styled(MUIButton)`
  && {
    box-sizing: border-box;
    font-family: Heebo;
    text-transform: initial;
    background: ${props =>
  // @ts-expect-error
    (props.inactive ? "transparent" : "white")
};
    border: 2px solid transparent;
    border-color: ${props =>
  // @ts-expect-error
    props.inactive
      ? "#b9b8d1"
    // @ts-expect-error
      : props.active
        ? "#2460d8"
        : props.disabled
          ? "#b9b8d1"
          : "transparent"
};
    border-radius: 8px;
    height: 45px;
    line-height: 45px;
    padding: 0 30px;
    box-shadow: ${props =>
  // @ts-expect-error
    props.active
      ? "0 2px 10px 0 rgba(22, 20, 102, 0.25)"
      : "0 1px 5px 0 rgba(22, 20, 102, 0.15);"
};
    color: ${props =>
  // @ts-expect-error
    (props.inactive ? "#b9b8d1" : "#2460d8")
};
    transition: all 0.175s;
    &:hover {
      background: white;
      box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.25);
    }
  }
`

export default Button
