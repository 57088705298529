import FirebaseTimestamp from "./FirebaseTimestamp"

const phoneCallFields = {
  id: String,
  claimId: String,
  callResult: String,
  callNotes: String,
  callType: String,
  claimNotes: String,
  endTime: FirebaseTimestamp,
  _endTime: Date,
  callReasons: Array,
  phoneNumber: String,
  phoneNumberValid: Boolean,
  startTime: FirebaseTimestamp,
  _startTime: Date,
  userId: String,
}

export default phoneCallFields
