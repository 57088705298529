import FirebaseTimestamp from "./FirebaseTimestamp"

const userFields = {
  alt_track: Boolean,
  id: String,  // Mandatory and must be equal to Firestore's native `_id`
  claimId: String,
  userId: String,
  email: String,
  emailVerified: Boolean,
  fullName: String,
  hasCompletedRegistration: Boolean,
  importedAt: FirebaseTimestamp,
  isAnonymous: Boolean,
  leadSource: String,
  phoneNumber: String,  // Phone number from auth provider
  phone: String,  // User provided phone number
  photoUrl: String,
  // providerData: []
  createdAt: FirebaseTimestamp,
  _createdAt: Date,
  lastSignInTime: FirebaseTimestamp,
  _lastSignInTime: Date,
  updatedAt: FirebaseTimestamp,
  _updatedAt: Date,
  updatedBy: String,
  updatedById: String,
  updatedTime: FirebaseTimestamp,  // deprecated
  _updatedTime: Date,  // deprecated
  registrationTime: FirebaseTimestamp,
  _registrationTime: Date,
  gdprCheckRequested: Boolean,
  gdprCheckDone: Boolean,
}

export default userFields
