import FirebaseTimestamp from "./FirebaseTimestamp"

const kapCaseFields = {
  id: String,
  claimId: String,
  createdAt: FirebaseTimestamp,
  _createdAt: Date,
  updatedAt: FirebaseTimestamp,
  _updatedAt: Date,
  case: Object,
  customer: Object,
  customFieldValues: Object,
  lawFirm: String,
  uploadedFiles: Array,
  tags: [{ id: String }],
  whatsAppLimitReached: Boolean,
}

export default kapCaseFields
