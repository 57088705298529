import React from "react"

import {
  useDeleteMany,
  useListContext,
  CRUD_DELETE_MANY,
  useRefresh,
  useNotify,
  useResourceContext,
  useUnselectAll,
  CRUD_DELETE,
  useDelete,
} from "react-admin"
  
import Button from "@material-ui/core/Button"
import DeleteIcon from "@material-ui/icons/Delete"
import IconCancel from "@material-ui/icons/Cancel"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { red } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core"
import {db} from "../firebase"

const buttonStyles = makeStyles(theme => ({
  deleteIcon: {
    color: red[500],
  },
  cancelIcon: {
    color: "#009be5",
  },
}))

export interface DeleteDialogProps{
    open:boolean,
    cancel:()=>void,
    deletion:()=>void,
  }

function DeleteDialog (props:DeleteDialogProps) {
  const {open, cancel, deletion} = props
  const classes = buttonStyles()
    
  return (
    <Dialog 
      fullWidth
      open={open}
      aria-label="Are you sure?"
    >
      <DialogTitle id="delete-dialog-title">
            Are you sure you want to delete?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-text">
              Corresponding tickets will be deleted as well.
        </DialogContentText>
        <DialogActions>
          <Button onClick={deletion}
            className={classes.deleteIcon}
            startIcon={<DeleteIcon />}
          >
                Delete
          </Button>
          <Button 
            startIcon={<IconCancel />}
            onClick={cancel}
            className={classes.cancelIcon}
          >
                Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default function ClaimBulkDelete (props: Record<string, unknown>) {
  const classes = buttonStyles()

  const [open, setOpen] = React.useState(false)

  const [deleteMany] = useDeleteMany()
  const [deleteOne] = useDelete()
  const records = useListContext()
  const refresh = useRefresh()
  const notify = useNotify()
  const resource = useResourceContext()
  const unselectAll = useUnselectAll()
  
  function handleModal () {
    setOpen(!open)
  }
  
  function handleDelete () {
    setOpen(!open)

    records.selectedIds?.forEach((id) => {
      // get database phoneCalls and delete if exists
      const query = db.collection("phoneCalls")
        .where("claimId", "==", id)

      query.get().then((documents) => {
        documents.forEach((doc) => {
          if (!doc.exists) {
            return
          }

          deleteOne("phoneCalls",
            doc.id, doc,
            {
              action: CRUD_DELETE,
            },
          )
        })
      })
    })

    deleteMany("tickets",
      records.selectedIds?.map((ids) =>
        `ticket_${ids}`), {
        action: CRUD_DELETE_MANY,
        onFailure: error => {
          notify(
            typeof error === "string"
              ? error
              : error.message || "ra.notification.http_error",
            {
              type: "warning",
              messageArgs: {
                _:
                                  typeof error === "string"
                                    ? error
                                    : error && error.message
                                      ? error.message
                                      : undefined,
              },
            },
          )
          setOpen(false)
        },
      })

    deleteMany("claims",
      records.selectedIds, {
        action: CRUD_DELETE_MANY,
        onSuccess: () => {
          notify("ra.notification.deleted", {
            type: "info",
            messageArgs: { smart_count: records.selectedIds.length },
          })
          unselectAll(resource)
          refresh()
        },
        onFailure: error => {
          notify(
            typeof error === "string"
              ? error
              : error.message || "ra.notification.http_error",
            {
              type: "warning",
              messageArgs: {
                _:
                            typeof error === "string"
                              ? error
                              : error && error.message
                                ? error.message
                                : undefined,
              },
            },
          )
          setOpen(false)
        },
      })
  }

  return (
    <>
      <Button onClick={handleModal}
        className={classes.deleteIcon}
        startIcon={<DeleteIcon />}
      >
        Delete
      </Button>
      <DeleteDialog
        open={open}
        cancel={handleModal}
        deletion={handleDelete}
      />
    </>
  )
}
