import styled from 'styled-components'
import media from '../../utils/media'

export default styled.h1`
  font-family: grad, serif;
  font-style: normal;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 15px;
  color: ${props =>
    // @ts-expect-error
    props.light ? '#e2e9fb' : '#161466'
  };
  text-align: ${props =>
    // @ts-expect-error
    props.center ? 'center' : 'inherit'
  };
  ${media.only.xs`
    font-size: 40px;
    line-height: 56px;
    letter-spacing: 0.2px;
  `}
  ${media.only.sm`
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.2px;
  `}
  ${media.only.md`
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.2px;
  `}
  ${media.only.lg`
    font-size: 80px;
    line-height: 88px;
    letter-spacing: 0.8px;
  `}
  ${media.only.xl`
    font-size: 88px;
    line-height: 96px;
    letter-spacing: 0.8px;
  `}
`
