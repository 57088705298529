import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"
import { DateTimePicker } from "@material-ui/pickers"
import moment, { Moment } from "moment"
import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"


const useStyles = makeStyles(theme => ({
  buttonGroup: {
    marginBottom: theme.spacing(1),
  },
  ungroupedButton: {
    marginRight: theme.spacing(1),
  },
}))


async function startSnooze (
  { ticket, endTime }: { ticket: Ticket, endTime: Moment | null },
) {
  let endTimeDate: Date

  if (endTime && endTime.toDate) {
    endTimeDate = endTime.toDate()
  }
  else {
    endTimeDate = new Date()
  }

  const startTime = moment()
  await ticket.update({
    snoozeTimeStart: startTime.toDate(),
    snoozeTimeEnd: endTimeDate,
  })
}


interface TimeDialogParams {
  handleClose: () => void
  open: boolean
  ticket: Ticket
  [x: string]: any
}

function CustomTimeDialog (
  { handleClose, open, ticket, ...props }: TimeDialogParams,
) {
  const [selectedDate, handleDateChange] = useState<Moment | null>(moment())

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Snooze</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Set the exact date and time
          you would like to this claim to grab your attention again
        </DialogContentText>
        <DateTimePicker
          label='DateTimePicker'
          value={selectedDate}
          onChange={handleDateChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={() => {
            startSnooze({ ticket, endTime: selectedDate })
            handleClose()
          }}
          color='primary'
        >
          Snooze
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default function SnoozeButtons (
  // @ts-expect-error
  { ticket, ...props }: { ticket: Ticket, [key: string]: string },
) {
  const [customDialogOpen, setCustomDialogOpen] = useState(false)
  const classes = useStyles()
  return (
    <div {...props}>
      <CustomTimeDialog
        ticket={ticket}
        open={customDialogOpen}
        handleClose={() => setCustomDialogOpen(false)}
      />
      <Typography variant='subtitle2'>Snooze</Typography>
      <Box>
        <Typography variant='caption'>8am on:</Typography>
        <ButtonGroup
          color='primary'
          size='small'
          fullWidth
          className={classes.buttonGroup}
        >
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              {
                ticket,
                endTime: moment().add(1, "days").startOf("day").add(8, "hours"),
              },
            )}
          >
            {moment().add(1, "days").format("dddd")}
          </Button>
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              {
                ticket,
                endTime: moment().add(2, "days").startOf("day").add(8, "hours"),
              })
            }
          >
            {moment().add(2, "days").format("dddd")}
          </Button>
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              {
                ticket,
                endTime: moment().add(3, "days").startOf("day").add(8, "hours"),
              })
            }
          >
            {moment().add(3, "days").format("dddd")}
          </Button>
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              {
                ticket,
                endTime: moment().add(4, "days").startOf("day").add(8, "hours"),
              })
            }
          >
            {moment().add(4, "days").format("dddd")}
          </Button>
        </ButtonGroup>
        <ButtonGroup
          color='primary'
          size='small'
          fullWidth
          className={classes.buttonGroup}
        >
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              {
                ticket,
                endTime: moment().add(5, "days").startOf("day").add(8, "hours"),
              })
            }
          >
            {moment().add(5, "days").format("dddd")}
          </Button>
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              {
                ticket,
                endTime: moment().add(6, "days").startOf("day").add(8, "hours"),
              })
            }
          >
            {moment().add(6, "days").format("dddd")}
          </Button>
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              {
                ticket,
                endTime: moment().add(7, "days").startOf("day").add(8, "hours"),
              })
            }
          >
            {moment().add(7, "days").format("dddd")}
          </Button>
        </ButtonGroup>
        
        <Typography variant='caption'>8am in:</Typography>
        <ButtonGroup
          color='primary'
          size='small'
          fullWidth
          className={classes.buttonGroup}
        >
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              { ticket, 
                endTime: 
                moment().add(10, "day").startOf("day").add(8, "hour") })
            }
          >
            10 days
          </Button>
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              { ticket, 
                endTime: 
                moment().add(2, "weeks").startOf("day").add(8, "hour") })
            }
          >
            2 weeks
          </Button>
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              { ticket, 
                endTime: 
                moment().add(1, "months").startOf("day").add(8, "hour") })
            }
          >
            1 month
          </Button>
        </ButtonGroup>
        <ButtonGroup
          color='primary'
          size='small'
          fullWidth
          className={classes.buttonGroup}
        >
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              { ticket, 
                endTime: 
                moment().add(3, "months").startOf("day").add(8, "hour") })
            }
          >
            3 months
          </Button>
          <Button variant='outlined' color='primary'
            onClick={() => startSnooze(
              { ticket,
                endTime: 
                moment().add(6, "months").startOf("day").add(8, "hours") })
            }
          >
            6 months
          </Button>

        </ButtonGroup>
      </Box>
      <Box mt={1}>
        <Button
          variant='outlined'
          size='small'
          color='primary'
          onClick={() => setCustomDialogOpen(true)}
          className={classes.ungroupedButton}
        >
          Custom
        </Button>
        <Button
          variant='outlined'
          size='small'
          color='primary'
          onClick={() => startSnooze({ ticket, endTime: null })}
        >
          Wake Up
        </Button>
      </Box>
    </div>
  )
}
