import React, { useEffect, useState } from "react"
import { remove, startsWith } from "lodash"
import {
  Box,
  List,
  Typography,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core"

import { db } from "../../firebase"
import TicketListItem from "./TicketListItem"
import ticketRequiresAttention from "../../utils/ticketRequiresAttention"
import Flex from "../../lib/components/Flex"
import { TicketAssignee } from "../../constants/enums"
import { ocTags } from "../../constants/ticketTags"

const LIMIT_BATCH_SIZE = 50


export default function TrackingColumn (
  { assignedGroup, tagFilters = [], tagData }:
  {
    assignedGroup: string
    tagFilters: string[]
    tagData: {name: string, id: string}[]
  },
) {
  const [ticketDocs, setTicketDocs] = useState<any[]>([])
  const [limit, setLimit] = useState(LIMIT_BATCH_SIZE)
  const [snapCount, setSnapCount] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [snoozedCount, setSnoozedCount] = useState(0)
  const [awakeCount, setAwakeCount] = useState(0)
  const [shouldLoadAll, setShouldLoadAll] = useState(false)

  useEffect(() => {
    // TODO: Don't load claim for each ticket.
    //   Tickets should already have a type field, or better:
    //   Tickets should be merged with claims,
    //   since each claim has exactly one ticket.
    async function getTypeOfClaim (claimId: string) {
      const claimQuerySnap = await db
        .collectionGroup("claims")
        .where("id", "==", claimId)
        .get()

      if (claimQuerySnap.empty) {
        return
      }

      return claimQuerySnap.docs[0].data().type
    }

    let q = db
      .collection("tickets")
      .where("assignedGroup", "==", assignedGroup)
      .where("type", "==", "online-casino")
      .orderBy("claimLastUpdated", "desc")
    
    if (tagFilters.length) {

      const tagDataName = tagData.map(({name}) => name)

      const parsedTagFilters = tagFilters.map((userInput) => {

        const tagNameIndex = tagDataName.reduce((acc, tagName, index) => {
          if (tagName.startsWith(userInput) || tagName === userInput) {
            acc = index
          }
          return acc
        }, -1)

        return tagNameIndex > -1 ? tagData[tagNameIndex].id : userInput
      })

      q = q.where("tags", "array-contains-any", parsedTagFilters)
    }

    q.onSnapshot(async (snap) => {
      const awakeTickets = []

      for (const doc of snap.docs) {
        if (doc.data()?.isLead === true) {
          continue
        }

        awakeTickets.push(doc)
      }

      const snoozed = remove(awakeTickets, (doc) => {
        const data = doc.data()
        return !ticketRequiresAttention((data || {}) as Ticket)
      })

      setAwakeCount(awakeTickets.length)
      setSnoozedCount(snoozed.length)
      setTicketDocs(awakeTickets.concat(snoozed).slice(0, limit))
      setLoaded(true)
      setSnapCount(snap.size)
    })
  }, [limit, assignedGroup, tagFilters])

  if (shouldLoadAll && snapCount >= limit) {
    setLimit(limit + LIMIT_BATCH_SIZE)
  }
  else if (
    loaded &&
    awakeCount === 0 &&
    snoozedCount === limit &&
    assignedGroup !== TicketAssignee.COMPLETE
  ) {
    setLimit(limit + LIMIT_BATCH_SIZE)
  }
  return (
    <Box p={0.5}>
      <Typography variant='h6' color='textPrimary' gutterBottom align='center'>
        {assignedGroup}
      </Typography>
      <div
        style={{padding: 8,
          textAlign: "center",
          display: "flex",
          justifyContent: "center"}}>
        <div style={{padding: 4, background: "#8bc34a", 
          borderRadius: 8, marginRight: 4, width: "50%"}}>
          Awake: {awakeCount}
        </div>
        <div style={{padding: 4, background: "#78909c", 
          borderRadius: 8, width: "50%"}}>
          Snoozed: {snoozedCount}
        </div>
      </div>
      <Flex center>
        <FormControlLabel
          control={
            <Switch
              checked={shouldLoadAll}
              onChange={() => setShouldLoadAll(!shouldLoadAll)}
            />
          }
          label='Load All'
          // @ts-expect-error TODO: variant does not exist?
          variant='filled'
          labelPlacement='start'
        />
      </Flex>
      <List dense>
        {ticketDocs.map(ticketDoc => {
          const { claimId } = ticketDoc.data()
          const link = `/online-casino-claims/${claimId}/show`
          return (
            <TicketListItem
              ticketDoc={ticketDoc}
              key={ticketDoc.id}
              link={link}
              claimId={claimId}
              showPhoneIcons
              colorItemsByStatus={false}
            />
          )
        })}
        {snapCount >= limit && (
          <Button
            fullWidth
            onClick={() => setLimit(limit + LIMIT_BATCH_SIZE)}
          >
            Show More
          </Button>
        )}
      </List>
    </Box>
  )
}
