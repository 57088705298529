import React, { useState, useEffect } from "react"
import { reject } from "lodash"
import Grid from "@material-ui/core/Grid"
import {Typography, Chip, TextField} from "@material-ui/core"

import useStyles from "../Tracking/styles"
import TrackingColumn from "./TrackingColumn"
import { ocTags } from "../../constants/ticketTags"
import Flex from "../../lib/components/Flex/Flex"
import { TicketAssignee } from "../../constants/enums"
import { db } from "../../firebase"


function TagFilters (
  { filters, setFilters }: {
    filters: string[]
    setFilters: (a: any) => void
  },
) {
  const [textInput, setTextInput] = useState("")

  function handleChangeFilter (e: React.ChangeEvent<HTMLInputElement>) {
    setTextInput(e.target.value)
    const ocTagIds = ocTags.map(({id}) => id)

    const cleanStateId = filters.filter((tagId) => ocTagIds.includes(tagId))

    if (!e.target.value || e.target.value.length < 1) {
      // clear value
      setFilters(cleanStateId)
      return
    }

    const userInput = e.target.value

    if (!userInput.trim().includes(",")) {
      setFilters([
        ...cleanStateId,
        userInput,
      ])

      return
    }

    const tagSplit = userInput.split(",")

    const multipleTags = tagSplit.filter(tag =>
      !cleanStateId.includes(tag.trim()),
    )

    setFilters([
      ...cleanStateId,
      ...multipleTags,
    ])

  }

  return (
    <Flex wrap center>
      <Typography
        color='textPrimary'
        style={{ marginRight: 14 }}
      >
        Filters:
      </Typography>
      <TextField
        variant="outlined"
        label="Tag Filter"
        onChange={handleChangeFilter}
        size="small"
        value={textInput}
      />
      {ocTags.map(({ name, id }) => {
        return (
          <Chip
            key={id}
            style={{ margin: 4 }}
            color={filters.includes(id) ? "primary" : "default"}
            clickable
            label={name}
            onClick={() => {
              filters.includes(id)
                ? setFilters(reject(filters, (t) => id === t))
                : setFilters(filters.concat(id))

              if (!textInput.includes(id)) {
                return
              }

              if (!textInput.includes(",")) {
                setTextInput(textInput.replace(id, ""))
                return
              }

              const indexOfId = textInput.indexOf(id)

              let cleanedFilterOutput

              if (
                textInput[indexOfId - 1] === "," &&
                textInput[indexOfId + id.length] === ","
              ) {
                cleanedFilterOutput = textInput.replace(id + ",", "")
              }
              else if (
                textInput[indexOfId - 1] === "," &&
                textInput[indexOfId + id.length] !== ","
              ) {
                cleanedFilterOutput = textInput.replace("," + id, "")
              }
              else {
                cleanedFilterOutput = textInput.replace(id + ",", "")
              }

              setTextInput(cleanedFilterOutput)
            }}
          />
        )
      })}
    </Flex>
  )
}


export default function Tracking () {
  const [filters, setFilters] = useState([])
  const [tagData, setTagData] = useState<{id: string, name: string}[]>([])


  const classes = useStyles({})

  function useDebounce (value: string[], delay: number) {
    const [debounceValue, setDebounceValue] = useState(value)

    useEffect(() => {
      const timer = setTimeout(() => setDebounceValue(value), delay)

      return () => {
        clearTimeout(timer)
      }
    }, [value, delay])

    return debounceValue
  }

  useEffect(() => {
    async function getCustomTags () {
      const customTagSnap = await db
        .collectionGroup("customTags")
        .get()

      if (customTagSnap.empty) {
        setTagData(ocTags)
        return
      }
      
      const customTagData = customTagSnap.docs
        .filter((tagDoc) => tagDoc.data().claimType === "online-casino")
        .map((tagDoc) => {
          const customTag = tagDoc.data()
          return {
            name: customTag.name,
            id: customTag.id,
          }
        })

      setTagData([...customTagData, ...ocTags])
    }

    getCustomTags()

  }, [])
  

  const toFilter = useDebounce(filters, 500)
  return (
    <div className={classes.root}>
      <TagFilters filters={filters} setFilters={setFilters} />
      <Grid container spacing={1} wrap='nowrap'>
        {Object
          .values(TicketAssignee)
          // Remove stages which are not applicable to online casino
          .filter((assignee) =>
            assignee !== TicketAssignee.BFR_CALL_LIST,
          )
          .map(type => {
            return (
              <Grid item xs key={type}>
                <TrackingColumn 
                  assignedGroup={type} 
                  tagData={tagData}  
                  tagFilters={toFilter} 
                />
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}
