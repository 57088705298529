import React from "react"
import {
  BooleanField,
  DateField,
  EditButton,
  FunctionField,
  Link,
  NumberField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from "react-admin"
import { useHistory } from "react-router-dom"
import { Button, makeStyles } from "@material-ui/core"

import PhoneCallButton from "../PhoneCallButton"
import SendSMSButton from "../SendSMSButton"
import { SendEmailButton } from "../SendEmailButton"
import ClaimCommunications from "../Claim/ClaimCommunications"
import ClaimHistory from "../Claim/ClaimHistory"
import OnlineCasinoClaimFiles from "./OnlineCasinoClaimFiles"
import SendToLawyersButton from "../SendToLawyersButton"
import TicketPanel from "../Claim/TicketPanel"
import SendWhatsappButton from "../SendWhatsappButton"
import GeneratePOAButton from "../GeneratePOAButton"
import { FlexContainer } from "../../lib/components/Container"
import INSURANCE_OPTIONS from "../../lib/constants/insurance-providers"
import { ChipListObjects } from "../ChipList"
import CasinoFieldValuesList from "../CasinoFieldValuesList"
import DuplicateClaims from "../DuplicateClaims"
import SendToCmtButton from "../SendToCmtButton"
import GenerateFunderContract from "../GenerateFunderContract"


const insuranceChoices = INSURANCE_OPTIONS
  .map(opt => ({ id: opt.value, name: opt.label }))

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: theme.spacing(1),
  },
  lastField: {
    marginBottom: theme.spacing(3),
  },
}))


interface ClaimActionsParams {
  data?: Claim
  basePath: string
}

function ClaimActions ({ data, basePath }: ClaimActionsParams) {
  const classes = useStyles()
  const history = useHistory()

  if (!data) {
    return null
  }

  return (
    <>
      <FlexContainer direction='rtl' p={0.3}>
        <EditButton
          // @ts-expect-error
          data={data}
          record={{ id: data.id }}
          basePath={basePath}
          className={classes.actionButton}
        />
        <PhoneCallButton claim={data} className={classes.actionButton} />
        <SendEmailButton email={data.userEmail} claimId={data.id}
          className={classes.actionButton} />
        <SendWhatsappButton claim={data} className={classes.actionButton} />
        <SendSMSButton claim={data} className={classes.actionButton} />
        <SendToLawyersButton
          claimData={data}
          className={classes.actionButton}
        />
        <SendToCmtButton
          claimData={data}
          className={classes.actionButton}
        />
        <DuplicateClaims ocClaim={data} selectedIds={[data.id]} isShow={true}/>
        <GeneratePOAButton claim={data} className={classes.actionButton}/>
        <GenerateFunderContract claim={data} className={classes.actionButton}/>
      </FlexContainer>
    </>
  )
}


function Aside ({ record }: { record?: Claim }) {
  if (!record) {
    return null
  }

  return (
    <TicketPanel
      userId={record.userId}
      claimId={record.id}
      claim={record}
      type="online-casino"
    />
  )
}


function OptionalBrcMultipleCars (props: Record<string, any>) {
  const claim = useRecordContext(props)
  return claim && claim.lawFirm === "BRC"
    ? <BooleanField
      source='brcMultipleCars'
      label='BRC Multiple Cars'
      addLabel={true}
    />
    : null
}


export default function OnlineCasinoClaimShow (props: Record<string, any>) {
  const classes = useStyles()

  return (
    <Show
      {...props}
      // @ts-expect-error TODO
      label='ShowClaim'
      // @ts-expect-error TODO
      actions={<ClaimActions {...props} />}
      style={{ flexGrow: 1 }}
      aside={<Aside />}
    >
      <TabbedShowLayout>
        <Tab label='Summary'>
          <BooleanField
            source='lead'
            label='Lead'
            addLabel={true}
          />
          <BooleanField
            source='ocOlgNotSuitable'
            label='Olg Not Suitable'
            addLabel={true}
          />
          <TextField label='Claim ID' source='id' />
          <FunctionField
            label='User ID'
            source='userId'
            render={(claim: any) =>
              <Link to={`/users/${claim.userId}/show`}>
                {claim.userId}
              </Link>
            }
          />
          <FunctionField
            label='Referring User ID'
            source='referredBy'
            render={(claim: any) => 
              <Link to={`/users/${claim.referredBy}/show`}>
                {claim.referredBy}
              </Link>
            }
          />
          <TextField label='User Email' source='userEmail' />
          <ChipListObjects source="tags" label="Tags" />
          <TextField label='Law Firm' source='lawFirm' />
          <TextField label='Funder (For CMT)' source='funder' />
          <TextField label='Law Firm Case ID' source='lawFirmCaseId' />
          <TextField label='Lead Source' source='leadSource' />
          <NumberField label='Attempted re-registrations'
            source='reRegistrationCount'/>
          <DateField
            source='userRegistrationTime'
            locales='sv-SE'
            showTime
          />
          <DateField
            source='createdTime'
            locales='sv-SE'
            showTime
          />
          <DateField
            source='updatedTime'
            locales='sv-SE'
            showTime
          />
          <DateField
            source='transmittedToLawfirmAt'
            locales='sv-SE'
            showTime
          />
          <DateField
            source='lastUpdateInCmtAt'
            label='Last updated claim in CMT at'
            locales='sv-SE'
            showTime
          />
          <OptionalBrcMultipleCars />
          <SelectField
            source='claimActionStatus'
            choices={[
              { id: "active", name: "Active" },
              { id: "dead", name: "Dead" },
              { id: "below5k", name: "Below 5k"},
              { id: "onHold", name: "On Hold" },
              { id: "finished", name: "Finished" },
            ]}
          />
          <TextField source='notes' />
          <BooleanField
            source='ocTipicoBigList'
            label='OC Tipico Big List'
            addLabel={true}
          />
          <BooleanField
            source='hasMultipleClaims'
            label='Has Multiple Claims'
            addLabel={true}
          />
          <BooleanField
            source='tlUploaded'
            label='Transaction List Uploaded'
            addLabel={true}
          />
          <BooleanField
            source='accInfoUploaded'
            label='Account Information Uploaded'
            addLabel={true}
          />
          <BooleanField
            source='poaUploaded'
            label='POA Uploaded'
            addLabel={true}
          />
          <DateField
            source='poaUploadedAt'
            label='POA Uploaded At'
            locales='sv-SE'
            showTime
          />
          <BooleanField
            source='tlRequested'
            label='Transaction List Requested'
            addLabel={true}
          />
          <DateField
            source='tlRequestedAt'
            label='Transaction List Requested At'
            locales='sv-SE'
            showTime
          />
          <FunctionField
            label='UTM Parameters'
            source='utmParams'
            render={(obj: any) => {
              if (!obj?.utmParams) {
                return
              }

              return <table>
                {Object
                  .entries(
                    obj.utmParams as
                      Record<string, { source: string, value: any }>,
                  )
                  .map(
                    ([key, valObj]: [string, { source: string, value: any }]) =>
                      <tr>
                        <td>{valObj?.source}: </td>
                        <td>
                          <strong>
                            {Array.isArray(valObj?.value)
                              ? valObj?.value.join(", ")
                              : valObj?.value
                            }
                          </strong>
                        </td>
                      </tr>,
                  )
                }
              </table>
            }}
          />
          <br />
        </Tab>
        <Tab label='Custom Fields'>
          <CasinoFieldValuesList />
          <br />
        </Tab>
        <Tab label='Contact Info'>
          <TextField label='Claim First Name'
            source='firstName' />
          <TextField label='Claim Last Name'
            source='lastName' />
          <TextField label='Contract First Name'
            source='purchaseContractFirstName' />
          <TextField label='Contract Last Name'
            source='purchaseContractLastName' />
          <TextField label='Contract Company'
            source='purchaseContractCompanyName' />
          <TextField source='address' />
          <TextField source='city' />
          <TextField source='postcode' />
          <TextField source='phoneNumber' />
          <SelectField
            source='whatsappContactedNumber'
            choices={[
              { id: null, name: "None" },
              { id: "0", name: "WA 0 Carl  442" },
              { id: "1", name: "WA 1 Follow Up  975" },
              { id: "2", name: "WA 2 Follow Up 982" },
            ]}
          />
          <TextField
            label='IBAN'
            source='iban'
            className={classes.lastField}
          />
        </Tab>
        <Tab label='Files'>
          <OnlineCasinoClaimFiles />
        </Tab>
        <Tab label='Communications'>
          <ClaimCommunications />
        </Tab>
        <Tab label='History'>
          <ClaimHistory />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
