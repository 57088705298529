import FirebaseTimestamp from "./FirebaseTimestamp"

// Fields need clean-up, have to redo this in graphql-compose
const analyticsFields = {
  date: FirebaseTimestamp,
  _date: Date,
  costWithDates: [], // safety-catch field for custom data, non-mandatory
  data: [], // safety-catch field for custom data, non-mandatory
  cost: Number,
  fromDate: String, // just a hack to get graphql querying get resolved
  toDate: String, // same above
}

export default analyticsFields
