/* eslint-disable indent */

import { css } from "styled-components"
import { BREAKPOINTS } from "../constants"


// use px in breakpoints to work properly cross-browser and support users
// changing their browsers font-BreakpointSize:
// https://zellwk.com/blog/media-query-units

function minCSS (px: number, ...args: any[]) {
  return css`
    @media (min-width: ${px}px) {
      ${
        // @ts-expect-error: Must have a tuple type or be passed to a rest param
        css(...args)
      }
    }
  `
}


function maxCss (px: number, ...args: any[]) {
  return css`
    @media (max-width: ${px}px) {
      ${
        // @ts-expect-error: Must have a tuple type or be passed to a rest param
        css(...args)
      }
    }
  `
}


function betweenCss (min: number, max: number, ...args: any[]) {
  return css`
    @media (min-width: ${min}px) and (max-width: ${max}px) {
      ${
        // @ts-expect-error: Must have a tuple type or be passed to a rest param
        css(...args)
      }
    }
  `
}


function from (fromPx: number) {
  function fromFn (...args: any[]) {
    return minCSS(fromPx, ...args)
  }

  fromFn.to = (toPx: number) => (...css: any[]) =>
    betweenCss(fromPx, toPx, ...css)

  return fromFn
}


function to (px: number) {
  return (...args: any[]) => maxCss(px, ...args)
}


Object.keys(BREAKPOINTS).forEach((key) => {
  const { min, max } = BREAKPOINTS[key as BreakpointSize]

  function fromFn (...args: any[]) {
    return minCSS(min, ...args)
  }

  fromFn.to = {}
  Object.keys(BREAKPOINTS).forEach((toKey) => {
    // @ts-expect-error
    fromFn.to[toKey] = (...css: any[]) =>
      betweenCss(min, BREAKPOINTS[toKey as BreakpointSize].max, ...css)
  })
  // @ts-expect-error
  from[key] = fromFn
  // @ts-expect-error
  to[key] = (...args) => maxCss(max, ...args)
})

// iterate through the sizes and create a media template
const media = ["above", "below", "only"]
  .reduce((media: Record<string, any>, key: string) => {
    media[key] = Object.keys(BREAKPOINTS)
      .reduce((accumulator, label: string) => {
        const { min, max } = BREAKPOINTS[label as BreakpointSize]
        switch (key) {
          case "above":
            // @ts-expect-error
            accumulator[label] = (...args: any[]) =>
              minCSS(max + 1, ...args)
            return accumulator

          case "only":
            // @ts-expect-error
            accumulator[label] = (...args: any[]) =>
              betweenCss(min, max, ...args)
            return accumulator

          case "below":
            // @ts-expect-error
            accumulator[label] = (...args: any[]) =>
              maxCss(min - 1, ...args)
            return accumulator

          default:
            return accumulator
        }
      }, {})
    return media
  }, {})

media.from = from
media.to = to

export default media
