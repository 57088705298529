import * as React from "react"
import { useLocation } from "react-router-dom"
import getHttpsCallable from "../utils/getHttpsCallable"

export function usePageTracking () {
  const location = useLocation()

  const trackPage = getHttpsCallable("admin-pageTracking")
  const activityPing = getHttpsCallable("admin-checkUserSession")

  const ping = React.useCallback(async () => {
    try {
      await activityPing({
        tstamp: Date.now(),
      })
    }
    catch (error) {
      console.error("Failed to send activity ping:", error)
    }
  }, [])

  React.useEffect(
    () => {
      trackPage({
        route: location.pathname,
        tstamp: Date.now(),
      })
    },
    [location],
  )

  React.useEffect(
    () => {
      const pingInterval = setInterval(() => {
        ping()
      }, 10000) // Ping every 10 seconds

      return () => clearInterval(pingInterval)
    },
    [],
  )
}
