import * as React from "react"
import { AppBar, Link } from "react-admin"
import { makeStyles, Typography } from "@material-ui/core"


const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
})


export default function CustomAppBar (props: any) {
  const classes = useStyles()

  return (
    <AppBar {...props}>
      <Typography
        variant='h6'
        color='inherit'
        className={classes.title}
        id='react-admin-title'
      />
      <Link to='/' style={{ color: "inherit" }} >
        <Typography variant='h6'>
          { process.env.REACT_APP_BUILD_ENV === "production"
            ? "Claimback Admin"
            : `⚠️⚠️⚠️ Environment: ${process.env.REACT_APP_BUILD_ENV} ⚠️⚠️⚠️`
          }
        </Typography>
      </Link>
      <span className={classes.spacer} />
    </AppBar>
  )
}
