import React from "react"
import {Edit, SimpleForm} from "react-admin"
import PhoneCallForm from "./PhoneCallForm"


export default function PhoneCallEdit (props: Record<string, unknown>) {
  return (
    <Edit {...props}>
      <PhoneCallForm sanitizeEmptyValues={false}/>
    </Edit>
  )
}
