const featureFlagFields = {
  id: String,
  name: String,
  data: String,
  description: String,
  percentageRollout: Number,
  value: Boolean,
}

export default featureFlagFields
