import React, { useState } from "react"
import { useNotify } from "react-admin"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  makeStyles,
} from "@material-ui/core"
import SMSIcon from "@material-ui/icons/Sms"

import firebase from "../firebase"
import { Flex } from "../lib"
import getHttpsCallable from "../utils/getHttpsCallable"


const sendSMSFn = getHttpsCallable("admin-sendSms")

const useStyles = makeStyles(theme => ({
  textarea: {
    width: "100%",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.text.primary,
    borderRadius: 4,
    borderColor: theme.palette.primary.light,
  },
  loader: {
    minHeight: 200,
  },
  results: {
    padding: theme.spacing(4),
  },
}))


interface SendFormParams {
  setMessage: (a: any) => void
  message: string
  handleClose: () => void
  setSubmitting: (a: any) => void
  setSubmitResult: (a: any) => void
  claim: Claim
}

function SendForm (
  {
    setMessage,
    message,
    handleClose,
    setSubmitting,
    setSubmitResult,
    claim,
  }: SendFormParams,
) {
  const classes = useStyles()
  const notify = useNotify()
  return (
    <>
      <DialogTitle>Send SMS Message to {claim.phoneNumber}</DialogTitle>
      <DialogContent>
        <textarea
          className={classes.textarea}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          rows={8}
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={async () => {
            setSubmitting(true)
            try {
              const result = await sendSMSFn({
                claim,
                messageContent: message,
              })
              if (result.data.sent) {
                notify("Message sent successfully", "success")
              }
              else {
                notify("Message failed to send", "warning")
              }
            }
            catch (err) {
              // @ts-ignore
              notify(err.message, "warning")
            }
            setSubmitting(false)
            handleClose()
          }}
        >
          Send
        </Button>
      </DialogActions>
    </>
  )
}


interface DialogContentsParams {
  submitting: boolean
  submitResult: any
  [key: string]: any
}

function DialogContents (
  { submitting, submitResult, ...props }: DialogContentsParams,
) {
  const classes = useStyles()
  if (submitting) {
    return (
      <Flex center className={classes.loader}>
        <CircularProgress />
      </Flex>
    )
  }
  // @ts-expect-error
  return <SendForm {...props} />
}

interface MessageDialogProps {
  open: boolean
  handleClose: any
  claim: Claim
}


function MessageDialog ({ open, handleClose, claim }: MessageDialogProps) {
  const [message, setMessage] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [submitResult, setSubmitResult] = useState({
    sent: false,
    messageContent: "Content is here",
    phoneNumber: "+123412341234",
    claimId: "asdfklasdfkj",
  })

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
    >
      <DialogContents
        message={message}
        submitting={submitting}
        submitResult={submitResult}
        setMessage={setMessage}
        setSubmitting={setSubmitting}
        setSubmitResult={setSubmitResult}
        phoneNumber={claim.phoneNumber}
        handleClose={handleClose}
        claim={claim}
      />
    </Dialog>
  )
}


export default function SendSMSButton (
  { claim, ...props }: {
    claim: Claim
    [key: string]: any
  },
) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <MessageDialog
        open={open}
        claim={claim}
        handleClose={() => setOpen(false)}
      />
      <Button
        variant='outlined'
        color='primary'
        disabled={!claim.phoneNumber}
        onClick={() => setOpen(true)}
        {...props}
      >
        <SMSIcon />
        <Hidden mdDown>&nbsp;Send SMS</Hidden>
      </Button>
    </>
  )
}
