import  FirebaseTimestamp  from "./FirebaseTimestamp"

const gdprBreachesFields = {
  id: String,
  Domain: String,
  Name: String,
  Description: String,
  isValid: Boolean,
  IsFabricated: Boolean,
  IsMalware: Boolean,
  IsRetired: Boolean,
  IsSensitive: Boolean,
  IsSpamList: Boolean,
  IsVerified: Boolean,
  LogoPath: String,
  ModifiedDate: String,
  PwnCount: Number,
  Title: String,
  createdAt: FirebaseTimestamp,
  AddedDate: String,
  BreachDate: String,
  DataClasses: Array,
}

export default gdprBreachesFields
