import MomentUtils from "@date-io/moment"
import CssBaseline from "@material-ui/core/CssBaseline"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
// TODO: Upgrade to @apollo/client after
//   https://github.com/marmelab/react-admin/issues/5210
import { createBrowserHistory as createHistory } from "history"
import React, { useEffect, useState } from "react"
import { Admin, Layout, Resource, RouteWithoutLayout } from "react-admin"
import { Route } from "react-router-dom"
import FullPageLoader from "./components/FullPageLoader"
import theme from "./theme"
import CustomAppBar from "./theme/CustomAppBar"

// icons
import CasinoIcon from "@material-ui/icons/Casino"
import ClaimIcon from "@material-ui/icons/Assignment"
import AttachmentIcon from "@material-ui/icons/Attachment"
import TicketIcon from "@material-ui/icons/ConfirmationNumber"
import EmailIcon from "@material-ui/icons/Email"
import FeatureFlagIcon from "@material-ui/icons/Flag"
import UserIcon from "@material-ui/icons/Group"
import PhoneIcon from "@material-ui/icons/Phone"
import SMSIcon from "@material-ui/icons/Sms"
import WorkIcon from "@material-ui/icons/Work"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar"
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"

// react admin overrides
import Menu from "./components/Menu"
import defaultDataProvider from "./dataproviders"
import englishMessages from "ra-language-english"
import polyglotI18nProvider from "ra-i18n-polyglot"
import getFakeDataProvider from "./dataproviders/getFakeDataProvider"

// custom page components
import SevenDay from "./components/SevenDay"
import DataImport from "./components/DataImport"
import Search from "./components/Search"
import SendEmails from "./components/SendEmails"
import SendSMS from "./components/SendSMS"
import BulkTransfer from "./components/BulkTransfer"
import Workflows from "./components/Workflows"
import SMSList from "./components/SMS/SMSList"
import Tracking from "./components/Tracking"
import OnlineCasinoTracking from "./components/OnlineCasinoTracking"
import { UserTrackingList, UserTrackingShow } from "./components/UserTracking"
import ZendeskOauth from "./components/ZendeskOauth"
import Whatsapp from "./components/Whatsapp"
import UserModificiation from "./components/UserModification"

// resource components
import { SMSShow } from "./components/SMS"
import {
  BrcCaseShow,
  BrcCaseCreate,
  BrcCaseEdit,
  BrcCaseList,
} from "./components/BrcCases"
import { CaseShow, CaseCreate, CaseEdit, CaseList } from "./components/Cases"
import { DieselShow, DieselList } from "./components/Diesel"
import {
  BankFeeRecoveryShow,
  BankFeeRecoveryList,
} from "./components/BankFeeRecovery"
import { DocumentUploadList } from "./components/DocumentUploads"
import { ClaimEdit, ClaimList, ClaimShow } from "./components/Claim"
import {
  OnlineCasinoClaimEdit,
  OnlineCasinoClaimList,
  OnlineCasinoClaimShow,
} from "./components/OnlineCasinoClaim"
import { TicketList, TicketShow } from "./components/Tickets"
import { EmailList, EmailShow } from "./components/Emails"
import { UserCreate, UserEdit, UserList, UserShow } from "./components/User"
import {
  Deckungsschutzklage,
  DeckungsschutzklageCreate,
  DeckungsschutzklageEdit,
  DeckungsschutzklageShow,
} from "./components/Deckungsschutzklage/index"

import {
  PhoneCallCreate,
  PhoneCallEdit,
  PhoneCallList,
  PhoneCallShow,
} from "./components/PhoneCall"
import {
  FeatureFlagCreate,
  FeatureFlagEdit,
  FeatureFlagList,
} from "./components/FeatureFlags"
import {
  PhoneNumberEdit,
  PhoneNumberCreate,
  PhoneNumberList,
} from "./components/PhoneNumbers"
import {
  AutomaticAnswersEdit,
  AutomaticAnswersCreate,
  AutomaticAnswersList,
} from "./components/AutomaticAnswers"
import {
  ClaimFeedbackList,
  ClaimFeedbackShow,
} from "./components/ClaimFeedback"
import {
  UrlShortenerEdit,
  UrlShortenerCreate,
  UrlShortenerList,
} from "./components/UrlShortener"
import {
  TemplateEdit,
  TemplateCreate,
  TemplateList,
} from "./components/Templates"
import {
  NotificationSchedulerEdit,
  NotificationSchedulerCreate,
  NotificationSchedulerList,
} from "./components/NotificationScheduler"

import {
  GdprBreachesList,
  GdprBreachesCreate,
  GdprBreachesEdit,
} from "./components/GdprBreaches"

import {
  GdprFunderList,
  GdprCaseEdit,
  GdprLeadsList,
  GdprRsvList,
  GdprSubmissionsList,
} from "./components/Gdpr"

import { ClaimRejectList } from "./components/ClaimReject"
import { FirebaseConfigAuthProvider } from "./firebase"
import {
  CustomFieldList,
  CustomFieldEdit,
  CustomFieldCreate,
} from "./components/CustomFields"
import LeadImport from "./components/LeadImport"
import {
  UtmSourceLawFirmList,
  UtmSourceLawFirmEdit,
  UtmSourceLawFirmCreate,
} from "./components/UtmSourceLawFirm"
import { usePageTracking } from "./hooks/usePageTracking"
import { ContactCreate, ContactEdit, ContactList } from "./components/Contacts"
import {
  SubscriptionEdit,
  SubscriptionList,
} from "./components/NewsletterSubscription"

const messages: { [key: string]: any } = {
  en: englishMessages,
}

const history = createHistory()

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
})

function App () {
  console.info("Initialize App")
  // TODO: Set type to useState<DataProvider | null>
  const [dataProvider, setDataprovider] = useState<any | null>(null)

  useEffect(() => {
    if (!dataProvider) {
      if (
        // Uncomment for easier UI testing of React-Admin
        // process.env.REACT_APP_BUILD_ENV === "localhost" ||
        process.env.REACT_APP_BUILD_ENV === "test"
      ) {
        setDataprovider(getFakeDataProvider())
        return
      }

      const dataProvider = defaultDataProvider()
      setDataprovider(dataProvider)
    }
  }, [dataProvider])

  if (!dataProvider) {
    return <FullPageLoader />
  }

  console.info("REACT_APP_BUILD_ENV", process.env.REACT_APP_BUILD_ENV)

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <CssBaseline />
      <Admin
        history={history}
        i18nProvider={i18nProvider}
        theme={theme}
        dataProvider={dataProvider}
        authProvider={FirebaseConfigAuthProvider}
        layout={(props) => {
          usePageTracking()
          return <Layout {...props} menu={Menu} appBar={CustomAppBar} />
        }}
        customRoutes={[
          <RouteWithoutLayout
            key="seven-day"
            exact
            path="/seven-day"
            component={() => <SevenDay />}
            noLayout // TODO: Should not be necessary
          />,
          <Route
            key="search"
            exact
            path="/search"
            component={() => <Search />}
          />,
          <Route
            key="data-import"
            exact
            path="/data-import"
            component={() => <DataImport />}
          />,
          <Route
            key="lead-import"
            exact
            path="/lead-import"
            component={() => <LeadImport />}
          />,
          <Route
            key="send-emails"
            exact
            path="/send-emails"
            component={() => <SendEmails />}
          />,
          <Route
            key="send-sms"
            exact
            path="/send-sms"
            component={() => <SendSMS />}
          />,
          <Route
            key="bulk-transfer"
            exact
            path="/bulk-transfer"
            component={() => <BulkTransfer />}
          />,
          <Route
            key="workflows"
            exact
            path="/workflows"
            component={() => <Workflows />}
          />,
          <Route
            key="whatsapp"
            exact
            path="/whatsapp"
            component={() => <Whatsapp />}
          />,
          <Route
            key="tracking"
            exact
            path="/tracking"
            component={() => <Tracking />}
          />,
          <Route
            key="online-casino-tracking"
            exact
            path="/online-casino-tracking"
            component={() => <OnlineCasinoTracking />}
          />,
          <Route
            key="zendesk_oauth"
            exact
            path="/zendesk_oauth"
            component={() => <ZendeskOauth />}
          />,
          <Route
            key="user_modification"
            exact
            path="/user-modification"
            component={() => <UserModificiation />}
          />,
          <Route
            key="user_tracking"
            exact
            path="/user-tracking"
            component={() => <UserTrackingList />}
          />,
          <Route
            key="user_tracking_show"
            exact
            path="/user-tracking/:id/show"
            component={() => <UserTrackingShow />}
          />,
          <Route
            key="gdpr_leads"
            exact
            path="/gdpr-leads"
            render={(routeProps) => (
              <GdprLeadsList
                resource="gdprCases"
                basePath={"/gdpr-cases"}
                {...routeProps}
              />
            )}
          />,
          <Route
            key="gdpr_rsv"
            exact
            path="/gdpr-rsv"
            render={(routeProps) => (
              <GdprRsvList
                resource="gdprCases"
                basePath={"/gdpr-cases"}
                {...routeProps}
              />
            )}
          />,
          <Route
            key="gdpr_funder"
            exact
            path="/gdpr-funder"
            render={(routeProps) => (
              <GdprFunderList
                resource="gdprCases"
                basePath={"/gdpr-cases"}
                {...routeProps}
              />
            )}
          />,
          <Route
            key="gdpr_submissions"
            exact
            path="/gdpr-submissions"
            render={(routeProps) => (
              <GdprSubmissionsList
                resource="gdprCases"
                basePath={"/gdpr-cases"}
                {...routeProps}
              />
            )}
          />,
          <Route
            key="gdpr_cases_edit"
            path="/gdpr-cases/:id"
            render={(routeProps) => (
              <GdprCaseEdit
                id={routeProps.match.params.id}
                resource="gdprCases"
                basePath={routeProps.match.url}
                {...routeProps}
              />
            )}
          />,
        ]}
      >
        <Resource
          name="deckungsschutzklage"
          options={{
            label: "Deckungsschutzklage",
          }}
          icon={VerifiedUserIcon}
          list={Deckungsschutzklage}
          // @ts-expect-error  TODO
          show={DeckungsschutzklageShow}
          // @ts-expect-error  TODO
          edit={DeckungsschutzklageEdit}
          create={DeckungsschutzklageCreate}
        />
        <Resource
          name="users"
          options={{
            label: "Users",
          }}
          icon={UserIcon}
          // @ts-expect-error  TODO
          list={UserList}
          // @ts-expect-error  TODO
          show={UserShow}
          // @ts-expect-error  TODO
          edit={UserEdit}
          create={UserCreate}
        />
        <Resource
          name="claims"
          options={{
            label: "Diesel Claims",
          }}
          icon={ClaimIcon}
          // @ts-expect-error  TODO
          list={ClaimList}
          // @ts-expect-error  TODO
          edit={ClaimEdit}
          show={ClaimShow}
        />
        <Resource
          name="online-casino-claims"
          options={{
            label: "Online Casino Claims",
          }}
          icon={CasinoIcon}
          // @ts-expect-error  TODO
          list={OnlineCasinoClaimList}
          // @ts-expect-error  TODO
          edit={OnlineCasinoClaimEdit}
          show={OnlineCasinoClaimShow}
        />
        {/*
        <Resource
          name="documentUploads"
          options={{
            label: "Document Uploads",
          }}
          icon={AttachmentIcon}
          // @ts-expect-error  TODO
          list={DocumentUploadList}
        />
        */}
        <Resource
          name="emails"
          options={{
            label: "Emails",
          }}
          icon={EmailIcon}
          // @ts-expect-error  TODO
          list={EmailList}
          // @ts-expect-error  TODO
          show={EmailShow}
        />
        {/*
        <Resource
          name="sms"
          options={{
            label: "SMS",
          }}
          icon={SMSIcon}
          list={SMSList}
          show={SMSShow}
        />
        */}
        <Resource
          name="phoneCalls"
          options={{
            label: "Phone Calls",
          }}
          icon={PhoneIcon}
          // @ts-expect-error  TODO
          list={PhoneCallList}
          // @ts-expect-error  TODO
          create={PhoneCallCreate}
          // @ts-expect-error  TODO
          show={PhoneCallShow}
          // @ts-expect-error  TODO
          edit={PhoneCallEdit}
        />
        {/*
        <Resource
          name="featureFlags"
          options={{ label: "Feature Flags" }}
          icon={FeatureFlagIcon}
          list={FeatureFlagList}
          edit={FeatureFlagEdit}
          create={FeatureFlagCreate}
        />
        <Resource
          name="tickets"
          options={{ label: "Tickets" }}
          icon={TicketIcon}
          list={TicketList}
          show={TicketShow}
        />
        <Resource
          name="brc-cases"
          options={{ label: "BRC Cases" }}
          icon={WorkIcon}
          list={BrcCaseList}
          show={BrcCaseShow}
          edit={BrcCaseEdit}
          create={BrcCaseCreate}
        />
        <Resource
          name="cases"
          options={{ label: "Cases" }}
          icon={WorkIcon}
          list={CaseList}
          show={CaseShow}
          edit={CaseEdit}
          create={CaseCreate}
        />
        <Resource
          name="diesel"
          options={{ label: "Diesel" }}
          icon={DirectionsCarIcon}
          list={DieselList}
          show={DieselShow}
        />
        <Resource
          name="bank-fee-recovery"
          options={{ label: "Bank Fee Recovery" }}
          icon={AccountBalanceWalletIcon}
          list={BankFeeRecoveryList}
          show={BankFeeRecoveryShow}
        />
        */}
        <Resource
          name="contacts"
          options={{ label: "Trucks Leads" }}
          icon={PhoneIcon}
          list={ContactList}
          edit={ContactEdit}
          create={ContactCreate}
        />
        <Resource
          name="claims-reject"
          options={{
            label: "Rejected Claims",
          }}
          icon={ClaimIcon}
          list={ClaimRejectList}
          // @ts-expect-error  TODO
          edit={OnlineCasinoClaimEdit}
          show={OnlineCasinoClaimShow}
        />
        <Resource
          name="phoneNumbers"
          options={{ label: "Phone Numbers" }}
          icon={PhoneIcon}
          list={PhoneNumberList}
          edit={PhoneNumberEdit}
          create={PhoneNumberCreate}
        />
        <Resource
          name="automaticAnswers"
          options={{ label: "Automatic Answers" }}
          icon={SMSIcon}
          list={AutomaticAnswersList}
          edit={AutomaticAnswersEdit}
          create={AutomaticAnswersCreate}
        />
        {/*
        <Resource
          name="claimFeedback"
          options={{ label: "Claim Feedback" }}
          icon={SMSIcon}
          list={ClaimFeedbackList}
          show={ClaimFeedbackShow}
        />
        <Resource
          name="urlShortener"
          options={{ label: "Url Shortener" }}
          icon={AttachmentIcon}
          list={UrlShortenerList}
          edit={UrlShortenerEdit}
          create={UrlShortenerCreate}
        />
        */}
        <Resource
          name="templates"
          options={{ label: "Templates" }}
          icon={LibraryBooksIcon}
          list={TemplateList}
          edit={TemplateEdit}
          create={TemplateCreate}
        />
        <Resource
          name="notificationScheduler"
          options={{ label: "Notification Scheduler" }}
          icon={LibraryBooksIcon}
          list={NotificationSchedulerList}
          edit={NotificationSchedulerEdit}
          create={NotificationSchedulerCreate}
        />
        <Resource
          name="newsletterSubscriptions"
          options={{ label: "Newsletter Subscription" }}
          icon={EmailIcon}
          list={SubscriptionList}
          edit={SubscriptionEdit}
        />
        <Resource
          name="gdprBreaches"
          options={{ label: "GDPR Breaches" }}
          list={GdprBreachesList}
          edit={GdprBreachesEdit}
          create={GdprBreachesCreate}
        />
        <Resource name="gdprCases" />
        <Resource
          name="customFields"
          options={{ label: "Custom Fields" }}
          list={CustomFieldList}
          edit={CustomFieldEdit}
          create={CustomFieldCreate}
        />
        <Resource
          name="UtmSourceLawFirm"
          options={{ label: "UtmSource Law Firm" }}
          list={UtmSourceLawFirmList}
          edit={UtmSourceLawFirmEdit}
          create={UtmSourceLawFirmCreate}
        />
      </Admin>
    </MuiPickersUtilsProvider>
  )
}

export default App
