import { useState, useEffect } from "react"
import { db } from "../../firebase"


export default function useDocument (path: string | null): [
  Record<string, any> | null,
  Record<string, any> | null,
  boolean,
  Record<string, any> | null,
] {
  const [loading, setLoading] = useState<boolean>(true)
  const [documentData, setDocumentData] =
    useState<Record<string, any> | null>(null)
  const [docSnapshot, setDocSnapshot] =
    useState<Record<string, any> | null>(null)
  const [error, setError] = useState<Record<string, any> | null>(null)

  useEffect(() => {
    if (!path) {
      return
    }

    const query = db.doc(path)
    setLoading(true)
    const unlisten = query.onSnapshot(docSnapshot => {
      if (docSnapshot.exists) {
        setDocumentData(docSnapshot.data() || null)
      }
      else {
        setDocumentData(null)
      }
      setDocSnapshot(docSnapshot)
      setLoading(false)
    }, err => {
      setError(err)
      setLoading(false)
    })
    return unlisten
  }, [path])

  if (!path) {
    return [null, null, false, null]
  }

  return [documentData, docSnapshot, loading, error]
}
