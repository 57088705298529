import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  downloadCSV,
  FunctionField,
} from "react-admin"
import jsonExport from "jsonexport/dist"
import Chip from "@material-ui/core/Chip"

import DefaultActions from "./DefaultActions"
import DefaultPagination from "./DefaultPagination"
import React from "react"
import TimestampField from "./TimestampField"
import { DateRangeFilterWithField } from "./DateRangeFilter"
import { Box } from "@material-ui/core"

function ClaimRejectFilters (
  props: {
    setFilters?: (filterMap: Record<string, any>) => void,
    filterValues?: Record<string, any>,
  },
) {

  return (
    <Filter {...props}>
      <TextInput label='Filter by claim id' source='id' alwaysOn />
      <TextInput label='Filter by email' source='userEmail' alwaysOn />
      {/* @ts-expect-error: Property 'alwaysOn' does not exist */}
      <Box mb={2} alwaysOn>
        <DateRangeFilterWithField source='createdTime' label='Created Time' />
      </Box>
    </Filter>
  )

}

function exporter (claims: any) {
  const fields: {[key: string]: string} = {
    createdTime: "Created Time",
    id: "Claim ID",
    firstName: "First Name",
    lastName: "Last Name",
    userEmail: "E-mail",
    phoneNumber: "Phone Number",
    providers: "Claims",
    claimActionStatus: "Claim Action Status",
    leadSource: "Lead Source",
    utmSource: "UTM Source",
  }

  const exportClaims = (claims as {[key: string]: any}[]).map(claim => {
    const tmp: {[key: string]: any} = {}
    for (const field of Object.keys(fields)) {
      if (Object.keys(claim).includes(field)) {
        if (field === "id") {
          tmp.Claim = `${claim.firstName} ${claim.lastName} (${claim.id})`
        }
        
        tmp[fields[field]] = claim[field]
      }
      else if (field === "providers") {
        const providers: {[key: string]: any}[] =
          claim?.customFieldValues?.providers?.value
        const result = providers.map(
          provider =>
            `${provider.name}(${provider.company}) ${provider.estimatedLosses}`,
        )
        tmp[fields[field]] = result.join(", ")
      }
    }

    return tmp
  })

  jsonExport(exportClaims, {
    fillGaps: true,
  }, (err, csv) => {
    downloadCSV(csv, "claims-reject")
  })
}

export function ClaimRejectList ({ ...props }) {
  return (
    <List
      {...props}
      filter={{
        type: "rejects",
      }}
      filters={<ClaimRejectFilters />}
      actions={<DefaultActions />}
      sort={{ field: "createdTime", order: "DESC" }}
      perPage={25}
      pagination={<DefaultPagination />}
      exporter={exporter}
    >
      <Datagrid rowClick='show'>
        <TimestampField
          source='createdTime'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <TextField label='Claim ID' source='id' sortable={false} />
        <TextField label='First Name' source='firstName' sortable={false} />
        <TextField label='Last Name' source='lastName' sortable={false} />
        <TextField label='E-mail' source='userEmail' sortable={false} />
        <TextField
          label='Phone Number'
          source='phoneNumber'
          sortable={false}
          style={{ whiteSpace: "nowrap" }}
        />
        <FunctionField
          source="providers"
          label='Claims'
          render={record => {
            const providers: {[key: string]: any}[] =
              record?.customFieldValues?.providers?.value
            const providerRender = providers.map(provider => {
              return <Chip
                label={
                  `${provider.name} (${provider.company}) ` +
                    provider.estimatedLosses
                }
              />
            })
            return <>{providerRender}</>
          }}
        />
        <TextField
          label='Claim Action Status'
          source='claimActionStatus'
          sortable={false}
        />
        <TextField label='Lead Source' source='leadSource' sortable={false} />
        <FunctionField
          source="utmParams"
          label='Utm Source'
          render={record => {
            if (!record?.utmParams) {
              return ""
            }

            const utmSource = record?.utmParams.find(
              (utm: { source: string; value: any; }) => 
                utm.source === "utm_source",
            )

            return utmSource?.value
          }}
        />
      </Datagrid>
    </List>
  )
}
