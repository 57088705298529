import { useNotify, useRefresh } from "react-admin"
import moment from "moment"
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { makeStyles } from "@material-ui/core/styles"
import { red } from "@material-ui/core/colors"

import { storage } from "../../firebase"
import useGdprFiles from "../../hooks/useGdprFiles"
import {GdprUploadFileInput} from "./index"

const storageRef = storage.ref()

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    color: red[500],
  },
}))

function FileTable (
  { files }: {
    files: ClaimFile[]
  },
) {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const formatString = "YYYY-MM-DD\u{00A0}\u{00A0}HH:mm:ss\u{00A0}\u{00A0}"

  async function confirmDelete (path: string) {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await storageRef.child(path).delete()
        notify("File was successfully deleted", "success")
        // TODO: Also delete document from Firestore
        refresh()
      }
      catch (error: any) {
        notify(error?.message, "warning")
      }
    }
  }

  if (!files || files.length === 0) {
    return <br/>
  }

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map(file => {
            return (
              <TableRow key={file.downloadURL}>
                <TableCell component="th" scope="row">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={file.downloadURL}
                  > {file.name}</Link>
                </TableCell>
                <TableCell>
                  {moment(file.meta?.timeCreated).format(formatString)}Z
                </TableCell>
                <TableCell>
                  {moment(file.meta?.updated).format(formatString)}Z
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={event => {
                      event.preventDefault()
                      confirmDelete(file.meta?.fullPath)
                    }}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}


export default function GdprFiles (
  props: {
    record?: {
      userId: string
      id: string
      lawFirm: string
      lawFirmCaseId: string
      customFieldValues: any
    },
  },
) {
  const gdprCaseId = props?.record?.id
  const userId = props?.record?.userId

  if (!userId) {
    return <Card>
      <CardContent>User id must be specified</CardContent>
    </Card>
  }
  if (!gdprCaseId) {
    return <Card>
      <CardContent>Gdpr case id must be specified</CardContent>
    </Card>
  }

  const [files, loading] = useGdprFiles({ userId, gdprCaseId })

  if (loading) {
    return <>
      <p>Loading ...</p>
      <p>(Please hard reload the website if this seems stuck)</p>
    </>
  }

  const uploadSections = [
    {
      title: "Screenshot Legal Insurance",
      files: files.screenshotLegalInsurance,
      subdir: "screenshotLegalInsurance",
    },
    {
      title: "Power of Attorney",
      files: files.poa,
      subdir: "poa",
    },
    {
      title: "Fee Agreement",
      files: files["fee-agreement"],
      subdir: "fee-agreement",
    },
    {
      title: "Signature",
      files: files.signature,
      subdir: "signature",
    },
    {
      title: "Documents",
      files: files.documents,
      subdir: "documents",
    },
    {
      title: "Screenshot Hartung Forms",
      files: files.forms,
      subdir: "forms",
    },
  ]

  return (
    <div style={{ padding: 20 }}>
      {
        uploadSections.map(section =>
          <div key={section.title}>
            <Typography variant="overline">
              {section.title}
            </Typography>
            <FileTable
              files={section.files}
            />
            <br/>

            <Grid container>
              <GdprUploadFileInput
                buttonLabel="Upload new file"
                subdir={section.subdir}
                initialUploadedFiles={[]}
                userId={userId}
                gdprCaseId={gdprCaseId}
                onChange={(event: any) => {
                  console.info(event)
                }}
              />
            </Grid>

            <Divider style={{ margin: "20px 0" }} />
          </div>,
        )
      }
    </div>
  )
}
