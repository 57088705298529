import FirebaseTimestamp from "./FirebaseTimestamp"

const emailFields = {
  id: String,
  body: String,
  claimId: String,
  email: String,
  provider: String,
  providerId: String,
  metadata: Object,
  sender: String,
  state: String,
  subject: String,
  template: String,
  timestamp: FirebaseTimestamp,
  _timestamp: Date,
  type: String,
  userId: String,
}

export default emailFields
