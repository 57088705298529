import firebase, { db } from "../../../firebase"
import getHttpsCallable from "../../../utils/getHttpsCallable"
import { PhoneCallReason } from "../../../constants/enums"
import { PhoneCallResult } from "../../../lib/enums"


interface CreatePhoneCallParams {
  claimId: string
  callResult: PhoneCallResult
  callNotes?: string
  phoneNumber: string
  phoneNumberValid: boolean
}

const updateCase = getHttpsCallable("admin-cases-updateCase")

export default async function createPhoneCall (
  {
    claimId,
    callResult,
    callNotes = "",
    phoneNumber,
    phoneNumberValid,
  }: CreatePhoneCallParams,
) {
  const claims = await db
    .collectionGroup("claims")
    .where("id", "==", claimId)
    .get()
  if (claims.size < 1) {
    throw new Error(`Claim ${claimId} not found`)
  }
  const { userId } = claims.docs[0].data()

  if (callResult === PhoneCallResult.ANSWERED) {
    await updateCase({
      claimId: claimId,
      updateData: {
        customFieldValues: [
          {
            id: 4107,
            value: (new Date()).toISOString(),
          },
        ],
      },
    })
  }

  return db.collection("phoneCalls").add({
    claimId,
    callResult,
    callReasons: [PhoneCallReason.BFR_REMINDER],
    phoneNumber,
    callNotes,
    phoneNumberValid,
    startTime: firebase.firestore.FieldValue.serverTimestamp(),
    userId,
  })
}
