import FirebaseTimestamp from "./FirebaseTimestamp"

const userTrackingFields = {
  id: String,
  sessionId: String,
  parameters: Object,
  createdAtDate: String,
  createdAt: FirebaseTimestamp,
  _createdAt: Date,
  convertedAtDate: String,
  convertedAt: FirebaseTimestamp,
  _convertedAt: Date,
}

export default userTrackingFields
