import { useRecordContext } from "react-admin"
import { Chip } from "@material-ui/core"
import PropTypes from "prop-types"


export function ChipList (props: { source: string }) {
  const record = useRecordContext(props)
  return (
    <>
      {
        record[props.source]?.map((item: string) =>
          <Chip label={item} key={item} />,
        )
      }
    </>
  )
}

ChipList.defaultProps = { addLabel: true }


export function ChipListObjects (props: { source: string, label: string }) {
  const record = useRecordContext(props)
  return (
    <>
      {
        record[props.source]?.map((item: { id: string}) =>
          <Chip
            key={item.id}
            label={item.id}
            style={{ marginRight: "0.5em" }}
          />,
        )
      }
    </>
  )
}

ChipListObjects.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

ChipListObjects.defaultProps = {
  addLabel: true,
}
