const ticketAssignees = {
  AWAITING_KV: "AWAITING_KV",
  DATA_ENTRY: "DATA_ENTRY",
  DATA_EVAL: "DATA_EVAL",
  FOLLOW_UP: "FOLLOW_UP",
  COMPLETE: "COMPLETE",
  BFR_CALL_LIST: "BFR_CALL_LIST",
}

export default ticketAssignees
