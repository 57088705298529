import { useState, useEffect } from "react"
import { storage } from "../firebase"

const storageRef = storage.ref()

const fileDirs = [
  "financeContracts",
  "financeContractMerged",
  "ocChat",
  "ocPoaSignature",
  "claimPoaSignature",
  "claimPoa",
  "claimFinancingAgreementSignature",
  "claimFinancingAgreement",
  "claimRemainingValueClaimant",
  "claimRemainingValueClaimantMerged",
  "claimRemainingValueClaimback",
  "claimRemainingValueClaimbackMerged",
  "idCard",
  "idCardMerged",
  "gdprMail",
  "gdprMailMerged",
  "ocCalculatedLossSheet",
  "ocTransactionList",
  "otherFiles",
  "purchaseContracts",
  "purchaseContractMerged",
  "salesContracts",
  "salesContractMerged",
  "vehicleRegistrationDocument",
  "vehicleRegistrationDocumentMerged",
  "currentMileageDocument",
  "latestMileage",
  "latestMileageMerged",
]


export default function useFiles (
  { userId, claimId }: {
    userId: string
    claimId: string
  },
): [Record<string, any[]>, boolean] {
  const initialDirToList =  Object.fromEntries(
    fileDirs.map(fileName => [fileName, []]),
  )
  const [fileObject, setFileObject] = useState(initialDirToList)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!userId) {
      console.error("User not found")
      setLoading(false)
      return
    }
    if (!claimId) {
      console.error("Claim not found")
      setLoading(false)
      return
    }

    async function mapLinks (documentsRef: { items: any[]}): Promise<any> {
      return Promise.all(documentsRef.items.map(async (item) => {
        const downloadURL = await item.getDownloadURL()
        const metadata = await item.getMetadata()
        return {
          ...item,
          meta: { ...metadata },
          name: item.name,
          downloadURL,
        }
      }))
    }

    async function getUserFiles () {
      setLoading(true)
      const filesRef = storageRef
        .child(userId)
        .child("claimFiles")
        .child(claimId)

      const dirToEntriesObj = Object.fromEntries(
        await Promise.all(
          fileDirs.map(async fileName => {
            const ref = filesRef.child(fileName)
            const allFiles = await ref.listAll()
            let links = await mapLinks(allFiles)

            // Fix: Files seem to be saved in a folder without final "s"
            if ([
              "financeContracts",
              "purchaseContracts",
              "salesContracts",
              "vehicleRegistrationDocument",
              "currentMileageDocument",
            ].includes(fileName)) {
              const ref = filesRef.child(fileName.slice(0, -1))
              const allFiles = await ref.listAll()
              links = links.concat(await mapLinks(allFiles) ?? [])
            }

            return [fileName, links || []]
          }),
        ),
      )

      setFileObject(dirToEntriesObj)

      setLoading(false)
    }

    getUserFiles()
      .catch(console.error)

  }, [userId, claimId])

  return [fileObject, loading]
}
