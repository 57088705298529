import React from "react"
import {
  Datagrid,
  TextField,
  List,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
  Filter,
  BooleanInput,
} from "react-admin"
import DefaultActions from "../DefaultActions"
import DefaultPagination from "../DefaultPagination"
import {Box, Button} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"

function wrapInBox (component: JSX.Element) {
  return (
    // @ts-expect-error: Property 'alwaysOn' does not exist
    <Box mb={2} alwaysOn>
      {component}
    </Box>
  )
}

function GdprFilters (
  props: {
    setFilters?: (filterMap: Record<string, any>) => void,
    filterValues?: Record<string, any>,
  },
) {
  return (
    <Filter {...props}>
      {wrapInBox(
        <Button
          variant='outlined'
          size='small'
          onClick={() => props.setFilters && props.setFilters({
            insuranceInfoProvided: false,
            screenshotLegalInsuranceUploaded: false,
          })}
        >
          <ClearIcon />
          Clear Filters
        </Button>,
      )}
      <BooleanInput source='insuranceInfoProvided' />
      <BooleanInput source='screenshotLegalInsuranceUploaded' />
    </Filter>
  )
}

export default function ({...props}) {
  const date = new Date()

  return (
    <List
      filters={<GdprFilters />}
      filter={{
        endSnoozeAt__lt: date,
        markForSubmission: false,
        type: "Insurance",
      }}
      sort={{ field: "formSubmittedAt", order: "DESC" }}
      {...props}
      actions={<DefaultActions/>}
      perPage={15}
      pagination={<DefaultPagination/>}>
      <Datagrid rowClick="edit">
        <TextField source="fullName" label="Name"/>
        <BooleanField
          source="insuranceInfoProvided"
          sortable={false}
          label="Insurance info provided"
        />
        <BooleanField
          source="screenshotLegalInsuranceUploaded"
          label="Insurance screenshot provided"
          sortable={false}
        />
        <TextField source="userId" label="User Id" sortable={false}/>
        <TextField source="id" label="Id" sortable={false}/>
        <DateField source="createdAt" label="Created at" sortable={false}/>
        <DateField source="updatedAt" label="Updated at" sortable={false}/>
        <ReferenceArrayField label="Gdpr breaches" reference="gdprBreaches"
          source="gdprBreachesIds">
          <SingleFieldList>
            <ChipField source="Name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="lawFirm" />
      </Datagrid>
    </List>
  )
}
