import {
  ThemeProvider,
  useTheme,
  Paper,
} from "@material-ui/core"
import { createTheme } from "@material-ui/core/styles"
import MUIDataTable from "mui-datatables"
import { sortBy } from "lodash"
import {useMemo} from "react"
  
type CustomFieldValues = Record<string, (any)>

type ProvidersDataRecord = Record<string, CustomFieldValues[]>

type CustomFieldRecord = Record<string, {
  label: string,
  name: string,
}>
function createValuesArray (
  providers: Record<string, (any)>,
): ProvidersDataRecord {
  if (typeof providers !== "object") {
    return {}
  }

  const providerEntries =
    Object.entries(providers).reduce<any>((acc, [key, value], index) => {
      const dataEntry: string[][] = []
      const dataObj = {}
      const companyName = value.company || "Unknown"

      Object.keys(value).forEach((keyName) => {
        if (keyName !== "company") {
          if (keyName === "name") return

          return dataEntry.push([keyName, value[keyName]])
        }
      })

      Object.assign(dataObj, {
        name: companyName,
        casino: value.name,
        id: key,
        value: dataEntry,
      })
      acc.push(dataObj)
      return acc
    }, [])

  const output =
    providerEntries.reduce((
      acc: ProvidersDataRecord, providerEntry: CustomFieldValues) => {
      if (
        !acc[providerEntry.name] ||
        acc[providerEntry.name] === undefined
      ) { // initialise empty object with key
        Object.assign(acc, {
          [providerEntry.name]: [providerEntry],
        })
        return acc
      }
      
      Object.assign(acc, {
        [providerEntry.name]: [...acc[providerEntry.name], providerEntry],
      })
      return acc
    }, {})

  return output
}

function populateCustomValueArray (
  customValue: Record<string, any>[],
): CustomFieldRecord[] {
  if (!Array.isArray(customValue)) {
    return [{}]
  }

  const output = customValue
    .filter((afv) => Array.isArray(afv) && typeof afv[1]?.value !== "object")
    .map((afv) => {
      if (Array.isArray(afv)) {
        return {
          name: afv[0],
          // if incorrect format it shows incorrect format
          value: afv[1]?.value || typeof afv[1]?.value === "boolean" 
            ? afv[1]?.value.toString() : "incorrect format",
        }
      }
      else {
        return {
          name: "",
          value: "",
        }
      }
    })

  return output
}
export default function CasinoFieldValuesList (
  props: {
      record?: {
        customFieldValues: CustomFieldValues
      },
      hideAdditionalFields?: boolean
    },
) {
  const customFieldValues = props.record?.customFieldValues

  let additionalFieldValues = [{}]
  if (customFieldValues) {
    additionalFieldValues = Object
      .entries(customFieldValues as Record<string, (any)>)
  }

  if (!customFieldValues) {
    return null
  }

  const fieldValuesArray = useMemo(
    () => createValuesArray(customFieldValues?.providers?.value)
    , [props.record?.customFieldValues.providersLawFirm?.value])

  const lawFirmValuesArray = useMemo(
    () => createValuesArray(customFieldValues?.providersLawFirm?.value)
    , [props.record?.customFieldValues.providersLawFirm?.value])

  const afvEntries = useMemo<CustomFieldRecord[]>(
    () => populateCustomValueArray(additionalFieldValues)
    , [props.record?.customFieldValues])


  const lossesArray:string[] = []

  for (const provider of Object.values(fieldValuesArray)) {
    let totalOperatorLosses = 0

    for (const casino of provider) {
      const values = Object.fromEntries(casino?.value)
      let lossesSum = 0
      let calculatedLossSum = 0
      let earningsSum = 0

      if (typeof casino === "object" && casino.name !== "Unknown") {
        
        const sportBetLoss = Number(values.lossSportBet 
          ? values.lossSportBet.replace(
            ",", ".",
          ) : 0)

        const casinoLoss = Number(values.lossCasino 
          ? values.lossCasino.replace(
            ",", ".",
          ) : 0)

        const casinoSportLoss = sportBetLoss + casinoLoss 

        if (values.lossCasino || values.lossSportBet) {
          lossesSum += casinoSportLoss
        }

        if (values.calculatedLosses) {
          calculatedLossSum +=
            values?.calculatedLosses
              ? Number(values?.calculatedLosses.toString()
                .replace(",", ".")) : 0
        }

        if (values.earnings) {
          earningsSum +=
            Number(values?.earnings.replace(",", "."))
        }
      }

      if (!values.lossCasino && !values.lossSportBet) {
        lossesSum = calculatedLossSum - earningsSum
      }

      totalOperatorLosses += lossesSum
    }


    lossesArray.push(new Intl
      .NumberFormat("de-DE", { style: "currency", currency: "EUR" })
      .format(totalOperatorLosses))
  }

  const theme = useTheme()
  const columns = [
    { label: "Name", name: "casino" },
    { label: "Value", name: "value", options: {
      customBodyRender: (value:Record<string, (any)>) => (
        Array.isArray(value) && value.map((records) => (
          <div>
            {records[0]}: {(records[0] === "typeOfGames" &&
            records[1] !== null && Array.isArray(records))
              ? records[1].map((games: string) => games + " | ") 
              : (records[1] !== null ? records[1].toString() : null)}
          </div>))
      ),
    } },
  ]

  const afvColumns = [
    { label: "Name", name: "name" },
    { label: "Value", name: "value"},
  ]

  function sortByProvider (fVArray:Record<string, (any)> ) {
    return  sortBy(fVArray, [function (o) {
      return o?.value.filter((values:string[]) => values.includes("company"))
    }])
  }

  const muiTheme = createTheme({
    ...theme,
    ...{
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            fontSize: 12,
            whiteSpace: "pre",
          },
        },
        MUIDataTableHeadCell: {
          // The columns which can be sorted
          data: {
            padding: 2,
            fontSize: 11,
            textAlign: "center",
          },
        },
        MUIDataTable: {
          paper: {
            marginTop: 20,
          },
        },
      },
    },
  })
  
  return (
    <ThemeProvider theme={muiTheme}>
      {Object.keys(fieldValuesArray).length > 0 &&
          Object.keys(fieldValuesArray).map((providerKey, index) =>
            <div>
              <MUIDataTable
                key={index}
                columns={columns}
                data={fieldValuesArray[providerKey]}
                title={`Providers: ${providerKey}`}
                options={{
                  selectableRowsHeader: false,
                  selectableRows: "none",
                  rowsPerPage: 100,
                }}
              />
              {providerKey !== "Unknown" &&
              <Paper elevation={1}
                style={{padding: 8,
                  textAlign: "center"}}>
                <b>{providerKey}</b> calculated losses total 
                : <b>{lossesArray[index]}</b>
              </Paper>}
            </div>)
      }
      {
        Object.keys(lawFirmValuesArray).length > 0 &&
          Object.keys(lawFirmValuesArray).map((providerKey, index) =>
            <MUIDataTable
              key={index}
              columns={columns}
              data={lawFirmValuesArray[providerKey]}
              title={`Providers Law Firm: ${providerKey}`}
              options={{
                selectableRowsHeader: false,
                selectableRows: "none",
                rowsPerPage: 100,
              }}
            />)
      }
      {!props.hideAdditionalFields &&
      <MUIDataTable
        columns={afvColumns}
        data={afvEntries}
        title="Additional Custom Fields"
        options={{
          selectableRowsHeader: false,
          selectableRows: "none",
          rowsPerPage: 100,
        }}
      />}
    </ThemeProvider>
  )
}
  
