import React, {useEffect, useMemo, useState} from "react"
import {
  Button,
  useNotify,
  useUnselectAll,
} from "react-admin"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import IconCancel from "@material-ui/icons/Cancel"
import {
  Box,
  Button as Btn, CircularProgress,
  Dialog, DialogContentText, Hidden, Link,
  InputLabel,
} from "@material-ui/core"
import FileCopy from "@material-ui/icons/FileCopy"
import getHttpsCallable from "../utils/getHttpsCallable"
import CloseIcon from "@material-ui/icons/Close"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { db } from "../firebase"

interface SendButtonParams {
  selectedIds: string[]
  [key: string]: any
  isShow?: boolean
  ocClaim?: Claim | boolean
}

type Provider = {
  company?: string,
  estimatedLosses?: string,
  name?: string
  unknownCasinoName?: string
}

const btnStyle = {
  claimListBtn: {color: "#fff",
    borderColor: "#fff",
    marginRight: "8px"},
    
  claimShowBtn: {color: "#009be5",
    borderColor: "rgba(0, 155, 229, 0.5)",
    marginRight: "8px"},
}

const duplicateClaims = getHttpsCallable("admin-duplicateClaims")


export default function DuplicateClaims (
  { selectedIds, isShow, ocClaim, ...props }: SendButtonParams,
) {
  const [showDialog, setShowDialog] = useState(false)
  const [claimData, setClaimData] = useState<Record<string, any> | undefined>(
    typeof ocClaim === "object" ? ocClaim : undefined,
  )
  const [duplicating, setDuplicating] = useState(false)
  const [newIds, setNewIds] = useState([])
  const [selectedOCProvider, setSelectedOCProvider] = 
    useState<Provider[]>([])

  const notify = useNotify()
  const unselectAll = useUnselectAll()

  async function setClaim (claimId: string) {
    const claims = await db.collectionGroup("claims")
      .where("id", "==", claimId)
      .get()

    if (!claims.docs) {
      return
    }

    setClaimData(claims.docs[0].data())
  }

  useEffect(() => {
    if (typeof ocClaim === "boolean" && selectedIds.length > 0) {
      if (selectedIds.length > 1) {
        setClaimData({})
        return
      }


      setClaim(selectedIds[0])
    }
  }, [selectedIds])

  const ocProvidersByName = useMemo(() => {
    if (!claimData || Object.keys(claimData).length < 1) {
      return {}
    }

    const providerData = claimData?.customFieldValues?.providers
    if (!providerData || !providerData.value.length) {
      return {}
    }

    return providerData.value
      .reduce((acc: Record<string, Provider[]>, provider: Provider) => {
        if (!provider.company && provider.name) {
          if (provider.name === "Anderes Casino" &&
           provider.unknownCasinoName) {
            Object.assign(acc, {
              [provider.unknownCasinoName]: [provider],
            })
          }
          else {
            Object.assign(acc, {
              [provider.name]: [provider],
            })
          }
          return acc
        }
        if (!provider.company) {
          return acc
        }
        
        if (acc[provider.company]) {
          acc[provider.company].push(provider)
          return acc
        }

        Object.assign(acc, {
          [provider.company]: [provider],
        })

        return acc
      }, {})
  }, [claimData])

  async function handleDuplicate () {
    if (selectedIds.length < 1) {
      return
    }

    setDuplicating(true)
    try {
      const result = await duplicateClaims({
        selectedIds,
        providers: claimData ? selectedOCProvider : null,
      })

      let message = "Error when duplicating claims"

      if (result.data && result.data.length > 0) {
        message = "Duplication successful"
        setNewIds(result.data)
      }

      setDuplicating(false)

      notify(
        message,
        {
          type: "info",
          messageArgs: {smart_count: 1},
        },
      )

      unselectAll(props.resource)
    }
    catch (e) {
      setDuplicating(false)

      window.alert(`
      Problem occurred trying to commit send operation:
      ${e}
      `)
    }
  }

  return (
    <>
      <Btn
        style={isShow ? btnStyle.claimShowBtn : btnStyle.claimListBtn}
        startIcon={<FileCopy />}
        variant='outlined'
        onClick={() => setShowDialog(true)}
      >
        <Hidden mdDown>
          Duplicate claim{isShow ? "" : "s"}
        </Hidden>
      </Btn>
      <Dialog
        fullWidth
        open={showDialog}
      >
        {newIds && newIds.length > 0
          ? <>
            <DialogTitle>
              Newly duplicated claim ID{isShow ? "" : "s"}
            </DialogTitle>
            <Box style={{position: "absolute", right: "5px", top: "10px"}}>
              <Btn
                size='medium'
                color='inherit'
                variant='text'
                endIcon={<CloseIcon />}
                onClick={() => setShowDialog(false)}
              >Close
              </Btn>
            </Box>
            <DialogContentText>
              <ol>
                {newIds.map((id: string) => (
                  <li>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={isShow 
                        ? window.location.href.replace(selectedIds[0], id)
                        : props.basePath + "/" + id + "/show"}>
                      {id}
                    </Link>
                  </li>
                ))}
              </ol>
            </DialogContentText>
          </>
          : <>
            <DialogTitle>
                Do you want to duplicate {isShow ? "the claim" 
                : "your selection"}?
            </DialogTitle>
            {!duplicating
              ? <>
                {
                  claimData && 
                  Object.keys(ocProvidersByName).length > 0 && 
                  <DialogContent>
                    <InputLabel
                      style={{
                        marginTop: "1rem",
                      }}
                    >Company</InputLabel>
                    <Select
                      onChange={(event) => {
                        setSelectedOCProvider(
                          ocProvidersByName[event.target.value as string],
                        )
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      {
                        Object.keys(ocProvidersByName).length > 0 &&
                        Object.keys(ocProvidersByName)
                          .map((providerName) => {
                            return <MenuItem value={providerName}>
                              {providerName}
                            </MenuItem>
                          })
                      }
                    </Select>
                  </DialogContent>
                }
                <DialogActions>
                  <Button
                    label="Duplicate"
                    key='button'
                    onClick={handleDuplicate}
                  >
                    <FileCopy />
                  </Button>
                  <Button
                    onClick={() => setShowDialog(false)}
                    label='ra.action.cancel'
                  >
                    <IconCancel />
                  </Button>
                </DialogActions>
              </>
              : <div style={{width: "100%", textAlign: "center",
                marginBottom: "1rem", marginTop: "1rem"}}>
                <CircularProgress/>
              </div>
            }
          </>
        }
      </Dialog>
    </>
  )
}
