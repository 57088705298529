import React from "react"
import { blueGrey, lightGreen } from "@material-ui/core/colors"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import moment from "moment"
import { TicketAssignee } from "../../../constants/enums"

import ticketRequiresAttention from "../../../utils/ticketRequiresAttention"


interface AttIndicParams {
  snoozeTimeEnd: FirebaseAdminTimestamp
  snoozeTimeStart: FirebaseAdminTimestamp
  ticket: Ticket
  [x: string]: any
}

export default function AttentionIndicator (
  { snoozeTimeEnd, snoozeTimeStart, ticket, ...props }: AttIndicParams,
) {
  const requiresAttention = ticketRequiresAttention(ticket)

  if (requiresAttention) {
    return (
      <Paper style={{ backgroundColor: lightGreen[500] }} square {...props}>
        <Typography variant='h6' color='textPrimary' align='center'>
          Requires Attention
        </Typography>
      </Paper>
    )
  }
  else {
    return (
      <Paper style={{ backgroundColor: blueGrey[500] }} square {...props}>
        <Typography variant='h6' color='textPrimary' align='center'>
          {ticket.assignedGroup === TicketAssignee.COMPLETE
            ? "COMPLETE"
            : "Snoozed"}
        </Typography>

        {snoozeTimeEnd && (
          <Typography
            variant='caption'
            color='textSecondary'
            align='center'
            component='div'
          >
            will awake {moment(snoozeTimeEnd.toDate()).fromNow()}
          </Typography>
        )}
      </Paper>
    )
  }
}
