import { useState, useEffect } from "react"
import { oneLineTrim } from "common-tags"
import styled from "styled-components"

import Container from "../../lib/components/Container"
import Show from "../../lib/components/Show"
import Flex from "../../lib/components/Flex"
import Body from "../../lib/components/Typography/Body"
import ProgressBar from "../../lib/components/ProgressBar"
import CheckIconBlue from "../../lib/icons/CheckIconBlue"
import firebase from "../../firebase"


const FileLabel = styled(Body)`
  margin: 0%;
`


const CompleteLabel = styled.div`
  display: flex;
  align-items: center;
  margin: 0%;
`


export function FileProgress (
  { name, progress, completed }: {
    name: string
    progress: number
    completed: boolean
  },
) {
  return (
    <Flex alignItems="center">
      <Container style={{ width: "35%" }}>
        <FileLabel>{name}</FileLabel>
      </Container>

      <Container style={{ width: "65%" }}>
        {completed
          ? <CompleteLabel>
            <CheckIconBlue />
            &nbsp;&nbsp;
            Upload erfolgreich
          </CompleteLabel>
          : <ProgressBar progress={progress} />
        }
      </Container>
    </Flex>
  )
}


function FileUploader (
  { fileRef, file, onComplete}:
    {
      fileRef: any
      file: {
        name: string
      }
      onComplete: (val?: any) => any
    },
) {
  const [progress, setProgress] = useState<number>(0)
  const [completed, setCompleted] = useState<boolean>(false)

  useEffect(() => {
    fileRef
      .put(file)
      .on(
        "state_changed",
        (snap: Record<string, any>) => {
          // Get task progress, including the number of bytes uploaded
          // and the total number of bytes to be uploaded
          const progress = (snap.bytesTransferred / snap.totalBytes) * 100
          setProgress(progress)
        },
        (error: Record<string, any>) => {
          // TODO: handle errors here
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors

          // TODO: must handle an error here
          console.warn("An exception was thrown in UploadFileInput")
          console.error(error)
        },
        async () => {
          // Upload completed successfully, now we can get the download URL
          // let downloadURL = await uploader.snapshot.ref.getDownloadURL()
          setCompleted(true)
          onComplete()
        },
      )
  }, [file, fileRef])

  return (
    <FileProgress
      name={file.name}
      progress={progress}
      completed={completed}
    />
  )
}


function DocumentIcon () {
  return (
    <svg width="12" height="14">
      <path
        fill="#2460D8"
        fillRule="nonzero"
        d={oneLineTrim`
          M11.884 3.666L8.604.106a.35.35 0 0 0-.297-.103H1.303
          C.585.003 0 .535 0 1.19v11.623C0 13.467.584 14 1.303 14
          h9.39c.719 0 1.302-.533 1.302-1.187V3.912c.001-.007.005-.013.005-.02
          a.283.283 0 0 0-.116-.226z
          M8.367 5.95a.638.638 0 0 1-.793-.03l-.962-.889v4.834
          c0 .261-.25.473-.561.473-.311 0-.562-.212-.562-.473V5.032l-.962.888
          a.637.637 0 0 1-.793.031c-.23-.176-.246-.474-.037-.667
          l1.939-1.791.01-.006.02-.017.007-.008c.02-.015.044-.02.066-.033
          l.013-.008
          c.025-.013.046-.031.072-.04.008-.004.017-.005.025-.008.031-.01.064
          -.012.096-.017.032-.006.062-.015.093-.015
          l.013-.002.012.002c.032 0 .062.01.093.015.033.005.065.007.096.017
          l.025.007c.026.01.047.028.071.04
          l.014.008c.021.013.046.019.066.034.004.002.005.006.008.008l.02.017
          c.003.002.007.003.01.006
          l1.938 1.79c.21.194.192.492-.037.668z
        `}
      />
    </svg>
  )
}


function CameraIcon () {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13">
      <path
        fill="#2460D8"
        fillRule="nonzero"
        d={oneLineTrim`
          M14.423 2.252h-2.448l-.58-1.637A.914.914 0 0 0 10.536 0H5.464
          a.914.914 0 0 0-.859.615l-.58 1.637H1.577C.707 2.252 0 2.973 0 3.862
          v6.972c0 .89.706 1.61 1.577 1.61h12.846c.87 0 1.577-.72 1.577-1.61
          V3.862c0-.889-.706-1.61-1.577-1.61z
          M8 10.667a3.556 3.556 0 1 1 0-7.111 3.556 3.556 0 0 1 0 7.11z
        `}
      />
    </svg>
  )
}


const UploadInput = styled.label`
  padding: 0 30px;
  display: inline-block;
  border: none;
  outline: none;
  border-radius: 8px;
  height: 45px;
  line-height: 45px;
  box-sizing: border-box;
  font-family: Heebo;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.15);
  transition: box-shadow 0.2s;
  background-color: hsl(0, 0%, 60%);
  color: #2460d8;
  ${props =>
  // @ts-expect-error  Property "disabled" does not exist on type
    !props.disabled && `
    &:hover{
      cursor: pointer;
      box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.25);
    }
    &:active {
      box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.15);
    }
  `}
  ${props =>
  // @ts-expect-error  Property "disabled" does not exist on type
    props.disabled && `
        border: solid 1px #b9b8d1;
        color: #b9b8d1;
      `
}
`

// eslint-disable-next-line import/no-named-as-default-member
const storageRef = firebase.storage().ref()


export default function GdprUploadFileInput (
  {
    buttonLabel,
    userId,
    gdprCaseId,
    subdir,
    onChange,
    initialUploadedFiles,
  }: {
    buttonLabel: string
    userId: string
    gdprCaseId: string
    subdir: string
    onChange: (val: any) => void
    initialUploadedFiles: any[]
  },
) {
  const [files, setFiles] = useState<{ fileRef: any, file: any }[]>([])

  function fileListToArray (list: any) {
    const array = []
    for (let i = 0; i < list.length; i++) {
      const file = list.item(i)
      const fileRef = storageRef
        .child(userId)
        .child("gdprCases")
        .child(gdprCaseId)
        .child(subdir)
        .child(file.name)
      array.push({
        file,
        fileRef,
      })
    }
    return array
  }

  return (
    <>
      <Flex direction="ttb">
        <Flex direction="ttb">
          {initialUploadedFiles.map((fileRef) => (
            <FileProgress
              key={fileRef.name}
              name={fileRef.name}
              progress={100}
              completed
            />
          ))}
        </Flex>
        <Flex direction="ttb">
          {files.map(({ fileRef, file }) => (
            <FileUploader
              key={file.name}
              file={file}
              fileRef={fileRef}
              onComplete={() => {
                onChange && onChange(file)
              }}
            />
          ))}
        </Flex>

        <input
          style={{ display: "none" }}
          id={`${subdir}-raised-button-file`}
          multiple
          type="file"
          onChange={event => {
            const newFiles = fileListToArray(event.target.files)
            setFiles([...files, ...newFiles])
          }}
        />
        <Container>
          <UploadInput htmlFor={`${subdir}-raised-button-file`}>
            {/* @ts-expect-error  Type is not assignable */}
            <Show below="md">
              <Flex alignItems="center">
                <CameraIcon />&nbsp;&nbsp;{buttonLabel || "Fotos hochladen"}
              </Flex>
            </Show>
            {/* @ts-expect-error  Type is not assignable */}
            <Show from="md">
              <Flex alignItems="center">
                <DocumentIcon />
                &nbsp;&nbsp;
                {buttonLabel || "Dokumente hochladen"}
              </Flex>
            </Show>
          </UploadInput>
        </Container>
      </Flex>
    </>
  )
}
