import { PhoneCallReason } from "../../constants/enums"
import {
  TextInput,
  BooleanInput,
  DateTimeInput,
  CheckboxGroupInput,
  required,
  SimpleForm,
  RadioButtonGroupInput,
} from "react-admin"
import { PhoneCallResult } from "../../lib/enums"


interface PhoneCallFormProps {
  claimId?: string
  phoneNumber?: string
  resource?: string
  save?: (values: string[]) => Promise<void>
  sanitizeEmptyValues?: boolean
}


export default function PhoneCallForm (props: PhoneCallFormProps) {
  return (
    <SimpleForm
      resource={props.resource}
      save={props.save}
      sanitizeEmptyValues={props.sanitizeEmptyValues}>
      <TextInput
        source='claimId'
        label='Claim ID'
        validate={required()}
        defaultValue={props.claimId}
      />
      <BooleanInput source='phoneNumberValid' />
      <RadioButtonGroupInput
        source="callResult"
        choices={
          Object
            .entries(PhoneCallResult)
            .map(([key, value]) =>
              ({ id: key, name: value }),
            )
        }
      />
      <CheckboxGroupInput
        source='callReasons'
        choices={
          Object
            .entries(PhoneCallReason)
            .map(([key, value]) =>
              ({ id: key, name: value }),
            )
        }
      />
      <DateTimeInput source='startTime' defaultValue={new Date()} />
      <TextInput source='phoneNumber' defaultValue={props.phoneNumber}/>
      <TextInput source='callNotes' fullWidth multiline />
    </SimpleForm>
  )
}
