import React, { useEffect, useState } from "react"
import INSURANCE_OPTIONS from "../../lib/constants/insurance-providers"
import { find, get } from "lodash"
import styled from "styled-components"
import LightButton from "./LightButton"
import CarSuccessIcon from "./CarSuccessIcon"
import { FlexContainer, Container, H4, Body, Jumbo } from "../../lib"
import InfoBox from "./InfoBox"
import Show from "./Show"
import { useLocation } from "react-router-dom"
import qs from "query-string"
import { useGetOne } from "react-admin"
import { CircularProgress } from "@material-ui/core"


const StyledContainer = styled(Container)`
  border-bottom: 1px solid #e2e9fb;
  ${LightButton} {
    margin-top: 20px;
  }

  & + & {
    margin-top: 20px;
  }

  ${H4} {
    margin-bottom: 10px;
  }
`


export function Section (props: any) {
  return <StyledContainer p={0} py={1} {...props} />
}


const OPTS = {
  YES: "yes",
  NO: "no",
  DONTKNOW: "dont-know",
}

const valuesMap = {
  [OPTS.YES]: "Ja",
  [OPTS.NO]: "Nein",
  [OPTS.DONTKNOW]: "Weiß nicht",
  true: "Ja",
  false: "Nein",
}

const formatters = {
  km: (v: string) => `${Number(v).toLocaleString("de-DE")} km`,
  eur: (v: string) => `${Number(v).toLocaleString("de-DE")} €`,
}


function QuestionAnswer (
  { question, answer, ...props }:
  {
    question: string
    answer: string
    [key: string]: any
  },
) {
  return (
    <FlexContainer p={0} {...props}>
      <Body>
        {question}
      </Body>
      &nbsp;
      &nbsp;
      {/* @ts-expect-error */}
      <Body.Bold style={{ color: "#2460d8" }}>{answer}</Body.Bold>
    </FlexContainer>
  )
}


export function VerifyQuestionBlock (
  { questionText, format, answerText }:
  {
    questionText: string
    format?: "km" | "eur"
    answerText: string
  },
) {
  const hasMap = Boolean(valuesMap[answerText])
  const formatter = format
    ? formatters[format]
    : String
  const answer = hasMap
    ? valuesMap[answerText]
    : formatter(answerText)

  return (
    <Container style={{ borderBottom: "1px solid #e2e9fb" }}>
      <QuestionAnswer question={questionText} answer={answer} />
    </Container>
  )
}


export function Part1 ({ claim }: { claim: Claim }) {
  const {
    hasCarBeenSold,
    carSalePrice,
    currentMileage,
    mileageAtSale,
  } = claim
  const wasCarSold = hasCarBeenSold === OPTS.YES
  return (
    <>
      <VerifyQuestionBlock
        questionText='Fahrzeug bereits weiterverkauft?'
        answerText={hasCarBeenSold}
      />
      {wasCarSold &&
        <VerifyQuestionBlock
          questionText='Ungefährer Verkaufspreis:'
          format='eur'
          answerText={String(carSalePrice)}
        />}
      {wasCarSold &&
        <VerifyQuestionBlock
          questionText='ungefährer Kilometerstand bei Verkauf:'
          format='km'
          answerText={String(mileageAtSale)}
        />}
      {!wasCarSold &&
        <VerifyQuestionBlock
          questionText='ungefährer Kilometerstand aktuell:'
          format='km'
          answerText={String(currentMileage)}
        />}
    </>
  )
}


export function Part2 ({ claim }: { claim: Claim }) {
  const {
    hasCarBeenSold,
    legalExpensesInsurance,
    legalExpensesInsuranceAtPurchase,
    insuranceProvider,
    legalExpensesInsurancePolicyAsCompany,
    legalExpensesInsurancePolicyholderCompanyName,
    legalExpensesInsurancePolicyholderFirstName,
    legalExpensesInsurancePolicyholderLastName,
    legalExpensesInsurancePolicyNumber,
    isFinancingPaidOff,
    hasCarBeenFinanced,
    returnCarInCaseOfSuccess,
  } = claim
  const insuranceProviderString = get(
    find(INSURANCE_OPTIONS, e => e.value === insuranceProvider),
    "label",
    "",
  )
  return (
    <>
      {/* legalExpensesInsurance */}
      <VerifyQuestionBlock
        questionText='Rechtsschutzversicherung vorhanden?'
        answerText={legalExpensesInsurance}
      />
      {legalExpensesInsurance === "yes" && (
        <InfoBox>
          Im Falle einer Deckungszusage übernimmt Ihre Rechtsschutzversicherung
          sämtliche Prozesskosten und Claimback die Kosten
          einer etwaigen Selbstbeteiligung.
          Andernfalls werden sämtliche zur Durchsetzung Ihrer Ansprüche
          anfallenden Kosten sowie auch das Kostenrisiko vom
          Prozesskostenfinanzierer übernommen.
        </InfoBox>
      )}
      {legalExpensesInsurance === "no" && (
        <InfoBox>
          Kein Problem, nach erfolgreicher Prüfung Ihres Falls
          werden sämtliche zur Durchsetzung Ihrer Ansprüche anfallenden Kosten
          sowie auch das Kostenrisiko vom Prozesskostenfinanzierer übernommen.
        </InfoBox>
      )}
      {legalExpensesInsurance === "dont-know" && (
        <InfoBox>
          Kein Problem, bitte teilen Sie uns - falls vorhanden -
          die Daten Ihrer Rechtsschutzversicherung,
          binnen 7 Tagen nach Übermittlung des Formulars, mit.
          Andernfalls werden sämtliche zur Durchsetzung Ihrer Ansprüche
          anfallenden Kosten sowie auch das Kostenrisiko
          vom Prozesskostenfinanzierer übernommen.
        </InfoBox>
      )}
      {/* legalExpensesInsuranceAtPurchase */}
      {legalExpensesInsurance === "yes" && (
        <VerifyQuestionBlock
          questionText={
            "Rechtsschutzversicherung bereits " +
            "bei Anschaffung des Fahrzeugs vorhanden?"
          }
          answerText={legalExpensesInsuranceAtPurchase}
        />
      )}
      {/* insuranceProvider */}
      {legalExpensesInsurance === "yes" &&
        legalExpensesInsuranceAtPurchase === "yes" && (
        <VerifyQuestionBlock
          questionText='Versichert über/bei:'
          answerText={insuranceProviderString}
        />
      )}

      {legalExpensesInsurance === "yes" &&
        legalExpensesInsuranceAtPurchase === "yes" && (
        <Container style={{ borderBottom: "1px solid #e2e9fb" }}>
          {/* legalExpensesInsurancePolicyAsCompany */}
          <QuestionAnswer
            mb={0.25}
            question='Versicherungsnehmer:'
            answer={
              legalExpensesInsurancePolicyAsCompany === "yes"
                ? "Firma"
                : "Privat"
            } />

          {/* legalExpensesInsurancePolicyholderCompanyName */}
          {legalExpensesInsurancePolicyAsCompany === "yes" && (
            <QuestionAnswer
              mb={0.25}
              question='Firmenname'
              answer={legalExpensesInsurancePolicyholderCompanyName}
            />
          )}
          <QuestionAnswer
            mb={0.25}
            question='Vorname'
            answer={legalExpensesInsurancePolicyholderFirstName}
          />
          <QuestionAnswer
            mb={0.25}
            question='Nachname'
            answer={legalExpensesInsurancePolicyholderLastName}
          />
          <QuestionAnswer
            mb={0.25}
            question='Versicherungsnummer'
            answer={legalExpensesInsurancePolicyNumber}
          />
        </Container>
      )}
      {/* hasCarBeenFinanced */}
      {hasCarBeenFinanced && (
        <VerifyQuestionBlock
          questionText='Wurde das Auto finanziert?'
          answerText={hasCarBeenFinanced}
        />
      )}
      {/* isFinancingPaidOff */}
      {hasCarBeenFinanced === "yes" && (
        <VerifyQuestionBlock
          questionText='Finanzierung bereits abgeschlossen?'
          answerText={isFinancingPaidOff}
        />
      )}
      {/* returnCarInCaseOfSuccess */}
      {hasCarBeenSold === "no" && (
        <>
          <VerifyQuestionBlock
            questionText='Wollen Sie im Erfolgsfall Ihr Auto zurückgeben?'
            answerText={returnCarInCaseOfSuccess}
          />
          {returnCarInCaseOfSuccess === "dont-know" && (
            <InfoBox>
              Kein Problem.
              In diesem Fall wählt die Rechtsanwaltskanzlei
              das für Sie wirtschaftlich günstigste Anspruchsziel aus
              und setzt dieses für Sie durch.
            </InfoBox>
          )}
        </>
      )}
    </>
  )
}


export function Part3 ({ claim }: { claim: Claim }) {
  const {
    carModel,
    carPurchasePrice,
    carYear,
    vinNumber,
    mileageAtPurchase,
    // carPurchaseEntity,
    companyName,
    firstName,
    lastName,
    purchaseContractCompanyName,
    purchaseContractFirstName,
    purchaseContractLastName,
    address,
    postcode,
    city,
  } = claim

  return (
    <>
      <VerifyQuestionBlock
        questionText='Fahrzeugmodell:'
        answerText={carModel}
      />
      <VerifyQuestionBlock
        questionText='Kaufpreis:'
        format='eur'
        answerText={String(carPurchasePrice)}
      />
      <VerifyQuestionBlock
        questionText='Baujahr:'
        answerText={String(carYear)}
      />
      <VerifyQuestionBlock
        questionText='FIN Nummer:'
        answerText={vinNumber}
      />
      <VerifyQuestionBlock
        questionText='Kilometerstand bei Kauf:'
        format='km'
        answerText={String(mileageAtPurchase)}
      />
      <Container style={{ borderBottom: "1px solid #e2e9fb" }}>
        {/* carPurchaseEntity */}
        {/* <QuestionAnswer
          mb={0.25}
          question='Käufer:'
          answer={carPurchaseEntity === 'privat' ? 'Privat' : 'Firma'}
        /> */}
        {/* name */}
        {(purchaseContractCompanyName ||
            purchaseContractFirstName ||
            purchaseContractLastName
        )
          ? (
            <>
              {purchaseContractCompanyName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Firmenname'
                  answer={purchaseContractCompanyName}
                />
              }
              {purchaseContractFirstName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Vorname:'
                  answer={purchaseContractFirstName}
                />
              }
              {purchaseContractLastName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Nachname:'
                  answer={purchaseContractLastName}
                />
              }
            </>
          )
          : (
            <>
              {companyName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Firmenname'
                  answer={companyName}
                />
              }
              {firstName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Vorname:'
                  answer={firstName}
                />
              }
              {lastName &&
                <QuestionAnswer
                  mb={0.25}
                  question='Nachname:'
                  answer={lastName}
                />
              }
            </>
          )}
        {/* address */}
        <QuestionAnswer mb={0.25} question='Adresse:' answer={address} />
        {/* postcode */}
        <QuestionAnswer mb={0.25} question='Postleitzahl:' answer={postcode} />
        {/* city */}
        <QuestionAnswer mb={0.25} question='Stadt:' answer={city} />
      </Container>
    </>
  )
}


// TODO: Merge duplicate code with application success page
export default function ApplicationSuccess ({ ...props }) {
  const [claim, setClaim] = useState<Claim | null>(null)
  const location = useLocation()
  const values = qs.parse(location.search)
  const claimId = (values.claimId
    ? (Array.isArray(values.claimId)
      ? values.claimId[0]
      : values.claimId)
    : "") as string
  const { data, loading } = useGetOne("claims", claimId)

  useEffect(() => {
    if (data) {
      // @ts-expect-error
      setClaim(data)
    }
  }, [data])

  if (loading) {
    return (
      <FlexContainer>
        <CircularProgress />
      </FlexContainer>
    )
  }

  return (
    <Container>
      <Section pt={0}>
        {/* @ts-expect-error */}
        <Body.Bold>
          Ihre Claim-ID:{" "}
          <span style={{ color: "#2460d8" }}>{get(claim, "id", "")}</span>
        </Body.Bold>
        {/* @ts-expect-error */}
        <Show below='md'>
          <Jumbo>
            Geschafft!
          </Jumbo>
          <Body>
            Hier sehen Sie alle Daten übersichtlich aufgelistet;
            Sie sind allerdings nur noch telefonisch oder per E-Mail änderbar.
          </Body>
          <div>
            <div style={{ width: 180, margin: "20px auto 0" }}>
              <CarSuccessIcon />
            </div>
          </div>
        </Show>
        {/* @ts-expect-error */}
        <Show from='md'>
          <FlexContainer p={0}>
            <div>
              <Jumbo>
                Geschafft!
              </Jumbo>
              <Body>
                Hier sehen Sie alle Daten übersichtlich aufgelistet;
                Sie sind allerdings nur noch telefonisch
                oder per E-Mail änderbar.
              </Body>
            </div>
            <div style={{ marginLeft: 20, minWidth: 238 }}>
              <CarSuccessIcon />
            </div>
          </FlexContainer>
        </Show>
      </Section>

      <Section>
        <H4>
          Teil 1:
        </H4>
        {claim && <Part1 claim={claim} />}
      </Section>
      <Section>
        <H4>
          Teil 2:
        </H4>
        {claim && <Part2 claim={claim} />}
      </Section>
      <Section style={{ borderBottom: "none" }}>
        <H4>
          Teil 3:
        </H4>
        {claim && <Part3 claim={claim} />}
      </Section>
      <Section>
        {/* @ts-expect-error */}
        <Body.Small>
          Unsere Partnerkanzlei wird Sie über diese Mailadresse
          auf dem aktuellen Stand halten: {claim?.userEmail}
        </Body.Small>
      </Section>
    </Container>
  )
}
