import { Datagrid, List, TextField } from "react-admin"

export default function CustomFieldList ({...props}) {
  return (
    <List {...props}>
      <Datagrid rowClick='edit'>
        <TextField label='ID' source='id' sortable={false} />
        <TextField label='Field Name' source='name' sortable={false} />
        <TextField label='Field Label' source='label' sortable={false} />
        <TextField label='Field Type' source='type' sortable={false} />
      </Datagrid>
    </List>
  )
}
