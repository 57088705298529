import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { BREAKPOINTS } from '../../constants'

const getPx = (prop: BreakpointSize, max = true) => {
  if (typeof prop === 'string') {
    if (max) {
      return BREAKPOINTS[prop].max
    } else {
      return BREAKPOINTS[prop].min
    }
  }
  if (typeof prop === 'number') {
    return prop
  }
  return 0
}

// CSS shortcuts
const between = (abovePx: number, belowPx: number) => css`
  @media (min-width: ${abovePx}px) and (max-width: ${belowPx}px) {
    display: inherit;
  };
`
const above = (abovePx: number) => css`
  @media (min-width: ${abovePx}px){
    display: inherit;
  }
`
const below = (belowPx: number) => css`
  @media (max-width: ${belowPx}px){
    display: inherit;
  }
`

const Wrapper = styled.div`
  display: none;
  ${
    //@ts-expect-error
    ({ abovePx, belowPx }) => {
      if (abovePx && belowPx) {
        return between(abovePx, belowPx)
      }
      if (abovePx) {
        return above(abovePx)
      }
      if (belowPx) {
        return below(belowPx)
      }
    }
  }
`

const Show = (
  { above, below, from, to, ...props }: {
    above: BreakpointSize,
    below: BreakpointSize,
    from: BreakpointSize,
    to: BreakpointSize,
  }
) => {
  if ((above || below) && (from || to)) {
    console.warn(
      '<Show>:',
      'Mixing props from/to and below/above ' +
        'is not supported and may give unexpected results',
    )
  }

  let abovePx; let belowPx
  // on above we want it to go to the next breakpoint
  // (ie above md means on lg and xl screens)
  if (above) {
    abovePx = getPx(above) + 1 // get the max of breakpoint 'above'
  }
  if (below) {
    belowPx = getPx(below, false) - 1 // get the min of breakpoint 'below'
  }
  if (from) {
    abovePx = getPx(from, false)
  }
  if (to) {
    belowPx = getPx(to)
  }

  // if we use this component without props
  // then we want it to Show on all screens
  if (
    above === undefined &&
    below === undefined &&
    from === undefined &&
    to === undefined
  ) {
    abovePx = 1
  }

  return (
    <Wrapper
      // @ts-expect-error
      abovePx={abovePx}
      belowPx={belowPx}
      {...props}
    />
  )
}

const breakpointEnum = PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])

Show.propTypes = {
  // can be a breakpoint selector or a number in px
  above: PropTypes.oneOfType([
    breakpointEnum,
    PropTypes.number,
  ]),
  below: PropTypes.oneOfType([
    breakpointEnum,
    PropTypes.number,
  ]),
  from: PropTypes.oneOfType([
    breakpointEnum,
    PropTypes.number,
  ]),
  to: PropTypes.oneOfType([
    breakpointEnum,
    PropTypes.number,
  ]),
}
export default Show
