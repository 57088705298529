import React, { useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import { find, get } from "lodash"
import { useNotify } from "react-admin"

import firebase from "../../firebase"
import getHttpsCallable from "../../utils/getHttpsCallable"


const transferTargets = [
  { name: "AILTS" },
  { name: "KAP" },
]
const useStyles = makeStyles(theme => ({
  toolbar: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  textarea: {
    width: "100%",
    backgroundColor: grey[800],
    color: theme.palette.text.primary,
    borderRadius: 4,
    borderColor: theme.palette.primary.light,
  },
}))


interface ResultDialogParams {
  open: boolean
  handleClose: (a: unknown) => unknown
  sending: boolean
  results: any[]
  claimIds: string[]
  target: string
}

function ResultDialog (
  {
    open,
    handleClose,
    sending,
    results,
    claimIds,
    target,
  }: ResultDialogParams,
) {
  const sentCount = results.filter(r => r.sent).length
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Result</DialogTitle>
      <DialogContent>
        {sending
          ? (
            <DialogContentText>
              Currently transferring {claimIds.length} claims to {target}
            </DialogContentText>
          )
          : (
            <>
              <Typography variant='h6'>
                Sent {sentCount} of {claimIds.length} claims
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Claim ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((result) => {
                    return (
                      <TableRow key={result.claimId}>
                        <TableCell>{result.claimId}</TableCell>
                        <TableCell>
                          {result.error
                            ? <span style={{color: "red"}}>Error</span>
                            : <span style={{color: "green"}}>OK</span>
                          }
                        </TableCell>
                        <TableCell>
                          {result.error
                            ? <span style={{color: "red"}}>
                              {result.error.message}
                            </span>
                            : <span style={{color: "green"}}>
                              {result.message}
                            </span>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          disabled={sending}
          color='primary'
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const bulkTransfer = getHttpsCallable("admin-bulkTransfer")

export default function BulkTransfer ({ list = [] }) {
  const [transferTarget, setTransferTarget] = useState(transferTargets[0])
  const [targetError, setTargetError] = useState<string | null>(null)
  const [claimIds, setClaimIds] = useState(list)
  const [claimIdsError, setClaimIdsError] = useState<string | null>(null)
  const [results, setResults] = useState([])
  const [sending, setSending] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const notify = useNotify()
  const classes = useStyles()

  async function handleSend () {
    if (claimIds.length < 1) {
      setClaimIdsError("You must enter one or more claim IDs")
      return
    }

    setClaimIdsError(null)
    setResults([])
    setSending(true)
    setDialogOpen(true)

    try {
      const transferData = {
        claimIds,
        selectedTarget: transferTarget,
      }

      setSending(false)
      const transferResult = await bulkTransfer(transferData)
      console.info("-> : transfer result", transferResult)
      setResults(transferResult.data)
    }
    catch (error) {
      // @ts-ignore
      notify(`Following error occurred: ${error.message}`, "warning")
    }
  }

  return (
    <>
      <ResultDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        sending={sending}
        results={results}
        claimIds={claimIds}
        target={transferTarget.name}
      />
      <Grid>
        <Toolbar className={classes.toolbar}>
          <FormControl style={{ minWidth: 300 }}>
            <InputLabel htmlFor='transferTarget'>Transfer Target</InputLabel>
            <Select
              error={Boolean(targetError)}
              disabled={sending}
              value={transferTarget.name}
              onChange={(event: any /* ChangeEvent */) => {
                setTransferTarget(event.target.value)
                setTargetError(null)
              }}
            >
              {Object.values(transferTargets).map(transferTarget => (
                <MenuItem key={transferTarget.name} value={transferTarget.name}>
                  {transferTarget.name}
                </MenuItem>
              ))}
            </Select>
            {targetError &&
              <FormHelperText>{targetError}</FormHelperText>
            }
          </FormControl>
          <Button
            variant='outlined'
            color='primary'
            disabled={sending}
            onClick={handleSend}
          >Send
          </Button>
        </Toolbar>

        <div style={{ margin: 30 }}>
          {claimIdsError &&
            <Typography variant='caption' style={{ color: "red" }}>
              {claimIdsError}
            </Typography>
          }
          <Typography variant='subtitle1' color='textPrimary'>
            Claim Ids
          </Typography>
          <Typography variant='caption' color='textPrimary'>
            Place one claim id per line
          </Typography>
          <textarea
            className={classes.textarea}
            rows={30}
            defaultValue={list.length > 0 ? list.join("\n") : ""}
            onChange={(event: any /* ChangeEvent */) => {
              setClaimIdsError(null)
              const claimsFromTextarea = event.target.value
                .trim()
                .split(/\r?\n/)
                .map((s: string) => s.trim())
              setClaimIds(claimsFromTextarea)
            }}
          />
        </div>
      </Grid>
    </>
  )
}
