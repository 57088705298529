import React from "react"
import DefaultActions from "./DefaultActions"
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin"
import DefaultPagination from "./DefaultPagination"

export function SubscriptionList ({ ...props }) {
  return (
    <List
      {...props}
      actions={<DefaultActions />}
      perPage={15}
      pagination={<DefaultPagination />}
    >
      <Datagrid rowClick='show'>
        <TextField source='email' label='Email' sortable={false} />
        <TextField source='name' label='Name' sortable={false} />
        <TextField source='claimType' label='Claim Type' sortable={false} />
      </Datagrid>
    </List>
  )
}

export function SubscriptionEdit ({...props}) {
  return (
    <Edit {...props}>
      {/* <ContactActions data={props} /> */}
      <SimpleForm>
        <TextInput source='email' label='Email' sortable={false} />
        <TextInput source='name' label='Name' sortable={false} />
        <TextInput source='claimType' label='Claim Type' sortable={false} />
      </SimpleForm>
    </Edit>
  )
}
