import React, { useEffect, useState } from "react"
import { remove } from "lodash"
import {
  Box,
  List,
  Typography,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core"

import { db } from "../../firebase"
import TicketListItem from "./TicketListItem"
import ticketRequiresAttention from "../../utils/ticketRequiresAttention"
import Flex from "../../lib/components/Flex"
import { TicketAssignee } from "../../constants/enums"

const LIMIT_BATCH_SIZE = 50


export default function TrackingColumn (
  { assignedGroup, tagFilters = [] }:
  {
    assignedGroup: string
    tagFilters: string[]
  },
) {
  const [ticketDocs, setTicketDocs] = useState<any[]>([])
  const [limit, setLimit] = useState(LIMIT_BATCH_SIZE)
  const [snapCount, setSnapCount] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [snoozedCount, setSnoozedCount] = useState(0)
  const [awakeCount, setAwakeCount] = useState(0)
  const [shouldLoadAll, setShouldLoadAll] = useState(false)

  useEffect(() => {
    // TODO: Don't load claim for each ticket.
    //   Tickets should already have a type field, or better:
    //   Tickets should be merged with claims,
    //   since each claim has exactly one ticket.

    let q = db
      .collection("tickets")
      .where("assignedGroup", "==", assignedGroup)
      .where("type", "==", "diesel")
      .orderBy("claimLastUpdated", "desc")
      .limit(limit)

    if (tagFilters.length) {
      q = q.where("tags", "array-contains-any", tagFilters)
    }

    q.onSnapshot(async (snap) => {
      const awakeTickets = snap.docs

      const snoozed = remove(awakeTickets, (doc) => {
        const data = doc.data()
        return !ticketRequiresAttention((data || {}) as Ticket)
      })

      setAwakeCount(awakeTickets.length)
      setSnoozedCount(snoozed.length)

      const ticketDocsAll = awakeTickets.concat(snoozed)

      setTicketDocs(ticketDocsAll)
      setLoaded(true)
      setSnapCount(ticketDocsAll.length)
    })
  }, [limit, assignedGroup, tagFilters])


  if (shouldLoadAll && snapCount >= limit) {
    setLimit(limit + LIMIT_BATCH_SIZE)
  }
  else if (
    loaded &&
    awakeCount === 0 &&
    snoozedCount === limit &&
    assignedGroup !== TicketAssignee.COMPLETE
  ) {
    setLimit(limit + LIMIT_BATCH_SIZE)
  }

  return (
    <Box p={0.5}>
      <Typography variant='h6' color='textPrimary' gutterBottom align='center'>
        {assignedGroup}
      </Typography>
      <Flex center>
        <FormControlLabel
          control={
            <Switch
              checked={shouldLoadAll}
              onChange={() => setShouldLoadAll(!shouldLoadAll)}
            />
          }
          label='Load All'
          // @ts-expect-error TODO: variant does not exist?
          variant='filled'
          labelPlacement='start'
        />
      </Flex>
      <List dense>
        {ticketDocs.map(ticketDoc => {
          const { claimId } = ticketDoc.data()
          const link = assignedGroup === TicketAssignee.DATA_ENTRY
            ? `/claims/${claimId}/show/6`
            : `/claims/${claimId}/show`
          return (
            <TicketListItem
              ticketDoc={ticketDoc}
              key={ticketDoc.id}
              link={link}
              claimId={claimId}
              showPhoneIcons
              colorItemsByStatus={false}
            />
          )
        })}
        {snapCount >= limit && (
          <Button
            fullWidth
            onClick={() => setLimit(limit + LIMIT_BATCH_SIZE)}
          >
            Show More
          </Button>
        )}
      </List>
    </Box>
  )
}
