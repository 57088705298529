import React, {useEffect, useState} from "react"
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Filter,
  List,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
  downloadCSV,
  FunctionField,
  useNotify,
} from "react-admin"
import jsonExport from "jsonexport/dist"

import { db } from "../../firebase"

import { Button, Box } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import DownloadIcon from "@material-ui/icons/GetApp"
import BuildCircle from "@material-ui/icons/BuildRounded"

import {
  createHash,
} from "crypto"


import { DateRangeFilterWithField } from "../DateRangeFilter"
import TimestampField from "../TimestampField"
import DefaultActions from "../DefaultActions"
import DefaultPagination from "../DefaultPagination"
import SendBulkEmailButton from "../Claim/SendBulkEmailButton"
import SendBulkWhatsappButton from "../Claim/SendBulkWhatsappButton"
import ClaimBulkDelete from "../ClaimBulkDelete"
import BulkTransferButton from "../Claim/BulkTransferButton"
import DuplicateClaims from "../DuplicateClaims"
import getHttpsCallable from "../../utils/getHttpsCallable"

function wrapInBox (component: JSX.Element) {
  return (
    // @ts-expect-error: Property 'alwaysOn' does not exist
    <Box mb={2} alwaysOn>
      {component}
    </Box>
  )
}

function ClaimFilters (
  props: {
    setFilters?: (filterMap: Record<string, any>) => void,
    filterValues?: Record<string, any>,
  },
) {
  const notify = useNotify()
  async function hashEmails () {
    const claims = await db
      .collectionGroup("claims")
      .where("type", "==", "online-casino")
      .get()

    let csvFile = ""

    claims.docs.forEach((claim) => {
      const user = claim.data()
      const email = user.userEmail
      const hashObj = createHash("sha512")

      const hashRaw = hashObj.update(email, "utf8")
      const hash = hashRaw.digest("hex")
      csvFile += `${hash},\n`
    })

    const date = new Date()
    const dateString = date.toISOString().split("T")[0]

    downloadCSV(csvFile, `${dateString.replaceAll("-", "")}-Encoded-Emails`)
  }

  async function fixFutureClaim () {
    const fixFutureData = getHttpsCallable("admin-fixFutureClaimDate")

    try {
      const res: Record<string, any> = await fixFutureData({})
  
      if (res.data && res.data.error) {
        notify(res.data.error.message, "error")
      }
  
      notify("Future Users and Claims updated", "success")
    }
    catch (err: any) {
      notify(err.message, "error")
    }
  }

  // async function backupDatabase () {
  //   const runBackupDatabase = 
  //    getHttpsCallable("admin-runnableFirestoreBackup")

  //   try {
  //     const res: Record<string, any> = await runBackupDatabase({})
  
  //     if (res.data && res.data.error) {
  //       notify(res.data.error.message, "error")
  //     }
  
  //     notify("Database had been backed up", "success")
  //     const base64Data = res.data.data

  //     // Decode the base64 string to JSON string
  //     const jsonData = atob(base64Data)

  //     // Create a Blob from the JSON string
  //     const blob = new Blob([jsonData], { type: "application/json" })

  //     // Create a URL for the Blob and download it
  //     const url = URL.createObjectURL(blob)
  //     const a = document.createElement("a")
  //     a.href = url
  //     a.download = "firestore-backup.json"
  //     document.body.appendChild(a)
  //     a.click()
  //     document.body.removeChild(a)
  //   }
  //   catch (err: any) {
  //     notify(err.message, "error")
  //   }
  // }

  return (
    <Filter {...props}>
      {wrapInBox(
        <Button
          variant='outlined'
          size='small'
          onClick={() => props.setFilters && props.setFilters({
            claimActionStatus: "",
            createdTime: "",
            id: "",
            lawFirm: "",
            tag: "",
            transmittedToLawfirmAt: "",
            userEmail: "",
            userId: "",
            userIsRegistered: true,
            userRegistrationTime: "",
          })}
        >
          <ClearIcon />
          Clear Filters
        </Button>,
      )}
      <TextInput label='Filter by claim id' source='id' alwaysOn />
      <TextInput label='Filter by user id' source='userId' alwaysOn />
      <TextInput label='Filter by email' source='userEmail' alwaysOn />
      <TextInput label='Filter by law firm case id'
        source='lawFirmCaseId__number' alwaysOn/>
      <SelectInput
        label='Law Firm'
        source="lawFirm"
        alwaysOn
        choices={[
          { id: "AILTS", name: "AILTS" },
          { id: "BRC", name: "BRC" },
          { id: "KAP", name: "KAP" },
          { id: "LEO", name: "LEO" },
          { id: "Von Rüden", name: "Von Rüden" },
          { id: "Hartung", name: "Hartung" },
          { id: "DPR", name: "Redell" },
        ]}
      />
      <TextInput label='Filter by lead source' source='leadSource' alwaysOn />
      {wrapInBox(
        <DateRangeFilterWithField source='createdTime' label='Created At' />,
      )}
      {/*
      {wrapInBox(
        <DateRangeFilterWithField
          source='userRegistrationTime'
          label='Registration Time'
        />,
      )}
      */}
      {wrapInBox(<Button
        variant='outlined'
        color='primary'
        onClick={() => hashEmails()}
      >
        <DownloadIcon/>
            Export Encoded Emails
      </Button>)}

      {wrapInBox(<Button
        variant='outlined'
        color='primary'
        onClick={() => fixFutureClaim()}
      >
        <BuildCircle />
        Fix Claim Date
      </Button>)}

      {/* {wrapInBox(<Button
        variant='outlined'
        color='primary'
        onClick={() => backupDatabase()}
      >
        <DownloadIcon/>
            Export Database
      </Button>)} */}

      <BooleanInput
        source='lead'
        label='Lead'
      />
      <BooleanInput
        source='transmittedToLawfirmAt__exists'
        label='Transmitted to Lawfirm'
      />
      <BooleanInput
        source='hasMultipleClaims'
        label='Has Multiple Claims'
      />
      <BooleanInput
        source='tlUploaded'
        label='Transaction List Uploaded'
      />
      <BooleanInput
        source='accInfoUploaded'
        label='Account Information Uploaded'
      />
      <BooleanInput
        source='poaUploaded'
        label='POA Uploaded'
      />
      <BooleanInput
        source='tlRequested'
        label='Transaction List Requested'
      />
      <SelectInput
        source='claimActionStatus'
        choices={[
          { id: "active", name: "Active" },
          { id: "dead", name: "Dead" },
          { id: "onHold", name: "On Hold" },
          { id: "finished", name: "Finished" },
        ]}
      />
    </Filter>
  )
}


function ClaimBulkActionButtons (
  props: { [x: string]: any, selectedIds: any },
) {
  return (
    <Box>
      <DuplicateClaims {...props} ocClaim={true} />
      <SendBulkEmailButton {...props} />
      <SendBulkWhatsappButton {...props} />
      <BulkTransferButton {...props} />
      <ClaimBulkDelete {...props} />
    </Box>
  )
}

function exporter (claims: any) {
  jsonExport(claims, {
    fillGaps: true,
  }, (err, csv) => {
    downloadCSV(csv, "online-casino-claims")
  })
}

const dateFieldOpts = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}


export default function OnlineCasinoClaimList (props: Record<string, unknown>) {
  return (
    <List
      // Permanent filter
      filter={{
        type: "online-casino",
      }}
      filters={<ClaimFilters />}
      // @ts-expect-error
      bulkActionButtons={<ClaimBulkActionButtons />}
      actions={<DefaultActions />}
      filterDefaultValues={{ userIsRegistered: true }}
      sort={{ field: "createdTime", order: "DESC" }}
      perPage={25}
      pagination={<DefaultPagination />}
      exporter={exporter}
      {...props}
    >
      <Datagrid rowClick='show'>
        <BooleanField
          label='Lead'
          source='lead'
          sortable={false}
        />
        <TextField label='Claim ID' source='id' sortable={false} />
        <TextField label='User ID' source='userId' sortable={false} />
        <TextField source='userEmail' sortable={false} />
        <TextField
          source='phoneNumber'
          sortable={false}
          style={{ whiteSpace: "nowrap" }}
        />
        <TextField source='claimActionStatus' sortable={false} />
        <TextField source='lawFirm' label='Law Firm' sortable={false} />
        {/* <TextField source='lawFirmCaseId'
          label='Law Firm Case ID' sortable={false} /> */}
        <TimestampField
          source='createdTime'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <TimestampField
          source='transmittedToLawfirmAt'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <TimestampField
          source='updatedTime'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        {/* <BooleanField
          source='hasMultipleClaims'
          label='Has Multiple Claims'
          sortable={false}
        /> */}
        <BooleanField
          source='tlUploaded'
          label='Transaction List Uploaded'
          sortable={false}
        />
        {/* <BooleanField
          source='accInfoUploaded'
          label='Account Information Uploaded'
          sortable={false}
        /> */}
        <BooleanField
          source='poaUploaded'
          label='POA Uploaded'
          sortable={false}
        />
        <TimestampField
          source='poaUploadedAt'
          label='POA Uploaded At'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <BooleanField
          source='tlRequested'
          label='Transaction List Requested'
          sortable={false}
        />
        <TimestampField
          source='tlRequestedAt'
          label='Transaction List Requested At'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <FunctionField
          source="utmParams"
          label='Utm Source'
          render={record => {
            if (!record?.utmParams) {
              return ""
            }

            const utmSource = record?.utmParams.find(
              (utm: { source: string; value: any; }) => 
                utm.source === "utm_source",
            )

            return utmSource?.value
          }}
        />
        <TextField source='leadSource' label='Lead Source' sortable={false} />

      </Datagrid>
    </List>
  )
}
