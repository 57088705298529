// TODO: Remove duplication after following issue has been fixed:
// https://github.com/firebase/firebase-tools/issues/1528#issuecomment-854119724

import firebase from "../firebase"

export default function getHttpsCallable (
  name: string,
): (val: any) => Promise<any> {
  return process.env.REACT_APP_BUILD_ENV === "localhost"
    ? firebase  // eslint-disable-line import/no-named-as-default-member
      .functions()
      .httpsCallable(name)
    : firebase
      .app()
      .functions("europe-west1")
      .httpsCallable(name)
}
