import { ListItem } from "@material-ui/core"
import React from "react"
import { Link } from "react-router-dom"


interface ListItemLinkParams {
  primary: any
  to: any
  children: any
  [x: string]: any
}

export default function ListItemLink (
  { primary, to, children, ...props }: ListItemLinkParams,
) {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        // @ts-expect-error
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to],
  )

  return (
    <ListItem button component={renderLink} {...props}>
      {children}
    </ListItem>
  )
}
