import React, { useEffect, useState } from "react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem, ListItemText, Avatar, ListItemAvatar, InputLabel,
  TextField, Select, MenuItem, FormControl } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CircularProgress from "@material-ui/core/CircularProgress"

import { Flex } from "../../lib"
import { FlexContainer } from "../../lib/components/Container"
import firebase, { db } from "../../firebase"
import { get, filter } from "lodash"
import moment from "moment"
import deepDiff from "../../lib/helpers/deepDifference"


interface HistoryListItemParams {
  entry: {
    snapshotTime: FirebaseAdminTimestamp
    writeData: {
      updatedById: string
      updatedBy: string
    }
    change: {[key: string]: any}
  }
  index: number
}

function HistoryListItem (
  { entry, index }: HistoryListItemParams,
) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              {index}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${get(entry, "writeData.updatedBy", "unknown")}`}
            secondary={
              `${moment(entry.snapshotTime.toDate()).format("LLL")} - ${
                get(entry, "writeData.updatedById", "Unknown")}`
            }
          />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {Object.keys(entry.writeData).sort().map(k => {
            return (
              <div key={k}>
                {Object.keys(entry.change).includes(k)
                  ? (
                    <Typography
                      variant='caption'
                      style={{ backgroundColor: "blue" }}
                    >
                      {k} - {
                        // @ts-expect-error
                        entry.writeData[k] instanceof firebase
                          .firestore.Timestamp
                        // @ts-expect-error
                          ? `${entry.writeData[k].toDate()}`
                        // @ts-expect-error
                          : JSON.stringify(entry.writeData[k])
                      }
                    </Typography>
                  )
                  : (
                    <Typography variant='caption'>
                      {k} - {
                        // @ts-expect-error
                        entry.writeData[k] instanceof firebase
                          .firestore.Timestamp
                        // @ts-expect-error
                          ? `${entry.writeData[k].toDate()}`
                        // @ts-expect-error
                          : JSON.stringify(entry.writeData[k])
                      }
                    </Typography>
                  )}
                <br />
              </div>
            )
          })}
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}


export default function ClaimHistory (
  { record }: { record?: { id: string } },
) {
  const [loading, setLoading] = useState(true)
  const [changeList, setChangeList] = useState<any[]>([])
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc")
  const [changeFilter, setChangeFilter] = useState("")

  const claimId = get(record, "id")

  useEffect(() => {
    return db
      .collection("mongo-job-queue")
      .where("documentId", "==", claimId)
      .orderBy("snapshotTime", "asc")
      .onSnapshot(snap => {
        setLoading(false)
        setChangeList(snap.docs.map((d, i) => {
          const data = d.data()
          let change
          if (i === 0) {
            change = data.writeData
          }
          else {
            const prevWriteData = snap.docs[i - 1].data().writeData
            change = deepDiff(data.writeData, prevWriteData)
          }

          return { ...d.data(), change }
        }))
      })
  }, [claimId])

  if (loading) {
    return (
      <Flex center style={{ margin: 20 }}>
        <CircularProgress />
      </Flex>
    )
  }

  const filteredList = changeFilter
    ? filter(
      changeList,
      entry => Object.keys(entry.change).includes(changeFilter),
    )
    : changeList

  return (
    <div>
      <FlexContainer>
        <FormControl>
          <InputLabel>Sort</InputLabel>
          <Select
            value={sortDir}
            onChange={(event: any) => setSortDir(event.target.value)}
          >
            <MenuItem value='asc'>Asc</MenuItem>
            <MenuItem value='desc'>Desc</MenuItem>
          </Select>
        </FormControl>

        <TextField
          style={{ marginLeft: 24 }}
          label='Filter'
          value={changeFilter}
          onChange={(e) => setChangeFilter(e.target.value)}
        />
      </FlexContainer>

      {sortDir === "asc"
        ? filteredList.map((entry, i) => (
          <HistoryListItem entry={entry} index={i} key={i} />
        ))
        : filteredList.slice().reverse().map((entry, i) => (
          <HistoryListItem
            entry={entry}
            index={filteredList.length - i}
            key={i}
          />
        ))}

    </div>
  )
}
