export enum TicketAssignee {
  AWAITING_KV = "AWAITING_KV",
  DATA_ENTRY = "DATA_ENTRY",
  DATA_EVAL = "DATA_EVAL",
  FOLLOW_UP = "FOLLOW_UP",
  COMPLETE = "COMPLETE",
  BFR_CALL_LIST = "BFR_CALL_LIST",
}


export enum EmailProviderMap {
  MANDRILL = "MANDRILL",
  ZENDESK_SUPPORT = "ZENDESK_SUPPORT",
}


export enum PhoneCallReason {
  KV_REMINDER = "KV_REMINDER",
  BFR_REMINDER = "BFR_REMINDER",
  T_C = "T_C",
  RSV_INFO = "RSV_INFO",
  EURO_5_6 = "EURO_5_6",
  OTHER = "OTHER",
}

export enum ClaimTypes {
  "bank-fee-recovery" = "bank-fee-recovery",
  "diesel" = "diesel",
  "online-casino" = "online-casino",
  "rejects" = "rejects",
}
