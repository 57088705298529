import Button from "@material-ui/core/Button"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import React from "react"
import { useNotify } from "react-admin"


export default function CopyablePhoneNumberButton (
  { value, ...props }: { value: string, [key: string]: any },
) {
  const notify = useNotify()

  async function copyTextToClipboard (text: string) {
    try {
      await navigator.clipboard.writeText(text)
      notify("Text Copied")
    }
    catch (error) {
      console.error("Could not copy text: ", error)
      notify(`Could not copy text: ${error}`, "warning")
    }
  }

  return (
    <Button
      endIcon={<FileCopyIcon />}
      onClick={() => copyTextToClipboard(value)}
      {...props}
    >
      {value}
    </Button>
  )
}
