import FirebaseTimestamp from "./FirebaseTimestamp"

const smsFields = {
  claimId: String,
  id: String,
  messageContent: String,
  phoneNumber: String,
  sent: Boolean,
  userId: String,
  timestamp: FirebaseTimestamp,
  _timestamp: Date,
}

export default smsFields
