import React from "react"
import { truncate } from "lodash"
import {
  Avatar,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Typography,
} from "@material-ui/core"
import { AvatarGroup } from "@material-ui/lab"
import SmartphoneIcon from "@material-ui/icons/PhoneAndroid"

import ListItemLink from "./ListItemLink"
import useStyles from "./styles"
import { dieselTags } from "../../constants/ticketTags"


export default function TicketListItem (
  {
    ticketDoc,
    claimId,
    link,
    showPhoneIcons,
    colorItemsByStatus,
  }: TicketListItemParams,
) {
  const ticketData = ticketDoc.data()
  const classes = useStyles({ ticketData, colorItemsByStatus })
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { hasPhoneNumber } = ticketData

  function handleClick (event: any) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose () {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <ListItemLink
      className={classes.ticketListItem}
      to={link}
      primary={truncate(claimId, { length: 15 })}
    >
      <ListItemText primary={truncate(claimId, { length: 15 })} />
      <ListItemSecondaryAction>
        <AvatarGroup
          // @ts-expect-error TODO: avatarGroup does not exist
          className={classes.avatarGroup}
        >
          {showPhoneIcons && hasPhoneNumber && (
            <Avatar className={classes.phoneAvatar}>
              <SmartphoneIcon />
            </Avatar>
          )}
          {ticketData?.tags?.length && (
            <div>
              <Avatar className={classes.tagAvatar} onClick={handleClick}>
                T
              </Avatar>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div style={{ padding: 12 }}>
                  {ticketData.tags.map((t: string) => (
                    <Typography key={t}>
                      {dieselTags.find(tag => tag.id === t)?.name || "Unknown"}
                    </Typography>
                  ))}
                </div>
              </Popover>
            </div>
          )}
        </AvatarGroup>

      </ListItemSecondaryAction>
    </ListItemLink>
  )
}
