import { Button, Hidden } from "@material-ui/core"
import PhoneIcon from "@material-ui/icons/Phone"
import React, { useState } from "react"
// import { Link } from 'react-admin'
import Dialog from "./MakePhoneCallDialog"


export default function PhoneCallButton (
  { claim, ...props }: { claim: Claim, [key: string]: any },
) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        claim={claim}
        selectedValue=''
      />
      <Button
        variant='outlined'
        color='primary'
        onClick={() => setOpen(true)}
        {...props}
      >
        <PhoneIcon />
        <Hidden mdDown>Call</Hidden>
      </Button>
    </>
  )
}
