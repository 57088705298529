/* eslint-disable */
import React from "react";

function SvgVolkswagen(props: any) {
  return (
    <svg width={42} height={42} {...props}>
      <defs>
        <linearGradient
          x1="35.715%"
          y1="30.382%"
          x2="93.63%"
          y2="109.919%"
          id="volkswagen_svg__a"
        >
          <stop stopColor="#1077B9" offset="0%" />
          <stop stopColor="#042A54" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="22.454%"
          y1="10.734%"
          x2="135.001%"
          y2="171.165%"
          id="volkswagen_svg__c"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#FFF" offset="50%" />
          <stop stopColor="#989B9E" offset="100%" />
        </linearGradient>
        <radialGradient
          cx="34.118%"
          cy="13.092%"
          fx="34.118%"
          fy="13.092%"
          r="183.696%"
          gradientTransform="matrix(1 0 0 .99569 0 0)"
          id="volkswagen_svg__b"
        >
          <stop stopColor="#209BD3" offset="0%" />
          <stop stopColor="#136194" offset="54.6%" />
          <stop stopColor="#0A3B6C" offset="100%" />
        </radialGradient>
      </defs>
      <g transform="translate(1 1)" fillRule="nonzero" fill="none">
        <path
          d="M39.98 19.996c0 11.04-8.945 19.989-19.98 19.989S.02 31.035.02 19.995C.02 8.957 8.964.008 20 .008s19.98 8.95 19.98 19.989z"
          stroke="#000"
          strokeWidth={0.5}
          fill="#BEC7CB"
        />
        <ellipse
          stroke="#000"
          strokeWidth={0.5}
          fill="url(#volkswagen_svg__a)"
          cx={20}
          cy={19.996}
          rx={19.157}
          ry={19.173}
        />
        <path
          d="M34.962 19.907c0 8.467-6.791 15.332-15.17 15.332-8.378 0-15.17-6.865-15.169-15.332 0-8.467 6.791-15.332 15.17-15.332 8.378 0 15.17 6.865 15.169 15.332z"
          fill="#FFF"
        />
        <ellipse
          fill="url(#volkswagen_svg__b)"
          cx={19.993}
          cy={20.011}
          rx={13.614}
          ry={13.673}
        />
        <path
          d="M20.666 4.023c-9.085 0-16.46 7.378-16.46 16.466 0 9.089 7.375 16.464 16.46 16.464s16.457-7.375 16.457-16.464c0-9.088-7.372-16.466-16.457-16.466zm-.13 2.28a14.27 14.27 0 014.151.582l-2.09 4.614-2.074 4.521-2.04-4.544-2.097-4.529a14.275 14.275 0 014.15-.643zm7.17 1.876c1.4.815 2.871 1.92 4.324 3.85-2.1 5-4.383 10.358-6.512 15.375l-2.813-6.441h-4.407l-2.885 6.331c-2.216-4.975-4.356-9.934-6.294-15.027 1.365-1.93 2.75-2.973 4.139-3.895 1.66 3.805 3.322 7.609 4.983 11.414h4.457c1.672-3.843 3.335-7.774 5.008-11.607zm6.369 7.724c.211.657.578 1.864.716 3.74.067.923.032 2.161-.188 3.363a14.225 14.225 0 01-2.58 5.92 14.582 14.582 0 01-5.701 4.532 43.872 43.872 0 001.634-3.217c.692-1.513 1.386-3.024 2.027-4.554.153-.366.324-.726.488-1.09a120.406 120.406 0 001.67-3.922c.575-1.408 1.419-3.33 1.934-4.772zm-26.936.35c.515 1.443 1.048 2.919 1.624 4.327a119.488 119.488 0 001.677 3.905 195.394 195.394 0 004.252 8.84c-2.566-1.235-4.28-2.85-5.563-4.639a14.089 14.089 0 01-2.623-8.317c.022-1.93.415-3.478.633-4.116zm13.331 8.605l4.267 9.189c-1.122.377-2.322.537-3.543.568-.195.005-.388.006-.583.005-1.633-.01-3.024-.227-4.297-.664l4.156-9.098z"
          fill="#000"
          opacity={0.65}
        />
        <path
          d="M21.147 3.88c-9.085 0-16.46 7.379-16.46 16.467 0 9.09 7.375 16.465 16.46 16.465s16.457-7.376 16.457-16.465c0-9.088-7.372-16.466-16.457-16.466zm-.13 2.282c1.404-.01 2.807.182 4.152.58l-2.09 4.615-2.075 4.521-2.04-4.544-2.096-4.529a14.277 14.277 0 014.149-.643zm7.17 1.875c1.4.816 2.872 1.92 4.325 3.85-2.101 5-4.384 10.358-6.512 15.376l-2.813-6.442H18.78l-2.886 6.331c-2.216-4.975-4.355-9.934-6.294-15.026 1.365-1.93 2.75-2.974 4.14-3.896l4.982 11.414h4.457c1.672-3.843 3.335-7.774 5.008-11.607zm6.37 7.724c.21.657.577 1.864.715 3.74.068.923.032 2.161-.188 3.363a14.224 14.224 0 01-2.58 5.92 14.585 14.585 0 01-5.701 4.532 43.908 43.908 0 001.634-3.217c.692-1.513 1.387-3.024 2.027-4.554.154-.366.325-.726.488-1.089a120.317 120.317 0 001.67-3.923c.576-1.408 1.419-3.33 1.934-4.772zm-26.937.35c.516 1.443 1.048 2.919 1.625 4.327a119.619 119.619 0 001.676 3.905 195.446 195.446 0 004.252 8.84c-2.565-1.235-4.28-2.849-5.563-4.638a14.09 14.09 0 01-2.623-8.318c.022-1.93.416-3.478.633-4.116zm13.332 8.605l4.267 9.189c-1.123.377-2.322.537-3.544.568a18.8 18.8 0 01-.583.005c-1.633-.009-3.024-.227-4.297-.663l4.157-9.099z"
          fill="#000"
          opacity={0.5}
        />
        <path
          d="M20.004 3.544c-9.085 0-16.46 7.378-16.46 16.467 0 9.088 7.375 16.464 16.46 16.464S36.461 29.1 36.461 20.01c0-9.089-7.372-16.467-16.457-16.467zm-.13 2.281c1.404-.01 2.807.183 4.152.58l-2.09 4.615-2.075 4.522-2.04-4.544-2.096-4.53a14.278 14.278 0 014.149-.643zm7.17 1.875c1.4.816 2.872 1.92 4.325 3.85-2.101 5-4.384 10.358-6.512 15.376l-2.813-6.442h-4.407l-2.886 6.332c-2.216-4.975-4.355-9.935-6.294-15.027 1.365-1.93 2.75-2.974 4.14-3.896l4.982 11.414h4.457c1.672-3.842 3.335-7.773 5.008-11.607zm6.37 7.724c.21.657.577 1.865.715 3.74.068.924.032 2.162-.188 3.363a14.224 14.224 0 01-2.58 5.921 14.582 14.582 0 01-5.701 4.532 43.871 43.871 0 001.634-3.217c.692-1.513 1.387-3.024 2.027-4.555.154-.366.325-.726.488-1.089a120.025 120.025 0 001.67-3.923c.576-1.408 1.419-3.33 1.934-4.772h0zm-26.937.35c.516 1.443 1.048 2.92 1.625 4.327a119.429 119.429 0 001.676 3.906 195.35 195.35 0 004.252 8.84c-2.565-1.235-4.28-2.85-5.563-4.64a14.09 14.09 0 01-2.623-8.316c.022-1.93.416-3.478.633-4.116zM19.81 24.38l4.267 9.188c-1.123.377-2.322.537-3.544.568-.194.005-.388.007-.583.005-1.633-.009-3.023-.226-4.297-.663l4.157-9.098h0z"
          stroke="#000"
          strokeWidth={0.5}
          fill="url(#volkswagen_svg__c)"
        />
      </g>
    </svg>
  );
}

export default SvgVolkswagen;
