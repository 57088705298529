import React from "react"
import DefaultActions from "./DefaultActions"
import MuiList from "./MuiList"
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  NumberField,
  Create,
  Show,
  BooleanInput,
  SimpleShowLayout,
  DateField,
  DateInput,
  NullableBooleanInput,
} from "react-admin"
import DefaultPagination from "./DefaultPagination"


export function GdprBreachesList ({ ...props }) {
  return (<List
    {...props}
    actions={<DefaultActions/>}
    perPage={15}
    pagination={<DefaultPagination/>}
  >
    <Datagrid rowClick="show">
      <TextField source="Domain" label="Domain"/>
      <TextField source="Name" label="Name"/>
      <TextField source="id" label="Breach Id"/>
      <BooleanField source='IsFabricated' label='Fabricated' addLabel={true}/>
      <BooleanField source='IsMalware' label='Malware' addLabel={true}/>
      <BooleanField source='IsRetired' label='Retired' addLabel={true}/>
      <BooleanField source='IsSensitive' label='Sensitive' sortable={false}
        addLabel={true}/>
      <BooleanField source='IsSpamList' label='Spam list' addLabel={true}/>
      <BooleanField source='IsVerified' label='Verified' addLabel={true}/>      
      <DateField source="createdAt" label="Created at"/>
      <BooleanField source="isValid" label='Valid'/>
    </Datagrid>
  </List>)
}

export function GdprBreachesShow ({...props}) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' sortable={false} />
        <TextField source="Domain" label="Domain"/>
        <TextField source="Name" label="Name"/>
        <TextField source="Description" label="Description"/>
        <BooleanField source="isValid" label='Valid'/>
        <BooleanField source='IsFabricated' label='Fabricated' addLabel={true}/>
        <BooleanField source='IsMalware' label='Malware' addLabel={true}/>
        <BooleanField source='IsRetired' label='Retired' addLabel={true}/>
        <BooleanField source='IsSensitive' label='Sensitive' sortable={false}
          addLabel={true}/>
        <BooleanField source='IsSpamList' label='Spam list' addLabel={true}/>
        <BooleanField source='IsVerified' label='Verified' addLabel={true}/>
        <TextField source="LogoPath" label="Logo path"/>
        <TextField source="ModifiedDate" label="Modified date"/>
        <NumberField source="PwnCount" label="PwnCount"/>
        <TextField source="Title" label="Title"/>
        <DateField source="createdAt" label="Created at"/>
        <TextField source="AddedDate" label="Added at"/>
        <TextField source="BreachDate" label="Breach date"/>
        <MuiList source="DataClasses" label="Data Classes"/>
      </SimpleShowLayout>
    </Show>
  )
}

export function GdprBreachesCreate ({ ...props }) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="id" label="ID"/>
        <TextInput source="Domain" label="Domain"/>
        <TextInput source="Name" label="Name"/>
        <TextInput source="Description" label="Description"/>
        <NullableBooleanInput source="isValid" label='Valid'/>
        <BooleanInput source="IsFabricated" label="Fabricated Data"/>
        <BooleanInput source="IsMalware" label="Malware"/>
        <BooleanInput source="IsRetired" label="Retired"/>
        <BooleanInput source="IsSensitive" label="Sensitive Data"/>
        <BooleanInput source="IsSpamList" label="Spam List"/>
        <BooleanInput source="IsVerified" label="Verified"/>
        <TextInput source="LogoPath" label="Logo path"/>
        <TextInput source="ModifiedDate" label="Modified date"/>
        <NumberInput source="PwnCount" label="PwnCount"/>
        <TextInput source="Title" label="Title"/>
        <DateInput source="CreatedAt" label="Created at"/>
        <TextInput source="AddedDate" label="Added at"/>
        <TextInput source="BreachDate" label="Breach date"/>
      </SimpleForm>
    </Create>
  )
}

export function GdprBreachesEdit ({ ...props }) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source='id' sortable={false} />
        <TextField source="Domain" label="Domain" />
        <TextField source="Name" label="Name"/>
        <TextField source="Title" label="Title"/>
        <DateField source="createdAt" label="Created at"/>
        <NullableBooleanInput source="isValid" label='Valid'/>
        <BooleanField source="IsFabricated" label="Fabricated Data"/>
        <BooleanField source="IsMalware" label="Malware"/>
        <BooleanField source="IsRetired" label="Retired"/>
        <BooleanField source="IsSensitive" label="Sensitive Data"/>
        <BooleanField source="IsSpamList" label="Spam List"/>
        <BooleanField source="IsVerified" label="Verified"/>
        <MuiList source="DataClasses" label="Data Classes"/>
        <NumberField source="PwnCount" label="PwnCount"/>
        <TextField source="ModifiedDate" label="Modified date"/>
        <TextField source="AddedDate" label="Added at"/>
        <TextField source="BreachDate" label="Breach date"/>
        <TextField source="Description" label="Description"/>
        <TextField source="LogoPath" label="Logo path"/>
      </SimpleForm>
    </Edit>
  )
}
