import FirebaseTimestamp from "./FirebaseTimestamp"

const ticketFields = {
  id: String,
  claimId: String,

  createdAt: FirebaseTimestamp,
  _createdAt: Date,

  updatedAt: FirebaseTimestamp,
  _updatedAt: Date,

  snoozeTimeStart: FirebaseTimestamp,
  _snoozeTimeStart: Date,

  snoozeTimeEnd: FirebaseTimestamp,
  _snoozeTimeEnd: Date,

  phoneNumberValid: Boolean,

  assignedGroup: String,
}

export default ticketFields
