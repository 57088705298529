// Combines MongoDB and Firebase into one interface

import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE,
} from "react-admin"

import { firestoreProvider } from "./firestoreProvider"
import getTable from "./getTable"


interface ParamsType {
  pagination: any
  sort: any
  filter: any
  id: string
  ids: string[]
  data: any
  previousData: any
  target: any
}


interface GqlProvider {
  gqlProvider: (
    verb: string,
    resource: string,
    params: ParamsType,
  ) => unknown
}


export default function getDataProvider () {
  return {
    getList: (resource: string, params: ParamsType) =>
      firestoreProvider(GET_LIST, getTable(resource), params),
    getOne: (resource: string, params: ParamsType) =>
      firestoreProvider(GET_ONE, getTable(resource), params),
    create: (resource: string, params: ParamsType) =>
      firestoreProvider(CREATE, getTable(resource), params),
    update: (resource: string, params: ParamsType) =>
      firestoreProvider(UPDATE, getTable(resource), params),
    updateMany: (resource: string, params: ParamsType) =>
      firestoreProvider(UPDATE_MANY, getTable(resource), params),
    delete: (resource: string, params: ParamsType) =>
      firestoreProvider(DELETE, getTable(resource), params),
    deleteMany: (resource: string, params: ParamsType) =>
      firestoreProvider(DELETE_MANY, getTable(resource), params),
    getMany: (resource: string, params: ParamsType) =>
      firestoreProvider(GET_MANY, getTable(resource), params),
    getManyReference: (resource: string, params: ParamsType) =>
      firestoreProvider(GET_MANY_REFERENCE, getTable(resource), params),
  }
}
