import React from "react"
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Filter,
  List,
  NullableBooleanInput,
  NumberField,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  downloadCSV,
} from "react-admin"
import { Button, Box } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"

import { DateRangeFilterWithField } from "../DateRangeFilter"
import TimestampField from "../TimestampField"
import DefaultActions from "../DefaultActions"
import DefaultPagination from "../DefaultPagination"
import SendBulkEmailButton from "./SendBulkEmailButton"
import SendBulkWhatsappButton from "./SendBulkWhatsappButton"
import ClaimBulkDelete from "../ClaimBulkDelete"
import BulkTransferButton from "./BulkTransferButton"
import DuplicateClaims from "../DuplicateClaims"
import jsonExport from "jsonexport/dist"


function wrapInBox (component: JSX.Element) {
  return (
    // @ts-expect-error: Property 'alwaysOn' does not exist
    <Box mb={2} alwaysOn>
      {component}
    </Box>
  )
}


function ClaimFilters (
  props: {
    setFilters?: (filterMap: Record<string, any>) => void,
    filterValues?: Record<string, any>,
  },
) {
  return (
    <Filter {...props}>
      {wrapInBox(
        <Button
          variant='outlined'
          size='small'
          onClick={() => props.setFilters && props.setFilters({
            userIsRegistered: true,
            userEmail: "",
            id: "",
            lawFirm: "",
            userId: "",
            createdTime: "",
            userRegistrationTime: "",
            hasUploadedPurchaseContract: "",
            hasUploadedSalesContract: "",
            hasUploadedFinanceContract: "",
            kvCorrect: "",
            acceptedTermsAndConditions: "",
            applicationApproved: "",
            carRecallStatus: "",
            claimActionStatus: "",
            carIsTop: "",
            carPurchasedBeforeRecall: "",
            isEligible: "",
            transmittedToLawfirmAt: "",
            tag: "",
            messageTracking: "",
          })}
        >
          <ClearIcon />
          Clear Filters
        </Button>,
      )}
      <SelectInput
        label='Law Firm'
        source="lawFirm"
        alwaysOn
        choices={[
          { id: "AILTS", name: "AILTS" },
          { id: "BRC", name: "BRC" },
          { id: "KAP", name: "KAP" },
        ]}
      />
      <BooleanInput source='userIsRegistered' alwaysOn />
      <TextInput label='Filter by email' source='userEmail' alwaysOn />
      <TextInput label='Filter by claim id' source='id' alwaysOn />
      <TextInput label='Filter by user id' source='userId' alwaysOn />
      {wrapInBox(
        <DateRangeFilterWithField source='createdTime' label='Created At' />,
      )}
      {/*
      {wrapInBox(
        <DateRangeFilterWithField
          source='userRegistrationTime'
          label='Registration Time'
        />,
      )}
      */}
      <BooleanInput source='lead' label='Lead' />
      {/*
      <BooleanInput source='hasUploadedPurchaseContract'
        label='KV Uploaded' />
      <BooleanInput source='hasUploadedSalesContract'
        label='Sales Contract Uploaded' />
      <BooleanInput source='hasUploadedFinanceContract'
        label='Finance Contract Uploaded' />
      <BooleanInput source='kvCorrect'
        label='KV Correct' />
      <BooleanInput source='acceptedTermsAndConditions'
        label='Accepted T&Cs' />
      <BooleanInput source='carIsTop' label='TOP Model' />
      */}
      <BooleanInput
        source='transmittedToLawfirmAt__exists'
        label='Transmitted to Lawfirm'
      />
      {/*
      <BooleanInput source='applicationApproved' />
      <SelectInput
        source='carRecallStatus'
        choices={[
          { id: "mandatory", name: "Mandatory" },
          { id: "voluntary", name: "Voluntary" },
          { id: "not_recalled", name: "Not Recalled" },
        ]}
      />
      <SelectInput
        source='claimActionStatus'
        choices={[
          { id: "active", name: "Active" },
          { id: "dead", name: "Dead" },
          { id: "onHold", name: "On Hold" },
          { id: "finished", name: "Finished" },
        ]}
      />
      <BooleanInput source='carPurchasedBeforeRecall' />
      <BooleanInput source='isEligible' />
      <TextInput label='Lead Source' source='leadSource' />
      <SelectArrayInput
        source='messageTracking.email'
        label={"Email State"}
        style={{ whiteSpace: "nowrap", minWidth: "10.5em" }}
        choices={[
          { id: null, name: "None" },
          { id: "delivered", name: "Delivered" },
          { id: "read", name: "Read" },
        ]}
      />
      <SelectArrayInput
        source='messageTracking.whatsApp'
        label={"WhatsApp State"}
        style={{ whiteSpace: "nowrap", minWidth: "13em" }}
        choices={[
          { id: null, name: "None" },
          { id: "delivered", name: "Delivered" },
          { id: "read", name: "Read" },
        ]}
      />
      <NullableBooleanInput
        label={"Form Link Clicked"}
        style={{ whiteSpace: "nowrap", minWidth: "12em" }}
        source='messageTracking.formLinkClicked'
      />
      */}
    </Filter>
  )
}


function ClaimBulkActionButtons (
  props: { [x: string]: any, selectedIds: any },
) {
  return (
    <Box>
      <DuplicateClaims {...props} />
      <SendBulkEmailButton {...props} />
      <SendBulkWhatsappButton {...props} />
      <BulkTransferButton {...props} />
      <ClaimBulkDelete {...props} />
    </Box>
  )
}

function exporter (claims: any) {
  jsonExport(claims, {
    fillGaps: true,
  }, (err, csv) => {
    downloadCSV(csv, "claims")
  })
}

const dateFieldOpts = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}


export default function ClaimList (props: Record<string, unknown>) {
  return (
    <List
      filter={{
        type: "diesel",
      }}
      filters={<ClaimFilters />}
      // @ts-expect-error
      bulkActionButtons={<ClaimBulkActionButtons />}
      actions={<DefaultActions />}
      filterDefaultValues={{ userIsRegistered: true }}
      sort={{ field: "createdTime", order: "DESC" }}
      perPage={15}
      pagination={<DefaultPagination />}
      exporter={exporter}
      {...props}
    >
      <Datagrid rowClick='show'>
        <BooleanField source='lead' label='Lead' sortable={false}
          addLabel={true} />
        <TextField label='Claim ID' source='id' sortable={false} />
        <TextField label='User ID' source='userId' sortable={false} />
        <TextField source='userEmail' sortable={false} />
        {/*
        <TextField
          source='messageTracking.email'
          label={"Email"}
          sortable={false}
        />
        */}
        <TextField
          source='phoneNumber'
          sortable={false}
          style={{ whiteSpace: "nowrap" }}
        />
        {/*
        <TextField
          source='messageTracking.whatsApp'
          label={"WhatsApp"}
          sortable={false}
        />
        <TextField
          source='messageTracking.formLinkClicked'
          label={"Link Clicked"}
          sortable={false}
        />
        */}
        <TextField source='claimActionStatus' sortable={false} />
        <TextField source='lawFirm' label='Law Firm' sortable={false} />
        {/*
        <BooleanField source='carIsTop' label='TOP' sortable={false}
          addLabel={true} />
        <BooleanField source='isEligible' sortable={false}
          addLabel={true} />
        */}
        <TimestampField
          source='createdTime'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <TimestampField
          source='transmittedToLawfirmAt'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <TimestampField
          source='updatedTime'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <TimestampField
          source='userRegistrationTime'
          locales='sv-SE'
          showTime
          sortable={false}
        />
        <BooleanField source='hasUploadedPurchaseContract'
          label='KV Uploaded' sortable={false} addLabel={true} />
        <BooleanField source='acceptedTermsAndConditions'
          label='Accepted T&Cs' sortable={false} addLabel={true} />
        <NumberField source='maxReward'
          label='Claim Value' sortable={false} />
      </Datagrid>
    </List>
  )
}
