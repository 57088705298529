import React from "react"
import DefaultActions from "./DefaultActions"
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
} from "react-admin"
import DefaultPagination from "./DefaultPagination"
import { LawFirmOptions } from "../constants/lawFirms"

export function UtmSourceLawFirmList ({ ...props }) {
  return (
    <List
      {...props}
      actions={<DefaultActions />}
      perPage={15}
      pagination={<DefaultPagination />}
    >
      <Datagrid rowClick='show'>
        <TextField source='utmSource' label='Utm Source' sortable={false} />
        <TextField source='lawFirm' label='Law Firm' sortable={false} />
      </Datagrid>
    </List>
  )
}

export function UtmSourceLawFirmEdit ({...props}) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source='utmSource' label='Utm Source' />
        <SelectInput
          source="lawFirm"
          allowEmpty={true}
          choices={LawFirmOptions}
          parse={(v: string) => v === "" ? null : v}
          emptyValue={""}
          emptyText="No law firm"
        />
      </SimpleForm>
    </Edit>
  )
}

export function UtmSourceLawFirmCreate ({...props}) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='utmSource' label='Utm Source' />
        <SelectInput
          source="lawFirm"
          allowEmpty={true}
          choices={LawFirmOptions}
          parse={(v: string) => v === "" ? null : v}
          emptyValue={""}
          emptyText="No law firm"
        />
      </SimpleForm>
    </Create>
  )
}
