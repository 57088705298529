import React from "react"
import { Show, SimpleShowLayout, TextField,
  ReferenceField, BooleanField, DateField } from "react-admin"


export default function PhoneCallShow (props: Record<string, unknown>) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='id' />
        <ReferenceField
          link='show'
          source='claimId'
          reference='claims'
        >
          <TextField source='id' />
        </ReferenceField>
        <BooleanField source='phoneNumberValid' addLabel={true} />
        <TextField source='callResult' addLabel={true} />
        <DateField showTime source='startTime' />
        <DateField showTime source='endTime' />
        <TextField source='callNotes' />
      </SimpleShowLayout>
    </Show>
  )
}
