import React, {useEffect, useState} from "react"
import {
  Datagrid,
  DateField,
  EmailField,
  Filter,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin"
import { useTheme } from "@material-ui/styles"
import { Box, Button } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"

import DefaultActions from "../DefaultActions"
import DefaultPagination from "../DefaultPagination"
import getHttpsCallable from "../../utils/getHttpsCallable"

const getTemplates = getHttpsCallable("admin-getTemplates")

function EmailFilters (
  props: {
    setFilters?: (filters: Record<string, unknown>) => void,
  },
) {
  const [templates, setTemplates] = useState([])
  const [fetchTemplate, setFetchTemplate] = useState(true)

  useEffect(() => {
    async function getTemplate () {
      const data = await getTemplates("email")
      setTemplates(data?.data)
      setFetchTemplate(false)
    }

    if (fetchTemplate) {
      getTemplate()
    }
  }, [fetchTemplate])

  return (
    <Filter {...props}>
      {/* @ts-expect-error: Property 'alwaysOn' does not exist */}
      <Box mb={2} alwaysOn>
        <Button
          variant='outlined'
          size='small'
          onClick={() => props.setFilters && props.setFilters({
            claimId: "",
            userId: "",
            email: "",
            state: "",
          })}
        >
          <ClearIcon />
          Clear Filters
        </Button>
      </Box>
      <TextInput label='Filter by email' source='email' alwaysOn />
      <TextInput label='Filter by claim id' source='claimId' alwaysOn />
      <TextInput label='Filter by user id' source='userId' alwaysOn />
      <SelectInput
        label='Filter by state'
        source='state'
        alwaysOn
        choices={[
          { id: "sent", name: "Sent" },
          { id: "rejected", name: "Rejected" },
          { id: "soft-bounced", name: "Soft-Bounced" },
          { id: "bounced", name: "Bounced" },
        ]}
      />
      <SelectInput
        label="Filter by template"
        source='template'
        alwaysOn
        choices={
          Object
            .values(templates)
            .filter((template: Template) =>
              template.name && template.userDefinedName)
            .sort((a: Template, b: Template) : number =>
              (a.userDefinedName && b.userDefinedName)
                ? a.userDefinedName.localeCompare(b.userDefinedName)
                : 0,
            )
            .sort((a: Template, b: Template) : number =>
              Number(a.userDefinedName?.includes(":")) -
              Number(b.userDefinedName?.includes(":")),
            )
            .map((template: Template) =>
              ({ id: template.name, name: template.userDefinedName }))
        }
      />
    </Filter>
  )
}


export default function EmailList (props: Record<string, unknown>) {
  const theme: DefaultTheme = useTheme()
  return (
    <List
      {...props}
      filters={<EmailFilters />}
      actions={<DefaultActions />}
      sort={{ field: "timestamp", order: "DESC" }}
      perPage={15}
      pagination={<DefaultPagination />}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' sortable={false} />
        <ReferenceField
          link='show'
          source='claimId'
          reference='claims'
          sortable={false}
        >
          <TextField source='id' />
        </ReferenceField>
        <ReferenceField
          link='show'
          source='userId'
          reference='users'
          sortable={false}
        >
          <TextField source='id' />
        </ReferenceField>
        <EmailField
          source='email'
          sortable={false}
          // @ts-expect-error
          style={{ color: theme.palette.primary.main }}
        />
        <TextField source='sender' sortable={false} />
        <TextField source='state' sortable={false} />
        <TextField source='subject' sortable={false} />
        <TextField source='template' sortable={false} />
        <DateField source='timestamp' sortable={false} />
        <TextField source='type' sortable={false} />
      </Datagrid>
    </List>
  )
}
