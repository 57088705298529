import Divider from "@material-ui/core/Divider"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import DeclinedIcon from "@material-ui/icons/CallEnd"
import PhoneInvalidIcon from "@material-ui/icons/PhoneDisabled"
import PhoneAnsweredIcon from "@material-ui/icons/PhoneInTalk"
import Container, { FlexContainer } from
  "../../../lib/components/Container/Container"
import React, { useState, useEffect } from "react"
import { useNotify } from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import { green, indigo, red } from "@material-ui/core/colors"
import moment from "moment"
import { db } from "../../../firebase"
import BrandIcon from "../../BrandIcon"
import { Link } from "react-router-dom"
import List from "@material-ui/core/List"
import ListSubheader from "@material-ui/core/ListSubheader"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { get } from "lodash"
import { PhoneCallResult } from "../../../lib/enums"


const useStyles = makeStyles(theme => ({
  answeredIcon: {
    color: green[600],
  },
  declinedIcon: {
    color: red[300],
  },
  invalidIcon: {
    color: indigo[200],
  },
  prevCalls: {
    display: "flex",
    alignItems: "center",
  },
  paperRoot: {
    position: "sticky",
    top: 20,
  },
}))



function Field ({ label, value }: { label: string, value: string }) {
  return (
    <div>
      <Typography variant='overline' color='textPrimary'>{label}</Typography>
      <Typography variant='body2' color='textPrimary'>{value}</Typography>
    </div>
  )
}


interface PreviousCallListItemParams {
  icon: any
  call: {
    startTime: FirebaseAdminTimestamp
    callNotes: any[]
  }
}

function PreviousCallListItem (
  { icon, call }: PreviousCallListItemParams,
) {
  return (
    <ListItem>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText
        primary={
          `${call.startTime && moment(call.startTime.toDate()).format("LLL")}`
        }
        secondary={call.callNotes}
      />
    </ListItem>
  )
}


function PreviousCallList ({ claimId }: { claimId: string }) {
  const classes = useStyles()
  const [callDocs, setCallDocs] = useState<any[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    return db.collectionGroup("phoneCalls")
      .where("claimId", "==", claimId)
      .orderBy("startTime", "asc")
      .onSnapshot(snap => {
        setCallDocs(snap.docs)
        setLoading(false)
      })
  }, [claimId])

  return (
    <List subheader={<ListSubheader>Previous Calls</ListSubheader>} dense>
      {!loading && callDocs.map((callDoc) => {
        const call = callDoc.data()
        let icon = null
        if (call.callResult === PhoneCallResult.ANSWERED) {
          icon = <PhoneAnsweredIcon
            className={classes.answeredIcon}
            fontSize='small'
          />
        }
        else if (call.phoneNumberValid) {
          icon = <DeclinedIcon
            className={classes.declinedIcon}
            fontSize='small'
          />
        }
        else {
          icon = <PhoneInvalidIcon
            className={classes.invalidIcon}
            fontSize='small'
          />
        }
        return <PreviousCallListItem
          icon={icon}
          call={call}
          key={callDoc.id}
        />
      })}
      {!loading && callDocs.length < 1 && "Never been called"}
    </List>
  )
}


export default function CallListSidebar (
  { ticketRef, ...props }: { ticketRef: any, [key: string]: any },
) {
  const classes = useStyles()
  const ticket = ticketRef.data()
  const [claim, setClaim] = useState<Claim | null>(null)
  const notify = useNotify()

  useEffect(() => {
    db
      .collectionGroup("claims")
      .where("id", "==", ticket.claimId)
      .onSnapshot(snap => {
        if (snap.size) {
          // @ts-expect-error
          setClaim(snap.docs[0].data())
        }
        else {
          notify(`Claim ${ticket.claimId} not found`, "warning")
        }
      })
  }, [ticket.claimId, notify])

  return (
    <Container {...props}>
      <Paper className={classes.paperRoot}>
        <FlexContainer direction='ttb' p={0.5} {...props}>
          <Typography
            variant='h6'
            color='primary'
            component={Link}
            to={`online-casino-claims/${ticket.claimId}/show`}
          >
            {ticket.claimId}
          </Typography>
          <Typography
            variant='subtitle1'
            gutterBottom
            color='textPrimary'
          >
            {ticket.claimPhoneNumber}
          </Typography>
          <PreviousCallList claimId={ticket.claimId} />
          <Divider />
          {claim && (
            <>
              <FlexContainer center pb={0.5}>
                <BrandIcon brand={claim.carBrand} />
              </FlexContainer>
              <Field
                label='Claim created'
                value={claim.createdTime
                  ? moment(claim.createdTime.toDate()).format("LLL")
                  : "Unkown"
                }
              />
              <Field
                label='Email Address'
                value={get(claim, "userEmail", "?")}
              />
              <label style={{marginTop: "0.5rem", fontSize: "0.75rem"}}>
                CASINO
              </label>
              <ul style={{paddingInlineStart: "30px", marginTop: "0.5rem",
                marginBottom: "0.5rem"}}>
                {
                  claim?.customFieldValues?.providers?.value.length > 0 &&
                  claim?.customFieldValues?.providers?.value
                    .map((item: any) =>
                      <li key={item.name}>{item.name}</li>,
                    )
                }
              </ul>
              <Field
                label='TL Requested'
                value={
                  get(claim, "tlRequested", "?") === true
                    ? "true" : "false"
                }
              />
              <Field
                label='Has multiple claims'
                value={
                  get(claim, "hasMultipleClaims", "?") === true
                    ? "true" : "false"
                }
              />
              {
                claim?.utmParams?.length > 0 &&
                <>
                  <label style={{marginTop: "0.5rem", fontSize: "0.75rem"}}>
                    UTM PARAMETERS
                  </label>
                  <ul style={{paddingInlineStart: "30px", marginTop: "0.5rem",
                    marginBottom: "0.5rem"}}>
                    {
                      claim?.utmParams
                        .map((item: any) =>
                          <li key={item.source}>
                            {item.source} : {item.value}
                          </li>,
                        )
                    }
                  </ul>
                </>
              }
            </>
          )}
        </FlexContainer>
      </Paper>
    </Container>
  )
}
