import {Button, Hidden} from "@material-ui/core"
import React from "react"
import DescriptionIcon from "@material-ui/icons/Description"
import {useNotify} from "react-admin"
import getHttpsCallable from "../utils/getHttpsCallable"

type FunderContractProps = {
  claim: Claim,
  className: string,
}

function GenerateFunderContract (
  props: React.PropsWithChildren<FunderContractProps>,
) {
  const { id: claimId, userId } = props.claim

  const notify = useNotify()

  async function handleClick () {
    try {
      const apiFunderContract = getHttpsCallable("admin-generateFunderContract")

      const res = await apiFunderContract({
        claimId, userId,
      })

      notify(
        res.data.message,
        { type: "success", autoHideDuration: 10e3, multiLine: true },
      )
    }
    catch (err: any) {
      return notify(
        err.message || "Unknown error",
        "error",
      )
    }
  }

  return (
    <Button
      className={props.className}
      color='primary'
      variant='outlined'
      onClick={() => handleClick()}
    >
      <DescriptionIcon/>
      <Hidden mdDown>&nbsp;Generate Funder Contract</Hidden>
    </Button>
  )
}

export default GenerateFunderContract
