import React, {useEffect, useState} from "react"
import { find, get } from "lodash"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Hidden,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"

import WhatsappIcon from "../WhatsappIcon"
import { Flex } from "../../lib"
import getHttpsCallable from "../../utils/getHttpsCallable"
import EmailWsPrompt from "../EmailWsPrompt"

const sendWhatsAppTemplateFn = getHttpsCallable("admin-sendWhatsappTemplate")
const getTemplates = getHttpsCallable("admin-getTemplates")

interface WhatsappSendDialogParams {
  open: boolean
  handleClose: () => void
  handleSendWhatsapp: (
    template: string,
    setSending: (sending: boolean) => void,
    setTemplateError: (error: string | null) => void,
    setResult: (data: any) => void,
  ) => void
  template: string
  sending: boolean
  templateError: string | null
  setTemplate: (template: string) => void
  setTemplateError: (error: string | null) => void
  setSending: (sending: boolean) => void
  setResult: (data: any) => void
  result: [any]
  phoneNumber: string
  claimId: string
}

function WhatsappSendDialog (
  {
    open,
    handleClose,
    handleSendWhatsapp,
    template,
    sending,
    templateError,
    setTemplate,
    setTemplateError,
    setSending,
    setResult,
    result,
    phoneNumber,
    claimId,
  }: WhatsappSendDialogParams,
) {
  function SendWhatsappForm () {
    const [templates, setTemplates] = useState([])
    const [fetchTemplate, setFetchTemplate] = useState(true)

    useEffect(() => {
      async function getTemplate () {
        const data = await getTemplates("whatsapp")
        setTemplates(data?.data)
        setFetchTemplate(false)
      }

      if (fetchTemplate) {
        getTemplate()
      }
    }, [fetchTemplate])

    return (
      <>
        <DialogContent>
          <DialogContentText>
            Select the template you wish to send to the applicant of this claim.
          </DialogContentText>
          <Select
            error={Boolean(templateError)}
            disabled={sending}
            value={template}
            fullWidth
            label='Template'
            onChange={(event: any) => {
              setTemplate(event.target.value)
              setTemplateError(null)
            }}
          >
            {Object
              .values(templates)
              .sort((a: Template, b: Template) : number =>
                (a.userDefinedName && b.userDefinedName)
                  ? a.userDefinedName.localeCompare(b.userDefinedName)
                  : 0,
              )
              .sort((a: Template, b: Template) : number =>
                Number(a.userDefinedName?.includes(":")) -
                Number(b.userDefinedName?.includes(":")),
              )
              .map((template: Template) => (
                <MenuItem key={template.name} value={template.name}>
                  {template.userDefinedName}
                </MenuItem>
              ))
            }
          </Select>
          {templateError &&
            <FormHelperText error>{templateError}</FormHelperText>
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <EmailWsPrompt 
            successAction={() => {
              handleSendWhatsapp(
                template,
                setSending,
                setTemplateError,
                setResult,
              )
            }}
            failureAction={handleClose} 
            claimId={claimId}
            selectedTemplate={template} 
            templates={templates}
          >
            Send
          </EmailWsPrompt>
        </DialogActions>
      </>
    )
  }

  function SendResult () {
    let status = result[0]?.rawProviderData?.statusCode ??
        (result[0]?.rawProviderData?.status ?? "")
    status = status === "queued" ? "Submitted to Twilio" : status
    const message = result[0]?.rawProviderData?.statusMessage ?? ""

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ClaimID</TableCell>
              <TableCell align='center'>Phone</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center'>(Message)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                {claimId}
              </TableCell>
              <TableCell>{phoneNumber}</TableCell>
              <TableCell>{status ? status : "error"}</TableCell>
              <TableCell>
                {
                  message ? message : (result[0]?.error ?? "")
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>Send WhatsApp</DialogTitle>
      {!sending && !result && <SendWhatsappForm />}
      {sending &&
        <Flex center style={{ margin: 20 }}>
          <CircularProgress />
        </Flex>
      }
      {result && <SendResult />}
    </Dialog>
  )
}


export function SendWhatsappButton (
  { claim, ...props }: {
    claim: Claim
    [key: string]: any
  },
) {
  const { id: claimId, phoneNumber } = claim
  const [WhatsappSendDialogOpen, setWhatsappSendDialogOpen] = useState(false)
  const [template, setTemplate] = useState("")
  const [templateError, setTemplateError] = useState<string | null>(null)
  const [sending, setSending] = useState(false)
  const [result, setResult] = useState<any | null>(null)
  const [templates, setTemplates] = useState({})
  const [fetchTemplate, setFetchTemplate] = useState(true)

  useEffect(() => {
    async function getTemplate () {
      const data = await getTemplates("whatsapp")
      setTemplates(data?.data)
      setFetchTemplate(false)
    }

    if (fetchTemplate) {
      getTemplate()
    }
  }, [fetchTemplate])

  async function handleSendWhatsapp (
    template: string,
    setSending: (sending: boolean) => void,
    setTemplateError: (error: string | null) => void,
    setResult: (data: any) => void,
  ) {
    if (!template) {
      return setTemplateError("You must select a template to send")
    }
    setSending(true)
    try {
      const selectedTemplate: any =
        find(templates, (t: any) => t.name === template)
      console.info("-> : selectedTemplate", selectedTemplate)
      console.info(`sending ${template} to ${claimId}`)
      const result = await sendWhatsAppTemplateFn({
        claimIds: [claimId],
        template: selectedTemplate?.name,
        provider: selectedTemplate?.provider,
        templateLocale: selectedTemplate?.locale,
        placeholders: selectedTemplate?.placeholders ?? [],
      })
      console.info(`send result: ${JSON.stringify(result, null, 2)}`)
      setSending(false)
      setResult(result.data)
    }
    catch (error) {
      window.alert(`
        problem occurred trying to commit send operation
        ${error}
      `)
      setSending(false)
    }
  }

  function handleCloseDialog () {
    setTemplate("")
    setTemplateError(null)
    setSending(false)
    setResult(null)

    setWhatsappSendDialogOpen(false)
  }

  function handleOpenDialog () {
    setWhatsappSendDialogOpen(true)
  }

  if (!phoneNumber) {
    return (
      <Button disabled variant='outlined' color='primary'>
        <WhatsappIcon />
        <Hidden mdDown>Send WhatsApp</Hidden>
      </Button>
    )
  }

  return (
    <>
      <WhatsappSendDialog
        handleClose={handleCloseDialog}
        open={WhatsappSendDialogOpen}
        handleSendWhatsapp={handleSendWhatsapp}
        template={template}
        sending={sending}
        templateError={templateError}
        setTemplate={setTemplate}
        setTemplateError={setTemplateError}
        setSending={setSending}
        setResult={setResult}
        result={result}
        phoneNumber={phoneNumber}
        claimId={claimId}
      />
      <Button {...props}
        variant='outlined'
        color='primary'
        onClick={handleOpenDialog}
      >
        <WhatsappIcon />
        <Hidden mdDown>&nbsp;Send WhatsApp</Hidden>
      </Button>
    </>
  )
}
