import {
  Divider, Paper, Table, IconButton, TableBody, Link,
  TableCell, TableHead, TableRow, Typography, Grid, Button,
} from "@material-ui/core"
import { get, some } from "lodash"
import moment from "moment"
import React, { useState, useEffect } from "react"
import useFiles from "../../hooks/useFiles"
import DeleteIcon from "@material-ui/icons/Delete"
import { makeStyles } from "@material-ui/core/styles"
import { red } from "@material-ui/core/colors"
import { storage } from "../../firebase"
import UploadFileInput from "../../lib/components/UploadFileInput"
import { useNotify, useRefresh, useDataProvider } from "react-admin"
import getHttpsCallable from "../../utils/getHttpsCallable"
import path from "path"
import SendIcon from "@material-ui/icons/Send"


const uploadFile = getHttpsCallable("admin-cases-uploadFile")


function subdirToTargetFileName (
  subdir: string,
  extname: string,
): string | undefined {
  const mapping: Record<string, string> = {
    purchaseContracts: `Purchase-Contract${extname}`,
    purchaseContractMerged: `Purchase-Contract-Merged${extname}`,
    salesContracts: `Sales-Contract${extname}`,
    salesContractMerged: `Sales-Contract-Merged${extname}`,
    financeContracts: `Finance-Contact${extname}`,
    financeContractMerged: `Finance-Contact-Merged${extname}`,
    vehicleRegistrationDocument: `Vehicle-Registration${extname}`,
    vehicleRegistrationDocumentMerged: `Vehicle-Registration-Merged${extname}`,
    latestMileage: `Latest-Mileage${extname}`,
    latestMileageMerged: `Latest-Mileage-Merged${extname}`,
    currentMileageDocument: `Current-Mileage${extname}`,
    claimPoaSignature: `POA-Signature${extname}`,
    claimFinancingAgreementSignature: `Financing-Agreement-Signature${extname}`,
    claimRemainingValueClaimant: `Remaining-Value-Claimant${extname}`,
    claimRemainingValueClaimantMerged:
      `Remaining-Value-Claimant-Merged${extname}`,
    claimRemainingValueClaimback: `Remaining-Value-CB${extname}`,
    claimRemainingValueClaimbackMerged: `Remaining-Value-CB-Merged${extname}`,
    otherFiles: `Other-Files${extname}`,
  }

  return mapping[subdir]
}

const storageRef = storage.ref()

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    color: red[500],
  },
}))

function FileTable (
  { claimId, files, lawFirm, lawFirmCaseId, subdir, customFieldId }: {
    claimId: string
    files: ClaimFile[]
    lawFirm: string
    lawFirmCaseId?: string
    subdir: string,
    customFieldId?: number,
  },
) {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const formatString = "YYYY-MM-DD\u{00A0}\u{00A0}HH:mm:ss\u{00A0}\u{00A0}"

  async function confirmDelete (path: string) {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await storageRef.child(path).delete()
        notify("File was successfully deleted", "success")
        // TODO: Also delete document from Firestore
        refresh()
      }
      catch (e) {
        // @ts-ignore
        notify(e.message, "warning")
      }
    }
  }


  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>Transmit to Law Firm</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map(file => {
            return (
              <TableRow key={file.downloadURL}>
                <TableCell component="th" scope="row">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={file.downloadURL}
                  > {file.name}</Link>
                </TableCell>
                <TableCell>
                  {moment(file.meta?.timeCreated).format(formatString)}Z
                </TableCell>
                <TableCell>
                  {moment(file.meta?.updated).format(formatString)}Z
                </TableCell>
                <TableCell>
                  {lawFirmCaseId && subdir !== "idCard" &&
                      <Button
                        variant="outlined"
                        onClick={async () => {
                          console.info(
                            `Transmit file "${file.name}" to ${lawFirm}`,
                          )
                          try {
                            await uploadFile({
                              filePath: file.meta?.fullPath,
                              targetFileName: subdirToTargetFileName(
                                subdir,
                                path.extname(file.meta?.fullPath),
                              ),
                              lawFirm,
                              internalId: lawFirmCaseId,
                              claimId,
                              shouldUpdateCase: false,
                              customFieldId,
                            })

                            notify(
                              `Transmitted file "${file.meta.name}" 
                          to ${lawFirm}`,
                              "success",
                            )
                          }
                          catch (error: any) {
                            notify(error?.message, "warning")
                          }
                        }}
                      >
                        <SendIcon/>
                          &nbsp;
                        <small>Transmit to {lawFirm}</small>
                      </Button>
                  }
                  {!lawFirmCaseId &&
                      <small>
                          Claim must be transmitted<br/>
                          to law firm first
                      </small>
                  }
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={event => {
                      event.preventDefault()
                      confirmDelete(file.meta?.fullPath)
                    }}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}


interface ClaimFilesParams {
  record?: {
    userId: string
    id: string
    lawFirm: string
    lawFirmCaseId: string
    customFieldValues: any
  }
  lawFirm?: string
}

export default function ClaimFiles (props: ClaimFilesParams) {
  const userId = props?.record?.userId || ""
  const claimId = props?.record?.id || ""
  const customFieldValues = props?.record?.customFieldValues
  const notify = useNotify()
  const [files, loading] = useFiles({ userId, claimId  })
  const dataProvider = useDataProvider()
  const [kapCase, setKapCase] = useState<Case | undefined>()

  const lawFirm = props?.record?.lawFirm

  const lawFirmCaseId = props?.record?.lawFirmCaseId

  if (loading) {
    return <>
      <p>Loading ...</p>
      <p>(Please hard reload the website if this seems stuck)</p>
    </>
  }

  const uploadSections = [
    {
      title: "Purchase Contract Files (KV)",
      files: files.purchaseContracts,
      subdir: "purchaseContracts",
      prefix: "Kaufvertrag_",
      mergedFile: files.purchaseContractMerged,
      targetDir: "purchaseContractMerged",
      leoFieldId: 425,
    },
    {
      title: "Sales Contract Files",
      files: files.salesContracts,
      subdir: "salesContracts",
      prefix: "Verkaufsvertrag_",
      mergedFile: files.salesContractMerged,
      targetDir: "salesContractMerged",
      leoFieldId: 490,
    },
    {
      title: "Finance Contract Files",
      files: files.financeContracts,
      subdir: "financeContracts",
      prefix: "Finanzierungsvertrag_",
      mergedFile: files.financeContractMerged,
      targetDir: "financeContractMerged",
      leoFieldId: undefined,
    },
    {
      title: "Vehicle Registration Document",
      files: files.vehicleRegistrationDocument,
      subdir: "vehicleRegistrationDocument",
      prefix: "Fahrzeugschein_",
      mergedFile: files.vehicleRegistrationDocumentMerged,
      targetDir: "vehicleRegistrationDocumentMerged",
      leoFieldId: 424,
    },
    {
      title: "LATEST MILEAGE (PHOTO)",
      files: files.latestMileage,
      subdir: "latestMileage",
      prefix: "FotoAktuellerKilometerstand_",
      mergedFile: files.latestMileageMerged,
      targetDir: "latestMileageMerged",
      leoFieldId: undefined,
    },
    {
      title: "Current Mileage",
      files: files.currentMileageDocument,
      subdir: "currentMileageDocument",
      prefix: "AktuellerKilometerstand_",
      leoFieldId: undefined,
    },
    {
      title: "Power of Attorney Signature",
      files: files.claimPoaSignature,
      subdir: "claimPoaSignature",
      prefix: "UnterschriftVollmacht_",
      leoFieldId: 1308,
    },
    {
      title: "Power of Attorney",
      files: files.claimPoa,
      subdir: "claimPoa",
      prefix: "POA_",
      leoFieldId: undefined,
    },
    {
      title: "Financing Agreement Signature",
      files: files.claimFinancingAgreementSignature,
      subdir: "claimFinancingAgreementSignature",
      prefix: "UnterschriftFinanzierungsvertrag_",
      leoFieldId: 1343,
    },
    {
      title: "Financing Agreement",
      files: files.claimFinancingAgreement,
      subdir: "claimFinancingAgreement",
      prefix: "LFA_",
      leoFieldId: undefined,
    },
    {
      title: "Remaining Value Claimant",
      files: files.claimRemainingValueClaimant,
      subdir: "claimRemainingValueClaimant",
      prefix: "RestwertMandant_",
      mergedFile: files.claimRemainingValueClaimantMerged,
      targetDir: "claimRemainingValueClaimantMerged",
      leoFieldId: 4950,
    },
    {
      title: "Remaining Value Claimback",
      files: files.claimRemainingValueClaimback,
      subdir: "claimRemainingValueClaimback",
      prefix: "RestwertClaimback_",
      mergedFile: files.claimRemainingValueClaimbackMerged,
      targetDir: "claimRemainingValueClaimbackMerged",
      leoFieldId: 4949,
    },
    {
      title: "Other Files",
      files: files.otherFiles,
      subdir: "otherFiles",
      prefix: "",
      leoFieldId: undefined,
    },
  ]

  return (
    <>
      <div style={{ padding: 20 }}>
        {
          uploadSections.map(section =>
            <>
              <div key={section.title}>
                <Typography variant="overline">
                  {section.title}
                </Typography>
                <FileTable
                  claimId={claimId}
                  lawFirmCaseId={lawFirmCaseId}
                  files={section.files}
                  lawFirm={lawFirm || ""}
                  subdir={section.subdir}
                  customFieldId={section.leoFieldId}
                />
                <br/>

                <Grid container>
                  <UploadFileInput
                    buttonLabel="Upload new file"
                    subdir={section.subdir}
                    initialUploadedFiles={[]}
                    claimId={claimId}
                    userId={userId}
                    customFieldValues={customFieldValues}
                    onChange={event => {
                      console.info(event)
                    }}
                  />
                </Grid>

                {[
                  "purchaseContracts",
                  "salesContracts",
                  "financeContracts",
                  "vehicleRegistrationDocument",
                  "claimRemainingValueClaimant",
                  "claimRemainingValueClaimback",
                ].includes(section.subdir) &&
                    <Button
                      variant="outlined"
                      key='button'
                      style={{
                        height: "45px",
                        marginLeft: "31px",
                        width: "175px",
                      }}
                      onClick={event => {
                        try {
                          const apiMergeFilesInBucket =
                            getHttpsCallable("admin-mergeFilesInBucket")

                          apiMergeFilesInBucket({
                            claimId,
                            userId,
                            bucket: section.subdir,
                            targetDir: section.targetDir,
                          }).then(() => {
                            location.reload()
                          })
                        }
                        catch (err: any) {
                          return notify(
                            err.message || "Unknown error",
                            "error",
                          )
                        }
                      }}
                    >
                        Merge files
                    </Button>
                }
                <Divider style={{ margin: "20px 0" }} />
              </div>
              {[
                "purchaseContracts",
                "salesContracts",
                "financeContracts",
                "vehicleRegistrationDocument",
                "claimRemainingValueClaimant",
                "claimRemainingValueClaimback",
              ].includes(section.subdir) &&
          <div key={section.title}>
            <Typography variant="overline">
              {section.title + " (Merged)"}
            </Typography>
            <FileTable
              claimId={claimId}
              lawFirmCaseId={lawFirmCaseId}
              files={section.mergedFile ?? files.otherFiles}
              lawFirm={lawFirm || ""}
              subdir={section.subdir + "Merged"}
              customFieldId={section.leoFieldId}
            />
            <br/>
            <Divider style={{ margin: "20px 0" }} />
          </div>}
            </>
            ,
          )
        }
      </div>
    </>
  )
}
