import React from "react"
import {
  Datagrid,
  TextField,
  BooleanField,
  List,
  DateField,
  ReferenceArrayField,
  SingleFieldList, ChipField, SelectField,
} from "react-admin"
import TimestampField from "../TimestampField"
import DefaultActions from "../DefaultActions"
import DefaultPagination from "../DefaultPagination"

const typeChoices = [
  { id: "Funder", name: "Funder" },
  { id: "Insurance", name: "Insurance" },
]

export default function ({...props}) {
  return (
    <List
      filter={{
        markForSubmission: true,
      }}
      sort={{ field: "createdAt", order: "DESC" }}
      {...props}
      actions={<DefaultActions/>}
      perPage={15}
      pagination={<DefaultPagination/>}>
      <Datagrid rowClick="edit">
        <TextField source="fullName" label="Name" sortable={false}/>
        <TextField source="userId" label="User Id" sortable={false}/>
        <TextField source="id" label="Id" sortable={false}/>
        <SelectField source="type" choices={typeChoices} />
        <DateField source="createdAt" label="Created at" sortable={false}/>
        <DateField source="updatedAt" label="Updated at" sortable={false}/>
        <ReferenceArrayField label="Gdpr breaches" reference="gdprBreaches"
          source="gdprBreachesIds">
          <SingleFieldList>
            <ChipField source="Name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <BooleanField
          source='submissionStatus'
          sortable={false}
        />
        <TimestampField
          source='submittedToLawFirmAt'
          locales='sv-SE'
          sortable={false}
          showTime
        />
        <TextField
          source="lawFirmCaseId"
          label="Law Firm Case ID"
          sortable={false}
        />
        <TextField source="lawFirm" sortable={false} />
      </Datagrid>
    </List>
  )
}

