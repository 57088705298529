import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import ListItem from "@material-ui/core/ListItem"
import Snackbar from "@material-ui/core/Snackbar"
import ListItemText from "@material-ui/core/ListItemText"
import DeclinedIcon from "@material-ui/icons/CallEnd"
import PhoneInvalidIcon from "@material-ui/icons/PhoneDisabled"
import PhoneAnsweredIcon from "@material-ui/icons/PhoneInTalk"
import clsx from "clsx"
import React, { useState } from "react"
import CopyablePhoneNumberButton from "./CopyablePhoneNumberButton"
import useStyles from "./styles"
import CallAcceptedDialog from "./CallAcceptedDialog"
import createPhoneCall from "./createPhoneCall"
import { useNotify } from "react-admin"
import { AvatarGroup } from "@material-ui/lab"
import { PhoneCallResult } from "../../../lib/enums"


interface CustomSnackbarParams {
  open: boolean
  handleClose: (event: any, reason: string) => void
  onUndo: () => void
}

function CustomSnackbar (
  { open, handleClose, onUndo }: CustomSnackbarParams,
) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message='Invalid phone number removed from list'
      action={
        <>
          <Button color='secondary' size='small' onClick={onUndo}>
            UNDO
          </Button>
          {/* @ts-expect-error */}
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={handleClose}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      }
    />
  )
}


interface CallListItemParams {
  ticketRef: any
  active: boolean
  onRowClicked: (ticketRef: any) => void
}

export default function CallListItem (
  { ticketRef, active, onRowClicked }: CallListItemParams,
) {
  const notify = useNotify()
  const classes = useStyles()
  const ticket = ticketRef.data()
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)
  const [toDeleteOnUndo, setToDeleteOnUndo] = useState<any | null>(null)

  return (
    <>
      <CustomSnackbar
        open={snackOpen}
        handleClose={(event, reason) => {
          if (reason === "clickaway") return
          setSnackOpen(false)
        }}
        onUndo={async () => {
          if (toDeleteOnUndo) {
            await toDeleteOnUndo.delete()
            setToDeleteOnUndo(null)
          }
          setSnackOpen(false)
        }}
      />
      <CallAcceptedDialog
        open={saveDialogOpen}
        ticket={ticket}
        handleClose={() => setSaveDialogOpen(false)}
      />
      <ListItem
        className={clsx(active && classes.active)}
        onClick={() => onRowClicked(ticketRef)}
        button
      >
        <ListItemText
          primary={ticket.claimId}
          secondary={
            `Reached: ${ticket.reachedCount} Called ${ticket.callCount} times`
          }
        />
        {/* @ts-expect-error */}
        <AvatarGroup className={classes.avatarGroup}>
          {ticket?.tags && ticket?.tags?.includes("topModels") &&
            (
              <Avatar className={clsx(classes.topBadge, classes.avatar)}>
                T
              </Avatar>
            )}
          {ticket?.tags && ticket?.tags?.includes("kapModels") &&
            (
              <Avatar className={clsx(classes.kapBadge, classes.avatar)}>
                K
              </Avatar>
            )}
        </AvatarGroup>

        <CopyablePhoneNumberButton
          className={classes.callButtons}
          value={ticket.claimPhoneNumber}
        />

        <IconButton
          className={clsx(classes.callButtons, classes.invalidIcon)}
          onClick={async (e) => {
            e.stopPropagation()
            try {
              setSnackOpen(true)
              const toDeleteOnUndo = await createPhoneCall({
                claimId: ticket.claimId,
                callResult: PhoneCallResult.MISSED,
                phoneNumberValid: false,
                phoneNumber: ticket.claimPhoneNumber,
              })
              setToDeleteOnUndo(toDeleteOnUndo)
            }
            catch (error) {
              notify(`${error}`, "warning")
            }
          }}
        >
          <PhoneInvalidIcon />
        </IconButton>

        <IconButton
          className={clsx(classes.callButtons, classes.declinedIcon)}
          onClick={async (e) => {
            e.stopPropagation()
            try {
              await createPhoneCall({
                claimId: ticket.claimId,
                callResult: PhoneCallResult.MISSED,
                phoneNumberValid: true,
                phoneNumber: ticket.claimPhoneNumber,
              })
            }
            catch (error) {
              notify(`${error}`, "warning")
            }
          }}
        >
          <DeclinedIcon />
        </IconButton>

        <IconButton
          className={clsx(classes.callButtons, classes.answeredIcon)}
          onClick={async () => {
            setSaveDialogOpen(true)
          }}
        >
          <PhoneAnsweredIcon />
        </IconButton>
      </ListItem>
    </>
  )
}
