import React from "react"
import DefaultActions from "./DefaultActions"
import {
  List,
  Datagrid,
  DateField,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
} from "react-admin"
import DefaultPagination from "./DefaultPagination"

const dateFieldOpts = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}

export function PhoneNumberList ({ ...props }) {
  return (
    <List
      {...props}
      actions={<DefaultActions />}
      perPage={15}
      pagination={<DefaultPagination />}
    >
      <Datagrid rowClick='show'>
        <TextField source='phoneNumber' label='Phone Number' sortable={false} />
        <DateField source='lastUsedAt' options={{...dateFieldOpts}} />
      </Datagrid>
    </List>
  )
}


export function PhoneNumberEdit ({...props}) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source='phoneNumber' label='Phone Number' />
        <DateField source='lastUsedAt' options={{...dateFieldOpts}} />
      </SimpleForm>
    </Edit>
  )
}


export function PhoneNumberCreate ({...props}) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='phoneNumber' label='Phone Number' />
        <DateField source='lastUsedAt' options={{...dateFieldOpts}} />
      </SimpleForm>
    </Create>
  )
}
