// Firebase App (the core Firebase SDK)
// is always required and must be listed first
import firebase from "firebase/compat/app"

// Add the Firebase products that you want to use
require("firebase/compat/auth")
require("firebase/compat/firestore")
require("firebase/compat/storage")
require("firebase/compat/functions")
import {FirebaseAuthProvider} from "react-admin-firebase"
import {
  connectFirestoreEmulator,
  getFirestore,
} from "@firebase/firestore"
// import 'firebase/analytics'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_ANALYTICS_ID,
}

if (!config.apiKey) throw new Error('Missing value for "apiKey"')
if (!config.authDomain) throw new Error('Missing value for "authDomain"')
if (!config.databaseURL) throw new Error('Missing value for "databaseURL"')
if (!config.projectId) throw new Error('Missing value for "projectId"')
if (!config.storageBucket) throw new Error('Missing value for "storageBucket"')
if (!config.messagingSenderId) {
  throw new Error('Missing value for "messagingSenderId"')
}
if (!config.appId) throw new Error('Missing value for "appId"')
if (!config.measurementId) throw new Error('Missing value for "measurementId"')

const firebaseApp = firebase.initializeApp(config)

// eslint-disable-next-line new-cap
const FirebaseConfigAuthProvider = FirebaseAuthProvider(config, {
  app: firebaseApp,
})
// const analytics = firebase.analytics()
const auth = firebase.auth()
const db = firebase.firestore()
db.settings({
  ignoreUndefinedProperties: true,
  experimentalForceLongPolling: true,
})
const storage = firebase.storage()
const functions = firebase.functions()

if (process.env.REACT_APP_BUILD_ENV !== "test") {
  // State will be persisted even when the browser window is closed
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
}
const dbV9 = getFirestore(firebaseApp)

if (window.location.hostname === "localhost") {
  console.info("Use Firebase emulators")

  connectFirestoreEmulator(dbV9, "localhost", 8045)
  auth.useEmulator("http://localhost:9099")
  db.useEmulator("localhost", 8045)
  storage.useEmulator("localhost", 9199)
  functions.useEmulator("localhost", 5001)
}

export default firebase
export { db, storage, functions, auth, FirebaseConfigAuthProvider, dbV9 }
