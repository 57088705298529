import React, {useEffect, useState} from "react"
import keyBy from "lodash/keyBy"
import {
  Loading,
  ListContextProvider,
} from "react-admin"
import {useParams} from "react-router-dom"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {db} from "../../firebase"
import Button from "@material-ui/core/Button"

function getTimestamp (dt?: number) {
  const date: any = new Date()

  if (dt) {
    date.setDate(date.getDate() + dt)
  }

  return date
}

function getFormattedDate (timestamp: number) {
  const date = new Date(timestamp * 1000)

  const yy = date.getFullYear()
    .toString()
  const mm = (date.getMonth() + 1)
    .toString()
  const dd  = date.getDate()
    .toString()

  const mmChars = mm.split("")
  const ddChars = dd.split("")

  return (ddChars[1] ? dd : "0" + ddChars[0]) + "." +
    (mmChars[1] ? mm : "0" + mmChars[0]) + "." + yy
}

export default function UserTrackingShow () {
  const params: {id: string} = useParams()
  const id = decodeURIComponent(params.id)
  const [pageUrl, setPageUrl] = useState("")
  const fetchDateRangePerLoad = 10
  const [startAt, setStartAt] = useState(0)
  const [endAt, setEndAt] = useState(-fetchDateRangePerLoad)
  const [loaded, setLoaded] = useState(false)
  const [loadingMoreData, setLoadingMoreData] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(true)
  const [allData, setAllData] = useState<any>({})
  const [sessionData, setSessionData] = useState<any>({})
  const [utmParams, setUtmParams] = useState<any>({})
  const [totalUtmParams, setTotalUtmParams] = useState<any>({})
  const [grabbedData, setGrabbedData] = useState<any>(undefined)
  const labelStyle = {color: "rgba(255, 255, 255, 0.7)",
    fontSize: "0.8rem"}
  const tdStyle = {color: "rgba(255, 255, 255, 0.7)",
    fontSize: "0.8rem", paddingRight: "1rem"}

  useEffect(() => {
    async function hasMoreData () {
      const visitedPagesData = await db.collectionGroup("visitedPages")
        .where("trackedPageId", "==", id)
        .where("createdAt", "<=", getTimestamp(startAt - fetchDateRangePerLoad))
        .where("createdAt", ">=", getTimestamp(endAt - fetchDateRangePerLoad))
        .orderBy("createdAt", "desc")
        .get()

      if (!visitedPagesData.size) {
        setHasMoreData(false)
      }
    }

    async function getData () {
      const visitedPagesData = await db.collectionGroup("visitedPages")
        .where("trackedPageId", "==", id)
        .where("createdAt", "<=", getTimestamp(startAt))
        .where("createdAt", ">=", getTimestamp(endAt))
        .orderBy("createdAt", "desc")
        .get()

      const mappedData = visitedPagesData.docs.map((d: any) => d.data())
      setGrabbedData(mappedData)
      hasMoreData()
    }

    async function getUserTrackingData (data: any) {
      const allTrackingData = data.reduce((r: any, a: any) => {
        if (!pageUrl) {
          setPageUrl(a.page)
        }

        if (!a.createdAtDate) {
          a.createdAtDate = getFormattedDate(a.createdAt.seconds)
        }

        r[a.createdAtDate] = r[a.createdAtDate] || []

        const userTrackingRef = db.doc(`userTracking/${a.userTrackingId}`)
        userTrackingRef.get().then((response) => {
          const responseData = response.data()

          if (responseData !== undefined) {
            if (sessionData[a.createdAtDate] === undefined) {
              sessionData[a.createdAtDate] = []
            }
            if (!sessionData[a.createdAtDate]
              .some((data: { sessionId: string }) => data.sessionId ===
                responseData?.sessionId)
            ) {
              sessionData[a.createdAtDate].push(responseData)
              setSessionData(sessionData)

              if (utmParams[a.createdAtDate] === undefined) {
                utmParams[a.createdAtDate] = []
              }
              // eslint-disable-next-line array-callback-return
              Object.keys(responseData?.parameters).map((param: string) => {
                const paramValue = responseData?.parameters[param]
                if (utmParams[a.createdAtDate][param] === undefined) {
                  utmParams[a.createdAtDate][param] = []
                }

                if (!(paramValue in utmParams[a.createdAtDate][param])) {
                  utmParams[a.createdAtDate][param][paramValue] = 1

                }
                else {
                  utmParams[a.createdAtDate][param][paramValue] += 1
                }

                if (totalUtmParams[a.createdAtDate] === undefined) {
                  totalUtmParams[a.createdAtDate] = []
                }
                if (totalUtmParams[a.createdAtDate][param] === undefined) {
                  totalUtmParams[a.createdAtDate][param] = 0
                }

                totalUtmParams[a.createdAtDate][param] += 1
                setTotalUtmParams(totalUtmParams)
                setUtmParams(utmParams)
              })
            }
          }

          setTimeout(() => {
            setLoaded(true)
            setLoadingMoreData(false)
          }, 1000)
        })
        r[a.createdAtDate].push(a)

        return r
      }, Object.create(null))

      setAllData({ ...allData, ...allTrackingData })
    }

    if (grabbedData === undefined) {
      getData()
    }

    if (grabbedData) {
      getUserTrackingData(grabbedData)
    }
  }, [grabbedData])

  function loadMoreData () {
    setStartAt(startAt - fetchDateRangePerLoad)
    setEndAt(endAt - fetchDateRangePerLoad)
    setGrabbedData(undefined)
    setLoadingMoreData(true)
  }

  if (!loaded) {
    return <Loading/>
  }

  return (
    <div style={{marginBottom: "1rem"}}>
      <h3>{pageUrl}</h3>
      <ListContextProvider value={{
        data: keyBy(allData, "id"),
      }}>
        {Object.keys(allData).map((k) => (
          <Accordion key={k}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
            >
              <span style={{fontWeight: 500, fontSize: "1rem"}}>{k}</span>
            </AccordionSummary>
            <AccordionDetails style={{display: "block"}}>
              <div>
                <div style={{paddingBottom: "0.5rem"}}>
                  <label style={labelStyle}>
                    <span>Total users</span>
                  </label>
                </div>
                <div>
                  <span>{sessionData[k] ? sessionData[k].length : ""}</span>
                  <span>{utmParams[k]}</span>
                </div>
              </div>

              <div style={{paddingTop: "1rem"}}>
                <div style={{paddingBottom: "0.5rem"}}>
                  <label style={labelStyle}>
                    <span>UTM parameters</span>
                  </label>
                </div>
                {utmParams[k] && Object.keys(utmParams[k]).map((p) => (
                  <Accordion key={p}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                    >
                      <span style={{fontWeight: 500}}>
                        {p} ({totalUtmParams[k][p] ? totalUtmParams[k][p] : 0})
                      </span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <table>
                        <tbody>
                          {Object.keys(utmParams[k][p]).map((v) => (
                            <tr key={v}>
                              <td style={tdStyle}>{v}</td>
                              <td>{utmParams[k][p][v]}</td>
                            </tr>),
                          )}
                        </tbody>
                      </table>
                    </AccordionDetails>
                  </Accordion>),
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
        {hasMoreData &&
          <div style={{width: "100%", textAlign: "center",
            marginTop: "1rem"}}>
            {!loadingMoreData
              ? <Button
                variant='outlined'
                color='primary'
                onClick={loadMoreData}
              >
                Load more
              </Button>
              : <CircularProgress/>
            }
          </div>
        }
      </ListContextProvider >

    </div>
  )
}
