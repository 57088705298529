import {
  Badge,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
  Checkbox, ListItemIcon,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import EmailIcon from "@material-ui/icons/Email"
import PhoneIcon from "@material-ui/icons/Phone"
import SMSIcon from "@material-ui/icons/Sms"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import ScheduleIcon from "@material-ui/icons/Schedule"

import WhatsappIcon from "../WhatsappIcon"
import ZendeskIcon from "../../lib/icons/ZendeskIcon"
import { FlexContainer } from "../../lib/components/Container"
import { get, sortBy, truncate } from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useNotify } from "react-admin"
import { db } from "../../firebase"
import { EmailProviderMap, PhoneCallReason } from "../../constants/enums"
import { 
  ZendeskTicket,
  startAuthFlow,
  api, 
} from "./TicketPanel/ZendeskPanel"

import getHttpsCallable from "../../utils/getHttpsCallable"

import ListSubheader from "@material-ui/core/ListSubheader"

const useStyles = makeStyles(theme => ({
  centeredList: {
    alignItems: "center",
  },
  inline: {
    display: "inline",
  },
  padded: {
    padding: 8,
  },
  checkBox: {
    marginRight: "14px",
  },
  margin: {
    marginRight: "1.5em",
  },
}))

const notifTypeEnum = {
  email: "Email",
  whatsApp: "WhatsApp",
}

function useCommunications ({ claimId = null }) {
  const [commsArray, setCommsArray] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!claimId) {
      console.error("Claim not found")
      return
    }

    async function getUserComms () {
      const emails = await db
        .collection("emails")
        .where("claimId", "==", claimId)
        .get()
      const sms = await db
        .collection("sms")
        .where("claimId", "==", claimId)
        .get()
      const phoneCalls = await db
        .collection("phoneCalls")
        .where("claimId", "==", claimId)
        .get()
      const whatsapp = await db
        .collection("whatsapp")
        .where("claimId", "==", claimId)
        .get()

      const emailList = emails.docs
        .map(s => ({
          ...s.data(),
          type: "email",
        }))
      const smsList = sms.docs
        .map(s => ({
          ...s.data(),
          type: "sms",
        }))
      const phoneCallList = phoneCalls.docs
        .map(s => ({
          ...s.data(),
          type: "phoneCall",
          timestamp: s.data().startTime,
        }))
      let whatsappList = whatsapp.docs
        .map(s => ({
          ...s.data(),
          type: "whatsapp",
          timestamp: s.data().timestamp,
        }))

      whatsappList = await whatsappList.filter((message) => {
        // @ts-expect-error
        return message.provider !== null || (
          // @ts-expect-error
          message?.rawProviderData?.status === "queued" &&
          // 86400 seconds == 1 day
          message?.timestamp._seconds > Math.floor(Date.now() / 1000) - 86400
        )
      })

      const comms = emailList
        .concat(smsList)
        .concat(phoneCallList)
        .concat(whatsappList)

      // @ts-expect-error
      setCommsArray(comms)
      setLoading(false)
    }
    getUserComms()
  }, [claimId])

  return [commsArray, loading]
}


interface EmailListAvatarParams {
  email: Email
  link: string
}

function EmailListAvatar ({ email, link }: EmailListAvatarParams) {
  const classes = useStyles()
  const badgeContent = email.provider === EmailProviderMap.ZENDESK_SUPPORT
    ? "Z"
    : "M"
  if (link) {
    return (
      <ListItemAvatar>
        <IconButton href={link} target='__blank' rel='noreferrer'>
          <Badge badgeContent={badgeContent} color='secondary'>
            <EmailIcon />
          </Badge>
        </IconButton>
      </ListItemAvatar>
    )
  }

  return (
    <ListItemAvatar className={classes.padded}>
      <EmailIcon />
    </ListItemAvatar>
  )
}

function EmailListItem (
  { email, ...props }: { email: Email, [x: string]: any },
) {
  const time = moment(email.timestamp.toDate()).format("LLL")
  const zendeskLink = get(email, "metadata.zendeskTicketLink") &&
    `https://${get(email, "metadata.zendeskTicketLink")}`

  return (
    <>
      <ListItem {...props} dense>
        <EmailListAvatar email={email} link={zendeskLink} />
        <ListItemText
          primary={email.template || email.subject}
          secondary={
            <>
              <Typography
                variant='caption'
                color='textSecondary'
              >{`Sent by:${email.sender} - ${time}`}
              </Typography>
              {email.body && <br />}
              {email.body && `${truncate(email.body, { length: 200 })}`}
            </>
          }
        />
        <Chip label={email.state} />
      </ListItem>
    </>
  )
}


function SMSListItem ({ sms, ...props }: { sms: Sms, [x: string]: any }) {
  const classes = useStyles()
  return (
    <ListItem {...props} dense>
      <ListItemAvatar className={classes.padded}>
        <SMSIcon />
      </ListItemAvatar>
      <ListItemText
        primary={truncate(sms.messageContent, { length: 60 })}
        secondary={

          <Typography
            variant='caption'
            color='textSecondary'
          >
            {moment(sms.timestamp.toDate()).format("LLL")}
          </Typography>
        }
      />
      <Chip label={sms.sent ? "sent" : "failed"} />
    </ListItem>
  )
}


function PhoneListItem (
  { phoneCall, ...props }: { phoneCall: PhoneCall, [x: string]: any },
) {
  const classes = useStyles()
  return (
    <>
      <ListItem {...props} dense>
        <ListItemAvatar className={classes.padded}>
          <PhoneIcon />
        </ListItemAvatar>
        <ListItemText
          primary={phoneCall.callReasons
            ? phoneCall.callReasons
              .map((k: PhoneCallReason) => PhoneCallReason[k])
              .join(", ")
            : "Unknown"
          }
          secondary={
            <>
              <Typography
                component='span'
                variant='body2'
                className={classes.inline}
                color='textPrimary'
              >
                {moment(phoneCall.timestamp.toDate()).format("LLL")}
              </Typography>
              {phoneCall.callNotes && ` - ${phoneCall.callNotes}`}
            </>
          }
        />
        <Chip label={
          !phoneCall.phoneNumberValid
            ? "invalid number"
            : phoneCall.callResult
        } />
      </ListItem>
    </>
  )
}


interface WhatsappListItemParams {
  whatsapp: Whatsapp
  [x: string]: any
}

function WhatsappListItem ({ whatsapp, ...props }: WhatsappListItemParams) {
  const classes = useStyles()
  return (
    <>
      <ListItem {...props} dense>
        <ListItemAvatar className={classes.padded}>
          {
            whatsapp?.rawProviderData?.errorCode !== null
              ? <ErrorOutlineIcon/>
              : <WhatsappIcon />
          }
        </ListItemAvatar>
        <ListItemText
          primary={
            truncate(whatsapp.template, { length: 60 }) +
            (whatsapp?.rawProviderData?.errorCode !== null
              ? " / ERROR CODE: " + whatsapp?.rawProviderData?.errorCode : "")
          }
          secondary={
            <Typography
              variant='caption'
              color='textSecondary'
            >
              {moment(whatsapp.timestamp.toDate()).format("LLL")}
            </Typography>
          }
        />
        <Chip label={whatsapp.operationType} />
      </ListItem>
    </>
  )
}

function ZendeskListItem (
  {
    claimId,
    zendeskTicket,
    ...props
  }:
  {
    claimId: string
    zendeskTicket: ZendeskTicket
  }) {
  const classes = useStyles()
  return (
    <ListItem {...props} dense>
      <ListItemAvatar className={classes.padded}>
        <ZendeskIcon
          style={{ filter: "brightness(0) invert(1)" }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={truncate(
          zendeskTicket.subject ||
                zendeskTicket.description, {length: 60},
        )}
        secondary={
          <Typography
            variant='caption'
            color='textSecondary'
          >
            {moment(zendeskTicket.created_at).format("LLL")}
          </Typography>
        }
      />
      <Chip label={`Go to ticket: ${zendeskTicket.id} `} onClick={() =>
        window.open(`https://claimback.zendesk.com/agent/tickets/${
          zendeskTicket.id}`)}
      variant="outlined"
      />
    </ListItem>
  )
}


export default function ClaimCommunications (
  props: {
    record?: Claim
  },
) {
  const [comms, loading] = useCommunications({
    // @ts-expect-error
    claimId: props?.record?.id || "",
  })

  const [queue, setQueue] =
    useState<Record<string, any> | undefined>(props?.record?.notificationQueue)

  const [selectedItems, setSelectedItems] =
    useState<{ [notifType: string]: string[] }>({})

  const [relatedTickets, setRelatedTickets] = useState<ZendeskTicket[]>([])
  const claimId = props?.record?.id
  const notify = useNotify()

  const [notificationQueueMap, setNotificationQueueMap] =
    useState<Record<string, any>[]>([])

  function handleCheck (templateName: string, notifType: string) {
    if (!selectedItems[notifType]) selectedItems[notifType] = []

    const currentIndex = selectedItems[notifType].indexOf(templateName)

    const newSelected = selectedItems[notifType]

    if (currentIndex === -1) {
      newSelected.push(templateName)
    }
    else {
      newSelected.splice(currentIndex, 1)
    }

    selectedItems[notifType] = newSelected
    setSelectedItems(selectedItems)
  }

  async function handleAbort () {
    const hasSelected = Object.keys(selectedItems).reduce((acc, notifType) => {
      if (selectedItems[notifType] && selectedItems[notifType].length > 0) {
        acc = true
      }
      return acc
    }, false)

    if (!hasSelected) {
      return notify(
        "No notification was selected",
        "error",
      )
    }

    try {
      const apiSignatures = getHttpsCallable("admin-updateNotificationQueue")

      const queueClone = JSON.parse(JSON.stringify(queue))

      Object.keys(selectedItems).forEach((notifType) => {
        const selectedNotifs = selectedItems[notifType]

        selectedNotifs.forEach((notificationName) => {
          delete queueClone[notifType][notificationName]
        })

        if (Object.keys(queueClone[notifType]).length < 1) {
          delete queueClone[notifType]
        }
      })

      const res = await apiSignatures({
        claimId: claimId,
        notificationQueue: queueClone,
      })

      notify(
        res.data.message,
        { type: "success", autoHideDuration: 10e3, multiLine: true },
      )

      setQueue(queueClone)
    }
    catch (err: any) {
      return notify(
        err.message,
        "error",
      )
    }
  }
  const zendeskAuthed = localStorage.getItem("zauth")

  useEffect(() => {
    async function mapNotification (
      claimId: string,
      queueRecord: Record<string, any>,
      commsRecord: Record<string, any>[],
      zendesk: Record<string, any>[],
    ) {

      try {
        const getNotificationPass =
          await getHttpsCallable("admin-getNotificationException")

        for (const type of Object.keys(queueRecord)) {
          for (const templateName of Object.keys(queueRecord[type])) {
            const res = await getNotificationPass({
              claimId: claimId,
              exceptions: queueRecord[type][templateName].exceptions,
            })

            Object.assign(queueRecord[type][templateName], {
              exceptionFailed: res.data && !res.data.error
                ? res.data.exception_failed
                : true,
            })
          }
        }
      }
      catch (err) {
        console.error(err)
      }

      const zendeskTickets = zendesk.map(item => ({
        ...item,
        type: "zendesk",
        timestamp: item.created_at,
      }))

      const mappedQueue =
        Object.keys(queueRecord).length > 0
          ? Object.keys(queueRecord).reduce<Record<string, any>[]>(
            (acc, type) => {

              Object.keys(queueRecord[type]).forEach(async (templateName) => {

                acc.push(
                  {
                    type: type,
                    timestamp: queueRecord[type][templateName].scheduledTime,
                    templateName: templateName,
                    scheduled: true,
                    exceptionFailed:
                      queueRecord[type][templateName].exceptionFailed,
                  },
                )
              })
              return acc
            }, [])
          :      []

      if (commsRecord && Array.isArray(commsRecord)) {
        const commsCopy = [...commsRecord]
        const sorted = sortBy(
          commsCopy
            // @ts-ignore
            .concat(mappedQueue)
            .concat(zendeskTickets),
          (item) => {
            let filter
            if (typeof item.timestamp === "object" ) {
              filter = moment(item.timestamp.toDate()).unix()
            }
            else {
              filter = moment(item.timestamp).unix()
            }
            return filter
          },
        )

        setNotificationQueueMap(sorted)
      }

    }

    mapNotification(
      claimId || "",
      queue || {},
      // @ts-expect-error
      comms || [],
      relatedTickets || [],
    )
  }, [claimId, queue, comms, relatedTickets])

  useEffect(() => {
    if (zendeskAuthed) {
      api(
        `search.json?query=type:ticket custom_field_360005022257:"${claimId}"`,
      )
        .then(result => {
          if (result.error) {
            return notify(`${result.error} ${result.description}`, "warning")
          }
          setRelatedTickets(result.results)
        })
        .catch(err => notify(err.message, "warning"))
    }
  }, [notify, claimId, api]) // eslint-disable-line

  const classes = useStyles()
  if (loading) {
    return (
      <FlexContainer center>
        <CircularProgress
          style={{ margin: 20 }}
          size={50}
          color='secondary'
        />
      </FlexContainer>
    )
  }

  return (
    <>
      <List>
        {
          notificationQueueMap.length > 0 
            ? notificationQueueMap.map((notifItem, index) => {
              if (notifItem.scheduled) {
                return (
                  <ListItem key={index} dense>
                    <Checkbox
                      className={classes.checkBox}
                      tabIndex={-1}
                      onClick={() =>
                        handleCheck(notifItem.templateName, notifItem.type,
                        )}
                    />
                    <ListItemText
                      primary={
                      // @ts-expect-error
                        truncate(`Scheduled: ${notifTypeEnum[notifItem.type]
                        } 
                    "${notifItem.templateName}"`,
                        { length: 60 },
                        )
                      }
                      secondary={
                        <Typography
                          variant='caption'
                          color='textSecondary'
                        >
                          {`Scheduled Date:
                        ${moment(notifItem.timestamp)
                    .format("LLL")}`}
                        </Typography>
                      }
                    />
                    <a
                      style={{
                        marginRight: "1rem",
                        fontSize: "small",
                      }}
                    >
                      {
                        notifItem.exceptionFailed
                          ? "Exception met"
                          : "Exception not met"
                      }
                    </a>
                    <ListItemIcon>
                      {
                        notifItem.exceptionFailed
                          ?                        <ErrorOutlineIcon/>
                          :                        <ScheduleIcon/>
                      }
                    </ListItemIcon>
                    <Chip label={"scheduled"}
                    />
                  </ListItem>
                )
              }
              else {
                switch (notifItem.type) {
                  case "email":
                    return <EmailListItem
                      // @ts-expect-error
                      email={notifItem} key={index}
                    />
                  case "sms":
                    return <SMSListItem
                      // @ts-expect-error
                      sms={notifItem} key={index}
                    />
                  case "phoneCall":
                    return <PhoneListItem
                      // @ts-expect-error
                      phoneCall={notifItem} key={index}
                    />
                  case "whatsapp":
                    return <WhatsappListItem
                      // @ts-expect-error
                      whatsapp={notifItem} key={index}
                      notificationQueue={queue}
                      claimId={claimId}
                      handleCheck={handleCheck}
                    />
                  case "zendesk":
                    return <ZendeskListItem
                      // @ts-expect-error
                      claimId={claimId} zendeskTicket={notifItem}
                      key={index}
                    />
                  default:
                    return null
                }
              }

            })
            :            <>
            </>
        }
        <ListItem dense>
          {
            queue && Object.keys(queue).length > 0 && <Button
              className={classes.margin}
              variant='outlined'
              size='small'
              onClick={handleAbort}
            >Abort selected
            </Button>
          }
          {
            !zendeskAuthed && <Button
              variant='outlined'
              size='small'
              // startIcon={<ZendeskIcon />}
              onClick={() => startAuthFlow()}
            >Sign in to Zendesk
            </Button>
          }
        </ListItem>
      </List>
    </>
  )
}
