import React, { useState } from "react"
import {
  Button,
  Dialog,
  Box,
  makeStyles,
} from "@material-ui/core"
import {
  Close,
  DoubleArrow,
} from "@material-ui/icons"
import BulkTransfer from "../BulkTransfer"


const useStyles = makeStyles(theme => ({
  dialogButton: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))


interface TransferDialogParams {
  open: boolean
  list: string[]  // Claim ids
  handleClose: () => void
}

function TransferDialog (
  { open, list, handleClose }: TransferDialogParams,
) {
  const classes = useStyles()
  return (
    <Dialog
      fullScreen
      open={open}
    >
      <Box className={classes.closeButton}>
        <Button
          size='large'
          color='inherit'
          variant='text'
          endIcon={<Close />}
          onClick={() => handleClose()}
        >Close
        </Button>
      </Box>
      <Box m={4}>
        {/* @ts-expect-error */}
        <BulkTransfer list={list} />
      </Box>
    </Dialog>
  )
}


interface SendButtonParams {
  selectedIds: string[]
  [key: string]: any
}

export default function BulkTransferButton (
  { selectedIds, ...props }: SendButtonParams,
) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  return (
    <>
      <TransferDialog
        open={open}
        list={selectedIds}
        handleClose={() => setOpen(false)}
      />
      <Button
        startIcon={<DoubleArrow />}
        variant='outlined'
        onClick={() => setOpen(true)} className={classes.dialogButton}
      >
        Bulk Transfer
      </Button>
    </>
  )
}
