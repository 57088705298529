import getHttpsCallable from "./getHttpsCallable"

const getTemplates = getHttpsCallable("admin-getTemplates")

export default async function getTemplate (
  type: string,
  name: string) : Promise<Template | undefined> {

  const templates : Template[] = await getTemplates(type)

  return (
    Object
      .values(templates)
      .find((template: Template) => template.name === name)
  )
}
