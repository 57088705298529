import { isObject } from "lodash"
import firebase from "firebase/compat/app"
require("firebase/compat/firestore")


export default function normalizeTimestamps (obj: Record<string, any>): void {
  function normTs (obj: any, key: any) {
    if (obj[key] && typeof obj[key] === "string") {
      obj[key] = new Date(obj[key])
    }
  }

  normTs(obj, "applicationApprovedTime")
  normTs(obj, "_applicationApprovedTime")
  normTs(obj, "claimActionStatusUpdateTime")
  normTs(obj, "_claimActionStatusUpdateTime")
  normTs(obj, "createdAt")
  normTs(obj, "_createdAt")
  normTs(obj, "createdTime")
  normTs(obj, "_createdTime")
  normTs(obj, "date")
  normTs(obj, "endTime")
  normTs(obj, "_endTime")
  normTs(obj, "importedAt")
  normTs(obj, "lastSignInTime")
  normTs(obj, "_lastSignInTime")
  normTs(obj, "registrationTime")
  normTs(obj, "_registrationTime")
  normTs(obj, "snoozeTimeStart")
  normTs(obj, "_snoozeTimeStart")
  normTs(obj, "snoozeTimeEnd")
  normTs(obj, "_snoozeTimeEnd")
  normTs(obj, "startTime")
  normTs(obj, "_startTime")
  normTs(obj, "timeCreated")
  normTs(obj, "timestamp")
  normTs(obj, "_timestamp")
  normTs(obj, "transmittedToLawfirmAt")
  normTs(obj, "_transmittedToLawfirmAt")
  normTs(obj, "lastPushbackLawFirmAt")
  normTs(obj, "_lastPushbackLawFirmAt")
  normTs(obj, "updatedAt")
  normTs(obj, "_updatedAt")
  normTs(obj, "updatedTime")
  normTs(obj, "_updatedTime")
  normTs(obj, "uploadTime")
  normTs(obj, "_uploadTime")
  normTs(obj, "userCreatedAt")
  normTs(obj, "userRegistrationTime")
  normTs(obj, "_userRegistrationTime")
  normTs(obj, "tlRequestedAt")
  normTs(obj, "_tlRequestedAt")
  normTs(obj, "poaUploadedAt")
  normTs(obj, "_poaUploadedAt")
}


export function checkAndNormTimestamp (value: FirebaseAdminTimestamp) {
  if (value && isObject(value)) {
    // If has valid _seconds and _nanoseconds fields
    if (value._seconds > 0) {
      return new firebase.firestore
        .Timestamp(
          value._seconds,
          value._nanoseconds,
        )
        .toDate()
    }
    // If is has invalid _seconds field
    // return empty string to render correctly in date fields
    else if (value._seconds !== undefined) {
      return ""
    }
    // If is already a Firebase timestamp
    else if (value.toDate) {
      return value.toDate()
    }
  }

  return value
}


export function normTimestamps (data: Record<string, any>) {
  for (const key in data) {
    if (!Object.prototype.hasOwnProperty.call(data, key)) {
      continue
    }
    data[key] = checkAndNormTimestamp(data[key])
  }
  return data
}


export function normTimestampsInList (data: Record<string, any>[]) {
  return data.map(record => {
    for (const key in record) {
      if (!Object.prototype.hasOwnProperty.call(record, key)) {
        continue
      }
      record[key] = checkAndNormTimestamp(record[key])
    }
    return record
  })
}
