import FirebaseTimestamp from "./FirebaseTimestamp"

const documentUploadFields = {
  id: String,
  claimId: String,
  contentEncoding: String,
  contentLanguage: String,
  contentType: String,
  crc32c: String,
  documentType: String,
  etag: String,
  filename: String,
  md5Hash: String,
  size: String,
  timeCreated: String,
  updated: String,
  uploadTime: FirebaseTimestamp,
  _uploadTime: Date,
  userCreatedAt: FirebaseTimestamp,
  _userCreatedAt: Date,
  userEmail: String,
  userId: String,
}

export default documentUploadFields
