import React, {useEffect, useState} from "react"
import { Confirm, useNotify } from "react-admin"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from "@material-ui/core"

import createPhoneCall from "./createPhoneCall"
import getHttpsCallable from "../../../utils/getHttpsCallable"
import {find} from "lodash"
import { PhoneCallResult } from "../../../lib/enums"
import { db } from "../../../firebase"

const sendEmailTemplate = getHttpsCallable("admin-sendEmailTemplate")
const sendWhatsAppTemplate = getHttpsCallable("admin-sendWhatsappTemplate")
const getTemplates = getHttpsCallable("admin-getTemplates")

interface CallAcceptedDialogParams {
  open: boolean
  handleClose: (ticketRef?: any) => void
  ticket: Ticket
}

export default function CallAcceptedDialog (
  { open, handleClose, ticket }: CallAcceptedDialogParams,
) {
  const notify = useNotify()
  const [notes, setNotes] = useState("")
  const [whatsAppTemplates, setWhatsAppTemplates] = useState([])
  const [emailTemplates, setEmailTemplates] = useState([])
  const [fetchData, setFetchData] = useState(true)
  const [cancelingCase, setCancelingCase] = useState<boolean>(false)
  const [claimData, setClaimData] = useState<null|Partial<Claim>>(null)
  const [sendingEmail, setSendingEmail] = useState(false)
  const [emailSendResult, setEmailSendResult] =
    useState<SendEmailTemplateResult | null>(null)
  const [sendingWhatsApp, setSendingWhatsApp] =
    useState(false)
  const [WhatsAppSendResult, setWhatsAppSendResult] =
    useState<{sent: boolean} | null>(null)
  const [cancelCaseDialog, setCancelCaseDialog] =
    useState<boolean>(false)
  const [claimActionStatus, setClaimActionStatus] =
    useState<string | undefined>(undefined)

  useEffect(() => {
    async function getTemplate () {
      const dataWhatsappTemplate = await getTemplates("whatsapp")
      setWhatsAppTemplates(dataWhatsappTemplate?.data)

      const dataEmailTemplate = await getTemplates("email")
      setEmailTemplates(dataEmailTemplate?.data)
    }

    async function getClaimActionStatus () {
      const claims = await db
        .collectionGroup("claims")
        .where("id", "==", ticket.claimId)
        .get()

      if (!claims.size || !claims.docs[0]) {
        console.error(`Claim ${ticket.claimId} not found`)
      }

      const claim = claims.docs[0].data() as Partial<Claim>

      if (claim) {
        setClaimData(claim)
      }

      setClaimActionStatus(claim.claimActionStatus)
    }

    if (fetchData) {
      getTemplate()
      getClaimActionStatus()
      setFetchData(false)
    }
  }, [fetchData])

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          Leave any notes here about the phone call
          or do any of the following actions.
        </DialogContentText>
        <>
          <Button
            fullWidth
            color='primary'
            variant='outlined'
            disabled={
              Boolean(sendingWhatsApp || WhatsAppSendResult?.sent)
            }
            style={{marginBottom: "15px"}}
            onClick={async () => {
              if (WhatsAppSendResult) {
                return
              }

              setSendingWhatsApp(true)
              const selectedTemplate: any = find(
                whatsAppTemplates,
                (t: Template) =>
                  t.name === "oc_tl_anfordern_phonecall_negative",
              )

              if (!selectedTemplate) {
                notify("Error: template not found", "warning")
                setSendingWhatsApp(false)
                return
              }

              const result = await sendWhatsAppTemplate({
                claimIds: [ticket.claimId],
                template: selectedTemplate?.name,
                provider: selectedTemplate?.provider,
                templateLocale: selectedTemplate?.locale,
                placeholders: selectedTemplate?.placeholders ?? [],
              })

              setWhatsAppSendResult(result.data[0])
              setSendingWhatsApp(false)
            }}
          >
            {!WhatsAppSendResult ? (sendingWhatsApp
              ? <CircularProgress size={20} />
              : "Send \"OC: TL anfordern Phonecall negative\" WhatsApp")
              : (WhatsAppSendResult?.sent
                ? "\"OC: TL anfordern Phonecall negative\" WhatsApp Sent"
                : "\"OC: TL anfordern Phonecall negative\"" +
              " WhatsApp Failed to Send")
            }
          </Button>
          <Button
            fullWidth
            color='primary'
            variant='outlined'
            disabled={Boolean(sendingEmail || emailSendResult?.sent)}
            style={{marginBottom: "15px"}}
            onClick={async () => {
              if (emailSendResult) {
                return
              }

              setSendingEmail(true)

              const selectedTemplate: any = find(
                emailTemplates,
                (t: Template) =>
                  t.userDefinedName === "OC: TL anfordern Phonecall negativ",
              )

              if (!selectedTemplate) {
                notify("Error: template not found", "warning")
                setSendingEmail(false)
                return
              }

              const result = await sendEmailTemplate({
                claimIds: [ticket.claimId],
                templateName: selectedTemplate?.name,
              })
              setEmailSendResult(result.data[0])
              setSendingEmail(false)
            }}
          >
            {!emailSendResult ? (sendingEmail
              ? <CircularProgress size={20} />
              : "Send \"OC: TL anfordern Phonecall negativ\" Email")
              : (emailSendResult?.sent
                ? "\"OC: TL anfordern Phonecall negativ\" Email Sent"
                : "\"OC: TL anfordern Phonecall negativ\" Email Failed to Send")
            }
          </Button>
          <Button
            fullWidth
            color='primary'
            variant='outlined'
            disabled={cancelingCase ||
            claimActionStatus === "dead" ||
            claimActionStatus === undefined}
            style={{marginBottom: "15px", color: "red", borderColor: "red"}}
            onClick={ async () => {
              setCancelingCase(true)
              try {
                setClaimActionStatus("dead")

                if (claimData) {
                  const claimRef = db.doc(
                    `users/${claimData.userId}` +
                    `/claims/${claimData.id}`,
                  )
                  return claimRef.update({claimActionStatus: "dead"})
                }

                notify("Claim updated successfully", "success")
              }
              catch (error) {
                console.error(error)
                if (error instanceof Error) {
                  notify(error.message, "warning")
                }
              }
              setCancelingCase(false)
            }}
          >
            {cancelingCase
              ? <CircularProgress size={20} />
              : "Set Canceled by Client"
            }
          </Button>
          <Confirm
            isOpen={cancelCaseDialog}
            title="Set Canceled by Client?"
            content={""}
            confirm="Yes"
            cancel="No"
            onClose={() => setCancelCaseDialog(false)}
            onConfirm={ async () => {
              setCancelCaseDialog(false)
              setCancelingCase(true)
              try {
                setClaimActionStatus("dead")

                if (claimData) {
                  const claimRef = db.doc(
                    `users/${claimData.userId}` +
                  `/claims/${claimData.id}`,
                  )
                  return claimRef.update({claimActionStatus: "dead"})
                }

                notify("Claim updated successfully", "success")
              }
              catch (error) {
                console.error(error)
                if (error instanceof Error) {
                  notify(error.message, "warning")
                }
              }
              setCancelingCase(false)
            }}
          />
        </>
      </DialogContent>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Call Notes'
          fullWidth
          onChange={(e) => setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={async () => {
            try {
              await createPhoneCall({
                claimId: ticket.claimId,
                callResult: PhoneCallResult.ANSWERED,
                phoneNumberValid: true,
                phoneNumber: ticket.claimPhoneNumber,
                callNotes: notes,
              })
              handleClose()
            }
            catch (error) {
              notify(`${error}`, "warning")
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
