import React, { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { Title, useNotify } from "react-admin"
import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import { useTheme } from "@material-ui/styles"

import firebase from "../../firebase"
import getHttpsCallable from "../../utils/getHttpsCallable"


const leadSourceDefault = "CSV-Import"
const importCsv = getHttpsCallable("data-importCsv")

async function importCsvWithCloudFunc (
  csvContent: string,
  leadSource: string,
  isDryRun = true,
) {
  const numRows = (csvContent.match(/\n/g) || "").length
  console.info(`Import a CSV with ${numRows} rows`)

  try {
    const res: Record<string, any> = await importCsv({
      csvContent,
      leadSource,
      isDryRun,
    })

    return res.data
  }
  catch (err: any) {
    throw new Error(err.message)
  }
}


function uploadAndImport (
  file: Blob,
  leadSource: string,
  isDryRun = true,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onabort = () => {
      reject(new Error("File reading was aborted"))
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.onload = () => {
      const csvText = String(reader.result)

      if (csvText) {
        importCsvWithCloudFunc(csvText || "", leadSource, isDryRun)
          .then(result => {
            console.info(result)
            resolve(result)
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      }
      else {
        reject(new Error("No valid CSV file was submitted"))
      }
    }

    reader.readAsText(file)
  })
}


function getDataGrid (
  isImporting: boolean,
  setIsImporting: (val: boolean) => any,
  importIsCompleted: boolean,
  setImportIsCompleted: (val: boolean) => any,
  importedRecords: any,
  setImportedRecords: (records: any) => any,
  notify: any,
  file: Blob | undefined,
  leadSource: string,
) {
  if (isImporting || !importedRecords.length || !file) {
    return
  }

  const columnFields = importedRecords && importedRecords.length
    ? Object
      .keys(importedRecords[0])
      .filter(key =>
        // TODO: Correctly display Firebase Timestamps
        ![
          "claim.id",
          "claim.importedAt",
          "claim.userEmail",  // Duplicate of user.email
          "claim.userId",
          "claim.userRegistrationTime",
          "user.createdAt",
          "user.id",
          "user.importedAt",
          "user.phone",
          "user.registrationTime",
          "user.updatedTime",
        ].includes(key),
      )
    : []

  return (
    <div>
      <div style={{ margin: "4em 0" }}>
        <Typography variant='h6'>
          Following records will be imported:
        </Typography>

        {/*
          TODO: Use when auto columns sizing gets better
          <DataGrid
            rows={importedRecords}
            columns={columns}
            pageSize={20}
            autoHeight={true}
            loading={isImporting || !importedRecords.length}
          />
        */}

        <Table size="small">
          <TableHead>
            <TableRow>
              { columnFields.map(name =>
                <TableCell key={name} align="right">{name}</TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            { importedRecords.map((rec: Record<string, any>) =>
              <TableRow key={rec.id}>
                { columnFields
                  .map((name: string, index) =>
                    <TableCell key={index} align="right">
                      { String(rec[name]) }
                    </TableCell>,
                  )
                }
              </TableRow>,
            )}
          </TableBody>
        </Table>
      </div>

      { !importIsCompleted &&
          <Button
            variant="contained"
            onClick={() => {
              setIsImporting(true)
              uploadAndImport(file, leadSource, false /* Not a dry run */)
                .then(
                  (result: any) => {
                    setIsImporting(false)
                    setImportIsCompleted(true)
                    setImportedRecords(result.records)
                    notify(result.message, "success")
                  },
                )
                .catch((error: any) => {
                  setIsImporting(false)
                  notify(error.message, "error")
                })
            }}
          >
            Import Records
          </Button>
      }
    </div>
  )
}


function CustomDropZone () {
  const [importedRecords, setImportedRecords] = useState([])
  const [isImporting, setIsImporting] = useState(false)
  const [importIsCompleted, setImportIsCompleted] = useState(false)
  const [fileToImport, setFileToImport] = useState()
  const [leadSource, setLeadSource] = useState(leadSourceDefault)
  const notify = useNotify()
  const onDrop = useCallback(
    acceptedFiles => {
      setImportIsCompleted(false)

      if (acceptedFiles.length !== 1) {
        notify(
          "Only importing of single files is currently supported",
          "warning",
        )
      }
      else {
        setFileToImport(acceptedFiles[0])

        try {
          setIsImporting(true)
          uploadAndImport(acceptedFiles[0], leadSource, true).then(
            (result: any) => {
              setIsImporting(false)
              setImportedRecords(result.records)
              notify(result.message, "success")
            },
          )
            .catch((error: any) => {
              setIsImporting(false)
              notify(error.message, "warning")
            })
        }
        catch (error) {
          // @ts-ignore
          notify(error.message, "warning")
        }
      }
    },
    [leadSource],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <TextField
        label='Lead Source'
        id='leadSource'
        defaultValue={leadSourceDefault}
        onChange={(event: any) => {
          setLeadSource(event.target.value)
        }}
        style={{ marginBottom: "2em" }}
        variant="outlined"
      />
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed gray",
          borderRadius: "10px",
          padding: "4em",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <Typography variant='subtitle1' component='p'>
          {
            isDragActive
              ? "Drop the CSV file here ..."
              : "Drag & drop a CSV file here, or click to select a CSV file"
          }
        </Typography>
      </div>

      { isImporting && <p>Loading the CSV …</p> }

      {
        getDataGrid(
          isImporting,
          setIsImporting,
          importIsCompleted,
          setImportIsCompleted,
          importedRecords,
          setImportedRecords,
          notify,
          fileToImport,
          leadSource,
        )
      }
    </>
  )
}


export default function DataImport () {
  return (
    <Card>
      <Title title="Data Import" />
      <CardContent>
        <p>
          This importer currently only supports importing new claims,
          and not updating existing ones.
        </p>
        <p>
          All fields are supported in following 3 spelling variants:{" "}
          <code>field_name</code> or <code>fieldname</code>{" "}
          or <code>fieldName</code>.
        </p>
        <p>Supported claim fields:</p>
        <ul>
          <li>
            <code>created_at</code> or <code>created_time</code>
            <span> (international date format in UTC timezone
            - e.g. "2021-07-22 17:38:42")</span>
          </li>
          <li><code>full_name</code></li>
          <li><code>first_name</code></li>
          <li><code>last_name</code></li>
          <li><code>city</code></li>
          <li>
            <code>postal_code</code> or <code>post_code</code>{" "}
            or <code>zip</code>
          </li>
          <li>
            <code>street</code> or <code>street_address</code>{" "}
            or <code>address</code>
          </li>
          <li><code>e_mail</code> or <code>user_email</code></li>
          <li><code>phone</code> or <code>phone_number</code></li>
          <li>
            <code>is_kap</code>
            <span> (default: <code>true</code>)</span>
          </li>
          <li>
            <code>law_firm</code>
            <span> (default: <code>KAP</code>)</span>
          </li>
          <li>
            <code>lead_source</code>
            <span> (default: <code>{leadSourceDefault}</code>)</span>
          </li>
          <li>
            <code>type</code>
            <span> (mandatory: <code>
              diesel | online-casino | bank-fee-recovery</code>)</span>
          </li>
          <li>
            <code>lead</code>
            <span> (default: <code>false</code>)</span>
          </li>
          <li>
            <code>utm_source</code>
          </li>
          <li>
            <code>utm_medium</code>
          </li>
          <li>
            <code>utm_campaign</code>
          </li>
          <li>
            <code>utm_term</code>
          </li>
          <li>
            <code>utm_content</code>
          </li>
        </ul>

        <hr style={{ margin: "2em 0" }}/>

        <CustomDropZone />
      </CardContent>
    </Card>
  )
}
