import React from "react"


function checkForUrlParams () {
  const url = window.location.href
  if (url.indexOf("access_token=") !== -1) {
    const accessToken = readUrlParam(url, "access_token")
    localStorage.setItem("zauth", accessToken)
    const redirectUrl = localStorage.getItem("zendesk_oauth_complete_url")
    if (redirectUrl) {
      window.location.replace(redirectUrl)
    }
  }
}


function readUrlParam (url: string, param: string) {
  param += "="
  if (url.indexOf(param) !== -1) {
    const start = url.indexOf(param) + param.length
    let value = url.substr(start)
    if (value.indexOf("&") !== -1) {
      const end = value.indexOf("&")
      value = value.substring(0, end)
    }
    return value
  }
  else {
    return ""
  }
}


export default function ZendeskOauth () {
  checkForUrlParams()
  return (
    <div />
  )
}
