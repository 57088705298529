import { transform, isEqual, isObject } from "lodash"

function deepDifference (
  object: Record<string, any>,
  base: Record<string, any>,
) {
  function changes (object: Record<string, any>, base: Record<string, any>) {
    return transform(object, (result: Record<string, any>, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key]))
          ? changes(value, base[key])
          : value
      }
    })
  }
  return changes(object, base)
}

export default deepDifference
