import FirebaseTimestamp from "./FirebaseTimestamp"

const gdprCasesFields = {
  id: String,
  createdAt: FirebaseTimestamp,
  _createdAt: Date,
  updatedAt: FirebaseTimestamp,
  userId: String,
  gdprBreachesIds: Array,
  fullName: String,
  formSubmittedAt: FirebaseTimestamp,
  _formSubmittedAt: Date,
  submittedToLawFirmAt: FirebaseTimestamp,
  _submittedToLawFirmAt: Date,
  insuranceClosingDate: String,
  city: String,
  language: String,
  markForSubmission: Boolean,
  screenshotLegalInsuranceUploaded: { type: Boolean, default: false },
  signatureUploaded: Boolean,
  submissionStatus: { type: Boolean, default: false },
  policeNumber: String,
  insuranceInfoProvided: { type: Boolean, default: false },
  policyHolderName: String,
  postcode: String,
  insuranceName: String,
  street: String,
  type: String,
  utmParams: Object,
  lawFirm: String,
  snoozeTime: Number,
  endSnoozeAt: FirebaseTimestamp,
  _endSnoozeAt: Date,
  cancellationPolicy: String,
  acceptedTocAndDataProtectionAt: FirebaseTimestamp,
  _acceptedTocAndDataProtectionAt: Date,
}

export default gdprCasesFields
