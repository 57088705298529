import React, { useState } from "react"
import { useNotify } from "react-admin"
import { get, pick, startCase, toUpper } from "lodash"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core"
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck"

import { Flex } from "../../lib"
import firebase, { db } from "../../firebase"
import getHttpsCallable from "../../utils/getHttpsCallable"


const checkVinFn = getHttpsCallable("external-vincoder-decodeVin")

const useStyles = makeStyles(theme => ({
  loader: {
    height: "100%",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  inputField: {
    margin: theme.spacing(1, 0),
  },
}))

const FIELDS = [
  "carBrand",
  "carModel",
  "carAuthorizationCode",
  "carMotorDisplacement",
  "carMotorVariant",
  "carMotorKilowatt",
  "carSalePrice",
  "carPurchaseDate",
  "carPurchaseEntity",
  "carPurchasePrice",
  "carPurchaseYear",
  "carPurchasedBeforeRecall",
  "carVariant",
  "carType",
  "carYear",
  "carRecallStatus",
  "hasCarBeenSold",
  "currentMileage",
  "mileageAtPurchase",
  "mileageAtSale",
  "motorType",
  "euroRating",
]



interface VINDialogParams {
  fetching: boolean;
  open: boolean;
  handleClose: () => void;
  data: any;
  vinData: any;
  vinNumber: string;
}

function VINDialog (
  { fetching, open, handleClose, data, vinData, vinNumber }: VINDialogParams,
) {
  const classes = useStyles()
  const [submitResult, setSubmitResult] = useState(pick(data, FIELDS))

  function handleChange (event: any) {
    setSubmitResult({
      submitResult,
      [event.target.name]: event.target.value,
    })
  }

  function updateCarData () {
    const claimRef = db.doc(`users/${data.userId}/claims/${data.id}`)
    console.info(`Updating car data on claim "${data.id}"`)
    return claimRef.update({ ...submitResult })
  }

  if (fetching) {
    return (
      <Dialog
        fullScreen
        open={open}
      >
        <Flex center className={classes.loader}>
          <CircularProgress />
        </Flex>
      </Dialog>
    )
  }

  return (
    <Dialog
      fullScreen
      open={open}
    >
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='overline'>
              Check for VIN: {vinNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography color='textPrimary' variant='subtitle1' gutterBottom>
              Car Information
            </Typography>
            {FIELDS.map(field => (
              <div key={field}>
                <TextField
                  fullWidth
                  id='carBrand'
                  variant='filled'
                  name={field}
                  onChange={handleChange}
                  value={submitResult[field] || ""}
                  label={startCase(field)}
                  placeholder={startCase(field)}
                  className={classes.inputField}
                />
              </div>
            ))}
          </Grid>
          <Grid item xs={6}>
            <Typography color='textPrimary' variant='subtitle1'>
              VIN Check Information
            </Typography>
            <List>
              {get(vinData, "body.decode", [])
                .map((k: {value: any, label: any}) => (
                  <ListItem key={k.value}>
                    <ListItemText primary={k.label} secondary={k.value} />
                  </ListItem>
                ))}
            </List>

          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ margin: 10 }}
          variant='outlined'
          onClick={handleClose}
        >Cancel
        </Button>
        <Button
          style={{ margin: 10 }}
          variant='outlined'
          color='primary'
          onClick={async () => {
            await updateCarData()
            handleClose()
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}



interface CheckVinBtnParams {
  data: any
  [x: string]: any
}

export default function CheckVinButton ({ data, ...props }: CheckVinBtnParams) {
  const [open, setOpen] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [vinData, setVinData] = useState([])
  const notify = useNotify()

  const { vinNumber: VIN, claimId, userId } = data

  return (
    <>
      <VINDialog
        open={open}
        data={data}
        handleClose={() => setOpen(false)}
        vinData={vinData}
        fetching={fetching}
        vinNumber={VIN}
      />
      <Button
        variant='outlined'
        color='primary'
        disabled={!data.vinNumber}
        onClick={async () => {
          setFetching(true)
          setOpen(true)
          try {
            const res = await checkVinFn({
              VIN: toUpper(VIN),
              claimId,
              userId,
            })
            setVinData(res.data)
          }
          catch (error) {
            notify(
              "There was an error obtaining data from the VIN decoder API",
              "warning",
            )
            console.error(error)
          }
          setFetching(false)
        }}
        {...props}
      >
        <PlaylistAddCheckIcon />
        <Hidden mdDown>&nbsp; Check VIN</Hidden>
      </Button>
    </>
  )
}
