import React from "react"
import useStyles from "./styles"
import Flex from "../../../lib/components/Flex"
import DeclinedIcon from "@material-ui/icons/CallEnd"
import PhoneInvalidIcon from "@material-ui/icons/PhoneDisabled"
import PhoneAnsweredIcon from "@material-ui/icons/PhoneInTalk"


export default function CallKey () {
  const classes = useStyles()
  return (
    <Flex alignItems='center' className={classes.key}>
      <PhoneInvalidIcon fontSize='small' className={classes.invalidIcon} />
      &nbsp;= Phone Invalid,
      &nbsp;&nbsp;
      <DeclinedIcon fontSize='small' className={classes.declinedIcon} />
      &nbsp;= Call Declined/Not Answered,
      &nbsp;&nbsp;
      <PhoneAnsweredIcon fontSize='small' className={classes.answeredIcon} />
      &nbsp;= Call Answered
    </Flex>
  )
}
