/* eslint-disable */
import React from "react";

function SvgBmw(props: Record<string, any>) {
  return (
    <svg width={40} height={40} {...props}>
      <defs>
        <radialGradient
          cx="-33.16%"
          cy="-65.129%"
          fx="-33.16%"
          fy="-65.129%"
          r="395.967%"
          id="bmw_svg__i"
        >
          <stop stopColor="#FFF" offset="16.9%" />
          <stop stopColor="#E0EFF7" offset="19.6%" />
          <stop stopColor="#B2D9EC" offset="24.1%" />
          <stop stopColor="#8BC5E2" offset="28.7%" />
          <stop stopColor="#6BB5DA" offset="33.5%" />
          <stop stopColor="#52A9D4" offset="38.4%" />
          <stop stopColor="#41A0CF" offset="43.6%" />
          <stop stopColor="#369BCD" offset="49.1%" />
          <stop stopColor="#39C" offset="55.6%" />
          <stop stopColor="#3396C8" offset="60.6%" />
          <stop stopColor="#328EBC" offset="65.5%" />
          <stop stopColor="#3180A8" offset="70.5%" />
          <stop stopColor="#2F6D8C" offset="75.4%" />
          <stop stopColor="#2D5468" offset="80.3%" />
          <stop stopColor="#2A373D" offset="85.1%" />
          <stop stopColor="#292929" offset="87.1%" />
        </radialGradient>
        <radialGradient
          cx="-390.03%"
          cy="-65.138%"
          fx="-390.03%"
          fy="-65.138%"
          r="1359.379%"
          gradientTransform="matrix(.87272 0 0 1 -.496 0)"
          id="bmw_svg__d"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#FDFDFD" offset="31%" />
          <stop stopColor="#F6F6F6" offset="45.1%" />
          <stop stopColor="#E9E9E9" offset="55.7%" />
          <stop stopColor="#D7D7D7" offset="64.6%" />
          <stop stopColor="#BFBFBF" offset="72.4%" />
          <stop stopColor="#A2A2A2" offset="79.4%" />
          <stop stopColor="gray" offset="85.9%" />
          <stop stopColor="#575757" offset="92%" />
          <stop stopColor="#2B2B2B" offset="97.5%" />
          <stop stopColor="#141414" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="-485.273%"
          cy="-103.158%"
          fx="-485.273%"
          fy="-103.158%"
          r="965.799%"
          gradientTransform="matrix(1 0 0 .98452 0 -.016)"
          id="bmw_svg__e"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#FDFDFD" offset="31%" />
          <stop stopColor="#F6F6F6" offset="45.1%" />
          <stop stopColor="#E9E9E9" offset="55.7%" />
          <stop stopColor="#D7D7D7" offset="64.6%" />
          <stop stopColor="#BFBFBF" offset="72.4%" />
          <stop stopColor="#A2A2A2" offset="79.4%" />
          <stop stopColor="gray" offset="85.9%" />
          <stop stopColor="#575757" offset="92%" />
          <stop stopColor="#2B2B2B" offset="97.5%" />
          <stop stopColor="#141414" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="-141.319%"
          cy="-113.478%"
          fx="-141.319%"
          fy="-113.478%"
          r="986.884%"
          gradientTransform="matrix(1 0 0 .99965 0 0)"
          id="bmw_svg__f"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#FDFDFD" offset="31%" />
          <stop stopColor="#F6F6F6" offset="45.1%" />
          <stop stopColor="#E9E9E9" offset="55.7%" />
          <stop stopColor="#D7D7D7" offset="64.6%" />
          <stop stopColor="#BFBFBF" offset="72.4%" />
          <stop stopColor="#A2A2A2" offset="79.4%" />
          <stop stopColor="gray" offset="85.9%" />
          <stop stopColor="#575757" offset="92%" />
          <stop stopColor="#2B2B2B" offset="97.5%" />
          <stop stopColor="#141414" offset="100%" />
        </radialGradient>
        <radialGradient
          cx="-3.29%"
          cy=".85%"
          fx="-3.29%"
          fy=".85%"
          r="74.777%"
          gradientTransform="matrix(1 0 0 .99999 0 0)"
          id="bmw_svg__g"
        >
          <stop stopColor="#E6E6E6" offset="0%" />
          <stop stopColor="#D6D6D6" offset="10.4%" />
          <stop stopColor="#ADADAD" offset="30.7%" />
          <stop stopColor="#6C6C6C" offset="58.8%" />
          <stop stopColor="#121212" offset="93.3%" />
          <stop offset="100%" />
        </radialGradient>
        <radialGradient
          cx="16.19%"
          cy="9.75%"
          fx="16.19%"
          fy="9.75%"
          r="111.963%"
          id="bmw_svg__h"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#FDFDFD" offset="34.4%" />
          <stop stopColor="#F6F6F6" offset="48.1%" />
          <stop stopColor="#EAEAEA" offset="58.2%" />
          <stop stopColor="#D8D8D8" offset="66.5%" />
          <stop stopColor="#C2C2C2" offset="73.7%" />
          <stop stopColor="#A6A6A6" offset="80.2%" />
          <stop stopColor="#848484" offset="86%" />
          <stop stopColor="#5F5F5F" offset="91.3%" />
          <stop stopColor="#404040" offset="94.9%" />
          <stop stopColor="#404040" offset="95.7%" />
          <stop stopColor="#404040" offset="100%" />
          <stop offset="100%" />
        </radialGradient>
        <radialGradient
          cx="-3.29%"
          cy=".85%"
          fx="-3.29%"
          fy=".85%"
          r="74.777%"
          gradientTransform="matrix(1 0 0 .99999 0 0)"
          id="bmw_svg__b"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#A8A8A8" offset="30.6%" />
          <stop stopColor="#4F4F4F" offset="64.6%" />
          <stop stopColor="#161616" offset="88.5%" />
          <stop offset="100%" />
        </radialGradient>
        <radialGradient
          cx="-133.16%"
          cy="-165.131%"
          fx="-133.16%"
          fy="-165.131%"
          r="395.959%"
          id="bmw_svg__j"
        >
          <stop stopColor="#FFF" offset="16.9%" />
          <stop stopColor="#E0EFF7" offset="19.6%" />
          <stop stopColor="#B2D9EC" offset="24.1%" />
          <stop stopColor="#8BC5E2" offset="28.7%" />
          <stop stopColor="#6BB5DA" offset="33.5%" />
          <stop stopColor="#52A9D4" offset="38.4%" />
          <stop stopColor="#41A0CF" offset="43.6%" />
          <stop stopColor="#369BCD" offset="49.1%" />
          <stop stopColor="#39C" offset="55.6%" />
          <stop stopColor="#3396C8" offset="60.6%" />
          <stop stopColor="#328EBC" offset="65.5%" />
          <stop stopColor="#3180A8" offset="70.5%" />
          <stop stopColor="#2F6D8C" offset="75.4%" />
          <stop stopColor="#2D5468" offset="80.3%" />
          <stop stopColor="#2A373D" offset="85.1%" />
          <stop stopColor="#292929" offset="87.1%" />
        </radialGradient>
        <radialGradient
          cx="-3.29%"
          cy=".85%"
          fx="-3.29%"
          fy=".85%"
          r="74.777%"
          gradientTransform="matrix(1 0 0 .99998 0 0)"
          id="bmw_svg__k"
        >
          <stop stopColor="#E6E6E6" offset="0%" />
          <stop stopColor="#D6D6D6" offset="10.4%" />
          <stop stopColor="#ADADAD" offset="30.7%" />
          <stop stopColor="#6C6C6C" offset="58.8%" />
          <stop stopColor="#121212" offset="93.3%" />
          <stop offset="100%" />
        </radialGradient>
        <linearGradient
          x1="-7.319%"
          y1="-5.389%"
          x2="98.77%"
          y2="97.127%"
          id="bmw_svg__a"
        >
          <stop stopColor="#DFF4FF" offset="0%" />
          <stop stopColor="#D6EAF5" offset="9.8%" />
          <stop stopColor="#BFD0D9" offset="26.8%" />
          <stop stopColor="#98A5AC" offset="48.8%" />
          <stop stopColor="#646B6E" offset="74.7%" />
          <stop stopColor="#2B2B2B" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="107.32%"
          y1="105.388%"
          x2="1.23%"
          y2="2.874%"
          id="bmw_svg__c"
        >
          <stop stopColor="#DFF4FF" offset="0%" />
          <stop stopColor="#D6EAF5" offset="8.6%" />
          <stop stopColor="#BED0D9" offset="23.3%" />
          <stop stopColor="#96A4AC" offset="42.4%" />
          <stop stopColor="#5F686D" offset="65.2%" />
          <stop stopColor="#1A1D1E" offset="90.7%" />
          <stop offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-21.978%"
          y1="-37.705%"
          x2="128.032%"
          y2="107.25%"
          id="bmw_svg__l"
        >
          <stop stopColor="#F0F4FF" offset="0%" />
          <stop stopColor="#EBEFF9" offset="10.9%" />
          <stop stopColor="#DCE0EA" offset="24.7%" />
          <stop stopColor="#C4C7CF" offset="40.3%" />
          <stop stopColor="#A2A4AB" offset="57%" />
          <stop stopColor="#76777C" offset="74.7%" />
          <stop stopColor="#414243" offset="92.9%" />
          <stop stopColor="#2B2B2B" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-40.12%"
          y1="-18.937%"
          x2="109.892%"
          y2="126.024%"
          id="bmw_svg__m"
        >
          <stop stopColor="#F0F4FF" offset="0%" />
          <stop stopColor="#EBEFF9" offset="10.9%" />
          <stop stopColor="#DCE0EA" offset="24.7%" />
          <stop stopColor="#C4C7CF" offset="40.3%" />
          <stop stopColor="#A2A4AB" offset="57%" />
          <stop stopColor="#76777C" offset="74.7%" />
          <stop stopColor="#414243" offset="92.9%" />
          <stop stopColor="#2B2B2B" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="32.516%"
          y1="33.103%"
          x2="110.222%"
          y2="108.198%"
          id="bmw_svg__n"
        >
          <stop stopColor="#C2D7E8" offset="0%" />
          <stop stopColor="#BACFE1" offset="13.4%" />
          <stop stopColor="#A4BACD" offset="34.3%" />
          <stop stopColor="#8098AC" offset="60%" />
          <stop stopColor="#4E697F" offset="89.4%" />
          <stop stopColor="#3A566D" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-7.292%"
          y1="-52.539%"
          x2="142.57%"
          y2="91.985%"
          id="bmw_svg__o"
        >
          <stop stopColor="#F0F4FF" offset="0%" />
          <stop stopColor="#E8EBF6" offset="9.7%" />
          <stop stopColor="#D1D4DD" offset="25.7%" />
          <stop stopColor="#ABAEB5" offset="45.9%" />
          <stop stopColor="#78797D" offset="69.5%" />
          <stop stopColor="#363637" offset="95.8%" />
          <stop stopColor="#2B2B2B" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-.836%"
          y1=".873%"
          x2="51.835%"
          y2="51.774%"
          id="bmw_svg__p"
        >
          <stop stopColor="#D9DEF0" offset="0%" />
          <stop stopColor="#D4DAEC" offset="12.3%" />
          <stop stopColor="#C7CFE2" offset="26.3%" />
          <stop stopColor="#B0BCD1" offset="41.1%" />
          <stop stopColor="#90A1B8" offset="56.6%" />
          <stop stopColor="#677F99" offset="72.5%" />
          <stop stopColor="#355674" offset="88.5%" />
          <stop stopColor="#173D5D" offset="97.2%" />
        </linearGradient>
        <linearGradient
          x1="-54.724%"
          y1="-3.817%"
          x2="95.281%"
          y2="141.141%"
          id="bmw_svg__q"
        >
          <stop stopColor="#F0F4FF" offset="0%" />
          <stop stopColor="#EBEFF9" offset="10.9%" />
          <stop stopColor="#DCE0EA" offset="24.7%" />
          <stop stopColor="#C4C7CF" offset="40.3%" />
          <stop stopColor="#A2A4AB" offset="57%" />
          <stop stopColor="#76777C" offset="74.7%" />
          <stop stopColor="#414243" offset="92.9%" />
          <stop stopColor="#2B2B2B" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M19.994 0C8.952 0 0 8.951 0 19.994c0 11.042 8.952 19.994 19.994 19.994 11.043 0 19.995-8.952 19.995-19.994C39.989 8.951 31.038 0 19.994 0zm0 38.988c-10.49 0-18.993-8.504-18.993-18.994S9.504.999 19.994.999C30.485 1 38.99 9.504 38.99 19.994s-8.504 18.994-18.995 18.994z"
          fill="url(#bmw_svg__a)"
        />
        <path
          d="M39.132 19.994c0 10.569-8.569 19.137-19.138 19.137C9.426 39.131.857 30.563.857 19.994.857 9.424 9.426.857 19.994.857c10.57 0 19.138 8.568 19.138 19.137z"
          fill="#333"
        />
        <path
          d="M38.989 19.994c0 10.49-8.504 18.994-18.995 18.994-10.49 0-18.993-8.504-18.993-18.994S9.504.999 19.994.999C30.485 1 38.99 9.504 38.99 19.994z"
          fill="url(#bmw_svg__b)"
        />
        <path
          d="M32.729 19.994c0 7.033-5.702 12.734-12.734 12.734-7.034 0-12.735-5.701-12.735-12.734S12.961 7.26 19.995 7.26c7.032 0 12.734 5.7 12.734 12.734z"
          fill="url(#bmw_svg__c)"
        />
        <path
          d="M20.505 6.916l1.322-3.506v3.506h1.005V1.894h-1.51L19.95 5.49h.013l-1.373-3.595h-1.51v5.022h1.004V3.41l1.323 3.506h1.099zm13.787 2.251l-1.716 2.503 2.696-1.326.731.877-4.243 2.13-.857-1.038 1.644-2.48-.01-.012-2.735 1.171-.868-1.03 2.862-3.79.731.878-1.791 2.412 2.772-1.236.784.941z"
          fill="#000"
        />
        <path
          d="M11.102 8.208c-.405-.393-1.029-.511-1.615-.147.112-.29.066-.587.007-.75-.253-.46-.342-.519-.548-.693-.675-.565-1.384-.083-1.89.521l-2.451 2.92 3.852 3.232 2.584-3.079c.59-.703.681-1.4.06-2.004zM6.119 9.616L7.59 7.862c.171-.203.557-.195.804.014.273.228.273.552.09.77L7.03 10.38l-.91-.764zm4.06.292l-1.534 1.828-.961-.807L9.23 9.086c.175-.208.564-.269.827-.048.296.249.325.627.122.87z"
          fill="#333"
        />
        <path
          fill="url(#bmw_svg__d)"
          d="M20.515 6.721l1.323-3.505V6.72h1.005v-5.02h-1.51l-1.374 3.594h.014L18.599 1.7h-1.51v5.021h1.005V3.216l1.323 3.505z"
        />
        <path
          fill="url(#bmw_svg__e)"
          d="M34.302 8.83l-1.716 2.503 2.697-1.326.73.877-4.243 2.13-.857-1.038 1.645-2.48-.01-.012-2.737 1.171-.866-1.031 2.861-3.788.732.877-1.792 2.412 2.772-1.236z"
        />
        <path
          d="M10.922 8.109c-.404-.393-1.029-.511-1.614-.148.111-.29.066-.586.006-.749-.253-.46-.34-.518-.549-.693-.674-.566-1.383-.083-1.889.522L4.425 9.96l3.852 3.231 2.584-3.079c.59-.704.682-1.4.06-2.004zM5.939 9.517L7.41 7.764c.17-.204.555-.196.803.012.273.23.274.553.09.771l-1.455 1.734-.91-.764zM10 9.81l-1.534 1.828-.963-.807L9.05 8.987c.175-.208.564-.269.826-.048.298.25.328.627.124.87z"
          fill="url(#bmw_svg__f)"
        />
        <path
          d="M7.684 19.994c0-6.799 5.512-12.31 12.31-12.31 6.8 0 12.311 5.511 12.311 12.31 0 6.8-5.511 12.31-12.31 12.31-6.8 0-12.311-5.511-12.311-12.31z"
          fill="url(#bmw_svg__g)"
        />
        <path
          d="M8.038 19.994c0-6.603 5.353-11.957 11.957-11.957 6.603 0 11.957 5.354 11.957 11.957 0 6.604-5.354 11.957-11.957 11.957-6.604 0-11.957-5.353-11.957-11.957z"
          fill="url(#bmw_svg__h)"
        />
        <path
          d="M8.02 19.994h11.974V8.02C13.38 8.019 8.02 13.38 8.02 19.994z"
          fill="url(#bmw_svg__i)"
        />
        <path
          d="M19.994 19.994V31.97c6.615 0 11.976-5.363 11.976-11.976H19.994z"
          fill="url(#bmw_svg__j)"
        />
        <path
          fill="url(#bmw_svg__k)"
          d="M32.069 19.781H20.207V7.92h-.425v11.862H7.92v.425h11.862V32.07h.425V20.206h11.862z"
        />
        <path
          d="M20.207 8.255c6.317.112 11.415 5.21 11.528 11.526h.211c-.113-6.434-5.305-11.626-11.74-11.739v.213z"
          fill="url(#bmw_svg__l)"
        />
        <path
          d="M8.234 19.78c.112-6.326 5.217-11.43 11.542-11.543v-.212c-6.443.113-11.641 5.312-11.754 11.755h.212z"
          fill="#C2D7E8"
        />
        <path
          d="M19.782 31.733c-6.317-.112-11.415-5.21-11.527-11.527h-.212c.113 6.434 5.305 11.627 11.74 11.74v-.213z"
          fill="url(#bmw_svg__m)"
        />
        <path
          d="M31.735 20.206c-.113 6.317-5.211 11.415-11.528 11.527v.213c6.434-.113 11.627-5.306 11.74-11.74h-.212z"
          fill="#12404F"
        />
        <path
          fill="url(#bmw_svg__n)"
          d="M19.555 8.025v11.523H8.032v.228h11.75V8.025z"
        />
        <path
          fill="url(#bmw_svg__o)"
          d="M19.536 31.834V20.45H8.127v-.224H19.76v11.61z"
        />
        <path
          fill="url(#bmw_svg__p)"
          d="M20.45 31.834V20.45h11.385v-.224H20.226v11.61z"
        />
        <path
          fill="url(#bmw_svg__q)"
          d="M20.45 8.127V19.51h11.385v.225H20.226V8.126z"
        />
      </g>
    </svg>
  );
}

export default SvgBmw;
