import styled from 'styled-components'
import media from '../../utils/media'

export default styled.h3`
  font-family: grad, serif;
  font-style: normal;
  font-weight: bold;
  color: ${props =>
    // @ts-expect-error
    props.light ? '#e2e9fb' : '#161466'
  };
  text-align: ${props =>
    // @ts-expect-error
    props.center ? 'center' : 'inherit'
  }
  ${media.only.xs`
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.5px;
  `}
  ${media.only.sm`
    font-size: 32px;
    line-height: 1.25;
    letter-spacing: 0.5px;
  `}
  ${media.only.md`
    font-size: 40px;
    line-height: normal;
    letter-spacing: 0.8px;
  `}
  ${media.only.lg`
    font-size: 40px;
    line-height: normal;
    letter-spacing: 0.8px;
  `}
  ${media.only.xl`
    font-size: 48px;
    line-height: normal;
    letter-spacing: 0.8px;
  `}
`
