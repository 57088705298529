import React, { useState, useCallback, useRef } from "react"
import { useDropzone } from "react-dropzone"
import { Title, useNotify } from "react-admin"
import {
  Button,
  Card,
  CardContent,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import getHttpsCallable from "../../utils/getHttpsCallable"
import { LawFirmOptions } from "../../constants/lawFirms"


const leadSourceDefault = "Lead-Import"
const lawFirmDefault = "KAP"
const importExcel = getHttpsCallable("admin-leadImport")

async function importExcelWithCloudFunc (
  base64file: string,
  leadSource: string,
  lawFirm: string,
  isDryRun = true,
) {
  try {
    const res: Record<string, any> = await importExcel({
      base64file,
      leadSource,
      lawFirm,
      isDryRun,
    })

    if (res.data && res.data.error) {
      throw new Error(res.data.error.message)
    }

    return res.data
  }
  catch (err: any) {
    throw new Error(err.message)
  }
}


function uploadAndImport (
  file: Blob,
  leadSource: string,
  lawFirm: string,
  isDryRun = true,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onabort = () => {
      reject(new Error("File reading was aborted"))
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.onload = (e) => {
      let encoded = reader.result?.toString().replace(/^data:(.*,)?/, "")

      if (encoded && (encoded.length % 4) > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4))
      }

      if (encoded) {
        importExcelWithCloudFunc(encoded || "", leadSource, lawFirm, isDryRun)
          .then(result => {
            console.info(result)
            resolve(result)
          })
          .catch(error => {
            console.error(error)
            reject(error)
          })
      }
      else {
        reject(new Error("No valid Excel file was submitted"))
      }
    }

    reader.readAsDataURL(file)
  })
}


function getDataGrid (
  isImporting: boolean,
  setIsImporting: (val: boolean) => any,
  importIsCompleted: boolean,
  setImportIsCompleted: (val: boolean) => any,
  importedRecords: any,
  setImportedRecords: (records: any) => any,
  notify: any,
  file: Blob | undefined,
  leadSource: string,
  lawFirm: string,
) {
  if (isImporting || !importedRecords.length || !file) {
    return
  }

  const columnFields = importedRecords && importedRecords.length
    ? Object
      .keys(importedRecords[0])
      .filter(key =>
        // TODO: Correctly display Firebase Timestamps
        ![
          "claim.id",
          "claim.importedAt",
          "claim.userEmail",  // Duplicate of user.email
          "claim.userId",
          "claim.userRegistrationTime",
          "user.createdAt",
          "user.id",
          "user.importedAt",
          "user.phone",
          "user.registrationTime",
          "user.updatedTime",
        ].includes(key),
      )
    : []

  return (
    <div>
      <div style={{ margin: "4em 0" }}>
        <Typography variant="h6">
          Following records will be imported:
        </Typography>

        {/*
          TODO: Use when auto columns sizing gets better
          <DataGrid
            rows={importedRecords}
            columns={columns}
            pageSize={20}
            autoHeight={true}
            loading={isImporting || !importedRecords.length}
          />
        */}

        <Table size="small">
          <TableHead>
            <TableRow>
              { columnFields.map(name =>
                <TableCell key={name} align="right">{name}</TableCell>)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            { importedRecords.map((rec: Record<string, any>) =>
              <TableRow key={rec.id}>
                { columnFields
                  .map((name: string, index) =>
                    <TableCell key={index} align="right">
                      { String(rec[name]) }
                    </TableCell>,
                  )
                }
              </TableRow>,
            )}
          </TableBody>
        </Table>
      </div>

      { !importIsCompleted &&
          <Button
            variant="contained"
            onClick={() => {
              setIsImporting(true)
              uploadAndImport(file, leadSource, lawFirm, false)
                .then(
                  (result: any) => {
                    setIsImporting(false)
                    setImportIsCompleted(true)
                    setImportedRecords(result.records)
                    notify(result.message, "success")
                  },
                )
                .catch((error: any) => {
                  setIsImporting(false)
                  notify(error.message, "error")
                })
            }}
          >
            Import Records
          </Button>
      }
    </div>
  )
}


function CustomDropZone () {
  const [importedRecords, setImportedRecords] = useState([])
  const [isImporting, setIsImporting] = useState(false)
  const [importIsCompleted, setImportIsCompleted] = useState(false)
  const [fileToImport, setFileToImport] = useState()
  const [leadSource, setLeadSource] = useState(leadSourceDefault)
  const lawFirm = useRef(lawFirmDefault)
  const notify = useNotify()
  const onDrop = useCallback(
    acceptedFiles => {
      setImportIsCompleted(false)

      if (acceptedFiles.length !== 1) {
        notify(
          "Only importing of single files is currently supported",
          "warning",
        )
      }
      else {
        setFileToImport(acceptedFiles[0])

        try {
          setIsImporting(true)
          uploadAndImport(acceptedFiles[0], leadSource, lawFirm.current, true)
            .then(
              (result: any) => {
                setIsImporting(false)
                setImportedRecords(result.records)
                notify(result.message, "success")
              },
            )
            .catch((error: any) => {
              setIsImporting(false)
              notify(error.message, "warning")
            })
        }
        catch (error) {
          // @ts-ignore
          notify(error.message, "warning")
        }
      }
    },
    [leadSource],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "2em",
        }}
      >
        <TextField
          label="Lead Source"
          id="leadSource"
          defaultValue={leadSourceDefault}
          onChange={(event: any) => {
            setLeadSource(event.target.value)
          }}
          style={{ marginBottom: "2em" }}
          variant="outlined"
        />
        <TextField
          select
          variant="outlined"
          id="lawFirm"
          label="Law Firm"
          defaultValue={lawFirmDefault}
          onChange={(event: any) => {
            lawFirm.current = event.target.value
          }}
        >
          {LawFirmOptions.map(lawFirm => {
            return (
              <MenuItem key={lawFirm.id} value={lawFirm.id}>
                {lawFirm.name}
              </MenuItem>
            )
          })}
        </TextField>
      </div>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed gray",
          borderRadius: "10px",
          padding: "4em",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="subtitle1" component="p">
          {
            isDragActive
              ? "Drop the Excel file here ..."
              : "Drag & drop an Excel file here, " + 
                "or click to select an Excel file"
          }
        </Typography>
      </div>

      { isImporting && <p>Loading the Excel File …</p> }

      {
        getDataGrid(
          isImporting,
          setIsImporting,
          importIsCompleted,
          setImportIsCompleted,
          importedRecords,
          setImportedRecords,
          notify,
          fileToImport,
          leadSource,
          lawFirm.current,
        )
      }
    </>
  )
}


export default function LeadImport () {
  return (
    <Card>
      <Title title="Data Import" />
      <CardContent>
        <p>
          This importer currently only supports importing new online casino
          claims and users, and not updating existing ones.
        </p>
        <p>
          The field will be extracted by the columns and 
          first row will be skipped (data starts on the second row).
          These Columns are:
        </p>
        <ul>
          <li>A: <code>Date</code></li>
          <li>B: <code>Time</code></li>
          <li>E: <code>First Name</code></li>
          <li>G: <code>Last Name</code></li>
          <li>H: <code>Estimated Losses</code></li>
          <li>I: <code>Casino Name</code> separated by `|`</li>
          <li>M: <code>Telephone</code></li>
          <li>N: <code>Email</code></li>
          <li>O: <code>POA Uploaded</code> if this set to `Vollmacht erhalten`
          then POA uploaded will be set to true</li>
        </ul>

        <hr style={{ margin: "2em 0" }}/>

        <CustomDropZone />
      </CardContent>
    </Card>
  )
}
