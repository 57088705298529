import fakeRest from "ra-data-fakerest"
import { default as cas } from "casual-browserify"
import { TICKET_ASSIGNEES } from "../lib/constants"
import firebase from "firebase/app"
import { normTimestamps } from "../utils/normalizeTimestamps"

cas.seed(123)


// Define more primitives

cas.define("short_id", () => {
  return cas._uuid().slice(0, 8)
})

cas.define("assigned_group", () => {
  return cas.random_value(TICKET_ASSIGNEES)
})

// cas.define('firebase_timestamp', () => {
//   return firebase.firestore.Timestamp.fromDate(new Date())
// })

cas.define("js_date", () => {
  return cas.moment.toDate()
})

cas.define("case", () => {
  return {
    id: (cas as any).short_id,
  }
})

cas.define("array_of_tags", () => {
  const tagNames = cas.array_of_words(3)
  return tagNames.map(name => ({
    id: name,
  }))
})

cas.define("custom_field_values", () => {
  const keys = cas.array_of_words(3)
  return Object.fromEntries(
    keys.map(key => [key, { value: cas._word() }]),
  )
})


cas.define("claim", () => {
  return {
    id: (cas as any).short_id,
    acceptedTermsAndConditions: cas.boolean,
    address: cas.address,
    applicationApproved: cas.boolean,
    applicationApprovedByUser: cas.integer(0),
    applicationApprovedTime: cas.string,
    _applicationApprovedTime: (cas as any).js_date,
    calculatedAward: cas.integer(0),
    brcMultipleCars: cas.boolean,
    carAuthorizationCode: cas.string,
    carBrand: cas.word,
    carIsTop: cas.boolean,
    carModel: cas.word,
    carMotorDisplacement: cas.string,
    carMotorVariant: cas.word,
    carMotorKilowatt: cas.integer(0),
    carSalePrice: cas.integer(0),
    carPurchaseDate: cas.string,
    carPurchaseEntity: cas.string,
    carPurchasePrice: cas.integer(0),
    carPurchaseYear: cas.integer(0),
    carPurchasedBeforeRecall: cas.boolean,
    carVariant: cas.string,
    carType: cas.string,
    carYear: cas.integer(0),
    carRecallStatus: cas.string,
    claimValue: cas.integer(0),
    currentMileage: cas.integer(0),
    city: cas.city,
    claimActionStatus: cas.word,
    claimPassesMinimum: cas.boolean,
    claimVerifiedExternally: cas.boolean,
    claimActionStatusUpdateTime: cas.string,
    _claimActionStatusUpdateTime: (cas as any).js_date,
    companyName: cas.company_name,
    createdTime: cas.string,
    _createdTime: (cas as any).js_date,
    customFieldValues: (cas as any).custom_field_values,
    euroRating: cas.string,
    firstName: cas.first_name,
    hasCarBeenFinanced: cas.boolean,
    hasCarBeenSold: cas.boolean,
    hasEcCard: cas.boolean,
    hasPowerOfAttorneyDoc: cas.boolean,
    hasUploadedFinanceContract: cas.boolean,
    hasUploadedPurchaseContract: cas.boolean,
    hasUploadedSalesContract: cas.boolean,
    highValueInsuranceProvider: cas.boolean,
    iban: cas.string,
    importedAt: cas.string,
    insuranceProvider: cas.string,
    isEligible: cas.boolean,
    isFinancingPaidOff: cas.string,
    kapEmailUrl: cas.string,
    keepCarReward: cas.integer(0),
    kvCorrect: cas.boolean,
    kvDataComplete: cas.boolean,
    kvWrongDocReason: cas.string,
    dieselWarning: cas.string,
    kvReadable: cas.boolean,
    lastName: cas.last_name,
    lawFirm: cas.word,
    lawFirmCaseId: cas.word,
    legalExpensesInsurance: cas.string,
    legalExpensesInsuranceAtPurchase: cas.string,
    legalExpensesInsurancePolicyAsCompany: cas.string,
    legalExpensesInsurancePolicyNumber: cas.string,
    legalExpensesInsurancePolicyholderCompanyName: cas.company_name,
    legalExpensesInsurancePolicyholderFirstName: cas.first_name,
    legalExpensesInsurancePolicyholderLastName: cas.last_name,
    maxReward: cas.integer(0),
    mileageAtPurchase: cas.integer(0),
    mileageAtSale: cas.integer(0),
    motorType: cas.string,
    notes: cas.string,
    ocTipicoBigList: cas.boolean,
    participatedInClassAction: cas.string,
    phoneNumber: cas.phone,
    postcode: cas.zip,
    purchaseContractCompanyName: cas.company_name,
    purchaseContractFirstName: cas.first_name,
    purchaseContractLastName: cas.last_name,
    purchaseContractOnHand: cas.string,
    returnCarInCaseOfSuccess: cas.string,
    returnCarReward: cas.integer(0),
    tags: (cas as any).array_of_tags,
    transmittedToLawfirmAt: (cas as any).js_date,  // From firebase_timestamp
    _transmittedToLawfirmAt: (cas as any).js_date,
    lastPushbackLawFirmAt: (cas as any).js_date,  // From firebase_timestamp
    _lastPushbackLawFirmAt: (cas as any).js_date,
    type: cas.string,
    updatedTime: (cas as any).js_date,
    _updatedTime: (cas as any).js_date,
    userEmail: cas.email,
    userId: (cas as any).short_id,
    userIsRegistered: cas.boolean,
    userRegistrationTime: (cas as any).js_date,
    _userRegistrationTime: (cas as any).js_date,
    vinCheck: cas.string,
    vinNumber: cas.string,
    wouldHavePurchasedVehicle: cas.boolean,
    whatsappContactedNumber: cas.phone,
    casinoFormFinishedAt: cas.string,
    utmParams: (cas as any).utm_params,
    hasMultipleClaims: cas.boolean,
    tlUploaded: cas.boolean,
    idUploaded: cas.boolean,
    accInfoUploaded: cas.boolean,
    poaUploaded: cas.boolean,
    tlRequested: cas.boolean,
    lead: cas.boolean,
    tlRequestedAt: cas.string,
    _tlRequestedAt: (cas as any).js_date,
    poaUploadedAt: cas.string,
    _poaUploadedAt: (cas as any).js_date,
    funder: cas.string,
    reRegistrationCount: cas.integer(0),
  }
})

cas.define("user", () => {
  return {
    id: (cas as any).short_id,
    claimId: (cas as any).short_id,
    userId: (cas as any).short_id,
    email: cas.email,
    emailVerified: cas.boolean,
    hasCompletedRegistration: cas.boolean,
    isAnonymous: cas.boolean,
    phoneNumber: cas.phone,
    phone: cas.phone,
    photoUrl: cas.url,
    createdAt: (cas as any).js_date,  // Converted from firebase_timestamp
    _createdAt: (cas as any).js_date,
    lastSignInTime: (cas as any).js_date,  // Converted from firebase_timestamp
    _lastSignInTime: (cas as any).js_date,
    updatedTime: (cas as any).js_date,  // Converted from firebase_timestamp
    _updatedTime: (cas as any).js_date,
    registrationTime: (cas as any).js_date, // Converted from firebase_timestamp
    _registrationTime: (cas as any).js_date,
  }
})

cas.define("ticket", () => {
  return {
    id: (cas as any).short_id,
    claimId: (cas as any).short_id,
    createdAt: (cas as any).js_date,  // Converted from firebase_timestamp
    _createdAt: (cas as any).js_date,
    updatedAt: (cas as any).js_date,  // Converted from firebase_timestamp
    _updatedAt: (cas as any).js_date,
    snoozeTimeStart: (cas as any).js_date,  // Converted from firebase_timestamp
    _snoozeTimeStart: (cas as any).js_date,
    snoozeTimeEnd: (cas as any).js_date,  // Converted from firebase_timestamp
    _snoozeTimeEnd: (cas as any).js_date,
    phoneNumberValid: cas.boolean,
    assignedGroup: (cas as any).assigned_group,
  }
})

cas.define("document_upload", () => {
  return {
    id: (cas as any).short_id,
    claimId: (cas as any).short_id,
    contentEncoding: "utf8",
    contentLanguage: "de",
    contentType: cas.mime_type,
    crc32c: cas.word,
    documentType: cas.random_element([
      "purchaseContract",
      "salesContract",
      "financeContract",
    ]),
    etag: (cas as any).short_id,
    filename: cas.populate("{{word}}.pdf"),
    md5Hash: (cas as any).short_id,
    size: cas.integer(0),
    timeCreated: (cas as any).js_date,
    updated: (cas as any).js_date,
    uploadTime: (cas as any).js_date,
    _uploadTime: (cas as any).js_date,
    userCreatedAt: (cas as any).js_date,
    _userCreatedAt: (cas as any).js_date,
    userEmail: cas.email,
    userId: (cas as any).short_id,
  }
})

cas.define("email_entry", () => {
  return {
    id: (cas as any).short_id,
    body: cas.sentence,
    claimId: (cas as any).short_id,
    email: cas.email,
    provider: cas.word,
    providerId: (cas as any).short_id,
    metadata: {
      meta: true,
    },
    sender: cas.email,
    state: cas.random_element(["sent"]),
    subject: cas.sentence,
    // TODO: Move list of email templates to lib and use here
    template: cas.word,
    timestamp: (cas as any).js_date,
    _timestamp: (cas as any).js_date,
    type: cas.random_element(["send"]),
    userId: (cas as any).short_id,
  }
})

cas.define("sms", () => {
  return {
    claimId: (cas as any).short_id,
    id: (cas as any).short_id,
    messageContent: cas.string,
    phoneNumber: cas.phone,
    sent: cas.boolean,
    userId: (cas as any).short_id,
    timestamp: (cas as any).js_date,
    _timestamp: (cas as any).js_date,
  }
})

cas.define("feature_flag", () => {
  return {
    id: (cas as any).short_id,
    name: cas.word,
    data: cas.word,
    description: cas.sentence,
    percentageRollout: cas.random,
    value: cas.boolean,
  }
})

cas.define("phone_number", () => {
  return {
    id: (cas as any).short_id,
    phoneNumber: cas.phone,
    createdAt: (cas as any).js_date,
    lastUsedAt: (cas as any).js_date,
  }
})

cas.define("automatic_answers", () => {
  return {
    id: (cas as any).short_id,
    incomingMsg: cas.sentence,
    automaticAnswer: cas.sentence,
    status: cas.random_element([
      "active",
      "disabled",
    ]),
    logic: cas.random_element([
      null,
      "disableAccount",
    ]),
  }
})

cas.define("claim_feedback", () => {
  return {
    id: (cas as any).short_id,
    claimId: (cas as any).short_id,
    ratingProcessFlow: cas.integer(0),
    ratingResult: cas.integer(0),
    ratingCustomerSupport: cas.integer(0),
    optionalNote: cas.string,
    createdAt: (cas as any).js_date,
  }
})

cas.define("brc_case", () => {
  return {
    id: (cas as any).short_id,
    claimId: (cas as any).short_id,
    createdAt: (cas as any).js_date,
    _createdAt: (cas as any).js_date,
    updatedAt: (cas as any).js_date,
    _updatedAt: (cas as any).js_date,
    case: (cas as any).case,
    customer: (cas as any).user,
    customFieldValues: {
      brc1: "test1",
      brc2: "test2",
      brc3: "test3",
    },
    // TODO: uploadedFiles: [],
  }
})

cas.define("kap_case", () => {
  return {
    id: (cas as any).short_id,
    claimId: (cas as any).short_id,
    createdAt: (cas as any).js_date,
    _createdAt: (cas as any).js_date,
    updatedAt: (cas as any).js_date,
    _updatedAt: (cas as any).js_date,
    case: (cas as any).case,
    // @ts-expect-error
    customer: cas.user,
    customFieldValues: {
      kap1: "test1",
      kap2: "test2",
      kap3: "test3",
    },
    lawFirm: cas.word,
    // TODO: uploadedFiles: [],
    tags: (cas as any).array_of_tags,
  }
})

cas.define("url_shortener", () => {
  return {
    id: (cas as any).short_id,
    token: cas.string,
    url: cas.string,
  }
})

cas.define("template", () => {
  return {
    id: (cas as any).short_id,
    type: cas.string,
    state: cas.string,
    userDefinedName: cas.string,
    locale: cas.string,
    provider: cas.string,
    name: cas.string,
    sid: cas.string,
    ignoreMaximumWhatsAppsPerClaimRule: cas.boolean,
    customDurationToBlockTemplate: cas.integer(0),
    placeholders: cas.array_of_words,
    attachment_ids: cas.array_of_words,
    mergeLanguage: cas.string,
    pathname: cas.string,
    createdAt: (cas as any).js_date,
  }
})

cas.define("notification_scheduler", () => {
  return {
    id: (cas as any).short_id,
    notificationType: cas.random_element(["email", "whatsApp"]),
    endpoint: cas.random_element(["caseUpdateHook", "claims"]),
    templateName: cas.random_element(["website-registration", "bg-reminder"]),
    lawFirm: "AILTS",
    scheduledTime: cas.integer(0),
    scheduledMinute: cas.integer(0),
    customFieldWatchers: cas.random_element([[3993], [3964], [3993, 3964]]),
    conditions: cas.random_element([
      {"claim.claimActionStatus": ["active"]},
      {},
    ]),
    exceptions: cas.random_element([
      {
        "kapCases.customFieldValues.3080": [true],
        "kapCases.customFieldValues.3922": [false],
      },
      {},
    ]),
    state: "active",
  }
})

cas.define("user_tracking", () => {
  return {
    id: (cas as any).short_id,
    convertedAtDate: cas.string,
    convertedAt: cas.string,
    _convertedAt: (cas as any).js_date,
    createdAtDate: cas.string,
    createdAt: cas.string,
    _createdAt: (cas as any).js_date,
    sessionId: cas.string,
    parameters: (cas as any).parameters,
  }
})

cas.define("visited_pages", () => {
  return {
    id: (cas as any).short_id,
    userTrackingId: (cas as any).short_id,
    trackedPageId: (cas as any).short_id,
    url: cas.string,
    page: cas.string,
    createdAtDate: cas.string,
    createdAt: cas.string,
    _createdAt: (cas as any).js_date,
  }
})

cas.define("tracked_pages", () => {
  return {
    id: (cas as any).short_id,
    page: cas.string,
    totalVisits: cas.integer(0),
    createdAtDate: cas.string,
    createdAt: cas.string,
    _createdAt: (cas as any).js_date,
    lastVisitedAtDate: cas.string,
    lastVisitedAt: cas.string,
    _lastVisitedAt: (cas as any).js_date,
  }
})

cas.define("gdpr_breaches", () => {
  return {
    id: (cas as any).short_id,
    Domain: cas.domain,
    Name: cas.title,
    Description: cas.description,
    IsFabricated: cas.boolean,
    IsMalware: cas.boolean,
    IsRetired: cas.boolean,
    IsSensitive: cas.boolean,
    IsSpamList: cas.boolean,
    IsVerified: cas.boolean,
    LogoPath: cas.url,
    ModifiedDate: (cas as any).js_date,
    PwnCount: cas.random,
    Title: cas.title,
    createdAt: (cas as any).js_date,
    AddedAt: (cas as any).js_date,
    BreachDate: (cas as any).js_date,
    DataClasses: cas.array_of_words(7),
  }
})

cas.define("gdpr_cases", () => {
  return {
    id: (cas as any).short_id,
    createdAt: (cas as any).js_date,
    updatedAt: (cas as any).js_date,
    userId: (cas as any).short_id,
    gdprBreachesIds: cas.array_of_words(3),
    formSubmittedAt: (cas as any).js_date,
    submittedToLawFirmAt: (cas as any).js_date,
    insuranceClosingDate: cas.string,
    city: cas.string,
    language: cas.string,
    markForSubmission: cas.boolean,
    screenshotLegalInsuranceUploaded: cas.boolean,
    signatureUploaded: cas.boolean,
    submissionStatus: cas.boolean,
    policeNumber: cas.string,
    policyHolderName: cas.string,
    postcode: cas.string,
    insuranceName: cas.string,
    street: cas.string,
    type: cas.string,
    utmParams: (cas as any).utm_params,
    lawFirm: cas.string,
    snoozeTime: cas.integer(0),
    endSnoozeAt: (cas as any).js_date,
    cancellationPolicy: cas.string,
    acceptedTocAndDataProtectionAt: (cas as any).js_date,
  }
})

interface FuncMapType {
  claims: Claim[]
  users: User[]
  tickets: Ticket[]
  documentUploads: DocumentUpload[]
  emails: Email[]
  sms: Sms[]
  featureFlags: FeatureFlag[]
  phoneNumbers: PhoneNumber[]
  automaticAnswers: AutomaticAnswer[]
  kapCases: Case[]
  claimFeedback: ClaimFeedback[]
  urlShortener: UrlShortener[]
  templates: Template[]
  notificationScheduler: NotificationScheduler[]
  userTracking: UserTracking[]
  visitedPages: VisitedPages[]
  trackedPages: TrackedPages[]
  gdprBreaches: GdprBreaches[]
  gdprCases: GdprCases[]
}


function getFakeDataProvider () {
  const funcMap: FuncMapType = {
    claims: Array(100)
      .fill(null)
      .map((_, index) => ({
        ...(cas as any).claim,
        id: `claim_${index}`,
      })),
    users: Array(100)
      .fill(null)
      .map(_ => (cas as any).user),
    tickets: Array(100)
      .fill(null)
      .map(_ => (cas as any).ticket),
    documentUploads: Array(100)
      .fill(null)
      .map(_ => (cas as any).document_upload),
    emails: Array(100)
      .fill(null)
      .map(_ => (cas as any).email_entry),
    sms: Array(100)
      .fill(null)
      .map(_ => (cas as any).sms),
    featureFlags: Array(100)
      .fill(null)
      .map(_ => (cas as any).feature_flag),
    phoneNumbers: Array(100)
      .fill(null)
      .map(_ => (cas as any).phone_number),
    automaticAnswers: Array(100)
      .fill(null)
      .map(_ => (cas as any).automatic_answers),
    kapCases: Array(100)
      .fill(null)
      .map(_ => (cas as any).kap_case),
    claimFeedback: Array(100)
      .fill(null)
      .map(_ => (cas as any).claim_feedback),
    urlShortener: Array(100)
      .fill(null)
      .map(_ => (cas as any).url_shortener),
    templates: Array(100)
      .fill(null)
      .map(_ => (cas as any).template),
    notificationScheduler: Array(100)
      .fill(null)
      .map(_ => (cas as any).notification_scheduler),
    userTracking: Array(100)
      .fill(null)
      .map(_ => (cas as any).user_tracking),
    visitedPages: Array(100)
      .fill(null)
      .map(_ => (cas as any).visited_pages),
    trackedPages: Array(100)
      .fill(null)
      .map(_ => (cas as any).tracked_pages),
    gdprBreaches: Array(100)
      .fill(null)
      .map(_ => (cas as any).gdpr_breaches),
    gdprCases: Array(100)
      .fill(null)
      .map(_ => (cas as any).gdpr_cases),
  }

  return fakeRest(funcMap, true)
}

export default getFakeDataProvider
