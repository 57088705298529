/* eslint-disable max-len */
import React from "react"
import { SvgIcon } from "@material-ui/core"

function SvgWhatsapp (props: Record<string, unknown>) {
  return (
    <SvgIcon width={24} height={24} viewBox='0 0 24 24' {...props}>
      <path
        d='M.559 24a.512.512 0 01-.493-.645l1.559-5.699a11.894 11.894 0 01-1.484-5.761C.145 5.335 5.484 0 12.043 0c3.18 0 6.172 1.242 8.418 3.488a11.818 11.818 0 013.48 8.418c0 6.559-5.34 11.899-11.898 11.899h-.004a11.938 11.938 0 01-5.508-1.356L.688 23.984a.485.485 0 01-.13.016zm0 0'
        fill='#e5e5e5'
      />
      <path
        d='M.559 23.488l1.613-5.898a11.381 11.381 0 01-1.52-5.692C.656 5.618 5.766.512 12.043.512c3.047 0 5.906 1.187 8.055 3.34a11.313 11.313 0 013.336 8.054c-.004 6.278-5.114 11.387-11.391 11.387h-.004c-1.906 0-3.781-.48-5.445-1.387zm6.316-3.644l.348.207a9.426 9.426 0 004.816 1.316h.004c5.219 0 9.465-4.242 9.465-9.46 0-2.532-.98-4.907-2.77-6.696a9.396 9.396 0 00-6.691-2.777c-5.223 0-9.469 4.246-9.473 9.464 0 1.786.5 3.528 1.45 5.036l.226.359-.957 3.492zm0 0'
        fill='#fff'
      />
      <path
        d='M.953 23.094l1.559-5.7a10.983 10.983 0 01-1.465-5.496C1.047 5.84 5.98.91 12.039.91c2.941 0 5.703 1.145 7.777 3.223a10.923 10.923 0 013.22 7.777c-.005 6.059-4.934 10.992-10.993 10.992-.004 0 0 0 0 0h-.008c-1.84 0-3.648-.465-5.254-1.34zm0 0'
        fill='#64b161'
      />
      <path
        d='M.559 23.488l1.613-5.898a11.381 11.381 0 01-1.52-5.692C.656 5.618 5.766.512 12.043.512c3.047 0 5.906 1.187 8.055 3.34a11.313 11.313 0 013.336 8.054c-.004 6.278-5.114 11.387-11.391 11.387h-.004c-1.906 0-3.781-.48-5.445-1.387zm6.316-3.644l.348.207a9.426 9.426 0 004.816 1.316h.004c5.219 0 9.465-4.242 9.465-9.46 0-2.532-.98-4.907-2.77-6.696a9.396 9.396 0 00-6.691-2.777c-5.223 0-9.469 4.246-9.473 9.464 0 1.786.5 3.528 1.45 5.036l.226.359-.957 3.492zm0 0'
        fill='#fff'
      />
      <path
        d='M9.195 7.137c-.21-.473-.437-.485-.64-.492-.164-.008-.356-.008-.543-.008-.192 0-.5.074-.762.36-.258.28-.996.972-.996 2.37 0 1.403 1.02 2.754 1.164 2.945.14.188 1.969 3.153 4.86 4.293 2.406.95 2.894.762 3.417.715.52-.047 1.684-.691 1.918-1.355.239-.664.239-1.235.168-1.352-.07-.117-.261-.191-.547-.332-.285-.14-1.683-.832-1.945-.926-.258-.093-.45-.144-.64.141-.188.285-.735.926-.899 1.117-.168.192-.332.215-.617.07-.285-.14-1.203-.44-2.29-1.41-.843-.753-1.417-1.687-1.581-1.972-.168-.285-.004-.426.125-.582.23-.278.617-.781.71-.973.094-.187.048-.355-.023-.496-.07-.145-.625-1.55-.879-2.113zm0 0'
        fillRule='evenodd'
        fill='#fff'
      />
    </SvgIcon>
  )
}

export default SvgWhatsapp
