import isEqual from "lodash/isEqual"
import {db} from "../firebase"

function nestedConditionIterate (
  obj: Record<string, any>,
  objComparison: Record<string, any>,
): boolean {
  return Object.keys(obj).reduce<boolean>((acc, key) => {
    if (
      objComparison[key] === undefined
    ) {
      return acc
    }


    if (typeof obj[key] !== "object") {
      if (isEqual(obj[key], objComparison[key])) {
        acc = true
      }
      return acc
    }

    acc = nestedConditionIterate(
      obj[key], objComparison[key],
    ) // comparing value is an object

    return acc
  }, false)
}

function arrayConditionIterate (arr: any[], arrComparison: any[]) {
  return arrComparison.reduce((acc, value) => {
    const arrayIndex = arr.indexOf(value)

    if (arrayIndex < 0) {
      return acc
    }

    if (isEqual(value, arr[arrayIndex])) {
      acc = true
      return acc
    }

    return acc
  }, false)
}

// todo: checking for empty object/empty array

async function doesConditionsApply (
  conditions: Record<string, any>, claimId: string,
) {
  if (Object.keys(conditions).length < 1) return true

  const query = await db.collectionGroup("claims")
    .where("id", "==", claimId)
    .get()

  const claim = query.docs[0].data()

  const conditionCount = Object.keys(conditions).reduce((acc, conditionKey) => {
    const conditionValue = conditions[conditionKey]

    const propDoesNotExistOnClaim = (
      claim[conditionKey] === undefined ||
      claim[conditionKey] === null
    )

    if (
      propDoesNotExistOnClaim &&
      conditionValue !== "" // handle undefined/null checking
    ) {
      return acc
    }

    if (
      Array.isArray(conditionValue) &&
      Array.isArray(claim[conditionKey])
    ) {
      const arrayToCheck = conditionValue as any[]

      const isNestedItemsTrue = arrayConditionIterate(
        arrayToCheck, claim[conditionKey],
      )
      if (isNestedItemsTrue) {
        acc++
      }
      return acc
    }


    if (
      typeof conditionValue === "object" &&
      typeof claim[conditionKey] === "object"
    ) {
      const isNestedObjTrue =
        nestedConditionIterate(
          conditionValue, claim[conditionKey],
        )

      if (isNestedObjTrue) {
        acc++
      }

      return acc
    }

    if (
      conditionValue === "" &&
      (
        propDoesNotExistOnClaim ||
        isEqual(conditionValue, claim[conditionKey])
      )
    ) {
      acc++
    }

    if (isEqual(conditionValue, claim[conditionKey])) {
      acc++
    } 

    return acc

  }, 0)

  return conditionCount >= Object.keys(conditions).length
}

export { nestedConditionIterate, arrayConditionIterate, doesConditionsApply }
