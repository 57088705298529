import React, { useState, useRef } from "react"
import Button from "@material-ui/core/Button"
import Select from "@material-ui/core/Select"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import MenuItem from "@material-ui/core/MenuItem"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import CircularProgress from "@material-ui/core/CircularProgress"
import ListSubheader from "@material-ui/core/ListSubheader"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import LinkIcon from "@material-ui/icons/Link"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import { grey } from "@material-ui/core/colors"
import { useNotify } from "react-admin"
// eslint-disable-next-line import/no-unresolved
import { HttpsCallableResult } from "@firebase/functions-types"

import { Flex } from "../../lib"
import firebase from "../../firebase"
import getHttpsCallable from "../../utils/getHttpsCallable"


const generateLoginLinks = getHttpsCallable("admin-generateLoginLinks")


const useStyles = makeStyles(theme => ({
  result: {
    backgroundColor: grey[800],
    color: theme.palette.text.primary,
    borderRadius: 4,
    borderColor: theme.palette.primary.light,
    width: "100%",
    marginRight: theme.spacing(1),
  },
  dialog: {
    overflow: "none",
  },
}))


interface ResultParams {
  userId: string
  link: string
  shortLink: string
  path: string
}

function Result ({ userId, link, shortLink, path }: ResultParams) {
  const classes = useStyles()
  const shortLinkRef = useRef(null)
  const notify = useNotify()

  async function copyTextToClipboard (text: string) {
    try {
      await navigator.clipboard.writeText(text)
      notify("Text Copied")
    }
    catch (error) {
      console.error("Async: Could not copy text: ", error)
      notify(`Could not copy text: ${error}`, "warning")
    }
  }

  return (
    <List
      subheader={
        <ListSubheader>
          Links for userId: {userId} will redirect to path {path}
        </ListSubheader>
      }
    >
      <ListItem>
        <textarea
          ref={shortLinkRef}
          className={classes.result}
          readOnly
          rows={1}
          value={shortLink}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => copyTextToClipboard(shortLink)}>
            <FileCopyIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <textarea
          className={classes.result}
          readOnly
          rows={1}
          value={link}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => copyTextToClipboard(link)}>
            <FileCopyIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}


interface GenerateLinksDialogParams {
  open: boolean
  handleClose: () => void
  userId: string
}

function GenerateLinksDialog (
  { open, handleClose, userId }: GenerateLinksDialogParams,
) {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<HttpsCallableResult | null>(null)
  const [path, setPath] = useState("/sign-in")

  function GetLinkForm () {
    return (
      <>
        <DialogContent>
          <DialogContentText>
            Select where you want the user to be directed to
          </DialogContentText>
          <Select
            disabled={loading}
            value={path}
            fullWidth
            label='Login redirect'
            onChange={(event: any) => setPath(event.target.value || "")}
          >
            <MenuItem key='/sign-in' value='/sign-in'>Form Page</MenuItem>
            <MenuItem
              key='/application/kv-upload'
              value='/application/kv-upload'
            >
              Upload KV Page
            </MenuItem>
          </Select>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button
            color='primary' onClick={async () => {
              setLoading(true)
              const result = await generateLoginLinks({ userId, path })
              console.info("-> : result", result)
              setResult(result)
              setLoading(false)
            }}
          >
            Get
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Get Links</DialogTitle>
      {!loading && !result && <GetLinkForm />}
      {loading &&
        <Flex center style={{ margin: 20 }}>
          <CircularProgress />
        </Flex>
      }
      {result &&
        <Result path={path} {...result.data}>Result</Result>
      }
    </Dialog>
  )
}


interface GenLinkButtonParams {
  userId: string
  [key: string]: unknown
}

export default function GenerateLinkButton (
  { userId, ...props }: GenLinkButtonParams,
) {
  const [dialogOpen, setDialogOpen] = useState(false)

  function handleCloseDialog () {
    setDialogOpen(false)
  }

  function handleOpenDialog () {
    setDialogOpen(true)
  }

  if (!userId) {
    return (
      <Button disabled onClick={handleOpenDialog}>
        Get Link
      </Button>
    )
  }

  return (
    <>
      <GenerateLinksDialog
        handleClose={handleCloseDialog}
        open={dialogOpen}
        userId={userId}
      />
      <Button
        {...props}
        variant='outlined'
        color='primary'
        onClick={handleOpenDialog}
      >
        <LinkIcon />
      </Button>
    </>
  )
}
