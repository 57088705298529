import FirebaseTimestamp from "./FirebaseTimestamp"

const visitedPagesFields = {
  id: String,
  userTrackingId: String,
  trackedPageId: String,
  url: String,
  page: String,
  createdAtDate: String,
  createdAt: FirebaseTimestamp,
  _createdAt: Date,
}

export default visitedPagesFields
