import React, { createElement } from "react"
import { connect } from "react-redux"
import { MenuItemLink, getResources } from "react-admin"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { get } from "lodash"

import {
  AssignmentLate,
  CloudDownload,
  CloudUpload,
  DoubleArrow,
  Face,
  Phone,
  Search,
  Send,
  ViewList,
  CallSplit, PollRounded, Assignment,
} from "@material-ui/icons"
import WhatsappIcon from "./WhatsappIcon"


const styles = {
  root: {}, // Style applied to the MenuItem from material-ui
  // Style applied when the menu item is the active one
  active: { fontWeight: "bold" },
  icon: {}, // Style applied to the icon
}


interface MenuParams {
  classes: any  // Partial<ClassNameMap<MenuItemClassKey>> | undefined
  resources: any[]
  onMenuClick: (arg: unknown) => unknown
}

function Menu ({ classes, resources, onMenuClick }: MenuParams) {
  return (
    <div>
      <MenuItemLink
        classes={classes}
        to='/search'
        primaryText='Search'
        onClick={onMenuClick}
        leftIcon={<Search />}
      />
      <MenuItemLink
        classes={classes}
        to='/data-import'
        primaryText='Data Import'
        onClick={onMenuClick}
        leftIcon={<CloudUpload />}
      />
      <MenuItemLink
        classes={classes}
        to='/lead-import'
        primaryText='Lead Import'
        onClick={onMenuClick}
        leftIcon={<CloudUpload />}
      />
      {/*
      <MenuItemLink
        classes={classes}
        to='/generate-data-export'
        primaryText='Data Export'
        onClick={onMenuClick}
        leftIcon={<CloudDownload />}
      />
      <MenuItemLink
        classes={classes}
        to='/user-modification'
        primaryText='User Modification'
        onClick={onMenuClick}
        leftIcon={<Face />}
      />
      */}
      <MenuItemLink
        classes={classes}
        to='/tracking'
        primaryText='Diesel Tracking'
        onClick={onMenuClick}
        leftIcon={<AssignmentLate />}
      />
      <MenuItemLink
        classes={classes}
        to='/online-casino-tracking'
        primaryText='Online Casino Tracking'
        onClick={onMenuClick}
        leftIcon={<AssignmentLate />}
      />
      {/*
      <MenuItemLink
        classes={classes}
        to='/user-tracking'
        primaryText='Website Tracking'
        onClick={onMenuClick}
        leftIcon={<PollRounded />}
      />
      */}

      <hr />

      {resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          classes={classes}
          to={`/${resource.name}`}
          primaryText={get(resource, "options.label", resource.name)}
          leftIcon={resource.icon
            ? createElement(resource.icon)
            : <ViewList />
          }
          onClick={onMenuClick}
        />
      ))}

      <hr />

      <MenuItemLink
        classes={classes}
        to='/gdpr-leads'
        primaryText='GDPR Leads'
        onClick={onMenuClick}
        leftIcon={<Assignment />}
      />
      <MenuItemLink
        classes={classes}
        to='/gdpr-rsv'
        primaryText='GDPR Insurance'
        onClick={onMenuClick}
        leftIcon={<Assignment />}
      />
      <MenuItemLink
        classes={classes}
        to='/gdpr-funder'
        primaryText='GDPR Funder'
        onClick={onMenuClick}
        leftIcon={<Assignment />}
      />
      <MenuItemLink
        classes={classes}
        to='/gdpr-submissions'
        primaryText='GDPR Submissions'
        onClick={onMenuClick}
        leftIcon={<Assignment />}
      />

      <hr />

      <MenuItemLink
        classes={classes}
        to='/send-emails'
        primaryText='Send Emails'
        onClick={onMenuClick}
        leftIcon={<Send />}
      />
      {/*
      <MenuItemLink
        classes={classes}
        to='/send-sms'
        primaryText='Send SMS'
        onClick={onMenuClick}
        leftIcon={<Phone />}
      />
      */}
      <MenuItemLink
        classes={classes}
        to='/whatsapp'
        primaryText='Send WhatsApp'
        onClick={onMenuClick}
        leftIcon={<WhatsappIcon />}
      />
      {/*
      <MenuItemLink
        classes={classes}
        to='/bulk-transfer'
        primaryText='Bulk Transfer'
        onClick={onMenuClick}
        leftIcon={<DoubleArrow />}
      />
      <MenuItemLink
        classes={classes}
        to='/workflows'
        primaryText='Workflows'
        onClick={onMenuClick}
        leftIcon={<CallSplit />}
      />
      */}
    </div>
  )
}


function mapStateToProps (state: string) {
  return {
    resources: getResources(state),
  }
}


// @ts-expect-error
export default withRouter(connect(mapStateToProps)(withStyles(styles)(Menu)))
