import React, { useState } from "react"
import {
  ArrayField,
  BooleanField,
  ChipField,
  Datagrid,
  ListContextProvider,
  SingleFieldList,
  TextField,
  UrlField,
  useList,
  useNotify,
  useRecordContext,
} from "react-admin"
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { workflows } from "../../lib/constants/workflows"
import { ChipList } from "../ChipList"

// import firebase from '../../firebase'
// import getHttpsCallable from '../../utils/getHttpsCallable'


export default function Workflows () {
  const listContext = useList({
    data: workflows,
    ids: workflows.map(wf => wf.id),
    loading: false,
    loaded: true,
  })

  return (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <ChipList source="triggers" />
        <UrlField source="url" />
        <BooleanField source="isActive" />
      </Datagrid>
    </ListContextProvider>
  )
}
