import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import "./index.css"
import * as serviceWorker from "./serviceWorker"
import parseEnvVar from "./lib/helpers/parseEnvVar"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
// import 'funnel-graph-js/dist/css/main.min.css'
// import 'funnel-graph-js/dist/css/theme.min.css'
// import 'funnel-graph-js/dist/js/funnel-graph'

console.info(
  `Running Firebase project "${process.env.REACT_APP_FIREBASE_PROJECT_ID}"`,
)


ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
