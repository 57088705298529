import React, { useState } from "react"
import { useNotify } from "react-admin"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from "@material-ui/core"

import createPhoneCall from "./createPhoneCall"
import getHttpsCallable from "../../../utils/getHttpsCallable"
import getTemplate from "../../../utils/getTemplate"
import { PhoneCallResult } from "../../../lib/enums"

const sendEmailTemplate = getHttpsCallable("admin-sendEmailTemplate")

interface CallAcceptedDialogParams {
  open: boolean
  handleClose: (ticketRef?: any) => void
  ticket: Ticket
}

export default function CallAcceptedDialog (
  { open, handleClose, ticket }: CallAcceptedDialogParams,
) {
  const notify = useNotify()
  const [notes, setNotes] = useState("")
  const [sendingEmail, setSendingEmail] = useState(false)
  const [emailSendResult, setEmailSendResult] =
    useState<SendEmailTemplateResult | null>(null)

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          Leave any notes here about the phone call and send the follow email.
        </DialogContentText>
        <Button
          fullWidth
          color='primary'
          variant='outlined'
          disabled={sendingEmail}
          onClick={async () => {
            setSendingEmail(true)

            const template : Template | undefined =
              await getTemplate("email", "kvAfterCallReminder")

            if (template) {
              const result = await sendEmailTemplate({
                claimIds: [ticket.claimId],
                templateName: template.name,
                templatePathname: template.pathname,
              })
              setEmailSendResult(result.data[0])
            }

            setSendingEmail(false)
          }}
        >
          {sendingEmail
            ? <CircularProgress size={20} />
            : "Send Phone Call Follow Up Email"
          }
        </Button>
        {emailSendResult
          ? <Typography variant='caption' color='textPrimary'>
            {emailSendResult?.sent
              ? "Email Sent"
              : "Email Failed to Send"}
          </Typography>
          : null}
      </DialogContent>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='name'
          label='Call Notes'
          fullWidth
          onChange={(e) => setNotes(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={async () => {
            try {
              await createPhoneCall({
                claimId: ticket.claimId,
                callResult: PhoneCallResult.ANSWERED,
                phoneNumberValid: true,
                phoneNumber: ticket.claimPhoneNumber,
                callNotes: notes,
              })
              handleClose()
            }
            catch (error) {
              notify(`${error}`, "warning")
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
