// Implement a Firestore data provider for React Admin

import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE,
} from "react-admin"
// eslint-disable-next-line import/no-unresolved
import { DocumentSnapshot } from "@firebase/firestore-types"
import {pickBy, omitBy, get, replace} from "lodash"

import {
  getCountFromServer,
  collection,
  collectionGroup,
  query as v9Query,
  where,
  orderBy,
  endAt,
} from "@firebase/firestore"

import firebase, { dbV9 } from "../firebase"
import getTable from "./getTable"
import normalizeTimestamps, {
  normTimestamps,
  normTimestampsInList,
} from "../utils/normalizeTimestamps"


const collectionGroupTables = ["claims", "documentUploads"]

// Initialize Cloud Firestore through Firebase
const db = firebase.firestore()
// eslint-disable-next-line import/no-named-as-default-member
const auth = firebase.auth()


/**
 * Utility function to flatten firestore objects,
 * since 'id' is not a field in FireStore
 *
 * @param {DocumentSnapshot} docSnap Firestore document snapshot
 * @returns {Object} the DocumentSnapshot.data()
 *  with an additionnal "Id" attribute
 */
function getDataWithId (docSnap: DocumentSnapshot) {
  let dataWithId = {}
  if (docSnap && docSnap.exists) {
    dataWithId = {
      id: docSnap.id,
      ...docSnap.data(),
    }
  }
  return dataWithId
}

function filterExistsData (
  existsFilters: Record<string, unknown>,
  filter: any,
  docSnap: any,
) {
  return docSnap.filter((doc: any) => {
    for (const key in existsFilters) {
      if (!filter[key]) {
        continue
      }

      const fieldName = key.split("__exists")[0]

      return doc.data()[fieldName]
    }

    return doc
  })
}

function filterNotEqualityData (
  notEqualityFilters: Record<string, unknown>,
  filter: any,
  docSnap: any,
) {
  return docSnap.filter((doc: any) => {
    for (const key in notEqualityFilters) {
      if (!filter[key]) {
        continue
      }

      const fieldName = key.split("__not")[0]
      
      if (doc.data()[fieldName] !== notEqualityFilters.type__not) {
        return true
      }
      continue
    }
    return false
  })
}

function filterRangeData (
  rangeFilters: Record<string, unknown>,
  filter: any,
  docSnap: any,
) {
  return docSnap.filter((doc: any) => {
    let cnt = 0

    // eslint-disable-next-line guard-for-in
    for (const key in rangeFilters) {
      cnt++

      if (!filter[key]) {
        continue
      }

      const range = filter[key]
      const fieldName = key.split("__date-range")[0]

      if (cnt > 1) {
        const startDate: any = new Date(range.startDate)
        const endDate: any = new Date(range.endDate)

        return doc.data()[fieldName].seconds >= Math.floor( startDate / 1000) &&
          doc.data()[fieldName].seconds <= Math.floor( endDate / 1000)
      }
    }

    return doc
  })
}


/**
 * Maps react-admin queries to Firebase
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export async function firestoreProvider (
  type: string,
  resource: string,
  params: {
    pagination: any
    sort: any
    filter: any
    id: string
    ids: string[]
    data: any
    previousData: any
    target: any
  },
) {
  const resourceTableName = getTable(resource)
  const usingCollectionGroup = collectionGroupTables
    .includes(resourceTableName)
  console.info("Making", type, resourceTableName, params, usingCollectionGroup)

  const initialQuery = usingCollectionGroup
    ? db.collectionGroup(resourceTableName)
    : db.collection(resourceTableName)

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const filter = params.filter
      let hasLtFilters = false
      let hasExistTrueFilters = false
      let hasMoreRangeFilters = false
      let hasNotEqualityFilters = false

      const rangeFilters = pickBy(
        filter,
        (v, k) => k.endsWith("__date-range"),
      )
      const existsFilters = pickBy(
        filter,
        (v, k) => k.endsWith("__exists"),
      )

      const notEqualityFilters = pickBy(
        filter,
        (v, k) => k.endsWith("__not"),
      )

      // query all the docs from the first to page*perPage
      let query = initialQuery
      const v9Conditions = []

      if (filter) {
        const ltFilters = pickBy(
          filter,
          (v, k) => k.endsWith("__lt"),
        )
        const equalityFilters = omitBy(
          filter,
          (v, k) =>
            k.endsWith("__date-range") ||
            k.endsWith("__lt") ||
            k.endsWith("__exists") ||
            k.endsWith("__not"),
        )

        if (Object.keys(ltFilters).length !== 0) {
          hasLtFilters = true
        }

        query = Object.keys(equalityFilters)
          .reduce((q, k) => {
            const value = filter[k]

            if (k.indexOf("__custom-field-value") > -1) {
              const filterKey = replace(k, "__custom-field-value", "")
              k = `customFieldValues.${filterKey}`
            }

            if (k.indexOf("__case") > -1) {
              const filterKey = replace(k, "__case", "")
              k = `case.${filterKey}`
            }

            if (k.indexOf("__number") > -1) {
              const filterKey = replace(k, "__number", "")
              v9Conditions.push(where(filterKey, "==", Number(value)))
              return q.where(filterKey, "==", Number(value))
            }

            v9Conditions.push(where(k, "==", value))

            return q.where(k, "==", value)
          }, query)

        let cntRangeFilters = 0

        query = Object.keys(rangeFilters).reduce((q, k) => {
          cntRangeFilters++
          const range = filter[k]
          if (!range.startDate || !range.endDate) {
            return query
          }

          const fieldName = k.split("__date-range")[0] as string

          if ((cntRangeFilters > 1 && Object.keys(rangeFilters).length !== 0)) {
            hasMoreRangeFilters = true
            return query
          }

          v9Conditions.push(where(fieldName, ">=", new Date(range.startDate)))
          v9Conditions.push(where(fieldName, "<=", new Date(range.endDate)))

          if (fieldName !== "createdTime") {
            v9Conditions.push(orderBy(fieldName))

            return q
              .where(fieldName, ">=", new Date(range.startDate))
              .where(fieldName, "<=", new Date(range.endDate))
              .orderBy(fieldName)
          }

          return q
            .where(fieldName, ">=", new Date(range.startDate))
            .where(fieldName, "<=", new Date(range.endDate))
        }, query)

        query = Object.keys(ltFilters).reduce((q, k) => {
          const fieldName = k.split("__lt")[0] as string

          v9Conditions.push(orderBy(fieldName))
          v9Conditions.push(orderBy(field, order.toLowerCase()))
          v9Conditions.push(endAt(filter[k]))

          return q
            .orderBy(fieldName)
            .orderBy(field, order.toLowerCase())
            .endAt(filter[k])
        }, query)

        query = Object.keys(existsFilters).reduce((q, k) => {
          const fieldName = k.split("__exists")[0]


          if (!filter[k]) {
            v9Conditions.push(where(fieldName, "==", null))

            return q
              .where(fieldName, "==", null)
          }

          hasExistTrueFilters = true
          return q
        }, query)

        query = Object.keys(notEqualityFilters).reduce((q, k) => {
          const fieldName = k.split("__not")[0] as string
          
          const value = filter[k]

          if (!value) {
            return q.where(fieldName, "==", null)
          }

          v9Conditions.push(
            orderBy(field, order.toLowerCase()),
          )
          hasNotEqualityFilters = true
          return q
            .orderBy(field, order.toLowerCase())
        }, query)
      }

      if (field !== "id" && !hasLtFilters && !hasNotEqualityFilters) {
        query = query.orderBy(field, order.toLowerCase())
        v9Conditions.push(orderBy(field, order.toLowerCase()))
      }

      let queryDocs = (await query.limit(page * perPage).get()).docs

      const v9FilterQuery = v9Query(usingCollectionGroup
        ? collectionGroup(dbV9, resourceTableName)
        : collection(dbV9, resourceTableName), ...v9Conditions)
      const total = await getCountFromServer(v9FilterQuery)

      if (hasExistTrueFilters) {
        queryDocs = filterExistsData(existsFilters, filter, queryDocs)
      }

      if (hasNotEqualityFilters) {
        queryDocs = filterNotEqualityData(notEqualityFilters, filter, queryDocs)
      }

      if (hasMoreRangeFilters) {
        queryDocs = filterRangeData(rangeFilters, filter, queryDocs)
      }

      // slice the results
      const firstFilteredDocToDisplayCount = page === 1
        ? 1
        : Math.min((page - 1) * perPage, queryDocs.length)
      const firstFilteredDocToDisplay = queryDocs
        .slice(firstFilteredDocToDisplayCount - 1)

      return {
        data: normTimestampsInList(
          firstFilteredDocToDisplay.map((doc: any) => getDataWithId(doc)),
        ),
        total: !hasExistTrueFilters && !hasMoreRangeFilters
          ? total.data().count : queryDocs.length,
      }
    }

    case GET_ONE: {
      if (usingCollectionGroup) {
        return initialQuery
          .where("id", "==", params.id)
          .get()
          .then(querySnap => {
            if (querySnap.docs.length > 0) {
              return {
                data: normTimestamps(getDataWithId(querySnap.docs[0])),
              }
            }
            return { data: null }
          })
          .catch(error => {
            return Promise.reject({ message: error.message, status: 404 })
          })
      }
      else {
        return initialQuery
          // TODO: Fix type error
          // @ts-expect-error
          .doc(params.id)
          .get()
          .then((doc: DocumentSnapshot) => {
            if (doc.exists) {
              return {
                data: normTimestamps(getDataWithId(doc)),
              }
            }
            else {
              throw new Error("No such doc")
            }
          })
          .catch((error: Error) => {
            return Promise.reject({ message: error.message, status: 404 })
          })
      }
    }

    case UPDATE: {
      if (typeof params.data === "object") {
        normalizeTimestamps(params.data)
      }

      if (usingCollectionGroup) {
        console.info("Start collection group update")
        return initialQuery
          .where("id", "==", params.id)
          .get()
          .then((querySnap) => {
            console.info(querySnap.docs)
            if (querySnap.size > 0) {
              const updateObj = {
                ...params.data,
                updatedBy: "admin-dashboard",
                updatedById: get(auth, "currentUser.uid", null),
              }

              return querySnap.docs[0].ref
                .update(updateObj)
                .then(() => ({ data: params.data }))
            }
            else {
              console.error("No data found for id", params.id)
            }
          })
      }

      console.info("collection update")
      return initialQuery
        // TODO: Fix type error
        // @ts-expect-error
        .doc(params.id)
        .set({
          ...params.data,
          updatedBy: "admin-dashboard",
          updatedById: get(auth, "currentUser.uid", null),
        })
        .then(() => {
          return { data: params.data }
        })
    }
    case CREATE: {
      if (typeof params.data === "object") {
        normalizeTimestamps(params.data)
      }

      if (usingCollectionGroup) {
        console.error("CREATE not implemented with a collection group")
      }

      // remove a reference if it exists
      return db
        .collection(resourceTableName)
        .add({
          ...params.data,
          updatedBy: "admin-dashboard",
          updatedById: get(auth, "currentUser.uid", null),
        })
        .then(DocumentReference =>
          DocumentReference
            .get()
            .then(DocumentSnapshot => {
              return { data: getDataWithId(DocumentSnapshot) }
            }),
        )
    }

    case UPDATE_MANY: {
      if (typeof params.data === "object") {
        normalizeTimestamps(params.data)
      }
      if (usingCollectionGroup) {
        console.error("UPDATE_MANY not implemented on a collection group")
      }
      return params.ids.map((id: string) =>
        initialQuery
          // @ts-expect-error
          .doc(id)
          .set({
            ...params.data,
            updatedBy: "admin-dashboard",
            updatedById: get(auth, "currentUser.uid", null),
          })
          .then(() => id),
      )
    }

    case DELETE: {
      if (usingCollectionGroup) {
        return db.collectionGroup(resourceTableName)
          .where("id", "==", params.id)
          .get()
          .then((querySnap) => {
            if (querySnap.size > 0) {
              return querySnap.docs[0].ref.delete()
                .then(() => ({ data: params.previousData }))
            }
            else {
              return { data: params.previousData }
            }
          })
      }
      return initialQuery
        // @ts-expect-error
        .doc(params.id)
        .delete()
        .then(() => {
          return { data: params.previousData }
        })
    }

    case DELETE_MANY: {
      if (usingCollectionGroup) {
        console.info("params", params)
        const tasks = params.ids.map((id: string) =>
          db.collectionGroup(resourceTableName)
            .where("id", "==", id)
            .get()
            .then(querySnap =>
              querySnap.docs.map(snap =>
                snap.ref.delete(),
              ),
            ),
        )
        return Promise.all(tasks).then(() => ({ data: params.ids }))
      }
      return {
        data: params.ids.map((id: string) =>
          db
            .collection(resourceTableName)
            .doc(id)
            .delete()
            .then(() => id),
        ),
      }
    }

    case GET_MANY: {
      // Do not use FireStore Ref
      // because react-admin will not be able to create or update
      // Use a String field containing the ID instead
      if (usingCollectionGroup) {
        return Promise
          .all(
            params.ids.map((id: string) => {
              return initialQuery.where("id", "==", id).get()
            }),
          ).then(querySnaps => {
            return querySnaps.map((snap: any) =>
              snap.size > 0 ? snap.docs[0] : null)
          })
          .then(arrayOfResults =>
            ({ data: arrayOfResults.map(documentSnapshot =>
              getDataWithId(documentSnapshot)),
            }),
          )
      }
      else {
        return Promise
          .all(params.ids.map((id: string) => {
            return initialQuery
              // @ts-expect-error
              .doc(id)
              .get()
          }))
          .then(arrayOfResults => {
            return {
              data: arrayOfResults.map((documentSnapshot: any) =>
                getDataWithId(documentSnapshot)),
            }
          })
      }
    }

    case GET_MANY_REFERENCE: {
      console.info("Get many ref", params)
      const { target, id } = params
      const { field, order } = params.sort
      return initialQuery
        .where(target, "==", id)
        .orderBy(field, order.toLowerCase())
        .get()
        .then(QuerySnapshot => {
          return {
            data: QuerySnapshot.docs.map(DocumentSnapshot =>
              getDataWithId(DocumentSnapshot)),
            total: QuerySnapshot.docs.length,
          }
        })
    }

    default: {
      throw new Error(`Unsupported Data Provider request type ${type}`)
    }
  }
}
