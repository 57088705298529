import React, { useState } from "react"
import { DateRangePicker } from "react-date-range"
import { Button } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Flex } from "../lib/components"
import { Field } from "react-final-form"


interface Selection {
  startDate: Date | null
  endDate: Date | null
  key: string
}

const initialSelection: Selection = {
  startDate: null,
  endDate: null,
  key: "selection",
}

export function DateRangeFilter (
  { label, input = {} }: { label: string, input: { onChange?: any}},
) {
  const { onChange } = input
  const [open, setOpen] = useState(false)
  const [selection, setSelection] = useState(initialSelection)

  function handleOpen () {
    setOpen(true) 
  }
  function handleClose () {
    setOpen(false) 
  }
  function handleChange ({ selection }: { selection: Selection }) {
    setSelection(selection)
  }
  function handleClear () {
    setSelection(initialSelection)
  }
  function handleSelect () {
    onChange && onChange(selection)
    handleClose()
  }

  if (!input) {
    return null
  }

  return (
    <Flex direction='ttb' center>
      <Button
        variant='outlined'
        onClick={handleOpen}
      >{label || "Range"}</Button>
      <Dialog
        maxWidth='md'
        open={open}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>
          Filter By Date Range{label && ` ${label}`}
        </DialogTitle>

        <DialogContent>
          {/* @ts-expect-error */}
          <DateRangePicker
            ranges={[selection]}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear} color='primary'>
            Remove Filter
          </Button>
          <Button onClick={handleSelect} color='primary'>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </Flex>
  )
}


export function DateRangeFilterWithField (
  { source, label }: { source: string, label: string },
) {
  return (
    <Field
      name={`${source}__date-range`}
      // @ts-expect-error  TODO
      component={DateRangeFilter}
      label={label}
    />
  )
}

