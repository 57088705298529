import React from "react"
import Chip from "@material-ui/core/Chip"
import TextField from "@material-ui/core/TextField"
import Autocomplete,
{ createFilterOptions } from "@material-ui/lab/Autocomplete"
import Typography from "@material-ui/core/Typography"
import { omit, map, filter } from "lodash"

import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import {Button, InputLabel} from "@material-ui/core"
import {db} from "../../../firebase"

import {ClaimTypes} from "../../../constants/enums"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {useNotify} from "react-admin"

type TagOptionsType = {
  name: string,
  id: string,
  inputValue?: string,
  claimType?: string,
}


export default function TicketTag (
  { ticket, ticketRef, tags, claimType }: {
    ticket: Ticket
    ticketRef: any
    tags: TagOptionsType[]
    claimType: string | undefined
  },
) {
  const [value, setValue] = React.useState(
    ticket?.tags
      ? filter(tags, ({ id }) => ticket?.tags?.includes(id))
      : [],
  )
  const [tagOptions, setTagOptions] = React.useState<TagOptionsType[]>(tags)

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [modalValue, setModalValue] =
    React.useState({id: "", name: "", claimType: ""})

  const notify = useNotify()

  React.useEffect(() => {
    async function populateCustomTags () {
      const querySnapshot = await db.collection("customTags").get()
      querySnapshot.forEach((doc) => {
        const tagData = doc.data()

        if (
          tagData.claimType === undefined ||
          (tagData.claimType.length > 0 && tagData.claimType !== claimType)
        ) {
          return
        }

        const userDefinedTag: TagOptionsType = {
          name: "",
          id: "",
        }

        Object.assign(userDefinedTag, {
          name: tagData.name,
          id: tagData.id,
        })

        const shouldAddToOptions = filter(tagOptions,
          ({ id }) => id === userDefinedTag.id).length < 1

        const shouldAddToValue = filter(ticket?.tags,
          (id) => id === userDefinedTag.id,
        ).length > 0

        if (shouldAddToOptions) {
          setTagOptions(prevState => [...prevState, userDefinedTag])
        }

        if (shouldAddToValue) {
          setValue(prevState => [...prevState, userDefinedTag])
        }
      })
    }

    populateCustomTags()

  }, [])


  function handleCloseModal () {
    setModalValue({
      id: "",
      name: "",
      claimType: "",
    })
    setIsModalOpen(false)
  }

  async function handleSubmitModal () {
    const newTag = {
      name: modalValue.name,
      id: modalValue.id,
    }

    const next = [
      ...[newTag],
      ...value,
    ]

    const nextTagOption = [
      ...[newTag],
      ...tagOptions,
    ]


    const query = db.doc(`customTags/${newTag.id}`)

    query.onSnapshot((querySnapshot) => {
      if (!querySnapshot.exists) {
        querySnapshot.ref.set(
          Object.assign(newTag, {
            claimType: modalValue.claimType,
          }),
        )
      }
    })

    if (
      modalValue.claimType.length > 0 &&
      modalValue.claimType !== claimType
    ) {

      notify(
        `Successfully added new tag: ${newTag.name}. Claim type: ${
          modalValue.claimType === "" ? "any" : modalValue.claimType}`,
        { type: "success", autoHideDuration: 10e3 },
      )
      handleCloseModal()
      return
    }
    
    setValue(next)
    setTagOptions(nextTagOption)
    
    await ticketRef.set(
      { ...omit(ticket, "update"), tags: map(next, "id") },
    )
    
    handleCloseModal()

    notify(
      `Successfully added new tag: ${newTag.name}. Claim type: ${
        modalValue.claimType === "" ? "any" : modalValue.claimType}`,
      { type: "success", autoHideDuration: 10e3 },
    )
  }

  const autoCompleteFilter = createFilterOptions<TagOptionsType>()

  return (
    <>
      <Autocomplete
        multiple
        id='tags'
        value={value}
        onChange={(event, newValue) => {
          const newTag = filter(newValue,
            ({ inputValue }: { inputValue: string | undefined }) =>
              inputValue && inputValue.length > 0,
          ) as TagOptionsType[]

          if (newValue && Array.isArray(newValue) &&
            newTag.length > 0) {
            setIsModalOpen(true)

            const inputValue =
              newTag[0].inputValue === undefined
                ? "" : newTag[0].inputValue

            setModalValue({
              name: inputValue,
              id: "",
              claimType: claimType === undefined ? "" : claimType,
            })
          }
          else {
            const next = [
              ...newValue,
            ]

            setValue(next)

            ticketRef.set(
              { ...omit(ticket, "update"), tags: map(newValue, "id") },
            )
          }
        }}
        filterOptions={(options, params) => {
          const filtered = autoCompleteFilter(
            options as TagOptionsType[],
            params,
          )

          const isTag = filter(options,
            ({ name }: { name: string }) => name === params.inputValue)

          if (params.inputValue !== "" && isTag) {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
              id: "",
            })
          }

          return filtered
        }}
        options={tagOptions}
        // @ts-expect-error
        getOptionLabel={(option) => option.name}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              color='primary'
              variant='outlined'
              clickable
              key={index}
              // @ts-expect-error
              label={option.name}
              {...getTagProps({ index })}
            />))
        }
        renderInput={(params) => (
          <>
            <Typography variant='subtitle2'>Ticket Tags</Typography>
            <TextField
              {...params}
              style={{ paddingBottom: 20 }}
              variant='outlined'
              placeholder='Tags'
            />
          </>
        )}
      />
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Add a new tag</DialogTitle>
        <DialogContent>
          <TextField
            id="modalName"
            value={modalValue.name}
            label="Name"
            type="text"
            onChange={(event) => 
              setModalValue({ ...modalValue, name: event.target.value })
            }
            style={{
              maxWidth: "150px",
              marginRight: "1rem",
            }}
          />
          <TextField
            autoFocus
            id="modalId"
            value={modalValue.id}
            label="Id"
            type="text"
            onChange={(event) => 
              setModalValue({ ...modalValue, id: event.target.value })
            }
            style={{
              maxWidth: "150px",
              marginLeft: "1rem",
            }}
          />
          <InputLabel
            style={{
              marginTop: "1rem",
            }}
          >
            Claim Type
          </InputLabel>
          <Select
            value={modalValue.claimType}
            onChange={(event) =>
              setModalValue({ ...modalValue,
                claimType: event.target.value as string,
              })
            }
            style={{
              minWidth: "150px",
            }}
          >
            <MenuItem value="">
              Any
            </MenuItem>
            {Object.keys(ClaimTypes).map((claimType) =>
              <MenuItem value={claimType}>
                {claimType}
              </MenuItem>)}
          </Select>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
              Cancel
          </Button>

          <Button onClick={handleSubmitModal} color="primary">
              Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
