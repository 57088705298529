import React from "react"
import { DateField } from "react-admin"


interface TimestampFieldParams {
  source: string
  record?: any
  showTime?: boolean
  label?: string
  [key: string]: any
}

export default function TimestampField (
  { source, record = {}, ...props }: TimestampFieldParams,
) {
  if (record[source] && typeof record[source].toDate === "function") {
    record[source] = record[source].toDate()
  }
  return <DateField {...props} source={source} record={record} />
}
