import React, {useEffect, useState} from "react"
import useFiles from "../../hooks/useFiles"
import { useNotify } from "react-admin"
import DescriptionIcon from "@material-ui/icons/Description"
import getHttpsCallable from "../../utils/getHttpsCallable"

import {
  Button,
  Hidden,
} from "@material-ui/core"

export function GeneratePOAButton (
  { claim, ...props }: {
    claim: Claim
    [key: string]: any
  },
) {
  const { id: claimId, userId } = claim

  const notify = useNotify()

  async function handleClick () {
    try {
      const apiSignatures = getHttpsCallable("admin-generatePOA")

      const res = await apiSignatures({
        claimId, userId,
      })

      notify(
        res.data.message,
        { type: "success", autoHideDuration: 10e3, multiLine: true },
      )
    }
    catch (err: any) {

      return notify(
        err.message,
        "error",
      )
    }
  }

  return (
    <>
      <Button {...props}
        variant='outlined'
        color='primary'
        onClick={handleClick}
      >
        <DescriptionIcon/>
        <Hidden mdDown>&nbsp;Generate POA</Hidden>
      </Button>
    </>
  )
}
