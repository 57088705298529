import FirebaseTimestamp from "./FirebaseTimestamp"

const claimFields = {
  id: String,
  acceptedTermsAndConditions: { type: Boolean, default: false },
  address: String,
  applicationApproved: { type: Boolean, default: false },
  applicationApprovedByUser: Number,
  applicationApprovedTime: FirebaseTimestamp,
  _applicationApprovedTime: Date,
  calculatedAward: Number,
  brcMultipleCars: { type: Boolean, default: false },
  carAuthorizationCode: String,
  carBrand: String,
  carIsTop: { type: Boolean, default: false },
  carModel: String,
  carMotorDisplacement: String,
  carMotorVariant: String,
  carMotorKilowatt: String,
  carSalePrice: Number,
  carPurchaseDate: String,
  carPurchaseEntity: String,
  carPurchasePrice: Number,
  carPurchaseYear: Number,
  carPurchasedBeforeRecall: Boolean,
  carVariant: String,
  carType: String,
  carYear: Number,
  carRecallStatus: String,
  claimValue: Number,
  currentMileage: Number,
  city: String,
  claimActionStatus: String,
  claimPassesMinimum: Boolean,
  claimVerifiedExternally: Boolean,
  claimActionStatusUpdateTime: FirebaseTimestamp,
  _claimActionStatusUpdateTime: Date,
  companyName: String,
  createdTime: FirebaseTimestamp,
  _createdTime: Date,
  customFieldValues: Object,
  euroRating: String,
  firstName: String,
  hasCarBeenFinanced: String,
  hasCarBeenSold: String,  // TODO: Use Boolean
  hasEcCard: { type: Boolean, default: false },
  hasPowerOfAttorneyDoc: { type: Boolean, default: false },
  hasUploadedFinanceContract: { type: Boolean, default: false },
  hasUploadedPurchaseContract: { type: Boolean, default: false },
  hasUploadedSalesContract: { type: Boolean, default: false },
  highValueInsuranceProvider: { type: Boolean, default: false },
  iban: String,
  importedAt: FirebaseTimestamp,
  isEligible: { type: Boolean, default: false },
  isFinancingPaidOff: String,  // TODO: Use Boolean
  insuranceProvider: String,
  customInsuranceProvider: String,
  kapEmailUrl: String,
  keepCarReward: Number,
  kvCorrect: Boolean,
  kvDataComplete: Boolean,
  kvWrongDocReason: String,
  leadSource: String,
  dieselWarning: String,
  kvReadable: Boolean,
  lastName: String,
  lawFirm: String,
  lawFirmCaseId: String,
  legalExpensesInsurance: String,
  legalExpensesInsuranceAtPurchase: String,
  legalExpensesInsurancePolicyAsCompany: String,
  legalExpensesInsurancePolicyNumber: String,
  legalExpensesInsurancePolicyholderCompanyName: String,
  legalExpensesInsurancePolicyholderFirstName: String,
  legalExpensesInsurancePolicyholderLastName: String,
  maxReward: Number,
  messageTracking: {email: String, whatsApp: String, formLinkClicked: Boolean},
  mileageAtPurchase: Number,
  mileageAtSale: Number,
  motorType: String,
  notes: String,
  notificationQueue: Object,
  participatedInClassAction: String,
  phoneNumber: String,
  postcode: String,
  purchaseContractCompanyName: String,
  purchaseContractFirstName: String,
  purchaseContractLastName: String,
  purchaseContractOnHand: String,
  returnCarInCaseOfSuccess: String,
  returnCarReward: Number,
  tags: [{ id: String }],
  transmittedToLawfirmAt: FirebaseTimestamp,
  _transmittedToLawfirmAt: Date,
  lastPushbackLawFirmAt: FirebaseTimestamp,
  _lastPushbackLawFirmAt: Date,
  lastUpdateInCmtAt: FirebaseTimestamp,
  _lastUpdateInCmtAt: Date,
  type: String,
  updatedTime: FirebaseTimestamp,
  _updatedTime: Date,
  userEmail: String,
  userId: String,
  userIsRegistered: Boolean,  // Email address or phone number is available
  userRegistrationTime: FirebaseTimestamp,
  _userRegistrationTime: Date,
  vinCheck: String,
  vinNumber: String,
  wouldHavePurchasedVehicle: Boolean,
  whatsappContactedNumber: String,
  casinoFormFinishedAt: FirebaseTimestamp,
  utmParams: Object,
  hasMultipleClaims: { type: Boolean, default: false },
  tlUploaded: { type: Boolean, default: false },
  idUploaded: { type: Boolean, default: false },
  accInfoUploaded: { type: Boolean, default: false },
  poaUploaded: { type: Boolean, default: false },
  tlRequested: { type: Boolean, default: false },
  lead: { type: Boolean, default: false },
  tlRequestedAt: FirebaseTimestamp,
  _tlRequestedAt: Date,
  poaUploadedAt: FirebaseTimestamp,
  _poaUploadedAt: Date,
  funder: String,
  reRegistrationCount: Number,
}

export default claimFields
