import { Edit } from "react-admin"
import CustomFieldForm from "./CustomFieldForm"

export default function CustomFieldsEdit ({...props}) {
  return (
    <Edit {...props}>
      <CustomFieldForm />
    </Edit>
  )
}
