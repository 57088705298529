import React, { useState, useEffect } from "react"
import { Table, TableRow, TableCell, TableHead,
  TableBody, Button, IconButton } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { db } from "../../firebase"
import { get, reject, first } from "lodash"


function UserRecord (
  { claim, sendSms, remove }:
  {
    claim: Claim
    sendSms: (obj: { claim: Claim }) => void
    remove: (id: string) => void
  },
) {
  const { id, phoneNumber } = claim
  return (
    <TableRow key={id}>
      <TableCell>
        <IconButton onClick={() => remove(id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
      <TableCell component='th' scope='row'>
        {id}
      </TableCell>
      <TableCell>
        {phoneNumber}
      </TableCell>
      <TableCell>
        <Button
          variant='outlined'
          color='primary'
          onClick={async () => {
            const result = await sendSms({ claim })
            if (get(result, "sent") === true) {
              remove(id)
            }
          }}
        >Send
        </Button>
      </TableCell>
    </TableRow>
  )
}


export default function UserSMSTable (
  { claimIds, sendSms }:
  {
    claimIds: string[]
    sendSms: ({ claim }: { claim: Claim }) => Promise<any>
  },
) {
  const [claims, setClaims] = useState<ClaimRec[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getTasks () {
      const tasks = claimIds.map(async id => {
        const claims = await db
          .collectionGroup("claims")
          .where("id", "==", id)
          .get()
        const claim = first(claims.docs)
        const data = claim && claim.data()
        return { data, sent: null, error: null }
      })
      const claims = await Promise.all(tasks)
      setClaims(claims)
      setLoading(false)
    }
    getTasks()
  }, [claimIds])

  if (loading) {
    return <p>Loading</p>
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Delete</TableCell>
          <TableCell>Claim ID</TableCell>
          <TableCell>Phone Number</TableCell>
          <TableCell>Send</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {claims.map((claim: ClaimRec) =>
          <UserRecord
            key={get(claim, "data.id")}
            claim={claim.data}
            sendSms={sendSms}
            remove={(id) => {
              setClaims(reject(claims, c => c.data.id === id))
            }}
          />,
        )}
      </TableBody>
    </Table>
  )
}
