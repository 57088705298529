import styled from 'styled-components'

const FormHelper = styled.span`
  font-family: Heebo;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${props =>
    // @ts-expect-error
    props.error ? '#ff5b5b' : '#161466'
  };
`

export default FormHelper
