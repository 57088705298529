import React from "react"
import {
  BooleanField,
  BooleanInput, Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  Filter,
  List,
  ReferenceManyField,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"
import { Box, Button } from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"

import { DateRangeFilterWithField } from "./DateRangeFilter"
import DefaultActions from "./DefaultActions"
import DefaultPagination from "./DefaultPagination"
import DeleteButtonWithConfirm from "./DeleteButtonWithConfirm"
import TimestampField from "./TimestampField"


function UserFilter (props: { setFilters?: (obj: any) => void }) {
  return (
    <Filter {...props}>
      {/* @ts-expect-error: Property 'alwaysOn' does not exist */}
      <Box mb={2} alwaysOn>
        <Button
          variant='outlined'
          size='small'
          onClick={() => props.setFilters && props.setFilters({
            email: "",
            id: "",
            createdAt: "",
          })}
        >
          <ClearIcon />
          Clear Filters
        </Button>
      </Box>
      <TextInput label='Filter by email' source='email' alwaysOn />
      <TextInput label='Filter by id' source='id' alwaysOn />
      {/* @ts-expect-error: Property 'alwaysOn' does not exist */}
      <Box mb={2} alwaysOn>
        <DateRangeFilterWithField source='createdAt' label='Created At' />
      </Box>
    </Filter>
  )
}


function UserBulkActionButtons (props: Record<string, unknown>) {
  return (
    <>
      <DeleteButtonWithConfirm {...props} />
    </>
  )
}


export function UserList (props: Record<string, unknown>) {
  const dateFieldOpts = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }

  return (
    <List
      filters={<UserFilter />}
      actions={<DefaultActions />}
      sort={{ field: "createdAt", order: "DESC" }}
      // Only query registered users to reduce DB reads
      filter={{ hasCompletedRegistration: true }}
      title='Registered Users List'
      bulkActionButtons={<UserBulkActionButtons {...props} />}
      pagination={<DefaultPagination />}
      perPage={15}
      {...props}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' sortable={false} />
        <TextField source='email' sortable={false} />
        <TextField source='phone' sortable={false} />
        <BooleanField source='emailVerified' sortable={false} addLabel={true} />
        <DateField
          source='createdAt'
          options={{...dateFieldOpts}}
          sortable={false}
        />
        <DateField
          source='registrationTime'
          options={{...dateFieldOpts}}
          sortable={false}
        />
        <DateField
          source='lastSignInTime'
          options={{...dateFieldOpts}}
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}


export function UserShow (props: Record<string, unknown>) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source='email' />
        <TextField source='id' />
        <TextField source='leadSource' />
        <TimestampField
          label='Created At'
          source='createdAt'
          locales='sv-SE'
          showTime
          addLabel/>
        <TimestampField
          label='Last Signed In'
          source='lastSignInTime'
          locales='sv-SE'
          showTime
          addLabel
        />
        <TimestampField
          label='Registered Time'
          source='registrationTime'
          locales='sv-SE'
          showTime
          addLabel
        />
        <TimestampField
          label='Imported At'
          source='importedAt'
          locales='sv-SE'
          showTime
          addLabel
        />
        <TextField source='phone' label='User provider Phone Number' />
        <TextField
          source='phoneNumber'
          label='Phone number from authentication provider (e.g Facebook)'
        />
        <TextField
          source='personalReferrerCode'
          label='Personal referrer code'
        />
        <BooleanField source='hasCompletedRegistration' addLabel={true} />
        <TimestampField
          label='Diesel Newsletter Subscribed Date'
          source='dieselNewsletterSubscribedAt'
          locales='sv-SE'
          showTime
          addLabel
        />
        <BooleanField source='emailVerified' addLabel={true} />
        <ReferenceManyField
          label='Claims'
          reference='claims'
          source='id'
          target='userId'
        >
          <Datagrid>
            <TextField source='id' />
            <TextField source='type' />
            <TextField source='carBrand' />
            <TextField source='carModel' />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}


export function UserEdit (props: Record<string, unknown>) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source='leadSource' />
        <DateInput source='createdAt' locales='sv-SE' />
        <TextInput source='email' />
        <BooleanInput source='emailVerified' />
        <BooleanInput source='hasCompletedRegistration' />
        <BooleanInput source='isAnonymous' />
        <DateInput source='lastSignInTime' locales='sv-SE' />
        <DateInput
          source='dieselNewsletterSubscribedAt'
          locales='sv-SE'
        />
        <TextInput source='phone' label='User provided phone number' />
        <TextInput
          source='phoneNumber'
          label='Phone number from auth provider'
        />
      </SimpleForm>
    </Edit>
  )
}


export function UserCreate ({...props}) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='leadSource' />
        <DateInput source='createdAt' locales='sv-SE' />
        <TextInput source='email' />
        <BooleanInput source='emailVerified' />
        <BooleanInput source='hasCompletedRegistration' />
        <BooleanInput source='isAnonymous' />
        <DateInput source='lastSignInTime' locales='sv-SE' />
        <DateInput
          source='dieselNewsletterSubscribedAt'
          locales='sv-SE'
        />
        <TextInput source='phone' label='User provided phone number' />
        <TextInput
          source='phoneNumber'
          label='Phone number from auth provider'
        />
      </SimpleForm>
    </Create>
  )
}
