import React from "react"
import useCustomFields, { CustomFieldType } from "../../hooks/useCustomFields"
import {
  BooleanInput,
  DateInput,
  NumberInput,
  TextInput,
  regex,
} from "react-admin"

export default function CustomFieldFormInput ({...props}) {
  const {customFields, loading} = useCustomFields()
  const validateCurrencyFormat =
    regex(/(:?^[0-9]*$|^[0-9]{1,10}([0-9]{3})*(([\\,]{1}[0-9]{2})|())$)/,
      "Currency format is not valid")

  if (loading) {
    return <></>
  }

  return (
    <>
      {customFields.map((customField: CustomFieldType) => {
        switch (customField.type) {
          case "boolean":
            return <BooleanInput
              label={customField.label}
              source={props.getSource(customField.name) || ""}
              style={{display: "block"}}
            />
          case "date":
            return <DateInput
              source={props.getSource(customField.name) || ""}
              label={customField.label}
              style={{
                marginRight: "1rem",
              }}
            />
          case "currency":
            return <TextInput
              label={customField.label}
              source={props.getSource(customField.name) || ""}
              validate={validateCurrencyFormat}
              style={{
                marginRight: "1rem",
              }}
            />
          case "number":
            return <NumberInput
              label={customField.label}
              source={props.getSource(customField.name) || ""}
              style={{
                marginRight: "1rem",
              }}
              onKeyDown={
                (evt) => ["e", "E"].includes(evt.key) && evt.preventDefault()
              }
            /> 
          case "text":
          default:
            return <TextInput
              label={customField.label}
              source={props.getSource(customField.name) || ""}
              style={{
                marginRight: "1rem",
              }}
            /> 
        }
      })}
    </>
  )
}
