import {
  BooleanInput,
  Edit,
  FormDataConsumer, FunctionField, Link,
  required, SelectArrayInput,
  SelectInput,
  ReferenceArrayInput, TextField, TextInput,
  TabbedForm, FormTab, ReferenceField, DateInput,
} from "react-admin"
import React from "react"
import {GdprFiles} from "./index"
import TimestampField from "../TimestampField"
import insuranceChoices from "../../lib/constants/legalinsurance.json"
import GdprCommunications from "./GdprCommunications"


const typeChoices = [
  { id: "Funder", name: "Funder" },
  { id: "Insurance", name: "Insurance" },
]

const lawFirmChoices = [
  { id: "LEO", name: "LEO" },
  { id: "Hartung", name: "Hartung" },
]

const languageChoices = [
  { id: "de", name: "German" },
  { id: "en", name: "English" },
]

const snoozeChoices = [
  { id: null, name: "None" },
  { id: 1, name: "24 hours" },
  { id: 2, name: "48 hours" },
  { id: 3, name: "3 days" },
  { id: 5, name: "5 days" },
  { id: 14, name: "2 weeks" },
  { id: 30, name: "1 month" },
  { id: 180, name: "6 months" },
]

const cancellationPolicyChoiches = [
  { id: "ignoreCancellationPolicy", name: "Ignore cancellation policy" },
  { id: "respectCancellationPolicy", name: "Respect cancellation policy" },
]

export default function GdprCaseEdit ({ ...props }) {
  function transform (data: any) {
    let endSnoozeAt = null

    if (data.snoozeTime) {
      const date = new Date()
      date.setDate(date.getDate() + data.snoozeTime)
      endSnoozeAt = date
    }

    return {
      ...data,
      endSnoozeAt: endSnoozeAt,
      _endSnoozeAt: endSnoozeAt ? endSnoozeAt.toISOString() : endSnoozeAt,
    }
  }
  return (
    <Edit {...props} transform={transform}>
      <TabbedForm
        sanitizeEmptyValues={false}
      >
        <FormTab label="Data">
          <TextField
            source='id' fullWidth
          />
          <FunctionField
            label='User ID'
            source='userId'
            render={(gdprCase: any) =>
              <Link to={`/users/${gdprCase.userId}/show`}>
                {gdprCase.userId}
              </Link>
            }
          />
          <ReferenceField source="userId" reference="users"
            label="Email">
            <FunctionField
              source='email'
              render={(users: any) =>
                <Link to={`/users/${users.id}/show`}>
                  {users.email}
                </Link>
              }
            />
          </ReferenceField>
          <ReferenceField source="userId" reference="users"
            label="Phone number">
            <FunctionField
              source='phone'
              render={(users: any) =>
                <Link to={`/users/${users.id}/show`}>
                  {users.phone}
                </Link>
              }
            />
          </ReferenceField>
          <TextInput
            source='fullName' fullWidth
          />
          <ReferenceArrayInput source="gdprBreachesIds"
            reference="gdprBreaches">
            <SelectArrayInput optionText="Name" translateChoice={false} />
          </ReferenceArrayInput>
          <SelectInput
            source='type'
            choices={typeChoices}
            validate={[required()]}
          />
          <SelectInput
            source='lawFirm'
            choices={lawFirmChoices}
            validate={[required()]}
          />
          <SelectInput
            source='cancellationPolicy'
            choices={cancellationPolicyChoiches}
            validate={[required()]}
          />
          <FormDataConsumer fullWidth>
            {({ formData, ...rest }) => formData.type ===
            "Insurance"
              ? (
                <div>
                  <SelectInput
                    fullWidth
                    label='Name of the legal insurance'
                    source='legalInsurance'
                    choices={insuranceChoices}
                    emptyValue={"Andere Versicherung"}
                    emptyText="Andere Versicherung"
                    allowEmpty={true}
                  /> 

                  {
                    (!formData.legalInsurance || 
                    formData.legalInsurance === "Andere Versicherung") && 
                    <TextInput
                      source='insuranceName'
                      fullWidth
                      label='Custom insurance name'
                    />
                  }
                  <TextInput
                    source='policeNumber'
                    fullWidth
                    label='Policy number'
                  />
                  <TextInput
                    source='policyHolderName'
                    fullWidth
                    label='Name of the policyholder'
                  />
                  <DateInput
                    source='insuranceClosingDate'
                    label='Legal insurance closing date'
                    locales="sv-SE"
                    fullWidth
                  />
                </div>
              ) : null
            }
          </FormDataConsumer>
          <TextInput
            source='street'
            fullWidth
            label='Street'
          />
          <TextInput
            source='postcode'
            fullWidth
            label='Postal code'
          />
          <TextInput
            source='city'
            fullWidth
            label='City'
          />
          <SelectInput
            source='language'
            choices={languageChoices}
          />
          <BooleanInput
            label='Insurance info provided'
            source='insuranceInfoProvided'
          />
          <BooleanInput
            label='Insurance screenshot provided'
            source='screenshotLegalInsuranceUploaded'
          />
          <BooleanInput
            label='Ready for submission'
            source='markForSubmission'
          />
          <TimestampField
            label='Accepted TOC and Data Protection at'
            source='acceptedTocAndDataProtectionAt'
            locales='sv-SE'
            showTime
            addLabel
          />
          <SelectInput
            source="snoozeTime"
            choices={snoozeChoices}
          />
          <FormDataConsumer fullWidth>
            {({ formData, ...rest }) => formData.snoozeTime
              ? <div style={{marginBottom: "0.5rem"}}>
                <label
                  style={{
                    display: "block",
                    color: "rgba(255, 255, 255, 0.7)",
                    padding: "0",
                    fontSize: "0.8rem",
                    marginBottom: "0.5rem",
                  }}
                >Snooze end at</label>
                <>
                  <TimestampField
                    label='Snooze end at'
                    source='endSnoozeAt'
                    locales='sv-SE'
                    showTime
                    addLabel
                    {...rest}
                  />
                </>

              </div>
              : null
            }
          </FormDataConsumer>
          <TextField
            source='lawFirm' fullWidth
          />
          <FunctionField
            label='UTM Parameters'
            source='utmParams'
            render={(obj: any) => {
              if (!obj?.utmParams) {
                return
              }

              return <table>
                {Object
                  .entries(
                    obj.utmParams as
                      Record<string, { source: string, value: any }>,
                  )
                  .map(
                    ([key, value]: [string, { source: string, value: any }]) =>
                      <tr>
                        <td>{key}: </td>
                        <td>
                          <strong>
                            {value}
                          </strong>
                        </td>
                      </tr>,
                  )
                }
              </table>
            }}
          />
        </FormTab>
        <FormTab label='Files'>
          <GdprFiles />
        </FormTab>
        <FormTab label='Communications'>
          <GdprCommunications />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
