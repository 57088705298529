import { SvgIcon } from "@material-ui/core"
import React from "react"
import { oneLine } from "common-tags"


export default function ZendeskIcon (props: any) {
  return (
    <SvgIcon
      height='1em'
      overflow='visible'
      viewBox='0 0 24 24'
      width='1em'
      {...props}
    >
      <path
        d={oneLine`
          M11.086 6.338v17.656H0z
          m0-6.332c.002 4.04-2.48 7.321-5.543 7.321
          S-.002 4.04 0 0zM12.912 24
          c0-4.039 2.482-7.312 5.544-7.312 3.061 0 5.543 3.273 5.543 7.307z
          m0-6.332V0H24z
        `}
        stroke='none'
        fill='#03363d'
        fillRule='nonzero'
        id='zendesk-icon_svg__k5saiqiii9'
      />
    </SvgIcon>
  )
}
