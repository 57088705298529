import React from "react"
import styled from "styled-components"

import { Flex, Container, Body } from "../../lib"


function InfoIcon () {
  return (
    <svg
      style={{ minWidth: 24, marginLeft: 10, marginRight: 15 }}
      width='24'
      height='40'
    >
      <g fill='none' fillRule='nonzero'>
        <path
          fill='#2460D8'
          d='
            M11.986.486a.548.548 0 0 0-.555.556v5.277
            c0 .278.208.556.555.556a.548.548 0 0 0 .556-.556
            V1.042a.548.548 0 0 0-.556-.556zM18.167 8.472
            c.07.07.208.07.277.07.209 0 .348-.07.417-.278L21.57 3.75
            c.14-.278.07-.556-.208-.694-.278-.14-.555-.07-.694.208l-2.709 4.514
            c-.139.208 0 .555.209.694zM14.833 7.083h.14
            a.522.522 0 0 0 .485-.347l.973-3.055
            c.069-.278-.07-.556-.348-.625-.277-.07-.555.069-.625.347
            l-.972 3.055
            c-.07.209.07.486.347.625zM23.514 7.222a.545.545 0 0 0-.764 0
            l-2.361 2.153a.545.545 0 0 0 0 .764
            c.07.139.278.139.417.139a.53.53 0 0 0 .347-.14l2.36-2.152
            a.545.545 0 0 0 0-.764zM5.528 8.542
            c.07 0 .208 0 .278-.07.277-.139.347-.486.208-.694L3.306 3.194
            c-.14-.277-.487-.347-.695-.208-.278.139-.347.486-.208.695
            L5.11 8.194
            c.07.278.278.348.417.348zM7.889 3.056
            c-.278.069-.417.347-.347.625l.972 3.055
            c.07.208.278.347.486.347h.139
            c.278-.07.417-.347.347-.625l-.972-3.055
            c-.07-.278-.347-.486-.625-.347z
            M.458 7.222a.545.545 0 0 0 0 .764
            L2.82 10.14a.53.53 0 0 0 .348.139
            c.139 0 .277-.07.416-.14a.545.545 0 0 0 0-.763l-2.36-2.153
            a.545.545 0 0 0-.765 0z
          '
        />
        <path
          fill='#161466'
          d='
            M11.361 8.889a9.728 9.728 0 0 0-9.028 9.167
            c-.07 1.875.348 3.68 1.25 5.277v.07
            c.07.139.14.208.209.347a29.244 29.244 0 0 1 3.472 9.861
            c.07.486.139.972.208 1.597.14.695.625 1.25 1.25 1.459v.139
            c0 1.388 1.459 2.569 3.195 2.569 1.805 0 3.194-1.18 3.194-2.57
            v-.069
            c.556-.208 1.042-.625 1.25-1.18v-.14
            c.07-.833.208-1.735.347-2.569.556-3.055 1.598-5.972
            3.056-8.541.278-.417.555-.834.764-1.32.694-1.32.972
            -2.778.972-4.305
            0-2.64-1.111-5.278-3.056-7.084
            C16.64 9.653 14.07 8.75 11.361 8.89zm.625 29.305
            c-1.18 0-2.153-.694-2.153-1.527h4.306
            c0 .833-.972 1.527-2.153 1.527zm2.5-2.5h-5
            c-.486 0-.903-.347-.972-.694-.07-.556-.139-1.042-.208-1.528
            0-.139-.07-.347-.07-.486h7.5
            c-.139.764-.208 1.458-.347 2.222-.139.209-.486.486-.903.486z
            m-2.5-11.736
            c.556.417 1.32.834 2.083 1.042-.625 2.43-1.18 5.764-1.319 6.875
            h-1.528
            c-.139-1.111-.694-4.444-1.32-6.875.765-.278
            1.529-.694 2.084-1.042zm-.07-3.125h.209
            c.556.14.486.764.486.903 0
            .347-.347.695-.625.903-.278-.208-.555-.556-.625-.903
            0-.07 0-.764.556-.903zm7.848 1.528
            c-.278.417-.486.833-.764 1.25a29.758 29.758 0 0 0-3.056 8.195
            h-2.152
            c.208-1.25.694-4.445 1.32-6.667.346 0 .624 0 .902-.07.833-.208
            1.25-1.11 1.18-1.805-.069-.695-.486-1.18-1.041-1.25-.347-.07
            -1.042.139-1.39.903-.138.277-.207.625-.346.972a5.134 5.134
            0 0 1-1.528-.695
            c.347-.347.764-.833.833-1.458.07-.833-.347-1.805-1.389-2.014
            H11.986h-.07
            c-1.041.209-1.458 1.18-1.388 2.014.07.625.416 1.111.833 1.458
            a5.134 5.134 0 0 1-1.528.695
            c-.139-.417-.277-.764-.347-.972-.347-.834-1.042-.973-1.389-.903
            -.625.07-1.041.555-1.041 1.25-.07.694.347 1.597 1.18
            1.805.278.07.625.07.903.07.625 2.222 1.111 5.417 1.32 6.667H8.027
            c-.695-3.125-1.806-6.042-3.334-8.612-.069-.138-.138-.277-.208-.347
            0 0 0-.07-.07-.07-.833-1.388-1.18-3.055-1.11-4.721.208-4.375
            3.68-7.848 8.055-8.125 2.43-.14 4.722.625 6.458 2.291 1.737 1.597
            2.709 3.959 2.709 6.32.07 1.32-.209 2.57-.764 3.82zm-4.375 1.736
            c.07-.278.208-.555.278-.764.139-.277.277-.277.277-.277.14 0
            .14.277.14.277 0 .209-.07.625-.417.695 0 .07-.14.07-.278.07z
            m-6.875 0
            c-.139 0-.208 0-.347-.07-.348-.069-.417-.485-.417-.694 0 0
            0-.277.139-.277.07 0 .208 0 .347.277.139.278.208.486.278.764z
          '
        />
      </g>
    </svg>
  )
}


const Wrapper = styled(Flex)`
  background: white;
  border-radius: 8px;
  max-width: 600px;
  /* box-shadow: 0 2px 10px 0 rgba(22, 20, 102, 0.15); */
  min-height: 45px;
  padding: 12px;
  box-sizing: border-box;
`


// TODO: style this
export default function InfoBox ({ children }: { children: any }) {
  return (
    <Container>
      <Wrapper alignItems='center'>
        <InfoIcon />
        {/* @ts-expect-error */}
        <Body.Small>
          {children}
        </Body.Small>
      </Wrapper>
    </Container>
  )
}
