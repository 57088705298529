export const dieselTags = [
  { name: "7 Days Sent", id: "7DaysSent" },
  { name: "T & C not accepted", id: "t&cNotAccepted" },
  { name: "Has an RSV", id: "hasAnRSV" },
  { name: "TOP Models", id: "topModels" },
  { name: "KAP Models", id: "kapModels" },
  { name: "Ready for BRC", id: "readyForBrc" },
  { name: "Österreichischer Mdt", id: "austrainMandant" },
]

export const ocTags = [
  { name: "Phone Call Needed", id: "phoneCallNeeded" },
  { name: "Super High Value Claim", id: "superHighValue" },
  { name: "High Value Claim", id: "highValueClaim" },
  { name: "Low Value Claim", id: "lowValueClaim" },
  { name: "POA Missing", id: "poaMissing" },
  { name: "Funding Committed", id: "fundingCommitted" },
]
