import { Create } from "react-admin"
import CustomFieldForm from "./CustomFieldForm"

export default function CustomFieldsCreate ({...props}) {
  return (
    <Create {...props}>
      <CustomFieldForm />
    </Create>
  )
}
