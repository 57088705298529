import { useState, useEffect } from "react"
import firebase from "../../firebase"
import useDocument from "./useDocument"

const db = firebase.firestore()


function buildTicket (
  {
    data = {},
    snapshot,
  } : {
    data: Record<string, any> | null,
    snapshot: Record<string, any> | null,
  },
) {
  return {
    ...data,
    update: (data: Record<string, any>) => {
      if (snapshot) {
        // Strips the 'update' method during an update
        const { update, ...others } = data
        return snapshot.ref.set({
          ...others,
        }, { merge: true })
      }
      else {
        throw new Error("No snapshot found for ticket, cannot update")
      }
    },
  }
}


/**
 * This hook will return the first ticket from the tickets collection
 * for claimId and provide constant updates
 *
 * TODO: New tickets are created with a hash format ticket_claimId.
 *   Once a datamod is completed to format old ticket ids,
 *   update this function for better performance
 *   by referencing the document directly.
 */

export default function useTicket (claimId: string) {
  const [claimsError, setClaimsError] = useState<any | null>(null)
  const [loadingClaims, setLoadingClaims] = useState(true)
  const [ticketId, setTicketId] = useState<string | null>(null)
  const [data, snapshot, loading, error] = useDocument(
    ticketId
      ? `tickets/${ticketId}`
      : null,
  )

  /**
   * This effect will create or find the first ticket
   * related to a specific claimId
   */
  useEffect(
    () => {
      if (!claimId) {
        return
      }

      const unlisten = db
        .collection("tickets")
        .where("claimId", "==", claimId)
        .onSnapshot({
          next: (snap) => {
            if (!snap.empty) {
              setTicketId(snap.docs[0].id)
            }
            setLoadingClaims(false)
          },
          error: err => {
            setClaimsError(err)
            setLoadingClaims(false)
          },
        })
      return unlisten
    },
    [claimId],
  )

  return [
    buildTicket({ data, snapshot }),
    loading || loadingClaims,
    error || claimsError,
    snapshot && snapshot.ref,
  ]
}
