import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import moment from "moment"
import React from "react"


interface UpdateTimesParams {
  createdAt: FirebaseAdminTimestamp
  updatedAt: FirebaseAdminTimestamp
  claimLastUpdated: FirebaseAdminTimestamp
  [x: string]: any
}

export default function UpdateTimes (
  { createdAt, updatedAt, claimLastUpdated, ...props }: UpdateTimesParams,
) {
  return (
    <div {...props}>
      <Typography variant='caption' color='textPrimary'>
        Ticket Created: {createdAt
          ? moment(createdAt.toDate()).format("LLL")
          : "Unknown"
        }
      </Typography>
      <Divider style={{ marginTop: 2, marginBottom: 2 }} />
      <Typography variant='caption' color='textPrimary'>
        Ticket Last Updated:  {updatedAt
          ? moment(updatedAt.toDate()).format("LLL")
          : "Unknown"
        }
      </Typography>
      <Divider style={{ marginTop: 2, marginBottom: 2 }} />
      <Typography variant='caption' color='textPrimary'>
        Claim Last Updated:  {claimLastUpdated
          ? moment(claimLastUpdated.toDate()).format("LLL")
          : "Unknown"
        }
      </Typography>
    </div>
  )
}
