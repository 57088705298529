import React from "react"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Typography from "@material-ui/core/Typography"
import { TicketAssignee } from "../../../constants/enums"


interface MoveBtnsParams {
  ticket: Ticket
  claimType?: string
  [x: string]: any
}

export default function MoveButtons (
  { ticket, claimType, ...props }: MoveBtnsParams,
) {
  return (
    <div {...props}>
      <Typography variant='subtitle2'>Move To</Typography>
      <ButtonGroup color='primary' size='small' fullWidth>
        {claimType === "diesel" &&
          <Button
            variant='outlined'
            color='primary'
            onClick={
              () => ticket.update({ assignedGroup: TicketAssignee.AWAITING_KV })
            }
          >
            Aw.KV
          </Button>
        }

        <Button
          variant='outlined'
          color='primary'
          onClick={
            () => ticket.update({ assignedGroup: TicketAssignee.DATA_ENTRY })
          }
        >
          D.Entry
        </Button>

        <Button
          variant='outlined'
          color='primary'
          onClick={
            () => ticket.update({ assignedGroup: TicketAssignee.DATA_EVAL })
          }
        >
          D.Eval
        </Button>

        <Button
          variant='outlined'
          color='primary'
          onClick={
            () => ticket.update({ assignedGroup: TicketAssignee.FOLLOW_UP })
          }
        >
          FollowUp
        </Button>

        <Button
          variant='outlined'
          color='primary'
          onClick={
            () => ticket.update({ assignedGroup: TicketAssignee.COMPLETE })
          }
        >
          Complete
        </Button>
      </ButtonGroup>
    </div>
  )
}
