import React, {useEffect, useState} from "react"
import DefaultActions from "./DefaultActions"
import DefaultPagination from "./DefaultPagination"
import getHttpsCallable from "../utils/getHttpsCallable"
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  Create,
  Edit,
  NumberInput,
  TextInput,
  required,
  SelectInput,
  FormDataConsumer,
  SelectField,
  ArrayInput,
  SimpleFormIterator, SelectArrayInput, BooleanInput,
} from "react-admin"

import JsonInput from "./JsonInput"
const getTemplates = getHttpsCallable("admin-getTemplates")
const getNotifications =
  getHttpsCallable("admin-getScheduledNotificationTemplates")



const stateChoices = [
  { id: "active", name: "Active" },
  { id: "disabled", name: "Disabled" },
]

const notificationTypeChoices: { id: NotificationType; name: string }[] =
 [
   { id: "whatsApp", name: "Whatsapp" },
   { id: "email", name: "Email" },
 ]

const endpointTypeChoices: { id: EndpointType; name: string }[] =
  [
    { id: "caseUpdateHook", name: "caseUpdateHook" },
    { id: "claims", name: "claims" },
    { id: "perspective", name: "perspective"},
    { id: "partners", name: "partners"},
    { id: "claimChanges", name: "claimChanges"},
    { id: "gdprCases", name: "gdprCases"},
    { id: "none", name: "None"},
  ]

const lawFirmChoices = [
  { id: "AILTS", name: "AILTS" },
  { id: "BRC", name: "BRC" },
  { id: "KAP", name: "KAP" },
  { id: "LEO", name: "LEO" },
  { id: "Any", name: "Any" },
]

export function NotificationSchedulerList ({ ...props }) {
  return (
    <List
      {...props}
      actions={<DefaultActions />}
      perPage={15}
      pagination={<DefaultPagination />}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' sortable={false} />
        <SelectField
          source="notificationType"
          label="Type"
          choices={notificationTypeChoices} />
        <SelectField
          source="endpoint"
          label="Endpoint"
          choices={endpointTypeChoices} />
        <TextField source='templateName' label="Template" sortable={false} />
        <TextField source='lawFirm' label="Law Firm" sortable={false} />
        <TextField source='scheduledTime'
          label='Timer (hours)' sortable={false} />
        <SelectField source="state"
          choices={stateChoices} />
      </Datagrid>
    </List>
  )
}


function NotificationSchedulerForm (props: any) {
  const [emailTemplates, setEmailTemplates] = useState([])
  const [whatsappTemplates, setWhatsappTemplates] = useState([])
  const [notifications, setNotifications] = useState([])
  const [fetch, setFetch] = useState(true)

  useEffect(() => {
    async function getAllTemplates () {
      const whatsapp = await getTemplates("whatsapp")
      const email = await getTemplates("email")
      const notifications = await getNotifications(null)

      setWhatsappTemplates(whatsapp?.data)
      setEmailTemplates(email?.data)
      setNotifications(notifications?.data)
      setFetch(false)
    }

    if (fetch) {
      getAllTemplates()
    }
  }, [whatsappTemplates, emailTemplates, fetch])

  return (
    <SimpleForm {...props}>
      <SelectInput
        source='notificationType'
        choices={notificationTypeChoices}
        validate={[required()]}
      />

      <SelectInput
        source='endpoint'
        choices={endpointTypeChoices}
        validate={[required()]}
      />

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            { formData?.endpoint === "claimChanges" &&
              <TextInput source='claimField' />
            }
          </>
        )}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <SelectInput
            label='Template'
            source="templateName"
            translateChoice={false}
            choices={
              formData.notificationType === "whatsApp"
                ? Object
                  .values(whatsappTemplates)
                  .map((template: Template) =>
                    ({ id: template.name, name: template.userDefinedName }))
                : formData.notificationType === "email"
                  ? Object
                    .values(emailTemplates)
                    .map((template: Template) =>
                      ({ id: template.name, name: template.userDefinedName }))
                  : []
            }
            validate={[required()]}
            {...rest}
          />
        )}
      </FormDataConsumer>

      <SelectInput
        source='lawFirm'
        choices={lawFirmChoices}
        validate={[required()]}
      />

      <NumberInput
        source='scheduledTime'
        label='Scheduled time (hours)'
        validate={[required()]}
      />

      <NumberInput
        source='scheduledMinute'
        label='Scheduled time (minutes)'
      />

      <ArrayInput
        source='customFieldWatchers'
        label="Custom Field Values of which one must have changed state">
        <SimpleFormIterator disableReordering>
          <TextInput source="" label=""/>
        </SimpleFormIterator>
      </ArrayInput>

      <JsonInput
        source='conditions'
        multiline
        fullWidth
      />

      <JsonInput
        source='exceptions'
        multiline
        fullWidth
      />

      <SelectArrayInput
        label="Trigger notifications"
        source='triggerNotifications'
        choices={notifications}
        fullWidth
      />

      <BooleanInput source='doNotSendSameTemplateTwice' fullWidth />

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            { formData?.doNotSendSameTemplateTwice === true &&
                <NumberInput
                  source='increaseAmountOfAllowedResubmissions'
                  label='Set custom resubmission amount'
                />
            }
          </>
        )}
      </FormDataConsumer>

      <SelectInput
        source='state'
        choices={stateChoices}
        validate={[required()]}
      />
    </SimpleForm>
  )
}


function removeNulledJSON (data: any) {
  if (typeof data === "object") {
    for (const [key, value] of Object.entries(data)) {
      if (
        typeof value === "object" &&
        (key === "conditions" || key === "exceptions")
      ) {
        data[key] = JSON.parse(
          JSON.stringify(
            value,
            (_, v) => (v === null || (Array.isArray(v) && v.length === 0))
              ? undefined
              : v,
          ),
        )
      }
    }
  }
  return data
}


export function NotificationSchedulerEdit ({ ...props }) {
  return (
    <Edit {...props} transform={removeNulledJSON}>
      <NotificationSchedulerForm {...props}/>
    </Edit>
  )
}


export function NotificationSchedulerCreate ({ ...props }) {
  return (
    <Create {...props} transform={removeNulledJSON}>
      <NotificationSchedulerForm {...props}/>
    </Create>
  )
}

