import FirebaseTimestamp from "./FirebaseTimestamp"

const claimFeedbackFields = {
  id: String,
  claimId: String,
  createdAt: FirebaseTimestamp,
  _createdAt: Date,
  updatedAt: FirebaseTimestamp,
  _updatedAt: Date,
  ratingProcessFlow: Number,
  ratingResult: Number,
  ratingCustomerSupport: Number,
  optionalNote: String,
}

export default claimFeedbackFields
