import React from "react"
import DefaultActions from "./DefaultActions"
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  SelectField,
  SelectInput,
  Edit,
  SimpleForm,
  Create,
  required,
  DateField,
  DateInput,
} from "react-admin"
import DefaultPagination from "./DefaultPagination"

const statusChoices = [
  { id: "active", name: "Active" },
  { id: "disabled", name: "Disabled" },
]

const logicChoices = [
  { id: null, name: "No action" },
  { id: "disableAccount", name: "Disable account" },
]

export function AutomaticAnswersList ({ ...props }) {
  return (
    <List
      {...props}
      actions={<DefaultActions />}
      perPage={15}
      pagination={<DefaultPagination />}
    >
      <Datagrid rowClick='show'>
        <TextField component="pre" source='incomingMsg'
          label='Incoming message'  sortable={false} />
        <SelectField source="status" label='Status' choices={statusChoices} />
        <DateField source="deactivateAnswerAt" label="Deactivation date" />
        <SelectField source="logic" label='Action on incoming message'
          choices={logicChoices} />
      </Datagrid>
    </List>
  )
}


export function AutomaticAnswersEdit ({...props}) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput multiline component="pre" source='incomingMsg'
          label='Incoming message' sortable={false} validate={[required()]} />
        <TextInput multiline component="pre" source='automaticAnswer'
          label='Automatic answer' sortable={false} validate={[required()]} />
        <SelectInput source="status" label='Status' choices={statusChoices} />
        <DateInput source="deactivateAnswerAt" label="Deactivation date" />
        <SelectInput source="logic" label='Action on incoming message'
          choices={logicChoices} />
      </SimpleForm>
    </Edit>
  )
}


export function AutomaticAnswersCreate ({...props}) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput multiline component="pre" source='incomingMsg'
          label='Incoming message' sortable={false} validate={[required()]} />
        <TextInput multiline component="pre" source='automaticAnswer'
          label='Automatic answer' sortable={false} validate={[required()]} />
        <SelectInput source="status" label='Status'
          choices={statusChoices} initialValue='disabled' />
        <DateInput source="deactivateAnswerAt" label="Deactivation date" />
        <SelectInput source="logic" label='Action on incoming message'
          choices={logicChoices} />
      </SimpleForm>
    </Create>
  )
}
