import { makeStyles } from "@material-ui/core/styles"
import { green, indigo, red, orange, blue } from "@material-ui/core/colors"

const useStyles = makeStyles(theme => ({
  list: {
    flexGrow: 1,
    padding: 0,
  },

  key: {
    marginTop: 8,
    color: theme.palette.text.primary,
  },

  callButtons: {
    marginLeft: 12,
  },

  answeredIcon: {
    color: green[600],
  },
  declinedIcon: {
    color: red[300],
  },
  invalidIcon: {
    color: indigo[200],
  },

  sidebar: {
    width: 370,
  },
  active: {
    backgroundColor: indigo[600],
    borderColor: indigo[600],
    borderStyle: "solid",
    borderWidth: 1,
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: "1rem",
  },
  topBadge: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
  },
  kapBadge: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
}))

export default useStyles
