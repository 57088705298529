import React, {useState} from "react"
import keyBy from "lodash/keyBy"
import {
  useQuery,
  Datagrid,
  TextField,
  Pagination,
  Loading,
  ListContextProvider,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  List,
  DateField, SelectField,
} from "react-admin"
import DefaultActions from "../DefaultActions"
import DefaultPagination from "../DefaultPagination"


export default function ({...props}) {
  return (
    <List
      filter={{
        formSubmittedAt__exists: false,
      }}
      sort={{ field: "createdAt", order: "DESC" }}
      {...props}
      actions={<DefaultActions/>}
      perPage={15}
      pagination={<DefaultPagination/>}>
      <Datagrid rowClick="edit">
        <TextField source="fullName" label="Name" sortable={false} />
        <TextField source="userId" label="User Id" sortable={false} />
        <TextField source="id" label="Id" sortable={false} />
        <DateField source="createdAt" label="Created at" sortable={false} />
        <DateField source="updatedAt" label="Updated at" sortable={false} />
        <ReferenceArrayField label="Gdpr breaches" reference="gdprBreaches"
          source="gdprBreachesIds">
          <SingleFieldList>
            <ChipField source="Name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  )
}
