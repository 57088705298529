import React, { useState } from "react"

import {
  useNotify,
} from "react-admin"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import ArchiveIcon from "@material-ui/icons/Archive"
import getHttpsCallable from "../utils/getHttpsCallable"

const transmitClaim = getHttpsCallable("admin-transmitClaim")

export default function SendToCmtButton (
  { claimData, ...props }:
    {
      claimData: {
        id: string
        userId: string
        lawFirm: string
        type: string
        ocOlgNotSuitable: boolean
        funder: string
        transmittedToLawfirmAt: FirebaseAdminTimestamp
      }
      onClick?: () => void,
      component?: React.ElementType,
      [key: string]: any,
    },
) {
  if (!claimData) {
    return null
  }

  const notify = useNotify()
  const [isSending, setIsSending] = useState<boolean>(false)

  props.onClick = async () => {
    setIsSending(true)

    try {
      if (claimData.ocOlgNotSuitable && claimData.funder === "Munster") {
        throw new Error("The claim OLG is not suitable for 'Munster' funder.")
      }

      await transmitClaim({
        claimId: claimData.id,
        recipient: "CMT",
      })

      notify(
        "The claim was successfully submitted.",
        { type: "info", autoHideDuration: 10e3, multiLine: true },
      )
    }
    catch (error: any) {
      notify(error?.message, "warning")
    }

    setIsSending(false)
  }

  return (
    <Button
      {...props}
      variant="outlined"
      color="primary"
      disabled={!claimData || !claimData.id}
    >
      { isSending ||
          <>
            <ArchiveIcon />
            <Hidden mdDown>
              Transmit to CMT
            </Hidden>
          </>
      }
      { isSending && <>⏳</>}
    </Button>
  )
}
