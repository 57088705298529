import React, {useState} from "react"
import keyBy from "lodash/keyBy"
import {
  useQuery,
  Datagrid,
  TextField,
  Pagination,
  Loading,
  ListContextProvider,
} from "react-admin"

export default function UserTrackingList () {
  const [page, setPage] = useState(1)
  const perPage = 20
  const {data, total, loading, error} = useQuery({
    type: "getList",
    resource: "trackedPages",
    payload: {
      pagination: {page, perPage},
      sort: {field: "page", order: "ASC"},
      filter: {},
    },
  })

  if (loading) {
    return <Loading/>
  }
  if (error) {
    return <p>ERROR: {error}</p>
  }

  return (
    <ListContextProvider value={{
      data: keyBy(data, "id"),
      // @ts-ignore
      ids: data.map(({id}) => id),
      total,
      page,
      perPage,
      setPage,
      currentSort: {field: "id", order: "ASC"},
      basePath: "/user-tracking",
      selectedIds: [],
    }}>
      <Datagrid rowClick="show">
        <TextField source="page" label="Page"/>
      </Datagrid>
      <Pagination/>
    </ListContextProvider>
  )
}
