import React, {useEffect, useState} from "react"
import { BooleanField, Datagrid, DateField,
  List, SelectField, TextField } from "react-admin"
import { Tabs, Tab } from "@material-ui/core"
import callTypes from "./callTypes"
import DefaultActions from "../DefaultActions"
import AwaitingKVCallList from "./AwaitingKVCallList/AwaitingKVCallList"
import BFRCallList from "./BFRCallList/BFRCallList"
import OnlineCasinoCallList from "./OnlineCasinoCallList/OnlineCasinoCallList"
import { makeStyles } from "@material-ui/core/styles"


const useStyles = makeStyles(theme => ({
  tabs: {
    color: theme.palette.text.primary,
  },
}))

function DatagridList (
  {
    props,
  }: { props: Record<string, unknown> },
) {

  return (
    <List
      {...props}
      actions={<DefaultActions />}
    >
      <Datagrid
        rowClick='show'
      >
        {/* <TextField source='id' /> */}
        <TextField source='claimId' label='Claim ID' />
        {/* <TextField source='userId' /> */}
        {/* <TextField source='phoneNumber' /> */}
        <BooleanField source='phoneNumberValid' addLabel={true} />
        <TextField source='callResult' addLabel={true} />
        <SelectField source='callType' choices={callTypes} />
        <DateField showTime source='startTime' sortable={false} />
        <DateField showTime source='endTime' sortable={false} />
        {/* <NumberField source='duration' /> */}
        {/* <TextField multiline fullWidth source='callNotes' /> */}
        {/* <TextField source='claimNotes' /> */}
        {/* <EditButton /> */}
      </Datagrid>
    </List>
  )
}

export default function PhoneCallsList (props: Record<string, unknown>) {
  const classes = useStyles()
  const [tabNumber, setTabNumber] = useState(3)

  return (
    <>
      <Tabs
        value={tabNumber}
        onChange={(e, i) => setTabNumber(i)}
        className={classes.tabs}
        variant='fullWidth'
      >
        <Tab label='Datagrid' />
        <Tab label='AwaitingKV Call List' />
        <Tab label='BFR Call List' />
        <Tab label='Online Casino Call List' />
      </Tabs>
      {tabNumber === 0 && (
        <DatagridList props={props}/>
      )}
      {tabNumber === 1 && <AwaitingKVCallList {...props} />}
      {tabNumber === 2 && <BFRCallList {...props} />}
      {tabNumber === 3 && <OnlineCasinoCallList {...props} />}
    </>
  )
}
