import React, {useEffect, useState} from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { grey } from "@material-ui/core/colors"
import { find, get } from "lodash"

import getHttpsCallable from "../utils/getHttpsCallable"
import MultiEmailWsPrompt from "./MultiEmailWsPrompt"


const useStyles = makeStyles(theme => ({
  toolbar: {
    flexGrow: 1,
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  textarea: {
    width: "100%",
    backgroundColor: grey[800],
    color: theme.palette.text.primary,
    borderRadius: 4,
    borderColor: theme.palette.primary.light,
  },
}))


interface ResultDialogParams {
  open: boolean
  handleClose: (a: unknown) => unknown
  sending: boolean
  results: SendEmailTemplateResult[]
  claimIds: string[]
  template: string
  resultError: Record<string, any> | string
  claimType: string
}

function ResultDialog (
  {
    open,
    handleClose,
    sending,
    results,
    resultError,
    claimIds,
    template,
    claimType,
  }: ResultDialogParams,
) {
  const sentCount = results.filter(r => r.sent).length
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Result</DialogTitle>
      <DialogContent>
        {sending
          ? (
            <DialogContentText>
              Currently sending {claimIds.length} people
              the {template} template ....
            </DialogContentText>
          )
          : (
            <>
              <Typography variant='h6'>
                Sent {sentCount} from {claimIds.length}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {claimType === "email" ? "Email" : "ClaimID"}
                    </TableCell>
                    {/* <TableCell>Email</TableCell> */}
                    <TableCell>Sent</TableCell>
                    <TableCell>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((result) => {
                    return (
                      <TableRow key={result.claimId}>
                        <TableCell>
                          {claimType === "email"
                            ? result.email
                            : result.claimId}
                        </TableCell>
                        {/* <TableCell>{result.email}</TableCell> */}
                        <TableCell>{result.sent ? "yes" : "no"}</TableCell>
                        <TableCell>
                          {
                            result.error
                              ? typeof resultError === "object"
                                ? resultError.message || "An error has occurred"
                                : resultError
                              : ""
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          disabled={sending}
          color='primary'
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}


const sendEmailTemplate = getHttpsCallable("admin-sendEmailTemplate")
const getTemplates = getHttpsCallable("admin-getTemplates")


export default function SendEmails ({ list = [] }) {
  const [claimType, setClaimType] = useState("claim")
  const [template, setTemplate] = useState("")
  const [templateError, setTemplateError] = useState<string | null>(null)
  const [claimIds, setClaimIds] = useState(list)
  const [claimIdsError, setClaimIdsError] = useState<string | null>(null)
  const [results, setResults] = useState([])
  const [resultError, setResultError] = useState({})
  const [sending, setSending] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const classes = useStyles()
  const [templates, setTemplates] = useState([])
  const [fetchTemplate, setFetchTemplate] = useState(true)

  useEffect(() => {
    async function getTemplate () {
      const data = await getTemplates("email")
      setTemplates(data?.data)
      setFetchTemplate(false)
    }

    if (fetchTemplate) {
      getTemplate()
    }
  }, [fetchTemplate])

  async function handleSend () {
    if (!template) {
      return setTemplateError("You must select a template to send")
    }

    if (claimIds.length < 1) {
      return setClaimIdsError("You must input claim Ids")
    }
    setSending(true)
    setDialogOpen(true)

    try {
      const selectedTemplate = find(
        templates as { [key: string]: any },
        t => t.name === template,
      )

      console.info("-> : selectedTemplate", selectedTemplate)

      if (!selectedTemplate.pathname) {
        selectedTemplate.pathname = "/sign-in"
      }

      const result = await sendEmailTemplate({
        claimIds,
        templateName: selectedTemplate.name,
        templatePathname: selectedTemplate.pathname,
        claimType: claimType,
      })

      setSending(false)
      console.info("-> : result", result)

      const flatResults = result.data.map((data: SendEmailTemplateResult) => {
        if (data.error) {
          setResultError(data.message)
        }

        return {
          claimId: data.claimId,
          email: data.email,
          sent: data.sent,
          error: data.error,
        }
      })

      // what the hell...
      setResults(flatResults)
    }
    catch (e) {
      window.alert(`
      problem occurred trying to commit send operation
      ${e}
      `)
    }
  }

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <ResultDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        sending={sending}
        results={results}
        resultError={resultError}
        claimIds={claimIds}
        template={template}
        claimType={claimType}
      />
      <Grid>
        <Toolbar className={classes.toolbar}>
          <div>
            <FormControl style={{minWidth: 300, display: "flex"}}>
              <InputLabel htmlFor='template'>Claim type</InputLabel>
              <Select
                disabled={sending}
                value={claimType}
                onChange={(event: any /* ChangeEvent */) => {
                  setClaimType(event.target.value)
                }}
              >
                <MenuItem value="claim">
                  Regular claim
                </MenuItem>
                <MenuItem value="gdpr">
                  GDPR claim
                </MenuItem>
                <MenuItem value="email">
                  Email Address
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{minWidth: 300, display: "flex"}}>
              <InputLabel htmlFor='template'>Template</InputLabel>
              <Select
                error={Boolean(templateError)}
                disabled={sending}
                value={template}
                onChange={(event: any /* ChangeEvent */) => {
                  setTemplate(event.target.value)
                  setTemplateError(null)
                }}
              >
                {Object
                  .values(templates)
                  .sort((a: Template, b: Template) : number =>
                    (a.userDefinedName && b.userDefinedName)
                      ? a.userDefinedName.localeCompare(b.userDefinedName)
                      : 0,
                  )
                  .sort((a: Template, b: Template) : number =>
                    Number(a.userDefinedName?.includes(":")) -
                    Number(b.userDefinedName?.includes(":")),
                  )
                  .map((template: Template) =>
                    (<MenuItem key={template.name} value={template.name}>
                      {template.userDefinedName}
                    </MenuItem>))
                }
              </Select>
              {templateError &&
                <FormHelperText>{templateError}</FormHelperText>
              }
            </FormControl>
          </div>
          <MultiEmailWsPrompt
            successAction={handleSend}
            claimIds={claimIds}
            selectedTemplate={template}
            template={
              templates.filter((item: Template) => item.name === template)[0]
            }
            sending={sending}
          >
            Send
          </MultiEmailWsPrompt>
        </Toolbar>

        <div style={{ margin: 30 }}>
          {claimIdsError &&
            <Typography variant='caption' style={{ color: "red" }}>
              {claimIdsError}
            </Typography>
          }
          <Typography variant='subtitle1' color='textPrimary'>
            {claimType === "email" ? "Emails" : "Claim Ids"}
          </Typography>
          <Typography variant='caption' color='textPrimary'>
            Place one {claimType === "email" ? "email" : "claim id"} per line
          </Typography>
          <textarea
            className={classes.textarea}
            rows={30}
            defaultValue={list.length > 0 ? list.join("\n") : ""}
            onChange={(event: any /* ChangeEvent */) => {
              setClaimIds(
                event.target.value
                  .trim()
                  .split(/\r?\n/)
                  .map((s: string) => s.trim()),
              )
              setClaimIdsError(null)
            }}
          />
        </div>
      </Grid>
    </>
  )
}
