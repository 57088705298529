import React, {useEffect, useState} from "react"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Hidden,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import EmailIcon from "@material-ui/icons/Email"

import { Flex } from "../lib"
import getHttpsCallable from "../utils/getHttpsCallable"
import EmailWsPrompt from "./EmailWsPrompt"


const sendEmailTemplate = getHttpsCallable("admin-sendEmailTemplate")
const getTemplates = getHttpsCallable("admin-getTemplates")

interface EmailSendDialogParams {
  open: any
  handleClose: any
  handleSendEmail: any
  email: string
  template: string
  sending: boolean
  templateError: any
  setTemplate: any
  setTemplateError: any
  setSending: any
  setResult: any
  claimId: string
  result: SendEmailTemplateResult | null
}

function EmailSendDialog (
  {
    open,
    handleClose,
    handleSendEmail,
    email,
    template,
    sending,
    templateError,
    setTemplate,
    setTemplateError,
    setSending,
    setResult,
    result,
    claimId,
  }: EmailSendDialogParams,
) {
  function SendEmailForm () {
    const [templates, setTemplates] = useState([])
    const [fetchTemplate, setFetchTemplate] = useState(true)

    useEffect(() => {
      async function getTemplate () {
        const data = await getTemplates("email")
        setTemplates(data?.data)
        setFetchTemplate(false)
      }

      if (fetchTemplate) {
        getTemplate()
      }
    }, [fetchTemplate])

    return (
      <>
        <DialogContent>
          <DialogContentText>
            Select the template you wish to send to the applicant of this claim.
          </DialogContentText>
          <Select
            error={Boolean(templateError)}
            disabled={sending}
            value={template}
            fullWidth
            label='Template'
            onChange={(e) => {
              setTemplate(e.target.value)
              setTemplateError(null)
            }}
          >
            {Object
              .values(templates)
              .sort((a: Template, b: Template) : number =>
                (a.userDefinedName && b.userDefinedName)
                  ? a.userDefinedName.localeCompare(b.userDefinedName)
                  : 0,
              )
              .sort((a: Template, b: Template) : number =>
                Number(a.userDefinedName?.includes(":")) -
                Number(b.userDefinedName?.includes(":")),
              )
              .map((template: Template) => (
                <MenuItem key={template.name} value={template.name}>
                  {template.userDefinedName}
                </MenuItem>
              ))
            }
          </Select>
          {templateError &&
            <FormHelperText error>{templateError}</FormHelperText>
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <EmailWsPrompt
            successAction={() => {
              handleSendEmail(template, setSending, setTemplateError, setResult)
            }}
            selectedTemplate={template}
            templates={templates}
            claimId={claimId}
            failureAction={handleClose}
          >
            Send
          </EmailWsPrompt>
        </DialogActions>
      </>
    )
  }

  function SendResult () {
    function parseMessage (result: Record<string, any> | null) {
      const message = result?.message[0]

      if (typeof message === "object") {
        const messageRecord = message as Record<string, string | number>

        return {
          message: messageRecord?.message,
          status: messageRecord.status,
          email: message.email,
        }
      }

      if (typeof message === "string") {
        return {
          message: result?.message,
          status: result?.error ? "error" : "success",
          email: null,
        }
      }

      return { // no result data?
        message: "",
        status: "error",
        email: null,
      }
    }

    const message = parseMessage(result)

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ClaimID</TableCell>
              <TableCell align='center'>Email</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center'>Reject Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component='th' scope='row'>
                {result?.claimId}
              </TableCell>
              <TableCell>{message?.email ?? email}</TableCell>
              <TableCell>{message.status}</TableCell>
              <TableCell>{message.message || ""}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Button onClick={handleClose} color='primary'>
          Close
        </Button>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>Send Email</DialogTitle>
      {!sending && !result &&
          <SendEmailForm />}
      {sending &&
        <Flex center style={{ margin: 20 }}>
          <CircularProgress />
        </Flex>
      }
      {result && <SendResult />}
    </Dialog>
  )
}



export function SendEmailButton (
  {
    email,
    claimId,
    claimType = "claim",
    ...props
  }: {
    email: string
    claimId: string
    [key: string]: any
  },
) {
  const [emailSendDialogOpen, setEmailSendDialogOpen] = useState(false)
  const [template, setTemplate] = useState("")
  const [templateError, setTemplateError] = useState(null)
  const [sending, setSending] = useState(false)
  const [result, setResult] = useState<SendEmailTemplateResult | null >(null)
  const [templates, setTemplates] = useState<Template[]>([])
  const [fetchTemplate, setFetchTemplate] = useState(true)

  useEffect(() => {
    async function getTemplate () {
      const data = await getTemplates("email")
      setTemplates(data?.data ?? [])
      setFetchTemplate(false)
    }

    if (fetchTemplate) {
      getTemplate()
    }
  }, [fetchTemplate])

  async function handleSendEmail (
    templateName: string,
    setSending: (a: unknown) => unknown,
    setTemplateError: (a: unknown) => unknown,
    setResult: (a: unknown) => unknown,
  ) {
    if (!templateName) {
      return setTemplateError("You must select a template to send")
    }
    setSending(true)
    try {
      const selectedTemplate: Template | undefined =
        templates.find((template: Template) => template.name === templateName)

      if (!selectedTemplate) {
        throw new Error(`Could not find template ${templateName}`)
      }

      console.info("-> : selectedTemplate", selectedTemplate)

      console.info(`sending ${templateName} to ${email}`)
      const result = await sendEmailTemplate({
        claimIds: [claimId],
        templateName: selectedTemplate.name ?? "",
        templatePathname: selectedTemplate.pathname ?? "",
        attachments: selectedTemplate.attachments ?? [],
        claimType: claimType,
      })
      console.info(`send result: ${JSON.stringify(result, null, 2)}`)
      setSending(false)
      setResult(result?.data[0])
    }
    catch (e) {
      window.alert(`
        problem occurred trying to commit send operation
        ${e}
        `)
    }
  }

  function handleCloseDialog () {
    setTemplate("")
    setTemplateError(null)
    setSending(false)
    setResult(null)

    setEmailSendDialogOpen(false)
  }

  function handleOpenDialog () {
    setEmailSendDialogOpen(true)
  }

  if (!email) {
    return (
      <Button disabled variant='outlined' color='primary'>
        <EmailIcon />
        <Hidden mdDown>&nbsp;Send Email</Hidden>
      </Button>
    )
  }

  return (
    <>
      <EmailSendDialog
        handleClose={handleCloseDialog}
        open={emailSendDialogOpen}
        handleSendEmail={handleSendEmail}
        template={template}
        sending={sending}
        email={email}
        templateError={templateError}
        setTemplate={setTemplate}
        setTemplateError={setTemplateError}
        setSending={setSending}
        setResult={setResult}
        result={result}
        claimId={claimId}
      />

      <Button
        {...props}
        variant='outlined'
        color='primary'
        onClick={handleOpenDialog}
      >
        <EmailIcon />
        <Hidden mdDown>&nbsp;Send Email</Hidden>
      </Button>
    </>
  )
}
