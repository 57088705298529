import FirebaseTimestamp from "./FirebaseTimestamp"

const trackedPagesFields = {
  id: String,
  page: String,
  totalVisits: Number,
  createdAtDate: String,
  createdAt: FirebaseTimestamp,
  _createdAt: Date,
  lastVisitedAtDate: String,
  lastVisitedAt: FirebaseTimestamp,
  _lastVisitedAt: Date,
}

export default trackedPagesFields
