import FirebaseTimestamp from "./FirebaseTimestamp"

const templateFields = {
  id: String,
  type: String,
  state: String,
  userDefinedName: String,
  locale: String,
  provider: String,
  name: String,
  sid: String,
  ignoreMaximumWhatsAppsPerClaimRule: { type: Boolean, default: false },
  customDurationToBlockTemplate: Number,
  placeholders: Array,
  attachment_ids: Array,
  mergeLanguage: String,
  pathname: String,
  createdAt: FirebaseTimestamp,
  _createdAt: Date,
  updatedAt: FirebaseTimestamp,
  _updatedAt: Date,
  recipient: String,
}

export default templateFields
