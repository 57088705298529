import {TextInput} from "react-admin"
import React from "react"

function JsonInput (props: any) {
  return (
    <TextInput
      {...props}
      format={(input: any) => {
        const stringified = JSON.stringify(input, null, 2)
        return stringified === "{}" ? "" : stringified
      }}
      parse={(input: string) => {
        if (input === "") {
          return {}
        }
        try {
          return JSON.parse(input)
        }
        catch (e) {
          return
        }
      }}
      validate={(input) => {
        return typeof input === "object" ? undefined : "Invalid JSON"
      }}
    />
  )
}
export default JsonInput
