import React, { useState } from "react"

import { Link } from "react-router-dom"
import {
  Button as ReactAdminButton,
  useNotify,
} from "react-admin"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import SendIcon from "@material-ui/icons/Send"
import getHttpsCallable from "../../utils/getHttpsCallable"
import {db, storage} from "../../firebase"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import IconCancel from "@material-ui/icons/Cancel"
import {Dialog} from "@material-ui/core"
import {get} from "lodash"

const transmitClaim = getHttpsCallable("admin-transmitClaim")
const createCase = getHttpsCallable("admin-cases-createCase")

async function hasDifferentOperator (
  claimId: string,
): Promise<boolean> {
  const claims = await db
    .collectionGroup("claims")
    .where("id", "==", claimId)
    .get()

  if (!claims.size || !claims.docs[0]) {
    console.error(`Claim ${claimId} not found`)
    return false
  }

  const claim = claims.docs[0].data() as Partial<Claim>

  if (!claim?.customFieldValues?.providers?.value) {
    return false
  }

  const company = claim.customFieldValues.providers.value[0]?.company

  for (const index in claim.customFieldValues.providers.value) {
    if (company === claim.customFieldValues.providers.value[index]?.company) {
      continue
    }

    return true
  }

  return false
}


export default function SendToLawyersButton (
  { claimData, ...props }:
  {
    claimData: {
      id: string
      userId: string
      lawFirm: string
      type: string
      ocOlgNotSuitable: boolean
      funder: string
      transmittedToLawfirmAt: FirebaseAdminTimestamp
    }
    onClick?: () => void,
    component?: React.ElementType,
    [key: string]: any,
  },
) {
  if (!claimData) {
    return null
  }

  const notify = useNotify()

  const [isSending, setIsSending] = useState<boolean>(false)
  const [promptConfirmation, setPromptConfirmation] = useState<boolean>(false)

  if (claimData.type === "online-casino") {
    props.onClick = async () => {
      setIsSending(true)

      // Online casino claims must only be transmitted when
      // transaction list and power of attorney signature are available
      const storageRef = storage.ref()
      const dirsListResult = await storageRef
        .child(claimData.userId)
        .child("claimFiles")
        .child(claimData.id)
        .listAll()

      const dirs = dirsListResult.prefixes.map(dirRef => dirRef.name)

      if (claimData.funder === "Munster" && claimData.ocOlgNotSuitable) {
        notify("The claim OLG is not suitable for 'Munster' funder.", "warning")
      }
      else if (
        !dirs.includes("ocTransactionList") ||
        !dirs.includes("ocPoaSignature")
      ) {
        notify(
          "Please upload a transaction list file " +
            "and power of attorney signature first!",
          "error",
        )
      }
      else if (await hasDifferentOperator(claimData.id) &&
        !promptConfirmation) {
        setPromptConfirmation(true)
      }
      else {
        setPromptConfirmation(false)

        try {
          await transmitClaim({claimId: claimData.id})

          notify(
            "The claim was successfully submitted.",
            { type: "info", autoHideDuration: 10e3, multiLine: true },
          )
        }
        catch (error: any) {
          notify(error?.message, "warning")
        }
      }

      setIsSending(false)
    }
  }
  else if (claimData.type === "diesel" && claimData.lawFirm === "LEO") {
    props.onClick = async () => {
      setIsSending(true)
      setPromptConfirmation(false)

      try {
        const result = await createCase({claimId: claimData.id})
        console.info("-> : ClaimSelect -> result", result)
        const internalId = get(result, "data.case.id")
        notify(`Case created with id "${internalId}"`, "success")
      }
      catch (error: any) {
        notify(error?.message, "warning")
      }

      setIsSending(false)
    }
  }
  else {
    const casesLink = claimData.transmittedToLawfirmAt
      ? `/cases/${claimData.lawFirm?.toLowerCase()}Case_${claimData.id}/`
      : `/cases/create?claimId=${claimData.id}`

    props.component = React.forwardRef((linkProps, ref) => (
      <Link
        ref={ref}
        to={casesLink}
        {...linkProps}
      />
    ))
  }

  return (
    <>
      <Dialog
        fullWidth
        open={promptConfirmation}
      >
        <DialogTitle>
          This claim has more than one casino operator. Do you want to proceed?
        </DialogTitle>
        <DialogActions>
          <ReactAdminButton
            label="Transmit claim"
            key='button'
            onClick={props.onClick}
          >
            <SendIcon />
          </ReactAdminButton>
          <ReactAdminButton
            onClick={() => setPromptConfirmation(false)}
            label='ra.action.cancel'
          >
            <IconCancel />
          </ReactAdminButton>
        </DialogActions>
      </Dialog>
      <Button
        {...props}
        variant="outlined"
        color="primary"
        disabled={!claimData || !claimData.id}
      >
        { isSending ||
          <>
            <SendIcon />
            <Hidden mdDown>
              &nbsp;
              {`Transmit to ${claimData.lawFirm}`}
            </Hidden>
          </>
        }
        { isSending && <>⏳</>}
      </Button>
    </>
  )
}
