import {useState, PropsWithChildren} from "react"
import {Button, Link} from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import { doesConditionsApply } from "../utils/conditionsCheck"

type PromptProps = {
  successAction: () => unknown,
  failureAction: () => unknown,
  claimId: string | undefined,
  selectedTemplate: string,
  templates: Template[],
}


function EmailWsPrompt (props: PropsWithChildren<PromptProps>) {
  const [open, setOpen] = useState(false)

  const [body, setBody] = useState("")

  async function handleClickOpen () {
    const currentTemplate = 
      props.templates.find((template: Template) => 
        template.name === props.selectedTemplate)

    if (!currentTemplate) return

    if (!currentTemplate.enablePrompt) {
      return props.successAction()
    }

    const conditionApplies =
      await doesConditionsApply(currentTemplate.conditions, props.claimId || "")

    if (!conditionApplies) {
      return props.successAction()
    }

    setBody(currentTemplate.promptBody || "")
    setOpen(true)
  }

  function handleClickClose () {
    props.failureAction()
    setOpen(false)
  }

  const claimId = props.claimId || ""

  return (
    <div>
      <Button onClick={handleClickOpen}>
        {props.children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClickClose}
      >
        <DialogTitle>
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Link href={`/claims/${claimId}/show`}>
              {claimId}
            </Link>
            <p>
              {body}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={handleClickClose}
          >
            Abort
          </Button>
          <Button
            onClick={() => props.successAction()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EmailWsPrompt
