import React from "react"
import {
  ArrayInput,
  BooleanInput,
  Button,
  Edit,
  FormTab,
  FunctionField,
  NumberInput,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
  useEditContext,
  useRecordContext,
  SimpleFormIterator,
  FormDataConsumer,
  SelectArrayInput,
  DateInput,
  regex,
} from "react-admin"
import { makeStyles } from "@material-ui/core"
import ClaimDeleteBar from "../ClaimDeleteBar"
import TicketPanel from "../Claim/TicketPanel"
import CasinoFieldValuesList from "../CasinoFieldValuesList"
import INSURANCE_OPTIONS from "../../lib/constants/insurance-providers"
import { object } from "prop-types"
import casinodata from "../../constants/casinos.json"
import estimatedLosses from "../../constants/estimated-losses.json"
import { db } from "../../firebase"
import useCustomFields from "../../hooks/useCustomFields"
import CustomFieldFormInput from "../CustomFields/CustomFieldFormInput"
import { LawFirmOptions } from "../../constants/lawFirms"

const useStyles = makeStyles(theme => ({
  lastField: {
    marginBottom: theme.spacing(3),
  },
}))

const insuranceChoices = INSURANCE_OPTIONS
  .map(opt => ({ id: opt.value, name: opt.label }))

const OPTS = {
  YES: "yes",
  NO: "no",
  DONTKNOW: "dont-know",
}

const completeEstimatedLosses = [
  { id: "< 4000", name: "< 4000 €" },
  {id: "< 10000", name: "< 10 000 €"},
  {id: "< 30000", name: "< 30 000 €"},
  {id: "< 100000", name: "< 100 000 €"},
  {id: "> 100000", name: "> 100 000 €"},
  ...estimatedLosses.map((v: Record<string, any>) =>
    ({ id: v.value, name: v.label })),
]

const yesNoUnsureChoices = [
  { id: OPTS.YES, name: OPTS.YES },
  { id: OPTS.NO, name: OPTS.NO },
  { id: OPTS.DONTKNOW, name: OPTS.DONTKNOW },
]

const yesNoChoices = [
  { id: OPTS.YES, name: OPTS.YES },
  { id: OPTS.NO, name: OPTS.NO },
]

const yesNoNullChoices = [
  { id: null, name: "NULL" },
  ...yesNoChoices,
]
const currentYear = new Date().getFullYear()
const registrationDates = [{ id: "", name: "" } ]

for (let year = currentYear; year >= 1996; year--) {
  registrationDates.push({ id: String(year), name: String(year) })
}

function Aside ({ record }: { record?: Claim }) {
  if (!record) {
    return null
  }

  return (
    <TicketPanel
      userId={record.userId}
      claimId={record.id}
      claim={record}
      type="online-casino"
    />
  )
}

const dateFieldOpts = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
}

function renderCustomField ([key, valObj]: [string, { value: any }]) {
  const valPath = "customFieldValues." + key + ".value"

  if (key === "typeOfGames") return

  if (key === "onlySportBet") {
    return <>
      <BooleanInput
        label={"Sportbetting only"}
        source={valPath}
        style={{display: "block"}}
      />
    </>
  }

  if (typeof valObj?.value === "number") {
    return <>
      <NumberInput label={key} source={valPath} />
      <br/>
    </>
  }

  if (typeof valObj?.value === "boolean") {
    return <>
      <BooleanInput label={key} source={valPath} />
      <br/>
    </>
  }

  if (
    key === "playerRegistrationDate" ||
    (typeof valObj.value === "object" &&
    valObj.value?._seconds) ||
    (typeof valObj.value === "string" &&
    valObj.value.match(/^\d{4}-\d{1,2}-\d{1,2}/))
  ) {
    return (
      <>
        <DateInput
          style={{ width: 200 }}
          label={key}
          source={valPath}
          locales="de-DE"
          format={(value: any) => {
            let date: any
            if (!value) return ""
            if (typeof value === "object" && value._seconds) {
              date = value._seconds * 1000
            }
            else {
              date = value
            }
            return new Date(date).toISOString().split("T")[0]
          }} />
        <br/>
      </>
    )
  }

  if (key === "calculatedLosses") {
    return (
      <>
        <TextInput
          label={key}
          source={valPath}
          multiline
          format={(val: any) => JSON.stringify(val, null, 2)}
          parse={(val: string) => JSON.parse(val)}
          defaultValue={!valObj ? null : valObj}
          disabled={true}
        />
        <br/>
      </>
    )
  }

  if (key === "providers") {
    const AderesCasinoOptions =
      [{ id: "Anderes Casino", name: "Anderes Casino" }]
    const nameOptions =
      AderesCasinoOptions.concat(
        casinodata.map((v: Record<string, any>) =>
          ({ id: v["Name des Casinos"], name: v["Name des Casinos"] }),
        ),
      )
    const providersOptions = completeEstimatedLosses
    const validateCurrencyFormat =
      regex(/(:?^[0-9]*$|^[0-9]{1,10}([0-9]{3})*(([\\,]{1}[0-9]{2})|())$)/,
        "Currency format is not valid")

    const validateCurrencyFormatWithNegative = 
      regex(/(?:^-?[0-9]*$|^-?[0-9]{1,10}([0-9]{3})*(([\\,]{1}[0-9]{2})|())$)/,
        "Currency format is not valid")

    return (
      <ArrayInput source={valPath} label="Custom Field Providers">
        <SimpleFormIterator disableReordering>
          <FormDataConsumer>
            {({ getSource = () => {} }) => {
              return (
                <div>
                  <SelectInput
                    label={"Name"}
                    source={getSource("name") || ""}
                    initialValue={nameOptions[0].name}
                    choices={nameOptions}
                    onChange={e => {
                      const matchIndex = e.target.name.match(/\[(.*?)\]/)
                      const value = e.target.value

                      if (matchIndex) {
                        const index = matchIndex[1]
                        const selectedCasino =
                          casinodata.find(o => o["Name des Casinos"] === value)
                        const companyName = selectedCasino?.Betreiber

                        if (!companyName) {
                          return
                        }

                        if (valObj.value[index] === undefined) {
                          valObj.value[index] = {company: companyName}
                        }
                        else {
                          valObj.value[index].company = companyName
                        }
                      }
                    }}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <SelectInput
                    label={"Estimated Losses"}
                    source={getSource("estimatedLosses") || ""}
                    initialValue={providersOptions[0].name}
                    choices={providersOptions}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Unknown Casino Name"}
                    source={getSource("unknownCasinoName") || ""}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <SelectInput
                    style={{
                      width: 200,
                      marginRight: "1rem",
                    }}
                    label="Player Registration Date"
                    source={getSource("playerRegistrationDate") || ""}
                    choices={registrationDates}
                  />

                  <SelectArrayInput
                    style={{
                      width: 200,
                      marginRight: "1rem",
                    }}
                    label="Type of Games"
                    source={getSource("typeOfGames") || ""}
                    choices={[
                      { id: "Casino", name: "Casino" },
                      { id: "Live Sport Betting", name: "Live Sport Betting" },
                      { id: "Lotto", name: "Lotto" },
                      { id: "Poker", name: "Poker" },
                      { id: "Roulette", name: "Roulette" },
                      { id: "Slots", name: "Slots" },
                      { id: "Sport Betting", name: "Sport Betting" },
                    ]}
                  />

                  <TextInput
                    label={"Company"}
                    source={getSource("company") || ""}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Deposit (Sum)"}
                    source={getSource("deposit") || ""}
                    validate={validateCurrencyFormat}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Payout (Sum)"}
                    source={getSource("payout") || ""}
                    validate={validateCurrencyFormat}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Calculated losses (Sum)"}
                    source={getSource("calculatedLosses") || ""}
                    validate={validateCurrencyFormat}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Conversion Rate"}
                    source={getSource("conversionRate") || ""}
                    validate={validateCurrencyFormat}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <DateInput
                    source={getSource("firstDepositAt") || ""}
                    label="First Deposit At"
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Loss USD"}
                    source={getSource("loss_USD") || ""}
                    validate={validateCurrencyFormat}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Earnings"}
                    source={getSource("earnings") || ""}
                    validate={validateCurrencyFormat}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"TL Password"}
                    source={getSource("tlPassword") || ""}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Casino Losses"}
                    source={getSource("lossCasino") || ""}
                    validate={validateCurrencyFormatWithNegative}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Sportbetting Losses"}
                    source={getSource("lossSportBet") || ""}
                    validate={validateCurrencyFormatWithNegative}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Currency"}
                    source={getSource("currency") || ""}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <DateInput
                    source={getSource("ocGamblingStartDate") || ""}
                    label="Gambling Start Date"
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <DateInput
                    source={getSource("ocGamblingEndDate") || ""}
                    label="Gambling End Date"
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Limit Excess Losses"}
                    source={getSource("ocLimitExcessLosses") || ""}
                    validate={validateCurrencyFormat}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <DateInput
                    source={getSource("ocLicenseDate") || ""}
                    label="License Date"
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <BooleanInput
                    label={"hasDifferentCurrency"}
                    source={getSource("hasDifferentCurrency") || ""}
                    style={{display: "block"}}
                  />

                  <SelectInput
                    style={{
                      width: 200,
                      marginRight: "1rem",
                    }}
                    allowEmpty={true}
                    label="Has transactions after 2018"
                    source={getSource("hasTransactionafter2018") || ""}
                    choices={[
                      { id: "yes", name: "Yes" },
                      { id: "no", name: "No" },
                    ]}
                  />

                  <BooleanInput
                    source={getSource("operatorRefusesTransactionList") || ""}
                    label="Operator Refuses Transaction List"
                  />

                  <TextInput
                    label={"Calculated losses total"}
                    source={getSource("calculatedLossesTotal") || ""}
                    validate={validateCurrencyFormat}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <BooleanInput
                    source={getSource("limitExcess") || ""}
                    label="Limit Excess"
                  />

                  <DateInput
                    source={getSource("limitExcessMonth") || ""}
                    label="Limit Excess Month"
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Gambling Duration in Month"}
                    source={getSource("gamblingDurationInMonth") || ""}
                    validate={validateCurrencyFormat}
                    disabled={true}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Gambling Duration in Month License"}
                    source={getSource("gamblingDurationInMonthLicense") || ""}
                    validate={validateCurrencyFormat}
                    disabled={true}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Average Loss"}
                    source={getSource("averageLoss") || ""}
                    validate={validateCurrencyFormat}
                    disabled={true}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <TextInput
                    label={"Average Loss License"}
                    source={getSource("averageLossLicense") || ""}
                    validate={validateCurrencyFormat}
                    disabled={true}
                    style={{
                      marginRight: "1rem",
                    }}
                  />

                  <CustomFieldFormInput getSource={getSource} />
                </div>
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    )
  }

  return (
    <>
      <TextInput
        label={key}
        source={valPath}
        multiline
        format={(val: any) => JSON.stringify(val, null, 2)}
        parse={(val: string) => JSON.parse(val)}
        defaultValue={""}
      />
      <br/>
    </>
  )
}

function renderUtmParamsField (obj: any) {
  return (
    <ArrayInput source={"utmParams"} label="UTM parameters">
      <SimpleFormIterator disableReordering>
        <FormDataConsumer>
          {({ getSource = () => {} }) => {
            return (
              <div>
                <TextInput
                  label="Source"
                  source={getSource("source") || ""}
                  style={{
                    marginRight: "1rem",
                  }}
                />
                <TextInput
                  label="Value"
                  source={getSource("value") || ""}
                  style={{
                    marginRight: "1rem",
                  }}
                />
              </div>
            )
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}


function valueToBoolean (value: any) {
  if (typeof value === "string") {
    value = value.toLowerCase()
    if (value === "true" || value === "1" || value === "yes") {
      return true
    }
    if (value === "false" || value === "0" || value === "no") {
      return false
    }
  }

  if (value === true || value === 1) {
    return true
  }
  if (value === false || value === 0) {
    return false
  }
  else {
    throw new Error(
      `Value "${value}" can not be coerced to boolean`,
    )
  }
}

const funders = [
  { id: "Augusta Pool 3 (OC)", name: "Augusta Pool 3 (OC)" },
  { id: "GH PF", name: "GH PF" },
  {
    id: "Placeholder new Facility",
    name: "Placeholder new Facility",
  },
  { id: "Claimback", name: "Claimback" },
  { id: "Munster", name: "Munster" },
  { id: "Deminor", name: "Deminor" },
  { id: "Enity Capital (OC)", name: "Enity Capital (OC)" },
  { id: "Munster/Profin", name: "Munster/Profin" },
  { id: "AdvoFin", name: "AdvoFin"},
  { id: "Padronus", name: "Padronus"},
  { id: "EAS", name: "EAS"},
]

export default function OnlineCasinoClaimEdit (
  props: Record<string, unknown>,
) {
  const classes = useStyles()

  return (
    <Edit
      {...props}
      aside={<Aside />}
      transform={data => {
        data.customFieldValues = data.customFieldValues || {}

        data.additionalFields?.forEach(
          (field: { id: string, value: any, datatype: string }) => {
            if (field.value === null || typeof field.value === "undefined") {
              return
            }

            let value: any
            if (field.datatype === "number") {
              value = Number(field.value)
            }
            else if (field.datatype === "boolean") {
              value = valueToBoolean(field.value)
            }
            else {
              value = field.value
            }
            data.customFieldValues[field.id] = { value }
          },
        )

        for (const key in data.customFieldValues) {
          if (data.customFieldValues[key]._value) {
            const dateObject = data.customFieldValues[key]
            let date: string
            if (dateObject.value._seconds) {
              date = new Date(dateObject.value._seconds * 1000)
                .toISOString()
                .split("T")[0]
            }
            else {
              date = dateObject.value
            }
            data.customFieldValues[key] = {
              value: date,
            }
          }
        }

        delete data.additionalFields

        if (data.poaUploaded && !data.poaUploadedAt) {
          data.poaUploadedAt = new Date()
        }

        if (!data.poaUploaded) {
          data.poaUploadedAt = undefined
        }

        if (data.tlRequested && !data.tlRequestedAt) {
          data.tlRequestedAt = new Date()
        }

        if (!data.tlRequested) {
          data.tlRequestedAt = undefined
        }

        if (data.transmittedToLawfirmAt === undefined) {
          data.transmittedToLawfirmAt = null
          data._transmittedToLawfirmAt = null
        }

        if (data.tlRequestedAt === undefined) {
          data.tlRequestedAt = null
          data._tlRequestedAt = null
        }

        if (data.poaUploadedAt === undefined) {
          data.poaUploadedAt = null
          data._poaUploadedAt = null
        }

        return data
      }}
    >
      <TabbedForm
        redirect="show"
        sanitizeEmptyValues={false}
        toolbar={<ClaimDeleteBar path="online-casino-claims"/>}
      >
        <FormTab label="Summary">
          <BooleanInput
            source="lead"
            label="Lead"
          />
          <BooleanInput
            source="ocOlgNotSuitable"
            label="OLG not suitable"
          />
          <SelectInput
            source="lawFirm"
            allowEmpty={true}
            choices={LawFirmOptions}
            parse={(v: string) => v === "" ? null : v}
            emptyValue={""}
            emptyText="No law firm"
          />
          <SelectInput
            source="type"
            choices={[
              { id: "rejects", name: "Rejects" },
              { id: "online-casino", name: "Online Casino" },
            ]}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <SelectInput
                source="funder"
                label="Funder (For CMT)"
                allowEmpty={true}
                choices={
                  formData.ocOlgNotSuitable
                    ? funders.filter(v => v.id !== "Munster")
                    : funders
                }
                parse={(v: string) => v === "" ? null : v}
                emptyValue={""}
                emptyText="Set no funder"
              />
            )}
          </FormDataConsumer>
          
          <TextInput source="leadSource" />
          <SelectInput
            source="claimActionStatus"
            choices={[
              { id: "active", name: "Active" },
              { id: "dead", name: "Dead" },
              { id: "below5k", name: "Below 5k"},
              { id: "onHold", name: "On Hold" },
              { id: "finished", name: "Finished" },
            ]}
          />
          <TextInput source="userId" />
          <TextInput source="notes" />
          <BooleanInput
            source="ocTipicoBigList"
            label="OC Tipico Big List"
          />
          <BooleanInput
            source="hasMultipleClaims"
            label="Has Multiple Claims"
          />
          <BooleanInput
            source="tlUploaded"
            label="Transaction List Uploaded"
          />
          <BooleanInput
            source="accInfoUploaded"
            label="Account Information Uploaded"
          />
          <BooleanInput
            source="poaUploaded"
            label="POA Uploaded"
          />
          <DateInput source="poaUploadedAt"
            label="POA Uploaded At" />
          <BooleanInput
            source="tlRequested"
            label="Transaction List Requested"
          />
          <DateInput source="tlRequestedAt"
            label="Transaction List Requested At" />
          <DateInput source="transmittedToLawfirmAt" />
          <DateInput
            label="Last updated claim in CMT at"
            source="lastUpdateInCmtAt"
          />
          <FunctionField
            label="UTM Parameters"
            source="utmParams"
            render={(obj: any) => {
              return <>
                <table>
                  {renderUtmParamsField(obj)}
                </table>
              </>
            }}
          />
        </FormTab>
        <FormTab label="Custom Fields">
          <FunctionField
            label="Custom Field Values"
            source="customFieldValues"
            render={(obj: any) => {
              if (!obj?.customFieldValues?.onlySportBet) {
                obj.customFieldValues.onlySportBet = {
                  value: false,
                }
              }

              if (!obj?.customFieldValues) {
                return 
              }

              if (obj.transmittedToLawfirmAt) {
                return <>
                  <table>
                    {Object
                      .entries(
                      obj.customFieldValues as
                        Record<string, { value: any }>,
                      )
                      .filter((entries) => entries[0] !== "providersLawFirm" &&
                       entries[0] !== "location" && entries[0] !== "providers")
                      .map(renderCustomField)
                    }
                  </table>
                  <CasinoFieldValuesList 
                    record={obj} 
                    hideAdditionalFields={true}/>
                </>
                
              }
              return <>
                <table>
                  {Object
                    .entries(
                      obj.customFieldValues as
                        Record<string, { value: any }>,
                    )
                    .filter((entries) => entries[0] !== "providersLawFirm")
                    .map(renderCustomField)
                  }
                </table>
              </>
            }}
          />

          <ArrayInput source="additionalFields">
            <SimpleFormIterator>
              <TextInput label="ID" source="id" />
              <SelectInput
                label="Datatype"
                source="datatype"
                initialValue="text"
                choices={[
                  { id: "number", name: "Number" },
                  { id: "text", name: "Text" },
                  { id: "boolean", name: "Boolean" },
                  { id: "date", name: "Date" },
                ]}
              />
              <FormDataConsumer>
                {({ scopedFormData, getSource = () => "" }) => {
                  if (scopedFormData && scopedFormData?.datatype === "date") {
                    return (
                      <DateInput
                        label="Value"
                        source={getSource("value")}
                        locales="sv-SE" />
                    )
                  }
                  return <TextInput label="Value" source={getSource("value")} />
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>

        </FormTab>
        <FormTab label="Contact Information">
          <TextInput
            label="Claim First Name"
            source="firstName"
          />
          <TextInput
            label="Claim Last Name"
            source="lastName"
          />
          <TextInput
            label="Contract First Name"
            source="purchaseContractFirstName"
          />
          <TextInput
            label="Contract Last Name"
            source="purchaseContractLastName"
          />
          <TextInput
            label="Contract Company"
            source="purchaseContractCompanyName"
          />
          <TextInput source="address" />
          <TextInput source="city" />
          <TextInput source="postcode" />
          <TextInput source="phoneNumber" />
          <SelectInput
            source="whatsappContactedNumber"
            choices={[
              { id: null, name: "None" },
              { id: "0", name: "WA 0 Carl  442" },
              { id: "1", name: "WA 1 Follow Up  975" },
              { id: "2", name: "WA 2 Follow Up 982" },
            ]}
          />
          <TextInput
            label="IBAN"
            source="iban"
            className={classes.lastField}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
