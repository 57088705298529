import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  Filter,
  List,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin"
import DefaultActions from "../DefaultActions"
import DefaultPagination from "../DefaultPagination"
import TimestampField from "../TimestampField"
import insuranceOptions from "./insurance"
import brands from "./brand"

export function Deckungsschutzklage ({ ...props }) {
  return (
    <List
      // filter={{
      //   formSubmittedAt__exists: false,
      // }}
      sort={{ field: "createdAt", order: "DESC" }}
      {...props}
      actions={<DefaultActions />}
      perPage={15}
      pagination={<DefaultPagination />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="email" sortable={false} />
        <DateField source="createdAt" label="Created at" sortable={false} />
        <TextField source="updatedBy" label="Updated By" sortable={false} />
        <ArrayField label="Insurances" source="insurances" sortable={false}>
          <SingleFieldList>
            <ChipField source="insurance.label" clickable={false} />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  )
}

export function DeckungsschutzklageShow (props: Record<string, unknown>) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TimestampField
          label="Created At"
          source="createdAt"
          locales="sv-SE"
          showTime
          addLabel
        />

        <TextField source="email" label="Email" />
        <TextField source="updatedBy" label="Updated By" />

        <ArrayField label="Insurances" source="insurances">
          <Datagrid>
            <TextField source="insurance.label" label="Insurance" />
            <TextField source="carBrand.label" label="Car Brand" />
            <TextField
              source="coverageRejection"
              label="Coverage Rejection Count"
            />
            <TextField source="averageDispute" label="Average Dispute" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  )
}

export function DeckungsschutzklageEdit (props: Record<string, unknown>) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="email" label="Email" />
        <ArrayInput label="Insurances" source="insurances">
          <SimpleFormIterator>
            <SelectInput
              source="insurance"
              label="Insurance"
              choices={insuranceOptions.map((x) => ({
                name: x.label,
                id: x.value,
              }))}
              format={(c: any) => c?.value}
              parse={(name: any) =>
                insuranceOptions.find((c) => c.value === name)
              }
            />
            <SelectInput
              source="carBrand"
              label="Car Brand"
              choices={brands.map((x) => ({
                name: x.label,
                id: x.value,
              }))}
              format={(c: any) => c?.value}
              parse={(name: any) => brands.find((c) => c.value === name)}
            />
            <TextInput
              source="coverageRejection"
              label="Coverage Rejection Count"
            />
            <TextInput source="averageDispute" label="Average Dispute" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export function DeckungsschutzklageCreate ({ ...props }) {
  return (
    <Create {...props}>
      <SimpleForm>
        <DateInput
          source="createdAt"
          locales="sv-SE"
          defaultValue={new Date()}
          hidden
          disabled
        />
        <TextInput source="email" label="Email" />
        <ArrayInput label="Insurances" source="insurances">
          <SimpleFormIterator>
            <SelectInput
              source="insurance"
              label="Insurance"
              choices={insuranceOptions.map((x) => ({
                name: x.label,
                id: x.value,
              }))}
              format={(c: any) => c?.name}
              parse={(name: any) =>
                insuranceOptions.find((c) => c.value === name)
              }
            />
            <SelectInput
              source="carBrand"
              label="Car Brand"
              choices={brands.map((x) => ({
                name: x.label,
                id: x.value,
              }))}
              format={(c: any) => c?.name}
              parse={(name: any) => brands.find((c) => c.value === name)}
            />
            <TextInput
              source="coverageRejection"
              label="Coverage Rejection Count"
            />
            <TextInput source="averageDispute" label="Average Dispute" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  )
}
