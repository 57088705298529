import { Button, Tooltip, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import { Flex } from "../../../lib"
// import { ZendeskIcon } from '../../lib/icons'
import { get } from "lodash"
import React, { useEffect, useState } from "react"
import { useNotify } from "react-admin"


const useStyles = makeStyles(theme => ({
  zendeskLinkButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}))


export async function api (path: string, options?: Record<string, unknown>) {
  if (localStorage.getItem("zauth")) {
    const token = localStorage.getItem("zauth")
    const result = await fetch(
      `https://claimback.zendesk.com/api/v2/${path}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        ...options,
      },
    )
    const json = await result.json()
    return json
  }
  else {
    startAuthFlow()
  }
}


async function createZendeskTicket (
  { requesterId, claimId }: { requesterId: string, claimId: string },
) {
  const result = await api("tickets.json", {
    method: "POST",
    body: JSON.stringify({
      ticket: {
        requester_id: requesterId,
        comment: { body: `Ihre Claimback Fall ID: ${claimId}`, public: false },
        custom_fields: [{ id: 360005022257, value: claimId }],
      },
    }),
  })
  return result
}


async function getRequester (claim: Claim) {
  const email = get(claim, "userEmail")
  const firstName = get(claim, "firstName", "") || ""
  const lastName = get(claim, "lastName", "") || ""
  let name = `${firstName} ${lastName}`

  if (!name.trim()) {
    name = email
  }

  if (!email) {
    throw new Error("Cannot create a zendesk user without an email")
  }

  const searchResults = await api(`users/search.json?query=email:${email}`)
  if (searchResults.count > 0) {
    // use this user
    const requester = get(searchResults, "users[0]")
    return requester
  }
  else {
    // create one and use that
    const result = await api("users.json", {
      method: "post",
      body: JSON.stringify({
        user: { name, email },
      }),
    })
    return result.user
  }
}


export function startAuthFlow () {
  localStorage.setItem("zendesk_oauth_complete_url", window.location.href)

  const identifier = "claimback"
  const redirectUri = encodeURIComponent(`${window.origin}/zendesk_oauth`)
  const scope = encodeURIComponent("read write")

  const endpoint = "https://claimback.zendesk.com/oauth/authorizations/new"
  const urlParams = `?response_type=token&redirect_uri=${redirectUri
  }&client_id=${identifier}&scope=${scope}`
  // @ts-expect-error
  window.location = (endpoint + urlParams) as Location
}


interface ZendeskPanelParams {
  claim: Claim;
  claimId: string;
  [x: string]: any;
}

export interface ZendeskTicket {
  id: string
  subject: string
  description: string
  created_at: string
}

export default function ZendeskPanel (
  { claim, claimId, ...props }: ZendeskPanelParams,
) {
  const classes = useStyles()
  const notify = useNotify()
  const [relatedTickets, setRelatedTickets] = useState<ZendeskTicket[]>([])

  const zendeskAuthed = localStorage.getItem("zauth")

  useEffect(() => {
    if (zendeskAuthed) {
      api(
        `search.json?query=type:ticket custom_field_360005022257:"${claimId}"`,
      )
        .then(result => {
          if (result.error) {
            return notify(`${result.error} ${result.description}`, "warning")
          }
          setRelatedTickets(result.results)
        })
        .catch(err => notify(err.message, "warning"))
    }
  }, [notify, claimId, api]) // eslint-disable-line

  return (
    <div {...props}>
      <Typography variant='subtitle2'>Zendesk Tickets</Typography>
      <Flex wrap>
        {relatedTickets && relatedTickets.map(zendeskTicket => (
          <Tooltip
            title={zendeskTicket.subject || zendeskTicket.description}
            key={zendeskTicket.id}
          >
            <Button
              href={
                `https://claimback.zendesk.com/agent/tickets/${
                  zendeskTicket.id}`
              }
              target='__blank'
              rel='noreferrer'
              size='small'
              className={classes.zendeskLinkButton}
              // startIcon={<ZendeskIcon />}
            >{zendeskTicket.id}
            </Button>
          </Tooltip>
        ))}
      </Flex>

      {zendeskAuthed
        ? (
          <Flex justify='end'>
            <Tooltip title='Create new Zendesk ticket'>
              <Button
                variant='outlined'
                size='small'
                startIcon={<AddIcon />}
                onClick={async () => {
                  const requester = await getRequester(claim)
                  const zendeskTicket = await createZendeskTicket(
                    {
                      claimId: get(claim, "id"),
                      requesterId: get(requester, "id"),
                    },
                  )
                  const win = window.open(
                    `https://claimback.zendesk.com/agent/tickets/${
                      get(zendeskTicket, "ticket.id")}`,
                    "_blank",
                  )
                  if (win) {
                    win.focus()
                  }
                }}
              >Add
              </Button>
            </Tooltip>
          </Flex>
        )
        : (
          <Flex justify='end'>
            <Button
              variant='outlined'
              size='small'
              // startIcon={<ZendeskIcon />}
              onClick={() => startAuthFlow()}
            >Sign in to Zendesk
            </Button>
          </Flex>
        )}
    </div>
  )
}
