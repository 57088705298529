import {PropsWithChildren, useState} from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import {Button, Link} from "@material-ui/core"
import DialogActions from "@material-ui/core/DialogActions"
import {doesConditionsApply} from "../utils/conditionsCheck"

type MultiEmailWsPromptProps = {
  successAction: () => unknown,
  claimIds: string[],
  selectedTemplate?: string,
  template?: Record<string, any> | undefined,
  sending: boolean,
}

function MultiEmailWsPrompt (
  {
    successAction,
    claimIds,
    selectedTemplate,
    template,
    children,
    sending,
  } : PropsWithChildren<MultiEmailWsPromptProps>) {

  const [open, setOpen] = useState(false)
  const [conditionTrueClaims, setConditionTrueClaims] = useState<string[]>([])

  function handleClickClose () {
    setOpen(false)
  }

  async function handleClickOpen () {
    if (template === undefined) {
      return // template has not been set
    }

    if (template.name !== selectedTemplate || !template.enablePrompt) {
      return successAction()
    }


    // cant use filter because not asynchronous
    const passedClaims = []
    for (const claimId of claimIds) {
      const conditionApplies = await doesConditionsApply(
        template.conditions,
        claimId,
      )

      if (conditionApplies) {
        passedClaims.push(claimId)
      }
    }

    setConditionTrueClaims(passedClaims)

    if (passedClaims.length < 1) {
      return successAction()
    }

    setOpen(true)
  }

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        variant='outlined'
        color='primary'
        disabled={sending}
      >
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClickClose}
      >
        <DialogTitle>
          Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              conditionTrueClaims.map((claimId) => {
                return <div>
                  <Link href={`/claims/${claimId}/show`}>
                    {claimId}
                  </Link>
                </div>
              })
            }
            <p>
              {(template && template.promptBody) || ""}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={handleClickClose}
          >
            Abort
          </Button>
          <Button
            onClick={() => {
              successAction()
              setOpen(false)
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  )
}

export default MultiEmailWsPrompt
